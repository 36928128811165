import { BLOGS, BOOKINGS, GUIDE_PROFILE, GUIDE_SCHEDULE, GUIDE_TOURS, HOME, PARTNERS, PAYMENTS, REVIEWS, SUPPORT_TICKETS, TOURS, USERS } from "./menuItems";

export const SUPER_ADMIN_MENUS = [
    HOME, USERS, BOOKINGS, PARTNERS, TOURS, BLOGS, REVIEWS, SUPPORT_TICKETS, PAYMENTS,
];

export const ADMIN_MENUS = [
    HOME, USERS, BOOKINGS, PARTNERS, TOURS, BLOGS, SUPPORT_TICKETS
];

export const MANAGER_MENUS = [
    HOME, USERS, BOOKINGS, PARTNERS, TOURS, BLOGS, SUPPORT_TICKETS
];

export const PARTNERSHIP_MANAGER_MENUS = [
    HOME, USERS, BOOKINGS, PARTNERS, SUPPORT_TICKETS
];

export const CUSTOMER_RELATIONS_MANAGER_MENUS = [
    HOME, USERS, BOOKINGS, PARTNERS, TOURS, BLOGS, SUPPORT_TICKETS
];

export const SUPPORT_AGENT_MENUS = [
    HOME, USERS, BOOKINGS, PARTNERS, BLOGS, SUPPORT_TICKETS
];

export const HOTEL_MANAGER_MENUS = [
    HOME, USERS, BOOKINGS, PARTNERS, SUPPORT_TICKETS
];

export const TOUR_GUIDE_MENUS = [
    HOME, TOURS, GUIDE_PROFILE, GUIDE_TOURS, GUIDE_SCHEDULE,
];
