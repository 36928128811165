import { combineReducers } from "redux";

import { myProfileReducer } from "./myProfile/myProfile.reducer";
import { usersReducer } from "./users/users.reducer";
import { destinationsReducer } from "./destinations/destinations.reducer";
import { attractionsReducer } from "./attractions/attractions.reducer";
import { activitiesReducer } from "./activities/activities.reducer";
import { eventsReducer } from "./events/events.reducer";
import { hotelsReducer } from "./hotels/hotels.reducer";
import { partnersReducer } from "./partners/partners.reducer";
import { transportReducer } from "./transport/transport.reducer";
import { guidesReducer } from "./guides/guides.reducer";
import { tourPackagesReducer } from "./tourPackages/tourPackages.reducer";
import { notificationsReducer } from "./notifications/notifications.reducer";

const rootReducer = combineReducers({
    myProfile: myProfileReducer,
    users: usersReducer,
    destinations: destinationsReducer,
    attractions: attractionsReducer,
    activities: activitiesReducer,
    events: eventsReducer,
    hotels: hotelsReducer,
    partners: partnersReducer,
    transports: transportReducer,
    guides: guidesReducer,
    tourPackages: tourPackagesReducer,
    newNotificationsCount: notificationsReducer,
});

export default rootReducer;