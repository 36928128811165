import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Empty, Image, Popconfirm, Spin, Tag, notification, Skeleton, Steps, Rate } from 'antd'

import './styles.scss'
import { DEFAULT_THUMBNAIL } from '../../../../../Constants/otherConstsnts';
import { deleteTourInstalmentPlan, deleteTourItinerary, deleteTourPackage, deleteTourPackageExclude, deleteTourPackageHighlight, getAccommodationsByTourPackage, getInstalmentPlansByTourPackage, getItinerariesByTourPackage, getPackageIncludesByTourPackage, getTourPackageById, getTourPackageExcludes, getTourPackageHighlights, getTransportsByTourPackage, removePackageInclude, removeTourAccommodation, removeTourTransport } from '../../../../../../redux/api/tourPackages.api';
import AddItineryItem from './AddItineryItem';
import EditItineryItem from './EditItineryItem';
import AddTourAccommodation from './AddTourAccommodation';
import EditTourAccommodation from './EditTourAccommodation';
import AddTransportOptions from './AddTransportOptions';
import EditTransportOptions from './EditTransportOptions';
import { handleDeleteBlob, TOUR_PACKAGES_BLOB } from '../../../../../azure/blob';
import AddPackageInclude from './AddPackageInclude';
import EditPackageInclude from './EditPackageInclude';
import AddPackageInstalments from './AddPackageInstalments';
import EditPackageInstalments from './EditPackageInstalments';
import useMyProfile from '../../../../../hooks/useMyProfile';
import { ADMIN, SUPER_ADMIN } from '../../../../../Constants/roles';
import AddPackageHighlight from './AddPackageHighlight';
import AddPackageExclude from './AddPackageExclude';


const TourPackage = () => {
    const url = window.location.pathname
    const tourPckageId = url.split('/').pop()
    const [loading, setLoading] = useState(false)
    const [tourPackage, setTourPackage] = useState()
    const navigate = useNavigate()
    const [api, contextHolder] = notification.useNotification();
    const [loadingText, setLoadingText] = useState('Please Wait...')

    const [itinirary, setItinirary] = useState()
    const [loadingItinerary, setLoadingItinerary] = useState(false)
    const [addItineraryVisible, setAddItineraryVisible] = useState(false)
    const [editItineraryVisible, setEditItineraryVisible] = useState(false)
    const [selectedItinerary, setSelectedItinerary] = useState()

    const [highlights, setHighlights] = useState()
    const [loadingHighlights, setLoadingHighlights] = useState(false)
    const [addHighlightVisible, setAddHighlightVisible] = useState(false)

    const [packageExcludes, setPackageExcludes] = useState()
    const [loadingPackageExcludes, setLoadingPackageExcludes] = useState(false)
    const [addPackageExcludesVisible, setAddPackageExcludesVisible] = useState(false)

    const [accommodations, setAccommodations] = useState()
    const [loadingAccommodation, setLoadingAccommodation] = useState(false)
    const [addAccommodationVisible, setAddAccommodationVisible] = useState(false)
    const [editAccommodationVisible, setEditAccommodationVisible] = useState(false)
    const [selectedAccommodation, setSelectedAccommodation] = useState()

    const [transportOptions, setTransportOptions] = useState()
    const [loadingTransportOptions, setLoadingTransportOptions] = useState(false)
    const [addTransportOptionVisible, setAddTransportOptionVisible] = useState(false)
    const [editTransportOptionVisible, setEditTransportOptionVisible] = useState(false)
    const [selectedTransportOption, setSelectedTransportOption] = useState()

    const [packageIncludes, setPackageIncludes] = useState()
    const [loadingPackageIncludes, setLoadingPackageIncludes] = useState(false)
    const [addPackageIncludesVisible, setAddPackageIncludesVisible] = useState(false)
    const [editPackageIncludesVisible, setEditPackageIncludesVisible] = useState(false)
    const [selectedPackageIncludes, setSelectedPackageIncludes] = useState()

    const [packageInstalments, setPackageInstalments] = useState()
    const [loadingPackageInstalments, setLoadingPackageInstalments] = useState(false)
    const [addPackageInstalmentsVisible, setAddPackageInstalmentsVisible] = useState(false)
    const [editPackageInstalmentsVisible, setEditPackageInstalmentsVisible] = useState(false)
    const [selectedPackageInstalments, setSelectedPackageInstalments] = useState()
    const myProfile = useMyProfile().userProfile

    useEffect(() => {
        handleGetTourPackage(tourPckageId)
    }, [])

    const handleGetTourPackage = async (id) => {
        setLoading(true)
        try {
            const pkg = await getTourPackageById(id)
            setTourPackage(pkg.data[0])
        } catch (error) {
            console.error('Error fetching tour pacakge by id', error)
        }
        setLoading(false)
    }

    const handleDeleteItineryItem = async (iti) => {
        try {
            const res = await deleteTourItinerary(iti.id)

            if (res.status === 200) {
                api.success({
                    message: 'Itinery Item deleted successfully',
                    description: 'The itinery item has been successfully deleted'
                })
                handleGetItinirary()
            } else {
                api.error({
                    message: 'Error deleting itinery item',
                    description: 'An error occurred while deleting the itinery item'
                })
            }

        } catch (error) {
            console.error('Error deleting itinery item', error)
            api.error({
                message: 'Error deleting itinery item',
                description: error.message
            })
        }
    }

    const handleDeleteHighlight = async (highlight) => {
        try {
            const res = await deleteTourPackageHighlight(highlight.id)

            if (res.status === 200) {
                api.success({
                    message: 'Highlight deleted successfully',
                    description: 'The highlight has been successfully deleted'
                })
                handleGetHighlights()
            } else {
                api.error({
                    message: 'Error deleting highlight',
                    description: 'An error occurred while deleting the highlight'
                })
            }

        } catch (error) {
            console.error('Error deleting highlight', error)
            api.error({
                message: 'Error deleting highlight',
                description: error.message
            })
        }
    }


    const handleDeleteAccommodation = async (acc) => {
        try {
            const res = await removeTourAccommodation(acc.id)

            if (res.status === 200) {
                api.success({
                    message: 'Accommodation Item deleted successfully',
                    description: 'The accommodation has been successfully deleted'
                })
                hadleGetAccommodations()
            } else {
                api.error({
                    message: 'Error deleting accommodation',
                    description: 'An error occurred while deleting the accommodation'
                })
            }

        } catch (error) {
            console.error('Error deleting accommodation', error)
            api.error({
                message: 'Error deleting accommodation',
                description: error.message
            })
        }
    }

    const handleDeleteATransportOption = async (option) => {
        try {
            let blobs = option.vehicleImages?.split(',')

            if (blobs?.length > 0) {
                const imageDeletePromises = blobs.map(async (image, index) => {
                    const blobName = getBlobName(image);
                    return handleDeleteBlob(TOUR_PACKAGES_BLOB, blobName);
                });
                await Promise.all(imageDeletePromises);
            }

            const res = await removeTourTransport(option.id)

            if (res.status === 200) {
                api.success({
                    message: 'Transport Option deleted successfully',
                    description: 'The transport option has been successfully deleted'
                })
                handleGetTransports()
            } else {
                api.error({
                    message: 'Error deleting transport option',
                    description: 'An error occurred while deleting the transport option'
                })
            }

        } catch (error) {
            console.error('Error deleting transport option', error)
            api.error({
                message: 'Error deleting transport option',
                description: error.message
            })
        }
    }

    const handleDeletePackageInclude = async (acc) => {
        try {
            const res = await removePackageInclude(acc.id)

            if (res.status === 200) {
                api.success({
                    message: 'Package Include deleted successfully',
                    description: 'The package include has been successfully deleted'
                })
                handleGetPackageIncludes()
            } else {
                api.error({
                    message: 'Error deleting package include',
                    description: 'An error occurred while deleting the package include'
                })
            }

        } catch (error) {
            console.error('Error deleting package include', error)
            api.error({
                message: 'Error deleting package include',
                description: error.message
            })
        }
    }

    const handleDeletePackageExclude = async (acc) => {
        try {
            const res = await deleteTourPackageExclude(acc.id)

            if (res.status === 200) {
                api.success({
                    message: 'Package Exclude deleted successfully',
                    description: 'The package exclude has been successfully deleted'
                })
                handleGetPackageExcludes()
            } else {
                api.error({
                    message: 'Error deleting package exclude',
                    description: 'An error occurred while deleting the package exclude'
                })
            }
        } catch (error) {
            console.log('Error deleting package exclude', error)
            api.error({
                message: 'Error deleting package exclude',
                description: error.message
            })
        }
    }


    const handleDeleteInstalmentPlan = async (acc) => {
        try {
            const res = await deleteTourInstalmentPlan(acc.id)

            if (res.status === 200) {
                api.success({
                    message: 'Instalment plan deleted successfully',
                    description: 'The instalment plan has been successfully deleted'
                })
                handleGetInstalmentPlans()
            } else {
                api.error({
                    message: 'Error deleting instalment plan',
                    description: 'An error occurred while deleting the instalment plan'
                })
            }

        } catch (error) {
            console.error('Error deleting instalment plan', error)
            api.error({
                message: 'Error deleting instalment plan',
                description: error.message
            })
        }
    }

    const getBlobName = (blobUrl) => {
        const splitUrl = blobUrl.split('/')
        return splitUrl[splitUrl.length - 1]
    }

    const handleDeleteTourPackage = async () => {
        setLoading(true)
        try {
            // get all blobs
            let packageImages = tourPackage.packageImages?.split(',')
            let packageVideos = tourPackage.packageVideos?.split(',')
            let thumbnailUrl = tourPackage.thumbnailUrl

            if (packageImages?.length > 0) {
                setLoadingText('Deleting Images');
                const imageDeletePromises = packageImages?.map(async (image, index) => {
                    const blobName = getBlobName(image);
                    setLoadingText(`Deleting image ${index + 1}`);
                    return handleDeleteBlob(TOUR_PACKAGES_BLOB, blobName);
                });
                await Promise.all(imageDeletePromises);
            }

            if (packageVideos?.length > 0) {
                setLoadingText('Deleting Videos');
                const videoDeletePromises = packageVideos.map(async (video, index) => {
                    const blobName = getBlobName(video);
                    setLoadingText(`Deleting video ${index + 1}`);
                    return handleDeleteBlob(TOUR_PACKAGES_BLOB, blobName);
                });
                await Promise.all(videoDeletePromises);
            }

            if (thumbnailUrl) {
                setLoadingText('Deleting Thumbnail');
                const blobName = getBlobName(thumbnailUrl);
                await handleDeleteBlob(TOUR_PACKAGES_BLOB, blobName);
            }

            setLoadingText('Deleting Tour Package from DB');
            const res = await deleteTourPackage(tourPckageId)

            if (res.status === 200) {
                api.success({
                    message: 'Tour Package deleted successfully',
                    description: 'The tour package has been successfully deleted'
                })

                setTimeout(() => {
                    navigate('/dashboard/tours/tour-packages')
                }, 2000)

            } else {
                api.error({
                    message: 'Error deleting tour package',
                    description: 'An error occurred while deleting the tour package'
                })
            }

        } catch (error) {
            console.error('Error deleting tour package', error)
            api.error({
                message: 'Error deleting tour package',
                description: error.message
            })
        }
        setLoading(false)
    }

    const handleGetItinirary = async () => {
        setLoadingItinerary(true)
        try {
            const res = await getItinerariesByTourPackage(tourPckageId)
            setItinirary(res.data)
        } catch (error) {
            console.error('Error fetching itinirary', error)
        }
        setLoadingItinerary(false)
    }

    const handleGetHighlights = async () => {
        setLoadingHighlights(true)
        try {
            const res = await getTourPackageHighlights(tourPckageId)
            setHighlights(res.data)
        } catch (error) {
            console.error('Error fetching highlights', error)
        }
        setLoadingHighlights(false)
    }

    const hadleGetAccommodations = async () => {
        setLoadingAccommodation(true)
        try {
            const res = await getAccommodationsByTourPackage(tourPckageId)
            const sorted = res.data.sort((a, b) => a.dayNumber - b.dayNumber)
            setAccommodations(sorted)
        } catch (error) {
            console.error('Error fetching accommodations', error)
        }
        setLoadingAccommodation(false)
    }

    const handleGetTransports = async () => {
        setLoadingTransportOptions(true)
        try {
            const res = await getTransportsByTourPackage(tourPckageId)
            setTransportOptions(res.data)
        } catch (error) {
            console.error('Error fetching transport options', error)
        }
        setLoadingTransportOptions(false)
    }

    const handleGetPackageIncludes = async () => {
        setLoadingPackageIncludes(true)
        try {
            const res = await getPackageIncludesByTourPackage(tourPckageId)
            setPackageIncludes(res.data)
        } catch (error) {
            console.error('Error fetching package includes', error)
        }
        setLoadingPackageIncludes(false)
    }

    const handleGetPackageExcludes = async () => {
        setLoadingPackageExcludes(true)
        try {
            const res = await getTourPackageExcludes(tourPckageId)
            setPackageExcludes(res.data)
        } catch (error) {
            console.error('Error fetching package excludes', error)
        }
        setLoadingPackageExcludes(false)
    }

    const handleGetInstalmentPlans = async () => {
        setLoadingPackageInstalments(true)
        try {
            const res = await getInstalmentPlansByTourPackage(tourPckageId)
            setPackageInstalments(res.data)
        } catch (error) {
            console.error('Error fetching package instalment plans', error)
        }
        setLoadingPackageInstalments(false)
    }

    return (
        <div className='destination'>
            {contextHolder}
            <Spin
                spinning={loading}
                size='large'
                tip={loadingText}
            >
                <>
                    <div className='destination__header'>
                        <h1>
                            {tourPackage?.packageName}
                        </h1>
                        {(myProfile?.role === SUPER_ADMIN || myProfile?.role === ADMIN) &&
                            <div className='destination__header__actions'>
                                <Button type='primary' onClick={() => navigate(`/dashboard/tours/tour-packages/edit/${tourPckageId}`)} icon={<EditOutlined />}>
                                    Edit
                                </Button>
                                <Popconfirm
                                    title='Are you sure you want to delete this tour package?'
                                    description=
                                    {<p
                                        style={{
                                            width: '300px',
                                            color: 'red',
                                        }}
                                    >
                                        This action cannot be undone! This will permanently delete the tour package, its images, videos, its itinery, accommodations, transport options, package includes and package instalments etc.
                                    </p>}
                                    onConfirm={handleDeleteTourPackage}
                                    okText='Yes'
                                    cancelText='No'
                                >
                                    <Button danger type='primary' icon={<DeleteOutlined />}>
                                        Delete
                                    </Button>
                                </Popconfirm>
                            </div>
                        }
                    </div>
                    <div className='destination__content_images'>
                        <div className='destination__content__image'>
                            <img src={tourPackage?.thumbnailUrl || DEFAULT_THUMBNAIL} alt='activity' />
                        </div>
                        <div className='destination__content__galery'>
                            <Image.PreviewGroup>
                                {tourPackage?.packageImages?.split(',').map((image, index) => (
                                    <Image
                                        key={index}
                                        width={160}
                                        height={100}
                                        src={image}
                                        className='destination__content__galery__image'
                                    />
                                ))}
                            </Image.PreviewGroup>
                        </div>
                    </div>
                    <Divider />
                    <div className='destination__content_videos'>
                        <h2>Videos</h2>
                        <div className='destination__content__videos'>
                            {tourPackage?.packageVideos ? <>
                                {tourPackage?.packageVideos?.split(',').map((video, index) => (
                                    <video key={index} controls>
                                        <source src={video} type='video/mp4' />
                                    </video>
                                ))}
                            </>
                                :
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            }
                        </div>
                    </div>

                    <Divider />

                    <div className='hotel__content_details'>
                        <h2>
                            Details
                        </h2>
                        <div className='destination__content__details'>
                            <div className='sec'>
                                <p><strong>Id: </strong>{tourPackage?.id || 'N/A'}</p>
                                <p><strong>Tour Type: </strong>{tourPackage?.tourType || 'N/A'}</p>
                                <p><strong>Duration: </strong>
                                    {tourPackage?.durationDays + ' Days / ' + tourPackage?.durationNights + ' Nights'}
                                </p>
                                <p><strong>Maximu Grou Size: </strong>{tourPackage?.maxGroupSize || 'N/A'}</p>
                            </div>
                            <div className='sec'>
                                <p><strong>Max Age: </strong>{tourPackage?.maxAge || 'N/A'}</p>
                                <p><strong>Min Age: </strong>{tourPackage?.minAge || 'N/A'}</p>
                                <p><strong>Tags: </strong>
                                    {tourPackage?.tags?.split(',').map((tag, index) => (
                                        <Tag key={index}>{tag} </Tag>
                                    ))}
                                </p>
                                <p><strong>Available Status: </strong>
                                    <Tag>{tourPackage?.availableStatus}</Tag>
                                </p>
                            </div>
                        </div>
                        <Divider />
                        <h2>
                            Pricing (Per Pearson)
                        </h2>
                        <div className='destination__content__details'>
                            <div className='sec'>
                                <p><strong>Starting Price: </strong>{tourPackage?.startingPrice + ' AUD' || 'N/A'}</p>
                                <p><strong>Guide Price: </strong>{tourPackage?.priceGuide + ' AUD' || 'N/A'}</p>
                                <p><strong>Package Price: </strong>{tourPackage?.pricePackage + 'AUD' || 'N/A'}</p>
                                <p><strong>Package Discount: </strong>{tourPackage?.discountAmount + '%' || 'N/A'}</p>
                                <p><strong>Discount End Date: </strong>{tourPackage?.disvountEnds || 'N/A'}</p>
                            </div>
                            <div className='sec'>
                                <p><strong>Hatchback: </strong>{tourPackage?.vehicleHatchback + ' AUD' || 'N/A'}</p>
                                <p><strong>Sedan: </strong>{tourPackage?.vehicleSedan + ' AUD' || 'N/A'}</p>
                                <p><strong>SUV: </strong>{tourPackage?.vehicleSuv + 'AUD' || 'N/A'}</p>
                                <p><strong>Van: </strong>{tourPackage?.vehicleVan + 'AUD' || 'N/A'}</p>
                                <p><strong>Coster: </strong>{tourPackage?.VehicleCoster + 'AUD' || 'N/A'}</p>
                            </div>
                            <div className='sec'>
                                <p><strong>Single Rooom: </strong>{tourPackage?.roomSingle + ' AUD' || 'N/A'}</p>
                                <p><strong>Double Room: </strong>{tourPackage?.roomDouble + ' AUD' || 'N/A'}</p>
                                <p><strong>Triple Room: </strong>{tourPackage?.roomTriple + 'AUD' || 'N/A'}</p>
                                <p><strong>Family Room: </strong>{tourPackage?.roomFamily + 'AUD' || 'N/A'}</p>
                            </div>
                        </div>
                        <Divider />
                        <p><strong>Destination: </strong>
                            {
                                tourPackage?.destinations?.map((destination, index) => (
                                    <Tag key={index} color='blue' style={{ fontSize: '14px', padding: '5px 10px' }}>
                                        {destination?.destinationName}
                                    </Tag>
                                ))
                            }
                        </p>
                        <p><strong>Attractions: </strong>
                            {
                                tourPackage?.attractions?.map((destination, index) => (
                                    <Tag key={index} color='blue' style={{ fontSize: '14px', padding: '5px 10px' }}>
                                        {destination?.attractionName}
                                    </Tag>
                                ))
                            }
                        </p>
                        <p><strong>Activities: </strong>
                            {
                                tourPackage?.activities?.map((destination, index) => (
                                    <Tag key={index} color='blue' style={{ fontSize: '14px', padding: '5px 10px' }}>
                                        {destination?.activityName}
                                    </Tag>
                                ))
                            }
                        </p>
                        <p><strong>Events: </strong>
                            {
                                tourPackage?.events?.map((destination, index) => (
                                    <Tag key={index} color='blue' style={{ fontSize: '14px', padding: '5px 10px' }}>
                                        {destination?.eventName}
                                    </Tag>
                                ))
                            }
                        </p>
                    </div>

                    <Divider />

                    <div className='destination__content_description'>
                        <h2>
                            Tour Info
                        </h2>
                        <h3>
                            Short Description
                        </h3>
                        <ReactQuill
                            value={tourPackage?.shotDescription}
                            readOnly={true}
                            theme='bubble'
                        />

                        <h3>
                            Full Description
                        </h3>
                        <ReactQuill
                            value={tourPackage?.packageDescription}
                            readOnly={true}
                            theme='bubble'
                        />

                        <h3>
                            Notes
                        </h3>
                        <ReactQuill
                            value={tourPackage?.notes}
                            readOnly={true}
                            theme='bubble'
                        />
                        <h3>
                            How it Works
                        </h3>
                        <ReactQuill
                            value={tourPackage?.howItWorks}
                            readOnly={true}
                            theme='bubble'
                        />

                        <h3>
                            Route Map
                        </h3>
                        <iframe
                            src={tourPackage?.routeMap}
                            width="600"
                            height="450"
                            title="Route Map"
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>

                    <Divider />

                    <div className='content'>
                        <div className='header'>
                            <h2>
                                Highlights
                            </h2>
                            <div className='actions'>
                                <Button type='default'
                                    onClick={() => handleGetHighlights()}
                                    loading={loadingHighlights}>
                                    Get Highlights
                                </Button>
                                {(myProfile?.role === SUPER_ADMIN || myProfile?.role === ADMIN) &&
                                    <Button type='primary' onClick={() => setAddHighlightVisible(true)} icon={<PlusOutlined />}>
                                        Add Highlight
                                    </Button>
                                }
                            </div>
                        </div>

                        <div className='items_list_acc'>
                            {loadingHighlights && <Skeleton active />}

                            {highlights?.length > 0 ? <>
                                {highlights.map((item, index) => (
                                    <div key={index} className='item'>
                                        <div className='body'>
                                            <div className='detsils'>
                                                <div className='header'>
                                                    <p>
                                                        <strong>Highlight: </strong> {item?.highlight}
                                                    </p>
                                                    {(myProfile?.role === SUPER_ADMIN || myProfile?.role === ADMIN) &&
                                                        <div className='actions'>
                                                            <Popconfirm
                                                                title='Are you sure you want to delete this highlight?'
                                                                description={<p
                                                                    style={{
                                                                        width: '300px',
                                                                        color: 'red',
                                                                    }}
                                                                >This action cannot be undone! This will permanently delete the highlight</p>}
                                                                onConfirm={() => handleDeleteHighlight(item)}
                                                                okText='Yes'
                                                                cancelText='No'
                                                            >
                                                                <Button danger type='primary' icon={<DeleteOutlined />}>
                                                                    Delete
                                                                </Button>
                                                            </Popconfirm>
                                                        </div>
                                                    }
                                                </div>
                                                <p>
                                                    <strong>ID: </strong> {item?.id}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                                : <>
                                    {highlights?.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                </>
                            }
                        </div>
                    </div>

                    <Divider />

                    <div className='content'>
                        <div className='header'>
                            <h2>
                                Itinerary
                            </h2>
                            <div className='actions'>
                                <Button type='default'
                                    onClick={() => handleGetItinirary()}
                                    loading={loadingItinerary}>
                                    Get Itinerary
                                </Button>
                                {(myProfile?.role === SUPER_ADMIN || myProfile?.role === ADMIN) &&
                                    <Button type='primary' onClick={() => setAddItineraryVisible(true)} icon={<PlusOutlined />}>
                                        Add Itinerary
                                    </Button>
                                }
                            </div>
                        </div>

                        <div className='items_list_acc'>
                            {loadingItinerary && <Skeleton active />}

                            {itinirary?.length > 0 ? <>
                                {itinirary.map((item, index) => (
                                    <div key={index} className='item'>
                                        <div className='body'>
                                            <div className='detsils'>
                                                <div className='header'>
                                                    <h3>
                                                        {item.dayTitle}
                                                    </h3>

                                                    {(myProfile?.role === SUPER_ADMIN || myProfile?.role === ADMIN) &&
                                                        <div className='actions'>
                                                            <Button type='default' icon={<EditOutlined />}
                                                                onClick={() => {
                                                                    setSelectedItinerary(item)
                                                                    setEditItineraryVisible(true)
                                                                }}
                                                            >
                                                                Edit
                                                            </Button>

                                                            <Popconfirm
                                                                title='Are you sure you want to delete this itinery item?'
                                                                description={<p
                                                                    style={{
                                                                        width: '300px',
                                                                        color: 'red',
                                                                    }}
                                                                >This action cannot be undone! This will permanently delete the itinery item</p>}
                                                                onConfirm={() => handleDeleteItineryItem(item)}
                                                                okText='Yes'
                                                                cancelText='No'
                                                            >
                                                                <Button danger type='primary' icon={<DeleteOutlined />}>
                                                                    Delete
                                                                </Button>
                                                            </Popconfirm>
                                                        </div>
                                                    }
                                                </div>
                                                <ReactQuill
                                                    value={item?.dayDescription}
                                                    readOnly={true}
                                                    theme='bubble'
                                                />
                                                <p>
                                                    <strong>ID: </strong> {item?.id}
                                                </p>
                                                <div className='room__details'>
                                                    <div>
                                                        <p>
                                                            <strong>Main Destination: </strong> {item?.mainDestination?.destinationName}
                                                        </p>
                                                    </div>

                                                    <div >
                                                        <p>
                                                            <strong>Stay: </strong> {item.stay?.accommodationName}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p>
                                                            <strong>Day: </strong> {item?.dayNumber}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='day__plan'>
                                                    <Steps
                                                        direction="vertical"
                                                        size="small"
                                                        items={
                                                            item?.items
                                                                ?.sort((a, b) => a.position - b.position) // Sort items by position
                                                                ?.map((item, index) => ({
                                                                    description:
                                                                        <div className='item_iti_dis'>
                                                                            <img src={item?.thumbnailUrl || DEFAULT_THUMBNAIL} alt='thumbnail' />

                                                                            <div>
                                                                                <h3>{item?.eventName || item?.activityName || item?.attractionName || item?.destinationName || 'N/A'}</h3>
                                                                                <p>
                                                                                    <strong>Short Description: </strong>
                                                                                    <ReactQuill
                                                                                        value={item?.attractionDescriptionShort || item?.activityDescriptionShort || item?.eventDescriptionShort || item?.destinationDescriptionShort || 'N/A'}
                                                                                        readOnly={true}
                                                                                        theme='bubble'
                                                                                    />
                                                                                </p>
                                                                                <p>
                                                                                    <strong>Type: </strong> {item?.type}
                                                                                </p>
                                                                            </div>
                                                                        </div>,
                                                                    status: 'process',
                                                                }))
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                                : <>
                                    {itinirary?.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                </>
                            }
                        </div>
                    </div>

                    <Divider />

                    <div className='content'>
                        <div className='header'>
                            <h2>
                                Accommodation
                            </h2>
                            <div className='actions'>
                                <Button type='default'
                                    onClick={() => hadleGetAccommodations()}
                                    loading={loadingAccommodation}>
                                    Get Accommodation
                                </Button>
                                {(myProfile?.role === SUPER_ADMIN || myProfile?.role === ADMIN) &&
                                    <Button type='primary' onClick={() => setAddAccommodationVisible(true)} icon={<PlusOutlined />}>
                                        Add Accommodation
                                    </Button>
                                }
                            </div>
                        </div>

                        <div className='items_list_acc'>
                            {loadingAccommodation && <Skeleton active />}

                            {accommodations?.length > 0 ? <>
                                {accommodations.map((item, index) => (
                                    <div key={index} className='item'>
                                        <div className='body'>
                                            <div className='detsils'>
                                                <div className='header'>
                                                    <h3>
                                                        {item.title}
                                                    </h3>

                                                    {(myProfile?.role === SUPER_ADMIN || myProfile?.role === ADMIN) &&
                                                        <div className='actions'>
                                                            <Button type='default' icon={<EditOutlined />}
                                                                onClick={() => {
                                                                    setSelectedAccommodation(item)
                                                                    setEditAccommodationVisible(true)
                                                                }}
                                                            >
                                                                Edit
                                                            </Button>

                                                            <Popconfirm
                                                                title='Are you sure you want to delete this accommocation?'
                                                                description={<p
                                                                    style={{
                                                                        width: '300px',
                                                                        color: 'red',
                                                                    }}
                                                                >This action cannot be undone! This will permanently delete the accommodation</p>}
                                                                onConfirm={() => handleDeleteAccommodation(item)}
                                                                okText='Yes'
                                                                cancelText='No'
                                                            >
                                                                <Button danger type='primary' icon={<DeleteOutlined />}>
                                                                    Delete
                                                                </Button>
                                                            </Popconfirm>
                                                        </div>
                                                    }
                                                </div>
                                                <ReactQuill
                                                    value={item?.description}
                                                    readOnly={true}
                                                    theme='bubble'
                                                />
                                                <p>
                                                    <strong>ID: </strong> {item?.id}
                                                </p>
                                                <Rate disabled defaultValue={item?.starGrade} />
                                                <div className='room__details'>
                                                    <div>
                                                        <p>
                                                            <strong>Accommodation Type: </strong> {item?.accommodationType}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p>
                                                            <strong>Day Number: </strong> {item?.dayNumber}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                                : <>
                                    {accommodations?.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                </>
                            }
                        </div>
                    </div>

                    <Divider />

                    <div className='content'>
                        <div className='header'>
                            <h2>
                                Package Includes
                            </h2>
                            <div className='actions'>
                                <Button type='default'
                                    onClick={() => handleGetPackageIncludes()}
                                    loading={loadingPackageIncludes}>
                                    Get Package Includes
                                </Button>
                                {(myProfile?.role === SUPER_ADMIN || myProfile?.role === ADMIN) &&
                                    <Button type='primary' onClick={() => setAddPackageIncludesVisible(true)} icon={<PlusOutlined />}>
                                        Add Package Include
                                    </Button>
                                }
                            </div>
                        </div>

                        <div className='items_list_acc'>
                            {loadingPackageIncludes && <Skeleton active />}

                            {packageIncludes?.length > 0 ? <>
                                {packageIncludes.map((item, index) => (
                                    <div key={index} className='item'>
                                        <div className='body'>
                                            <div className='detsils'>
                                                <div className='header'>
                                                    <h3>
                                                        {item.includesName}
                                                    </h3>
                                                    {(myProfile?.role === SUPER_ADMIN || myProfile?.role === ADMIN) &&
                                                        <div className='actions'>
                                                            <Button type='default' icon={<EditOutlined />}
                                                                onClick={() => {
                                                                    setSelectedPackageIncludes(item)
                                                                    setEditPackageIncludesVisible(true)
                                                                }}
                                                            >
                                                                Edit
                                                            </Button>

                                                            <Popconfirm
                                                                title='Are you sure you want to delete this package include?'
                                                                description={<p
                                                                    style={{
                                                                        width: '300px',
                                                                        color: 'red',
                                                                    }}
                                                                >This action cannot be undone! This will permanently delete this package include</p>}
                                                                onConfirm={() => handleDeletePackageInclude(item)}
                                                                okText='Yes'
                                                                cancelText='No'
                                                            >
                                                                <Button danger type='primary' icon={<DeleteOutlined />}>
                                                                    Delete
                                                                </Button>
                                                            </Popconfirm>
                                                        </div>
                                                    }
                                                </div>

                                                <ReactQuill
                                                    value={item?.includesDescription}
                                                    readOnly={true}
                                                    theme='bubble'
                                                />
                                                <p>
                                                    <strong>ID: </strong> {item?.id}
                                                </p>
                                                <div className='room__details'>
                                                    <div>
                                                        <p>
                                                            <strong>Type: </strong> {item?.includesType}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                                : <>
                                    {packageIncludes?.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                </>
                            }
                        </div>
                    </div>

                    <Divider />

                    <div className='content'>
                        <div className='header'>
                            <h2>
                                Package Excludes
                            </h2>
                            <div className='actions'>
                                <Button type='default'
                                    onClick={() => handleGetPackageExcludes()}
                                    loading={loadingPackageExcludes}>
                                    Get Excludes
                                </Button>
                                {(myProfile?.role === SUPER_ADMIN || myProfile?.role === ADMIN) &&
                                    <Button type='primary' onClick={() => setAddPackageExcludesVisible(true)} icon={<PlusOutlined />}>
                                        Add Excludes
                                    </Button>
                                }
                            </div>
                        </div>

                        <div className='items_list_acc'>
                            {loadingPackageExcludes && <Skeleton active />}

                            {packageExcludes?.length > 0 ? <>
                                {packageExcludes.map((item, index) => (
                                    <div key={index} className='item'>
                                        <div className='body'>
                                            <div className='detsils'>
                                                <div className='header'>
                                                    <p>
                                                        <strong>Exclude: </strong> {item?.exclude}
                                                    </p>
                                                    {(myProfile?.role === SUPER_ADMIN || myProfile?.role === ADMIN) &&
                                                        <div className='actions'>
                                                            <Popconfirm
                                                                title='Are you sure you want to delete this package exclude?'
                                                                description={<p
                                                                    style={{
                                                                        width: '300px',
                                                                        color: 'red',
                                                                    }}
                                                                >This action cannot be undone! This will permanently delete the package exclude.</p>}
                                                                onConfirm={() => handleDeletePackageExclude(item)}
                                                                okText='Yes'
                                                                cancelText='No'
                                                            >
                                                                <Button danger type='primary' icon={<DeleteOutlined />}>
                                                                    Delete
                                                                </Button>
                                                            </Popconfirm>
                                                        </div>
                                                    }
                                                </div>
                                                <p>
                                                    <strong>ID: </strong> {item?.id}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                                : <>
                                    {packageExcludes?.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                </>
                            }
                        </div>
                    </div>

                    <Divider />

                    <div className='content'>
                        <div className='header'>
                            <h2>
                                Package Instalment Options
                            </h2>
                            <div className='actions'>
                                <Button type='default'
                                    onClick={() => handleGetInstalmentPlans()}
                                    loading={loadingPackageInstalments}>
                                    Get Instalment Options
                                </Button>
                                {(myProfile?.role === SUPER_ADMIN || myProfile?.role === ADMIN) &&
                                    <Button type='primary' onClick={() => setAddPackageInstalmentsVisible(true)} icon={<PlusOutlined />}>
                                        Add Instalment Options
                                    </Button>
                                }
                            </div>
                        </div>

                        <div className='items_list_acc'>
                            {loadingPackageInstalments && <Skeleton active />}

                            {packageInstalments?.length > 0 ? <>
                                {packageInstalments.map((item, index) => (
                                    <div key={index} className='item'>
                                        <div className='body'>
                                            <div className='detsils'>
                                                <div className='header'>
                                                    <h3>
                                                        {item.installmentType}
                                                    </h3>
                                                    {(myProfile?.role === SUPER_ADMIN || myProfile?.role === ADMIN) &&
                                                        <div className='actions'>
                                                            <Button type='default' icon={<EditOutlined />}
                                                                onClick={() => {
                                                                    setSelectedPackageInstalments(item)
                                                                    setEditPackageInstalmentsVisible(true)
                                                                }}
                                                            >
                                                                Edit
                                                            </Button>

                                                            <Popconfirm
                                                                title='Are you sure you want to delete this instalment plan?'
                                                                description={<p
                                                                    style={{
                                                                        width: '300px',
                                                                        color: 'red',
                                                                    }}
                                                                >This action cannot be undone! This will permanently delete this instalment plan.</p>}
                                                                onConfirm={() => handleDeleteInstalmentPlan(item)}
                                                                okText='Yes'
                                                                cancelText='No'
                                                            >
                                                                <Button danger type='primary' icon={<DeleteOutlined />}>
                                                                    Delete
                                                                </Button>
                                                            </Popconfirm>
                                                        </div>
                                                    }
                                                </div>
                                                <p>
                                                    <strong>ID: </strong> {item?.id}
                                                </p>
                                                <div className='room__details'>
                                                    <div>
                                                        <p>
                                                            <strong>Type: </strong> {item?.installmentType}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p>
                                                            <strong>Number of Instalments: </strong> {item?.months}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                                : <>
                                    {packageInstalments?.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                </>
                            }
                        </div>
                    </div>
                </>
            </Spin>

            <AddPackageHighlight
                isVisible={addHighlightVisible}
                setIsVisible={setAddHighlightVisible}
                tourPackage={tourPackage}
                handleGetHighlights={handleGetHighlights}
            />

            <AddItineryItem
                isVisible={addItineraryVisible}
                setIsVisible={setAddItineraryVisible}
                tourPackage={tourPackage}
                handleGetItinirary={handleGetItinirary}
            />

            <EditItineryItem
                isVisible={editItineraryVisible}
                setIsVisible={setEditItineraryVisible}
                handleGetItinirary={handleGetItinirary}
                selectedItinerary={selectedItinerary}
            />

            <AddTourAccommodation
                isVisible={addAccommodationVisible}
                setIsVisible={setAddAccommodationVisible}
                tourPackage={tourPackage}
                hadleGetAccommodations={hadleGetAccommodations}
            />

            <EditTourAccommodation
                isVisible={editAccommodationVisible}
                setIsVisible={setEditAccommodationVisible}
                hadleGetAccommodations={hadleGetAccommodations}
                selectedAccommodationItem={selectedAccommodation}
            />

            <AddTransportOptions
                isVisible={addTransportOptionVisible}
                setIsVisible={setAddTransportOptionVisible}
                tourPackage={tourPackage}
                handleGetTransports={handleGetTransports}
            />

            <EditTransportOptions
                isVisible={editTransportOptionVisible}
                setIsVisible={setEditTransportOptionVisible}
                selectedTransportOption={selectedTransportOption}
                handleGetTransports={handleGetTransports}
            />

            <AddPackageInclude
                isVisible={addPackageIncludesVisible}
                setIsVisible={setAddPackageIncludesVisible}
                tourPackage={tourPackage}
                handleGetPackageIncludes={handleGetPackageIncludes}
            />

            <AddPackageExclude
                isVisible={addPackageExcludesVisible}
                setIsVisible={setAddPackageExcludesVisible}
                tourPackage={tourPackage}
                handleGetPackageExcludes={handleGetPackageExcludes}
            />

            <EditPackageInclude
                isVisible={editPackageIncludesVisible}
                setIsVisible={setEditPackageIncludesVisible}
                selectedPackageIncludes={selectedPackageIncludes}
                handleGetPackageIncludes={handleGetPackageIncludes}
            />

            <AddPackageInstalments
                isVisible={addPackageInstalmentsVisible}
                setIsVisible={setAddPackageInstalmentsVisible}
                tourPackage={tourPackage}
                handleGetInstalmentPlans={handleGetInstalmentPlans}
            />

            <EditPackageInstalments
                isVisible={editPackageInstalmentsVisible}
                setIsVisible={setEditPackageInstalmentsVisible}
                selectedPackageInstalments={selectedPackageInstalments}
                handleGetInstalmentPlans={handleGetInstalmentPlans}
            />
        </div >
    )
}

export default TourPackage