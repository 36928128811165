import {
    FETCH_TRANSPORTS,
    FETCH_TRANSPORTS_SUCCESS,
    FETCH_TRANSPORTS_FAIL,

} from '../../types';

const initialState = {
    loading: false,
    transports: [],
    error: null
};

export const transportReducer = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case FETCH_TRANSPORTS:
            return {
                ...state,
                loading: true
            };
        case FETCH_TRANSPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                transports: action.payload,
                error: null
            };
        case FETCH_TRANSPORTS_FAIL:
            return {
                ...state,
                loading: false,
                transports: [],
                error: action.payload
            };
        default:
            return state;
    }
}