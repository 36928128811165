import React, { useEffect, useState } from 'react'
import useDestinations from '../../../../../hooks/useDestinations'
import { useNavigate } from 'react-router-dom'

import { EVENTS_CONTAINER, handleDeleteBlob } from '../../../../../azure/blob'

import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Divider, Empty, Image, Popconfirm, Spin, Tag, notification } from 'antd'
import 'react-quill/dist/quill.snow.css';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';

import './styles.scss'
import useEvents from '../../../../../hooks/useEvents';
import { deleteEvent, getEventById } from '../../../../../../redux/api/events.api';
import { ADMIN, SUPER_ADMIN } from '../../../../../Constants/roles'
import useMyProfile from '../../../../../hooks/useMyProfile'

Quill.register('modules/imageResize', ImageResize);

const modules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
        ["formula"],
        [{ size: ["small", false, "large", "huge"] }],
    ],
    clipboard: {
        matchVisual: false
    },
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
};

const Event = () => {
    const destinations = useDestinations()
    const destinationNames = destinations.destinationNames
    const events = useEvents().events
    const url = window.location.pathname
    const eventId = url.split('/').pop()
    const [loading, setLoading] = useState(false)
    const [event, setEvent] = useState()
    const navigate = useNavigate()
    const [api, contextHolder] = notification.useNotification();
    const [loadingText, setLoadingText] = useState('Please Wait...')
    const myProfile = useMyProfile().userProfile

    useEffect(() => {
        if (events?.length > 0) {
            const activity = events.find(event => event.id === eventId)
            if (activity) {
                setEvent(activity)
            } else {
                handleGetEvent(eventId)
            }
        } else {
            handleGetEvent(eventId)
        }
    }, [])

    const handleGetEvent = async (id) => {
        setLoading(true)
        try {
            const activity = await getEventById(id)
            setEvent(activity.data[0])
        } catch (error) {
            console.error('Error fetching activity', error)
        }
        setLoading(false)
    }

    const getBlobName = (blobUrl) => {
        const splitUrl = blobUrl.split('/')
        return splitUrl[splitUrl.length - 1]
    }

    const handleDeleteActivity = async () => {
        setLoading(true)
        try {
            // get all blobs
            let eventImages = event.eventImages?.split(',')
            let eventVideos = event.eventVideos?.split(',')
            let thumbnail = event.thumbnailUrl

            if (eventImages?.length > 0) {
                setLoadingText('Deleting Event Images');
                const imageDeletePromises = eventImages.map(async (image, index) => {
                    const blobName = getBlobName(image);
                    setLoadingText(`Deleting image ${index + 1}`);
                    return handleDeleteBlob(EVENTS_CONTAINER, blobName);
                });
                await Promise.all(imageDeletePromises);
            }

            if (eventVideos?.length > 0) {
                setLoadingText('Deleting Event Videos');
                const videoDeletePromises = eventVideos.map(async (video, index) => {
                    const blobName = getBlobName(video);
                    setLoadingText(`Deleting video ${index + 1}`);
                    return handleDeleteBlob(EVENTS_CONTAINER, blobName);
                });
                await Promise.all(videoDeletePromises);
            }

            if (thumbnail) {
                setLoadingText('Deleting Event Thumbnail');
                const blobName = getBlobName(thumbnail);
                setLoadingText(`Deleting thumbnail`);
                await handleDeleteBlob(EVENTS_CONTAINER, blobName);
            }

            const res = await deleteEvent(eventId)

            if (res.status === 200) {
                api.success({
                    message: 'Event deleted successfully',
                    description: 'The event has been successfully deleted'
                })

                setTimeout(() => {
                    navigate('/dashboard/tours/events')
                }, 2000)

            } else {
                api.error({
                    message: 'Error deleting event',
                    description: 'An error occurred while deleting the event'
                })
            }

        } catch (error) {
            console.error('Error deleting event', error)
            api.error({
                message: 'Error deleting event',
                description: error.message
            })
        }
        setLoading(false)
    }

    return (
        <div className='destination'>
            {contextHolder}
            <Spin
                spinning={loading}
                size='large'
                tip={loadingText}
            >
                <>
                    <div className='destination__header'>
                        <h1>
                            {event?.eventName}
                        </h1>
                        {(myProfile?.role === SUPER_ADMIN || myProfile?.role === ADMIN) &&
                            <div className='destination__header__actions'>
                                <Button type='primary' onClick={() => navigate(`/dashboard/tours/events/edit/${eventId}`)} icon={<EditOutlined />}>
                                    Edit
                                </Button>
                                <Popconfirm
                                    title='Are you sure you want to delete this activity?'
                                    description={<p
                                        style={{
                                            width: '300px',
                                            color: 'red',
                                        }}
                                    >This action cannot be undone! This will permanently delete the activity, its images, videos, attractions and other related items.</p>}
                                    onConfirm={handleDeleteActivity}
                                    okText='Yes'
                                    cancelText='No'
                                >
                                    <Button danger type='primary' icon={<DeleteOutlined />}>
                                        Delete
                                    </Button>
                                </Popconfirm>
                            </div>
                        }
                    </div>
                    <div className='destination__content_images'>
                        <div className='destination__content__image'>
                            <img src={event?.thumbnailUrl} alt='activity' />
                        </div>
                        <div className='destination__content__galery'>
                            <Image.PreviewGroup>
                                {event?.eventImages?.split(',').map((image, index) => (
                                    <Image
                                        key={index}
                                        width={160}
                                        height={100}
                                        src={image}
                                        className='destination__content__galery__image'
                                    />
                                ))}
                            </Image.PreviewGroup>
                        </div>
                    </div>
                    <Divider />
                    <div className='destination__content_videos'>
                        <h2>Videos</h2>
                        <div className='destination__content__videos'>
                            {event?.eventVideos ? <>
                                {event?.eventVideos?.split(',').map((video, index) => (
                                    <video key={index} controls>
                                        <source src={video} type='video/mp4' />
                                    </video>
                                ))}
                            </>
                                :
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            }
                        </div>
                    </div>

                    <Divider />

                    <div className='destination__content_details'>
                        <h2>
                            Details
                        </h2>
                        <div className='destination__content__details'>
                            <div className='left'>
                                <p><strong>City: </strong>{event?.eventCity || 'N/A'}</p>
                                <p><strong>Coordinates: </strong>{event?.eventCoordinates || 'N/A'}</p>
                                <p><strong>Weather: </strong>{event?.weather || 'N/A'}</p>
                                <p><strong>Type: </strong>{event?.eventType || 'N/A'}</p>
                            </div>
                            <div className='right'>
                                <p><strong>Destination: </strong>
                                    {destinationNames?.find(destination => destination.id === event?.destinationId)?.destinationName || 'N/A'}
                                </p>
                                <p><strong>Tags: </strong>
                                    {event?.tags?.split(',').map((tag, index) => (
                                        <Tag key={index}>{tag} </Tag>
                                    ))}
                                </p>
                                <p><strong>Ticket: </strong>{event?.ticketPrice || 'N/A'}</p>
                                <p><strong>Start Date: </strong>{event?.eventStartDate || 'N/A'}
                                    <strong
                                        style={{
                                            marginLeft: '10px'
                                        }}
                                    >End Date: </strong>{event?.eventEndDate || 'N/A'}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='destination__content_description'>
                        <h2>
                            Descriptions
                        </h2>
                        <h3>
                            Short Description
                        </h3>
                        <ReactQuill
                            modules={modules}
                            value={event?.eventDescriptionShort}
                            readOnly={true}
                            theme='bubble'
                        />

                        <h3>
                            Full Description
                        </h3>
                        <ReactQuill
                            modules={modules}
                            value={event?.eventDescription}
                            readOnly={true}
                            theme='bubble'
                        />


                        <h3>
                            Rules
                        </h3>
                        <ReactQuill
                            modules={modules}
                            value={event?.rules}
                            readOnly={true}
                            theme='bubble'
                        />
                    </div>

                    <Divider />
                    <div className='destination__content_reviews'>
                        <h2>
                            Reviews
                        </h2>
                    </div>

                    <Divider />

                    <div className='destination__content_emergency'>
                        <h2>
                            Emergency Contacts
                        </h2>
                    </div>
                </>
            </Spin>
        </div>
    )
}

export default Event