import React, { useEffect, useState } from 'react'
import usePartners from '../../../../../hooks/usePartners';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Form, Modal, notification, Popconfirm, Row, Select, Spin, Tag, Tooltip } from 'antd';
import { changePartnerProfileStatus, editPartnerProfile, getPartnerProfileById } from '../../../../../../redux/api/partners.api';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import './styles.scss'
import { DEFAULT_PROFILE_IMAGE, DEFAULT_THUMBNAIL } from '../../../../../Constants/otherConstsnts';
import EditPartnerProfile from './EditPartnerProfile';
import { ACTIVE, BLOCKED, INACTIVE, PENDING, SUSPENDED } from '../../../../../Constants/activeStatusType';

const PartnerProfile = () => {
    const partners = usePartners().partners;
    const [loading, setLoading] = useState(false)
    const url = window.location.pathname
    const partnerId = url.split('/').pop()
    const [partner, setPartner] = useState();
    const navigate = useNavigate()
    const [api, contextHolder] = notification.useNotification();
    const [isChangeStatusModalVisible, setIsChangeStatusModalVisible] = useState(false)
    const [editProfileVisible, setEditProfileVisible] = useState(false)
    const [changeStatusLoading, setChangeStatusLoading] = useState(false)

    useEffect(() => {
        if (partners?.length > 0) {
            const partner = partners.find(partner => partner.id === partnerId)
            if (partner) {
                setPartner(partner)
            } else {
                handleGetPartner(partnerId)
            }
        } else {
            handleGetPartner(partnerId)
        }
    }, [])


    const handleGetPartner = async (partnerId) => {
        setLoading(true)
        try {
            const response = await getPartnerProfileById(partnerId)
            if (response.data) {
                setPartner(response.data)
            } else {
                api.error({
                    message: 'Failed to get partner profile',
                    description: 'Please try again later or contact support'
                })
            }
        } catch (error) {
            console.error('Get Partner Profile Error', error)
            api.error({
                message: 'Failed to get partner profile',
                description: error.message
            })
        }
        setLoading(false)
    }

    const changePartnerStatus = async (values) => {
        setChangeStatusLoading(true)
        try {
            const status = values?.status
            const res = await changePartnerProfileStatus({
                id: partner?.id,
                status: status
            })
            if (res?.status === 200) {
                api.success({
                    message: 'Partner status changed successfully'
                })

                setIsChangeStatusModalVisible(false)
                await handleGetPartner(partner?.id)
            } else {
                api.error({
                    message: 'Failed to change partner status',
                    description: 'Please try again later or contact support'
                })
            }
        } catch (error) {
            console.error('Error changing user status', error)
            api.error({
                message: 'Failed to change partner status',
                description: error.message
            })

        }
        setChangeStatusLoading(false)
    }


    return (
        <div className='component__container'>
            {contextHolder}
            <Spin
                spinning={loading}
                size='large'
                tip='Loading Partner Profile'
            >
                <div className='container_header'>
                    <h1>{partner?.companyName}</h1>

                    <div className='container_header_actions'>
                        <Button type='default' icon={<EditOutlined />} onClick={() => setIsChangeStatusModalVisible(true)}>
                            Change Active Status
                        </Button>
                        <Button type='primary'
                            onClick={() => setEditProfileVisible(true)}
                            icon={<EditOutlined />}>
                            Edit Profile
                        </Button>
                    </div>
                </div>

                <div className='container_body'>
                    <div className='partner_profile'>
                        <div className='logo'>
                            <img src={partner?.companyNameLogoUrl || DEFAULT_THUMBNAIL} alt='logo' />
                        </div>
                        <div className='info'>
                            <h2>{partner?.companyName}</h2>
                            <p> <strong>Type: </strong> {partner?.partnerType}</p>
                            <p> <strong>Active Status: </strong> <Tag
                                color={partner?.activeStatus === 'ACTIVE' ? 'green' : 'red'}
                            >
                                {partner?.activeStatus}
                            </Tag></p>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'start',
                                    gap: '10px'
                                }}
                            >
                                <h3>Report Person: </h3>

                                <Tooltip title='Click to view report person profile'>
                                    <div className='report-person'
                                        onClick={() => navigate(`/dashboard/users/all/${partner?.partnerId}`)}
                                    >
                                        <div className='report'>
                                            <img src={partner?.profilePicUrl || DEFAULT_PROFILE_IMAGE} alt='logo' />
                                            <div className='report_info'>
                                                <h3>{partner?.userName}</h3>
                                                <p> <strong>User Id: </strong> {partner?.partnerId}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <Divider />

                    <div className='contact_info'>
                        <h2>About Company</h2>
                        <p>{partner?.companyNameDescription}</p>
                    </div>

                    <Divider />

                    <div className='contact_info'>
                        <h2>Company Information</h2>
                        <Row>
                            <Col span={12}>
                                <p> <strong>ID: </strong> {partner?.id}</p>
                                <p> <strong>Email: </strong> {partner?.companyNameEmail}</p>
                                <p> <strong>Phone: </strong> {partner?.companyNameContactNumber}</p>
                                <p> <strong>Address: </strong> {partner?.companyNameAddress}</p>
                                <p> <strong>Post Code: </strong> {partner?.companyNameCode}</p>
                            </Col>
                            <Col span={12}>
                                <p> <strong>Country: </strong> {partner?.companyNameCountry}</p>
                                <p> <strong>State: </strong> {partner?.companyNameState}</p>
                                <p> <strong>City: </strong> {partner?.companyNameCity}</p>
                                <p> <strong>Website: </strong> {partner?.companyNameWebsite}</p>
                                <p> <strong>Created Date: </strong> {partner?.createdAt}</p>
                            </Col>
                        </Row>
                    </div>

                    <Divider />

                    <div className='contact_info'>
                        <h2>Company Bank Information</h2>
                        <Row>
                            <Col span={12}>
                                <p> <strong>Bank Name: </strong> {partner?.bankName}</p>
                                <p> <strong>Bank Branch: </strong> {partner?.bankBranch}</p>
                            </Col>
                            <Col span={12}>
                                <p> <strong>Account Number: </strong> {partner?.bankAccountNumber}</p>
                                <p> <strong>Swift Code: </strong> {partner?.bankSwiftCode}</p>
                            </Col>
                        </Row>
                    </div>

                </div>
            </Spin>

            <Modal
                title="Change User Status"
                open={isChangeStatusModalVisible}
                onCancel={() => setIsChangeStatusModalVisible(false)}
                footer={null}
            >
                <Form
                    layout='vertical'
                    onFinish={changePartnerStatus}
                >
                    <Form.Item
                        label='Status'
                        name='status'
                        rules={[{ required: true, message: 'Please select a status' }]}
                    >
                        <Select
                            loading={changeStatusLoading}
                            defaultValue={partner?.activeStatus}
                        >
                            <Select.Option value={ACTIVE}>Active</Select.Option>
                            <Select.Option value={INACTIVE}>Inactive</Select.Option>
                            <Select.Option value={PENDING}>Pending</Select.Option>
                            <Select.Option value={SUSPENDED}>Suspend</Select.Option>
                            <Select.Option value={BLOCKED}>Block</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type='primary' htmlType='submit' loading={changeStatusLoading}>
                            Change Status
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <EditPartnerProfile
                editProfileVisible={editProfileVisible}
                setEditProfileVisible={setEditProfileVisible}
                partner={partner}
                handleGetPartner={handleGetPartner}
            />
        </div>
    )
}

export default PartnerProfile