import React, { useState } from 'react'
import { Button, Col, Drawer, Form, Input, Row, Select, Spin, notification } from 'antd'
import { ADMIN, CUSTOMER_RELATIONS_MANAGER, MANAGER, PARTNERSHIP_MANAGER, SUPER_ADMIN, SUPPORT_AGENT, TOUR_GUIDE } from '../../../../Constants/roles';
import { addUser } from '../../../../../redux/api/users.api';

const AddUser = ({
    isVisble,
    setIsVisible,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const onFinish = async (values) => {
        setIsLoading(true);
        try {
            Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

            const response = await addUser(values);
            if (response.status === 200) {
                api.success({
                    message: 'Success',
                    description: 'User added successfully'
                });
                setTimeout(() => {
                    setIsVisible(false);
                }, 2000);

            } else {
                api.error({
                    message: 'Error',
                    description: response?.response?.data || 'An error occurred while adding the user. Please try again.'
                });
            }

        } catch (error) {
            console.log(error);
            api.error({
                message: 'Error',
                description: `An error occurred while adding the user. Please try again. ${error.message}`
            });
        }
        setIsLoading(false);
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Add new user"
                width={720}
                open={isVisble}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                onClose={() => setIsVisible(false)}
            >
                <Spin spinning={isLoading} tip='Please wait...'>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="email"
                                    label="User Email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter the user email',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter the user email" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="userName"
                                    label="User Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the user name',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter the user name" showCount maxLength={50} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="firstName"
                                    label="First Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the first name',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter the first name" showCount maxLength={50} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="lastName"
                                    label="Last Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the last name',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter the last name" showCount maxLength={50} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="contactNumber"
                                    label="Enter Contact Number"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter contact number.'
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter contact number" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="role"
                                    label="Select Role"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a role',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select a room type"
                                        options={[
                                            { label: 'Super Admin', value: SUPER_ADMIN },
                                            { label: 'Admin', value: ADMIN },
                                            { label: 'Manager', value: MANAGER },
                                            { label: 'Partnership Manager', value: PARTNERSHIP_MANAGER },
                                            { label: 'Customer Relations Manager', value: CUSTOMER_RELATIONS_MANAGER },
                                            { label: 'Support Agent', value: SUPPORT_AGENT },
                                            { label: 'Tour Guide', value: TOUR_GUIDE },
                                        ]}
                                        maxCount={1}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item>
                            <Button type='primary' htmlType='submit' loading={isLoading}>
                                Add User
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default AddUser