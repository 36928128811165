import React, { useEffect, useState } from 'react'
import { getAllSupportTickets } from '../../../../redux/api/support.api'
import SupportRequestsTable from './SupportRequestsTable'

const Support = () => {
    const [requests, setRequests] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        handleGetRequests()
    }, [])

    const handleGetRequests = async () => {
        setLoading(true)
        const response = await getAllSupportTickets()
        console.log('response', response)
        if (response.status === 200) {
            setRequests(response.data)
        }
        setLoading(false)
    }

    return (
        <div className='payments'>
            <h1>Support Requests</h1>
            <SupportRequestsTable requests={requests} loading={loading} />
        </div>
    )
}

export default Support