import React, { useEffect, useRef, useState } from 'react'
import 'react-quill/dist/quill.snow.css';
import ReactQuill, { Quill } from 'react-quill';
import { useNavigate } from 'react-router-dom';
import ImgCrop from 'antd-img-crop';
import { useDispatch } from 'react-redux';

import { fetchDestinationNamesAC } from '../../../../../../redux/actions/destinations/destinations.ac';
import { addActivity } from '../../../../../../redux/api/activities.api';
import useDestinations from '../../../../../hooks/useDestinations';

import { ACTIVE, INACTIVE, PENDING } from '../../../../../Constants/activeStatusType';
import { ACTIVITIES_CONTAINER, handleImageUpload } from '../../../../../azure/blob';

import { Button, Col, Form, Image, Input, notification, Row, Select, Spin, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons';

import { activityTypes, weatherTypes } from '../../../../../Constants/itemTypes';

import './styles.scss'

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const weatherOptions = Object.keys(weatherTypes).map(key => {
    return {
        label: getOptionName(weatherTypes[key]),
        value: key
    };
});
const activityOptions = Object.keys(activityTypes).map(key => {
    return {
        label: getOptionName(activityTypes[key]),
        value: key
    };
});


const quillModules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
        ["formula"],
        [{ size: ["small", false, "large", "huge"] }],
    ],
    clipboard: {
        matchVisual: false
    },
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
};

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });


const AddActivity = () => {
    const [shortDescriptionCharCount, setShortDescriptionCharCount] = useState(0)
    const [longDescriptionCharCount, setLongDescriptionCharCount] = useState(0)
    const [fileList, setFileList] = useState([])
    const [videoList, setVideoList] = useState([])
    const [thumbnailFile, setThumbnailFile] = useState([]);
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [loadingText, setLoadingText] = useState('')
    const topRef = useRef(null);
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const destinations = useDestinations()
    const destinationNames = destinations.destinationNames

    useEffect(() => {
        if (destinationNames?.length === 0) {
            dispatch(fetchDestinationNamesAC())
        }
    }, [])

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const onVideoChange = ({ fileList: newFileList }) => setVideoList(newFileList);

    const onThumbnailChange = ({ fileList: newFileList }) => setThumbnailFile(newFileList);


    const onFinish = async (values) => {
        topRef.current.scrollTo(0, 0);
        setLoading(true);
        try {
            Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

            let imageUrls = [];
            let videoUrls = [];
            let thumbnailUrl = '';

            const name = values.activityName.replace(/\s/g, '_').toLowerCase();

            // Upload images
            if (fileList.length > 0) {
                setLoadingText('Uploading Images');
                const imageUploadPromises = fileList.map((file, index) => {
                    setLoadingText(`Uploading image ${index + 1}`);
                    return handleImageUpload(
                        ACTIVITIES_CONTAINER,
                        file.originFileObj,
                        file.size,
                        `${name}_${file.name}`
                    ).then(blobRes => {
                        imageUrls.push(blobRes.blockBlobClient.url);
                        setLoadingText(`Uploaded image ${index + 1}`);
                    });
                });
                await Promise.all(imageUploadPromises);
            }

            // Upload videos
            if (videoList.length > 0) {
                setLoadingText('Uploading Videos');
                const videoUploadPromises = videoList.map((file, index) => {
                    setLoadingText(`Uploading video ${index + 1}`);
                    return handleImageUpload(
                        ACTIVITIES_CONTAINER,
                        file.originFileObj,
                        file.size,
                        `${name}_${file.name}`
                    ).then(blobRes => {
                        videoUrls.push(blobRes.blockBlobClient.url);
                        setLoadingText(`Uploaded video ${index + 1}`);
                    });
                });
                await Promise.all(videoUploadPromises);
            }

            // Upload thumbnail
            if (thumbnailFile.length > 0) {
                setLoadingText('Uploading Thumbnail');
                const blobRes = await handleImageUpload(
                    ACTIVITIES_CONTAINER,
                    thumbnailFile[0].originFileObj,
                    thumbnailFile[0].size,
                    `${name}_${thumbnailFile[0].name}`
                );
                thumbnailUrl = blobRes.blockBlobClient.url;
            }

            delete values.activityImages;
            delete values.activityVideos;
            delete values.thumbnailUrl;

            if (values?.tags && values?.tags.length > 0) {
                values.tags = values.tags.join(',');
            }

            if (imageUrls.length > 0) {
                imageUrls = imageUrls.map(url => sanitizeBlobUrls(url));
                values.activityImages = imageUrls.join(',');
            }

            if (videoUrls.length > 0) {
                videoUrls = videoUrls.map(url => sanitizeBlobUrls(url));
                values.activityVideos = videoUrls.join(',');
            }

            if (thumbnailUrl) {
                thumbnailUrl = sanitizeBlobUrls(thumbnailUrl);
                values.thumbnailUrl = thumbnailUrl;
            }

            values.approveStatus = true;

            setLoadingText('Adding Attraction to Database');
            const response = await addActivity(values);
            if (response.status === 200) {
                api.success({
                    message: 'Attraction Added Successfully',
                    description: 'You have successfully added a new attraction'
                });

                setTimeout(() => {
                    navigate('/dashboard/tours/activities');
                }, 2000);
            } else {
                api.error({
                    message: 'Error Adding Attraction',
                    description: 'There was an error adding the attraction. Please try again'
                });
            }
        } catch (error) {
            console.log('Error Adding Attraction', error);
            api.error({
                message: 'Error Adding Attraction',
                description: 'There was an error adding the attraction. Please try again'
            });
        }
        setLoading(false);
    };


    const sanitizeBlobUrls = (blobUrl) => {
        const splitUrl = blobUrl.split('?')
        return splitUrl[0]
    }

    const destinationOptions = Object.keys(destinationNames).map(key => {
        return {
            label: getOptionName(destinationNames[key].destinationName),
            value: destinationNames[key].id
        };
    });

    return (
        <div className='add__destination' ref={topRef}>
            {contextHolder}
            <h2>Add Activity</h2>

            <Spin spinning={loading} tip={loadingText}>
                <Form
                    layout='vertical'
                    onFinish={onFinish}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Activity Name'
                                name='activityName'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter activity name'
                                    }
                                ]}
                            >
                                <Input placeholder='Activity Name' showCount maxLength={200} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Destination'
                                name='destinationId'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select destination'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Select Destination"
                                    options={destinationOptions}
                                    maxCount={1}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={`Activity Short Description ( ${shortDescriptionCharCount}/1000 characters)`}
                                name='activityDescriptionShort'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter activity short description'
                                    }
                                ]}
                            >
                                <ReactQuill
                                    theme="snow"
                                    limits={{ maxLength: 500 }}
                                    onChange={(content, delta, source, editor) => {
                                        setShortDescriptionCharCount(editor.getLength())
                                    }}
                                    modules={quillModules}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={`Activity Description ( ${longDescriptionCharCount}/10000 characters)`}
                                name='activityDescription'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter activity description'
                                    }
                                ]}
                            >
                                <ReactQuill
                                    theme="snow"
                                    limits={{ maxLength: 10000 }}
                                    onChange={(content, delta, source, editor) => {
                                        setLongDescriptionCharCount(editor.getLength())
                                    }}
                                    modules={quillModules}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={`Activity Rules ( ${longDescriptionCharCount}/500 characters)`}
                                name='rules'
                            >
                                <ReactQuill
                                    theme="snow"
                                    limits={{ maxLength: 500 }}
                                    onChange={(content, delta, source, editor) => {
                                        setLongDescriptionCharCount(editor.getLength())
                                    }}
                                    modules={quillModules}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Activity Type'
                                name='activityType'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select activity type'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Add Activity Type"
                                    options={activityOptions}
                                    maxCount={1}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Activity City'
                                name='activityCity'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add activity city'
                                    }
                                ]}
                            >
                                <Input placeholder='Activity City' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Activity Coordinates'
                                name='activityCoordinates'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input activity coordinates'
                                    }
                                ]}
                            >
                                <Input placeholder='Activity Coordinates' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Activity Map Location'
                                name='mapLocation'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add activity map location'
                                    }
                                ]}
                            >
                                <Input placeholder='Activity Map Location' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Activity Weather'
                                name='weather'
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Add weather"
                                    options={weatherOptions}
                                    maxCount={1}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Ticket Price (in USD). Add 0 if free'
                                name='ticketPrice'
                            >
                                <Input type='number' placeholder='Ticket Price' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Activity Tags'
                                name='tags'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add tags'
                                    }
                                ]}
                            >
                                <Select
                                    mode="tags"
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Add activity tags"
                                    options={[]}
                                    maxCount={5}
                                />
                            </Form.Item>
                        </Col>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '50%'
                            }}
                        >
                            <Col span={12}>
                                <Form.Item
                                    label='Min Age'
                                    name='minAge'
                                >
                                    <Input type='number' placeholder='Min Age' max={80} min={2} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label='Max Age'
                                    name='maxAge'
                                >
                                    <Input type='number' placeholder='Max Age' max={80} min={2} />
                                </Form.Item>
                            </Col>
                        </div>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Upload Activity Images (Max 10)'
                                name='activityImages'
                            >
                                <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                    multiple={true}
                                    maxCount={10}
                                    beforeUpload={(file) => {
                                        return false;
                                    }}
                                >
                                    {fileList.length <= 10 && '+ Upload'}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Upload Activity Videos (Max 3)'
                                name='activityVideos'
                            >
                                <Upload
                                    listType="picture"
                                    fileList={videoList}
                                    onChange={onVideoChange}
                                    multiple={true}
                                    maxCount={3}
                                    beforeUpload={(file) => {
                                        return false;
                                    }}
                                >
                                    {videoList?.length < 3 && <Button icon={<PlusOutlined />}>Upload</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Upload Activity Thumbnail Image'
                                name='thumbnailUrl'
                            >
                                <ImgCrop
                                    rotationSlider
                                    modalTitle='Crop Image'
                                    modalOk='Crop'
                                    modalCancel='Cancel'
                                    showReset={true}
                                    aspect={16 / 9}
                                >
                                    <Upload
                                        listType="picture-card"
                                        fileList={thumbnailFile}
                                        onChange={onThumbnailChange}
                                        multiple={false}
                                        beforeUpload={(file) => {
                                            return false;
                                        }}
                                    >
                                        {thumbnailFile.length < 1 && '+ Upload'}
                                    </Upload>
                                </ImgCrop>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Col span={12}>
                        <Form.Item
                            label='Active Status'
                            name='activeStatus'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select active status'
                                }
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Select Active Status"
                                options={[
                                    {
                                        label: 'Active',
                                        value: ACTIVE
                                    },
                                    {
                                        label: 'Inactive',
                                        value: INACTIVE
                                    },
                                    {
                                        label: 'Pending',
                                        value: PENDING
                                    }
                                ]}
                                maxCount={1}
                            />
                        </Form.Item>
                    </Col>

                    <Form.Item>
                        <Button type='primary' htmlType='submit'>
                            Add Destination
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>

            {previewImage && (
                <Image
                    wrapperStyle={{
                        display: 'none',
                    }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </div>
    )
}

export default AddActivity