import React from 'react'
import { capitalizeFisrtLetterEachWord } from '../../../../../../helpers/nameRender'
import { Button, Divider } from 'antd';

import './styles.scss'
import moment from 'moment';
import { BANK_TRANSFER, INSTALMENT, PAID, PENDING_PAYMENT, PENDING_VERIFICATION } from '../../../../../../Constants/finance';
import { useNavigate } from 'react-router-dom';

const BookingPayments = ({ userTour }) => {
    const navigate = useNavigate()

    const calculateDueDate = (date, days) => {
        const newDate = moment(date, 'MMM D YYYY h:mma').add(days, 'days');
        return <span style={{ color: newDate.isBefore(moment()) ? 'red' : 'green' }}>{newDate.format('MMM D YYYY h:mma')}</span>
    }
    const calculateDue = (date, days) => {
        const newDate = moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSZ').add(days, 'days');
        return <span style={{ color: newDate.isBefore(moment()) ? 'red' : 'green' }}>{newDate.format('MMM D YYYY h:mma')}</span>
    }

    return (
        <div className='booking_payments'>
            <h3 className='header_3' style={{ marginBottom: '10px' }}>Booking Payments</h3>
            <div className='p_container'>
                <p><span>Payment Method: </span>{userTour?.paymentMethod ? capitalizeFisrtLetterEachWord(userTour?.paymentMethod) : 'Contsct Us'}</p>
                <p
                    style={{
                        color: userTour?.paymentStatus === PAID ? '#52C41A' : userTour?.paymentStatus === PENDING_VERIFICATION ? '#ffae1f' : 'red'
                    }}
                ><span>Payment Status: </span>{userTour?.paymentStatus ? capitalizeFisrtLetterEachWord(userTour?.paymentStatus) : 'Contact Us'}</p>
                {userTour?.paymentMethod === BANK_TRANSFER && userTour?.paymentStatus === PENDING_PAYMENT &&
                    <p><span>Payment Due: </span>  {calculateDueDate(userTour?.createdAt, 3)}</p>}
            </div>

            <h4 className='header_4' style={{ margin: '15px 0 10px 0' }}>
                Tour Charges
            </h4>
            <div className='p_container'>
                <p><span>Sub Amount: </span>{userTour?.totalAmount} AUD</p>
                {userTour?.tourPackage?.discountAmount && <p><span>Discount: </span>{userTour?.discountAmount} %</p>}
                <p><span>Tax & Service Charge: </span>{userTour?.tax} %</p>
                <p><span>Total: </span>{userTour?.finalAmount} AUD</p>
            </div>


            {userTour?.paymentMethod === BANK_TRANSFER && userTour?.paymentStatus === PENDING_VERIFICATION &&
                <>
                    <Divider />
                    <div className='payment_splip_submit'>
                        <h4 className='header_4'>
                            Payment Slip Submitted
                        </h4>
                        <a
                            href={`/dashboard/payments/${userTour?.paymentId}`}
                            target='_blank'
                            rel='noreferrer'
                            style={{ marginBottom: '10px' }}
                        >
                            <Button>
                                View & Verify Payment
                            </Button>
                        </a>
                        <p
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px',
                            }}
                        >
                            Payment Status: <p style={{ color: '#ffae1f' }}>Pending Verification</p>
                        </p>
                        <p >
                            Payment Id: <a
                                href={`/dashboard/payments/${userTour?.paymentId}`}
                                target='_blank'
                                rel='noreferrer'
                            >{userTour?.paymentId}</a>
                        </p>
                        <p>
                            Amount: {userTour?.finalAmount} AUD
                        </p>
                    </div>
                </>
            }
            {userTour?.paymentMethod === BANK_TRANSFER && userTour?.paymentStatus === PAID &&
                <>
                    <div className='payment_splip_submit'>
                        <h4 className='header_4'>
                            Payment Verified
                        </h4>
                        <p
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px',
                            }}
                        >
                            Payment Status: <p style={{ color: '#52C41A' }}>Paid</p>
                        </p>
                        <p >
                            Payment Id: <a
                                href={`/dashboard/payments/${userTour?.paymentId}`}
                                target='_blank'
                                rel='noreferrer'
                            >{userTour?.paymentId}</a>
                        </p>
                        <p >
                            Amount: {userTour?.finalAmount} AUD
                        </p>
                    </div>
                </>
            }
            {userTour?.paymentMethod === INSTALMENT && userTour?.paymentStatus === PENDING_PAYMENT &&
                <>
                    <div className='payment_splip_submit'>
                        <h4 className='header_4'>
                            Your Monthly Instalment
                        </h4>
                        <p>
                            You have selected to pay via monthly instalments. Please submit your payment slip here before the due date. We will verify your payment and confirm your booking.
                        </p>
                        <div className='instalment_plan_container'>
                            {
                                userTour?.instalmentPayments?.map((instalment, index) => (
                                    <div key={index} className='instalment_plan'>
                                        <h5>Instalment {index + 1}</h5>
                                        <div className='details_container'>
                                            <div className='left'>
                                                <p>Amount: {instalment?.amount} AUD</p>
                                                <p>Payment Date: {calculateDue(instalment?.paymentDate, 0)}</p>
                                                <p>Due Date: {calculateDue(instalment?.dueDate, 0)}</p>
                                                <p>Status: {instalment?.isPaid ? 'Paid' : 'Pending'}</p>
                                                <>
                                                    <Divider style={{ margin: '5px 0' }} />
                                                    <p>Payment ID: {instalment?.payment?.id}</p>
                                                    <p><span>Payment Status: </span>{instalment?.payment?.paymentStatus ? capitalizeFisrtLetterEachWord(instalment?.payment?.paymentStatus) : 'Contact Us'}</p>
                                                </>
                                            </div>
                                            <div className='action'>
                                                {!instalment?.paymentId ? <>
                                                    Not Paid
                                                </>
                                                    :
                                                    <Button
                                                        onClick={() => {
                                                            navigate(`/dashboard/home/payment-information/${instalment?.payment?.id}`);
                                                        }}
                                                        type='link'>View My Payment</Button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default BookingPayments