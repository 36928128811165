import {
    FETCH_HOTELS,
    FETCH_HOTELS_SUCCESS,
    FETCH_HOTELS_FAIL,

} from '../../types';

const initialState = {
    loading: false,
    hotels: [],
    error: null
};

export const hotelsReducer = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case FETCH_HOTELS:
            return {
                ...state,
                loading: true
            };
        case FETCH_HOTELS_SUCCESS:
            return {
                ...state,
                loading: false,
                hotels: action.payload,
                error: null
            };
        case FETCH_HOTELS_FAIL:
            return {
                ...state,
                loading: false,
                hotels: [],
                error: action.payload
            };
        default:
            return state;
    }
}