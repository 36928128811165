import { LOGIN_REQUEST } from "./authConfig";

export const getAccessToken = async (instance) => {
    try {
        const response = await instance.acquireTokenSilent({
            ...LOGIN_REQUEST,
        });
        return response.accessToken;
    } catch (error) {
        console.error('Access Token Fetch Error', error);
        instance.handleRedirectPromise()
            .then((response) => {
                if (!response) {
                    instance.loginRedirect({
                        ...LOGIN_REQUEST,
                    })
                }
            })
    }
}