import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Drawer, Form, Input, Row, Select, Spin, Upload, notification } from 'antd';
import { guideLanguages, tourGuideTypes } from '../../../../../../Constants/itemTypes';
import { editGuideProfile } from '../../../../../../../redux/api/guides.api';
import { PlusOutlined } from '@ant-design/icons';
import { ACTIVE, INACTIVE, ONUSE, PENDING } from '../../../../../../Constants/activeStatusType';
import { handleDeleteBlob, handleImageUpload, PARTNERS_BLOB } from '../../../../../../azure/blob';
import { v4 as uuidv4 } from 'uuid'
import useMyProfile from '../../../../../../hooks/useMyProfile';
import { ADMIN, SUPER_ADMIN } from '../../../../../../Constants/roles';

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const guideTypeOptions = Object.keys(tourGuideTypes).map(key => {
    return {
        label: getOptionName(tourGuideTypes[key]),
        value: key
    };
});

const guideLanguageOptions = Object.keys(guideLanguages).map(key => {
    return {
        label: getOptionName(guideLanguages[key]),
        value: key
    };
});

const EditGuide = ({ isVisible, setIsVisible, guide, handleGetGuide }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [videoList, setVideoList] = useState([])
    const formRef = useRef(null);
    const [loadingText, setLoadingText] = useState('');
    const myProfile = useMyProfile().userProfile

    useEffect(() => {
        if (guide) {
            formRef?.current?.setFieldsValue({
                activeStatus: guide.activeStatus,
                guideType: guide.guideType,
                guideName: guide.guideName,
                guideArea: guide.guideArea,
                guideLanguages: guide.guideLanguages ? guide.guideLanguages.split(',') : [],
                vehicleAvailable: guide.vehicleAvailable ? 'true' : 'false'
            });

            if (guide.introVideoUrl) {
                setVideoList([
                    {
                        uid: '-1',
                        name: 'introVideoUrl',
                        status: 'done',
                        url: guide.introVideoUrl
                    }
                ]);
            }
        }
    }, [isVisible]);

    const onFinish = async (values) => {
        setIsLoading(true);
        try {
            Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

            let videoUrl = '';

            const newVideo = videoList.filter(file => !file.url);
            const deletedVideo = guide.introVideoUrl?.split(',').filter(introVideoUrl => !videoList.find(file => file.url === introVideoUrl));

            if (newVideo?.length > 0) {
                setLoadingText('Uploading Video');
                const videoUploadPromises = newVideo.map(async (file, index) => {
                    setLoadingText(`Uploading video ${index + 1}`);

                    let id = uuidv4();
                    id = id.replace(/-/g, '');
                    let extention = file?.name?.split('.').pop();
                    let name = `${id}.${extention}`;

                    return handleImageUpload(
                        PARTNERS_BLOB,
                        file.originFileObj,
                        file.size,
                        name
                    ).then((response) => {
                        videoUrl = response.blockBlobClient.url;
                    });
                });
                await Promise.all(videoUploadPromises);
            }

            if (deletedVideo?.length > 0) {
                setLoadingText('Deleting Video');
                const thumbnailDeletePromises = deletedVideo.map(async (video, index) => {
                    const blobName = getBlobName(video);
                    setLoadingText(`Deleting Video ${index + 1}`);
                    return handleDeleteBlob(PARTNERS_BLOB, blobName);
                });
                await Promise.all(thumbnailDeletePromises);
            }

            let updatedVideo = videoUrl || (videoList[0]?.url || null);

            if (updatedVideo) {
                updatedVideo = sanitizeBlobUrls(updatedVideo);
            }

            values.introVideoUrl = updatedVideo;

            if (values.vehicleAvailable === 'true') {
                values.vehicleAvailable = true;
            } else {
                values.vehicleAvailable = false;
            }

            if (values.guideLanguages) {
                values.guideLanguages = values.guideLanguages.join(',');
            }

            values.id = guide.id;

            setLoadingText('Updating Guide on DB');
            const response = await editGuideProfile(values);
            if (response.status === 200) {
                api.success({
                    message: 'Success',
                    description: 'Guide updated successfully'
                });
                await handleGetGuide(guide.id);
                setIsVisible(false);
            } else {
                api.error({
                    message: 'Error',
                    description: response?.response?.data || 'An error occurred while updating the guide. Please try again.'
                });
            }

        } catch (error) {
            console.log(error);
            api.error({
                message: 'Error',
                description: `An error occurred while adding the guide. Please try again. ${error.message}`
            });
        }
        setIsLoading(false);
    };

    const getBlobName = (blobUrl) => {
        const splitUrl = blobUrl.split('/')
        return splitUrl[splitUrl.length - 1]
    }

    const sanitizeBlobUrls = (blobUrl) => {
        const splitUrl = blobUrl.split('?')
        return splitUrl[0]
    }

    const onVideoChange = ({ fileList: newFileList }) => setVideoList(newFileList);

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Edit Tour Guide"
                width={720}
                open={isVisible}
                onClose={() => setIsVisible(false)}
            >
                <Spin spinning={isLoading} tip={loadingText}>
                    <Form layout="vertical" onFinish={onFinish} ref={formRef}>
                        <Row gutter={16}>
                            {(myProfile?.role === SUPER_ADMIN || myProfile?.role === ADMIN) &&
                                <Col span={12}>
                                    <Form.Item
                                        name="activeStatus"
                                        label="Active Status"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select user active status',
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Select Active Status"
                                            options={[
                                                {
                                                    label: 'Active',
                                                    value: ACTIVE
                                                },
                                                {
                                                    label: 'Inactive',
                                                    value: INACTIVE
                                                },
                                                {
                                                    label: 'Pending',
                                                    value: PENDING
                                                },
                                                {
                                                    label: 'On Use',
                                                    value: ONUSE
                                                }
                                            ]}
                                            maxCount={1}
                                        />
                                    </Form.Item>
                                </Col>
                            }

                            <Col span={12}>
                                <Form.Item
                                    name="guideType"
                                    label="Select Guide Type"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select guide type',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearchsa
                                        mode='single'
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Guide Type"
                                        options={guideTypeOptions}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="guideName"
                                    label="Guide Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter guide name',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Guide Name" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="guideArea"
                                    label="Select Guide Area"
                                >
                                    <Input placeholder="Guide Area" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="guideLanguages"
                                    label="Guide Spoken Languages"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select guide languages',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        mode='multiple'
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Guide Languages"
                                        options={guideLanguageOptions}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="vehicleAvailable"
                                    label="Select Vehicle Available"
                                >
                                    <Select
                                        showSearch
                                        mode='single'
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Vehicle Available"
                                        options={[
                                            {
                                                label: 'Yes',
                                                value: 'true'
                                            },
                                            {
                                                label: 'No',
                                                value: 'false'
                                            }
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label='Upload Videos (Max 1)'
                                    name='introVideoUrl'
                                >
                                    <Upload
                                        listType="picture"
                                        fileList={videoList}
                                        onChange={onVideoChange}
                                        multiple={true}
                                        maxCount={1}
                                        beforeUpload={(file) => {
                                            return false;
                                        }}
                                    >
                                        {videoList?.length < 1 && <Button icon={<PlusOutlined />}>Upload</Button>}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>


                        <Form.Item>
                            <Button type='primary' htmlType='submit' loading={isLoading}>
                                Update Guide
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Drawer>
        </div>
    );
};

export default EditGuide;
