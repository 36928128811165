import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Divider, Drawer, Form, Image, Input, Row, Select, Spin, Upload, notification } from 'antd'
import { partnerTypes } from '../../../../../../Constants/itemTypes';
import { handleDeleteBlob, handleImageUpload, PARTNERS_BLOB } from '../../../../../../azure/blob';
import { editPartnerProfile } from '../../../../../../../redux/api/partners.api';
import { v4 as uuidv4 } from 'uuid'

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const partnerOptions = Object.keys(partnerTypes).map(key => {
    return {
        label: getOptionName(partnerTypes[key]),
        value: key
    };
});


const EditPartnerProfile = ({
    editProfileVisible,
    setEditProfileVisible,
    partner,
    handleGetPartner,
}) => {
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [companyNameLogoUrlFile, setCompanyNameLogoUrlFile] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('Updating...');
    const [api, contextHolder] = notification.useNotification();
    const formRef = useRef();

    useEffect(() => {
        if (partner && formRef.current) {
            formRef.current.setFieldsValue({
                companyName: partner.companyName,
                companyNameDescription: partner.companyNameDescription,
                partnerType: partner.partnerType,
                companyNameEmail: partner.companyNameEmail,
                companyNameContactNumber: partner.companyNameContactNumber,
                companyNameAddress: partner.companyNameAddress,
                companyNameCode: partner.companyNameCode,
                companyNameCountry: partner.companyNameCountry,
                companyNameState: partner.companyNameState,
                companyNameCity: partner.companyNameCity,
                companyNameWebsite: partner.companyNameWebsite,
                bankName: partner.bankName,
                bankBranch: partner.bankBranch,
                bankAccountNumber: partner.bankAccountNumber,
                bankSwiftCode: partner.bankSwiftCode,
            });

            if (partner.companyNameLogoUrl) {
                setCompanyNameLogoUrlFile([
                    {
                        uid: '-1',
                        name: 'image.png',
                        status: 'done',
                        url: partner.companyNameLogoUrl,
                    },
                ]);
            }
        }
    }, [partner, editProfileVisible]);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = ({ fileList: newFileList }) => setCompanyNameLogoUrlFile(newFileList);

    const onFinish = async (values) => {
        setIsLoading(true);
        try {
            Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

            let companyNameLogoUrl = '';

            // find new images and list images need to be deleted
            const newLogo = companyNameLogoUrlFile.filter(file => !file.url);
            const deletedLogo = partner.companyNameLogoUrl?.split(',').filter(logo => !companyNameLogoUrlFile.find(file => file.url === logo));

            if (newLogo?.length > 0) {
                setLoadingText('Uploading Images');
                const imageUploadPromises = newLogo?.map(async (file, index) => {
                    setLoadingText(`Uploading image ${index + 1}`);

                    let id = uuidv4();
                    id = id.replace(/-/g, '');
                    let extention = file?.name?.split('.').pop();
                    let name = `${id}.${extention}`;

                    return handleImageUpload(
                        PARTNERS_BLOB,
                        file?.originFileObj,
                        file?.size,
                        name
                    ).then((response) => {
                        companyNameLogoUrl = response.blockBlobClient.url;
                    });
                });
                await Promise.all(imageUploadPromises);
            }

            // delete blobs
            if (deletedLogo?.length > 0) {
                setLoadingText('Deleting Images');
                const imageDeletePromises = deletedLogo.map(async (image, index) => {
                    const blobName = getBlobName(image);
                    setLoadingText(`Deleting image ${index + 1}`);
                    return handleDeleteBlob(PARTNERS_BLOB, blobName);
                });
                await Promise.all(imageDeletePromises);
            }

            let updatedLogo = companyNameLogoUrl || (companyNameLogoUrlFile[0]?.url || '')

            updatedLogo = sanitizeBlobUrls(updatedLogo);

            const data = {
                ...values,
                companyNameLogoUrl: updatedLogo === '' ? null : updatedLogo,
                id: partner.id,
            }

            setLoadingText('Updating DB...');
            const response = await editPartnerProfile(data);
            if (response.status === 200) {
                api.success({
                    message: 'Success',
                    description: 'Partner profile updated successfully'
                });
                await handleGetPartner(partner.id);
                setTimeout(() => {
                    setEditProfileVisible(false);
                }, 2000);

            } else {
                api.error({
                    message: 'Error',
                    description: 'An error occurred while updating the partner profile. Please try again.'
                });
            }
        } catch (error) {
            console.log(error);
            api.error({
                message: 'Error',
                description: `An error occurred while updating the partner profile. ${error.message}`
            });
        }
        setIsLoading(false);
    }

    const getBlobName = (blobUrl) => {
        const splitUrl = blobUrl?.split('/')
        return splitUrl[splitUrl.length - 1]
    }

    const sanitizeBlobUrls = (blobUrl) => {
        const splitUrl = blobUrl?.split('?')
        return splitUrl[0]
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title={`Edit Partner Profile`}
                width={720}
                open={editProfileVisible}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                onClose={() => setEditProfileVisible(false)}
            >
                <Spin spinning={isLoading} tip={loadingText}>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        ref={formRef}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="companyName"
                                    label="Company Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter company name',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter company name" showCount maxLength={50} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="partnerType"
                                    label="Partner Type"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a partner type',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select a partner type"
                                        options={partnerOptions}
                                        maxCount={1}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="companyNameDescription"
                                    label="Company Description"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Add a description for the company',
                                        },
                                    ]}
                                >
                                    <Input.TextArea rows={4} placeholder="Add a description for the company" showCount max={400} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="companyNameEmail"
                                    label="Company Email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the company email',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter the company email" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="companyNameContactNumber"
                                    label="Company Contact Number"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter company contact number',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter company contact number" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="companyNameAddress"
                                    label="Company Address"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter company address',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter company address" max={100} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="companyNameCode"
                                    label="Company Post Code"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter company post code',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter company post code" max={5} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="companyNameCountry"
                                    label="Company Country"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter company country',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter company country" max={10} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="companyNameState"
                                    label="Company State"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter company state',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter company state" max={10} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="companyNameCity"
                                    label="Company City"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter company city',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter company city" max={10} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="companyNameWebsite"
                                    label="Company Website"
                                >
                                    <Input placeholder="Please enter company website" max={50} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Divider />

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="bankName"
                                    label="Bank Name"
                                >
                                    <Input placeholder="Please enter bank name" max={20} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="bankBranch"
                                    label="Bank Branch"
                                >
                                    <Input placeholder="Please enter bank branch" max={50} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="bankAccountNumber"
                                    label="Bank Account Number"
                                >
                                    <Input placeholder="Please enter bank account number" max={20} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="bankSwiftCode"
                                    label="Bank Swift Code"
                                >
                                    <Input placeholder="Please enter bank swift code" max={50} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label='Upload Image'
                                    name='companyNameLogoUrl'
                                >
                                    <Upload
                                        listType="picture-card"
                                        fileList={companyNameLogoUrlFile}
                                        onPreview={handlePreview}
                                        onChange={handleChange}
                                        multiple={true}
                                        maxCount={10}
                                        beforeUpload={(file) => {
                                            return false;
                                        }}
                                    >
                                        {companyNameLogoUrlFile.length < 1 && '+ Upload'}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item>
                            <Button type='primary' htmlType='submit' loading={isLoading}>
                                Update Parner Profile
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Drawer>


            {previewImage && (
                <Image
                    wrapperStyle={{
                        display: 'none',
                    }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </div>
    )
}

export default EditPartnerProfile