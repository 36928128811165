import React, { useEffect, useRef, useState } from 'react'
import 'react-quill/dist/quill.snow.css';
import ReactQuill, { Quill } from 'react-quill';
import { useNavigate } from 'react-router-dom';
import ImgCrop from 'antd-img-crop';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid'

import { fetchDestinationNamesAC } from '../../../../../../redux/actions/destinations/destinations.ac';
import useDestinations from '../../../../../hooks/useDestinations';

import { handleImageUpload, TOUR_PACKAGES_BLOB } from '../../../../../azure/blob';

import { Button, Col, Divider, Form, Image, Input, notification, Row, Select, Spin, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons';

import { paymentTypes, tourTypes } from '../../../../../Constants/itemTypes';

import './styles.scss'
import useAttractions from '../../../../../hooks/useAttractions';
import useActivities from '../../../../../hooks/useActivities';
import useEvents from '../../../../../hooks/useEvents';
import { fetchAttractionNamesAC } from '../../../../../../redux/actions/attractions/attractions.ac';
import { fetchActivityNamesAC } from '../../../../../../redux/actions/activities/activities.ac';
import { fetchEventNamesAC } from '../../../../../../redux/actions/events/events.ac';
import { addTourPackage } from '../../../../../../redux/api/tourPackages.api';
import { ACTIVE, INACTIVE, PENDING } from '../../../../../Constants/activeStatusType';

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};


const tourTypeOPtions = Object.keys(tourTypes).map(key => {
    return {
        label: getOptionName(tourTypes[key]),
        value: key
    };
});

const paymentOptions = Object.keys(paymentTypes).map(key => {
    return {
        label: getOptionName(paymentTypes[key]),
        value: key
    };
});


const quillModules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
        ["formula"],
        [{ size: ["small", false, "large", "huge"] }],
    ],
    clipboard: {
        matchVisual: false
    },
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
};

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });


const AddTourPackage = () => {
    const [shortDescriptionCharCount, setShortDescriptionCharCount] = useState(0)
    const [longDescriptionCharCount, setLongDescriptionCharCount] = useState(0)
    const [howItWorksCharCount, setHowItWorksCharCount] = useState(0)
    const [notesCharCount, setNotesCharCount] = useState(0)

    const [fileList, setFileList] = useState([])
    const [videoList, setVideoList] = useState([])
    const [thumbnailFile, setThumbnailFile] = useState([]);
    const [itineraryFile, setItineraryFile] = useState([]);

    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [loadingText, setLoadingText] = useState('')
    const topRef = useRef(null);
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const destinations = useDestinations()
    const destinationNames = destinations.destinationNames
    const attractionNames = useAttractions().attarctionNames
    const activityNames = useActivities().activityNames
    const eventNames = useEvents().eventNames

    useEffect(() => {
        if (destinationNames?.length === 0) {
            dispatch(fetchDestinationNamesAC())
        }

        if (attractionNames?.length === 0) {
            dispatch(fetchAttractionNamesAC())
        }

        if (activityNames?.length === 0) {
            dispatch(fetchActivityNamesAC())
        }

        if (eventNames?.length === 0) {
            dispatch(fetchEventNamesAC())
        }

    }, [])

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const onVideoChange = ({ fileList: newFileList }) => setVideoList(newFileList);

    const onThumbnailChange = ({ fileList: newFileList }) => setThumbnailFile(newFileList);

    const onItineryFileChange = ({ fileList: newFileList }) => setItineraryFile(newFileList);

    function extractSrcFromIframe(iframeString) {
        const srcMatch = iframeString.match(/src="([^"]+)"/);
        return srcMatch ? srcMatch[1] : null;
    }

    const onFinish = async (values) => {
        topRef.current.scrollTo(0, 0);
        setLoading(true);
        try {
            Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

            let imageUrls = [];
            let videoUrls = [];
            let thumbnailUrl = '';
            let itineraryUrl = '';

            // Upload images
            if (fileList.length > 0) {
                setLoadingText('Uploading Images');
                const imageUploadPromises = fileList.map((file, index) => {
                    setLoadingText(`Uploading image ${index + 1}`);

                    let id = uuidv4();
                    id = id.replace(/-/g, '');
                    let extention = file?.name?.split('.').pop();
                    let name = `${id}.${extention}`;

                    return handleImageUpload(
                        TOUR_PACKAGES_BLOB,
                        file.originFileObj,
                        file.size,
                        name
                    ).then(blobRes => {
                        imageUrls.push(blobRes.blockBlobClient.url);
                        setLoadingText(`Uploaded image ${index + 1}`);
                    });
                });
                await Promise.all(imageUploadPromises);
            }

            // Upload videos
            if (videoList.length > 0) {
                setLoadingText('Uploading Videos');
                const videoUploadPromises = videoList.map((file, index) => {
                    setLoadingText(`Uploading video ${index + 1}`);

                    let id = uuidv4();
                    id = id.replace(/-/g, '');
                    let extention = file?.name?.split('.').pop();
                    let name = `${id}.${extention}`;

                    return handleImageUpload(
                        TOUR_PACKAGES_BLOB,
                        file.originFileObj,
                        file.size,
                        name
                    ).then(blobRes => {
                        videoUrls.push(blobRes.blockBlobClient.url);
                        setLoadingText(`Uploaded video ${index + 1}`);
                    });
                });
                await Promise.all(videoUploadPromises);
            }

            // Upload thumbnail
            if (thumbnailFile.length > 0) {
                setLoadingText('Uploading Thumbnail');

                let id = uuidv4();
                id = id.replace(/-/g, '');
                let extention = thumbnailFile[0]?.name?.split('.').pop();
                let name = `${id}.${extention}`;

                const blobRes = await handleImageUpload(
                    TOUR_PACKAGES_BLOB,
                    thumbnailFile[0].originFileObj,
                    thumbnailFile[0].size,
                    name
                );
                thumbnailUrl = blobRes.blockBlobClient.url;
            }

            // Upload Itinerary

            if (itineraryFile.length > 0) {
                setLoadingText('Uploading Itinerary');

                let id = uuidv4();
                id = id.replace(/-/g, '');
                let extention = thumbnailFile[0]?.name?.split('.').pop();
                let name = `${id}.${extention}`;

                const blobRes = await handleImageUpload(
                    TOUR_PACKAGES_BLOB,
                    thumbnailFile[0].originFileObj,
                    thumbnailFile[0].size,
                    name
                );

                itineraryUrl = blobRes.blockBlobClient.url;
            }

            delete values.packageImages;
            delete values.packageVideos;
            delete values.thumbnailUrl;
            delete values.itineraryDownloadUrl;

            if (values?.tags && values?.tags.length > 0) {
                values.tags = values.tags.join(',');
            }

            if (values?.destinations && values?.destinations.length > 0) {
                values.destinations = values.destinations.join(',');
            }

            if (values?.attractions && values?.attractions.length > 0) {
                values.attractions = values.attractions.join(',');
            }

            if (values?.activities && values?.activities.length > 0) {
                values.activities = values.activities.join(',');
            }

            if (values?.events && values?.events.length > 0) {
                values.events = values.events.join(',');
            }

            if (values?.paymentOptions && values?.paymentOptions.length > 0) {
                values.paymentOptions = values.paymentOptions.join(',');
            }

            // Assets

            if (imageUrls.length > 0) {
                imageUrls = imageUrls.map(url => sanitizeBlobUrls(url));
                values.packageImages = imageUrls.join(',');
            }

            if (videoUrls.length > 0) {
                videoUrls = videoUrls.map(url => sanitizeBlobUrls(url));
                values.packageVideos = videoUrls.join(',');
            }

            if (thumbnailUrl) {
                thumbnailUrl = sanitizeBlobUrls(thumbnailUrl);
                values.thumbnailUrl = thumbnailUrl;
            }

            if (itineraryUrl) {
                itineraryUrl = sanitizeBlobUrls(itineraryUrl);
                values.itineraryDownloadUrl = itineraryUrl;
            }

            if (values.routeMap) {
                values.routeMap = extractSrcFromIframe(values.routeMap);
            }

            values.approveStatus = true;

            setLoadingText('Adding to Database');
            const response = await addTourPackage(values);
            if (response.status === 200) {
                api.success({
                    message: 'Tour Package Added Successfully',
                    description: 'You have successfully added a new tour package'
                });

                setTimeout(() => {
                    navigate('/dashboard/tours/tour-packages');
                }, 2000);
            } else {
                api.error({
                    message: 'Error Adding Tour Package',
                    description: 'There was an error adding the tour package. Please try again'
                });
            }
        } catch (error) {
            console.log('Error Adding Tour Package', error);
            api.error({
                message: 'Error Adding Tour Package',
                description: 'There was an error adding the tour package. Please try again'
            });
        }
        setLoading(false);
    };


    const sanitizeBlobUrls = (blobUrl) => {
        const splitUrl = blobUrl.split('?')
        return splitUrl[0]
    }

    const destinationOptions = Object.keys(destinationNames).map(key => {
        return {
            label: getOptionName(destinationNames[key].destinationName),
            value: destinationNames[key].id
        };
    });

    const attractionOptions = Object.keys(attractionNames).map(key => {
        return {
            label: getOptionName(attractionNames[key].attractionName),
            value: attractionNames[key].id
        };
    });

    const activityOptions = Object.keys(activityNames).map(key => {
        return {
            label: getOptionName(activityNames[key].activityName),
            value: activityNames[key].id
        };
    });

    const eventOptions = Object.keys(eventNames).map(key => {
        return {
            label: getOptionName(eventNames[key].eventName),
            value: eventNames[key].id
        };
    })

    return (
        <div className='add__destination' ref={topRef}>
            {contextHolder}
            <h2>
                Add New Tour Package
            </h2>

            <Spin spinning={loading} tip={loadingText}>
                <Form
                    layout='vertical'
                    onFinish={onFinish}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Tour Package Name'
                                name='packageName'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter tour package name'
                                    }
                                ]}
                            >
                                <Input placeholder='Tour Package Name' showCount maxLength={200} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label='Tour Package Tags'
                                name='tags'
                            >
                                <Select
                                    mode="tags"
                                    showSearch
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Add Package Tags"
                                    options={[]}
                                    maxCount={5}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Tour Type'
                                name='tourType'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select tour type'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Select Tour Type"
                                    options={tourTypeOPtions}
                                    maxCount={1}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Tour Package Duration (Days)'
                                name='durationDays'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add accommodation duration days'
                                    }
                                ]}
                            >
                                <Input placeholder='Tour Package Duration' type='number' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Tour Package Duration (Nights)'
                                name='durationNights'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add package duration nights'
                                    }
                                ]}
                            >
                                <Input placeholder='Tour Package Duration' type='number' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Max Group Size'
                                name='maxGroupSize'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add max group size'
                                    }
                                ]}
                            >
                                <Input placeholder='Max Group Size' type='number' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Min Age of Travellers'
                                name='minAge'
                            >
                                <Input placeholder='Min Age of Travellers' type='number' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Max Age of Travellers'
                                name='maxAge'
                                rules={[{
                                    required: true,
                                    message: 'Please add max age of travellers'
                                }]}
                            >
                                <Input placeholder='Max Age of Travellers' type='number' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Tour Package Destinations'
                                name='destinations'
                                rules={[{
                                    required: true,
                                    message: 'Please add package destinations'
                                }]}
                            >
                                <Select
                                    mode="multiple"
                                    showSearch
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Add Package Destinations"
                                    options={destinationOptions}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Tour Package Attractions'
                                name='attractions'
                            >
                                <Select
                                    mode="multiple"
                                    showSearch
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Add Package Attractions"
                                    options={attractionOptions}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Tour Package Activities'
                                name='activities'
                            >
                                <Select
                                    mode="multiple"
                                    showSearch
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Add Package Activities"
                                    options={activityOptions}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Tour Package Events'
                                name='events'
                            >
                                <Select
                                    mode="multiple"
                                    showSearch
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Add Package Events"
                                    options={eventOptions}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Add Route Map Embed Code'
                                name='routeMap'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add route map'
                                    }]}
                            >
                                <Input.TextArea placeholder='Add Route Map Embed Code' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={`Tour Package Short Description ( ${shortDescriptionCharCount}/1000 characters)`}
                                name='shotDescription'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter tour package short description'
                                    }
                                ]}
                            >
                                <ReactQuill
                                    theme="snow"
                                    limits={{ maxLength: 500 }}
                                    onChange={(content, delta, source, editor) => {
                                        setShortDescriptionCharCount(editor.getLength())
                                    }}
                                    modules={quillModules}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={`Tour Package Description ( ${longDescriptionCharCount}/10000 characters)`}
                                name='packageDescription'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter tour package description'
                                    }
                                ]}
                            >
                                <ReactQuill
                                    theme="snow"
                                    limits={{ maxLength: 10000 }}
                                    onChange={(content, delta, source, editor) => {
                                        setLongDescriptionCharCount(editor.getLength())
                                    }}
                                    modules={quillModules}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={`How it works ( ${howItWorksCharCount}/10000 characters)`}
                                name='howItWorks'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter how it works'
                                    }
                                ]}
                            >
                                <ReactQuill
                                    theme="snow"
                                    limits={{ maxLength: 10000 }}
                                    onChange={(content, delta, source, editor) => {
                                        setHowItWorksCharCount(editor.getLength())
                                    }}
                                    modules={quillModules}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={`Notes ( ${notesCharCount}/10000 characters)`}
                                name='notes'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter special notes'
                                    }
                                ]}
                            >
                                <ReactQuill
                                    theme="snow"
                                    limits={{ maxLength: 10000 }}
                                    onChange={(content, delta, source, editor) => {
                                        setNotesCharCount(editor.getLength())
                                    }}
                                    modules={quillModules}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider>Pricing (Per Person)</Divider>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Tour Package Starting Price (AUD)'
                                name='startingPrice'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter tour package starting price'
                                    }
                                ]}
                            >
                                <Input placeholder='Tour Package Starting Price' type='number' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Tour Package Price (AUD)'
                                name='pricePackage'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter tour package price'
                                    }
                                ]}
                            >
                                <Input placeholder='Tour Package Price' type='number' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Tour Guide Price (AUD)'
                                name='priceGuide'
                            >
                                <Input placeholder='Tour Guide Price' type='number' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider>Transport (Per Person)</Divider>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Hatchback (AUD)'
                                name='vehicleHatchback'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter price for hatchback'
                                    }
                                ]}
                            >
                                <Input placeholder='Hatchback Price' type='number' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Sedan (AUD)'
                                name='vehicleSedan'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter price for sedan'
                                    }
                                ]}
                            >
                                <Input placeholder='Sedan Price' type='number' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='SUV (AUD)'
                                name='vehicleSuv'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter price for SUV'
                                    }
                                ]}
                            >
                                <Input placeholder='SUV Price' type='number' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Van (AUD)'
                                name='vehicleVan'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter price for van'
                                    }
                                ]}
                            >
                                <Input placeholder='Van Price' type='number' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Coster (AUD)'
                                name='VehicleCoster'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter price for Coster'
                                    }
                                ]}
                            >
                                <Input placeholder='Coster Price' type='number' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider>Accommodation (Per Person)</Divider>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Single Room (AUD)'
                                name='roomSingle'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter price for single room'
                                    }
                                ]}
                            >
                                <Input placeholder='Single Room Price' type='number' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Double Room (AUD)'
                                name='roomDouble'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter price for double room'
                                    }
                                ]}
                            >
                                <Input placeholder='Double Room Price' type='number' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Triple Room (AUD)'
                                name='roomTriple'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter price for triple room'
                                    }
                                ]}
                            >
                                <Input placeholder='Triple Room Price' type='number' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Family Room (AUD)'
                                name='roomFamily'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter price for family room'
                                    }
                                ]}
                            >
                                <Input placeholder='Family Room Price' type='number' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Payment Options'
                                name='paymentOptions'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add payment options'
                                    }
                                ]}
                            >
                                <Select
                                    mode="tags"
                                    showSearch
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Add Payment Options"
                                    options={paymentOptions}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider>Descount</Divider>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Discount (%)'
                                name='discountAmount'
                            >
                                <Input placeholder='Tour Package Discount' type='number' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Discount End Date'
                                name='disvountEnds'
                            >
                                <Input placeholder='Discount End Date' type='date' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Upload Ininerary Document'
                                name='itineraryDownloadUrl'
                            >
                                <Upload
                                    listType="picture"
                                    fileList={itineraryFile}
                                    onChange={onItineryFileChange}
                                    multiple={true}
                                    maxCount={3}
                                    beforeUpload={(file) => {
                                        return false;
                                    }}
                                >
                                    {itineraryFile?.length < 1 && <Button icon={<PlusOutlined />}>Upload</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Upload Images (Max 10)'
                                name='packageImages'
                            >
                                <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                    multiple={true}
                                    maxCount={10}
                                    beforeUpload={(file) => {
                                        return false;
                                    }}
                                >
                                    {fileList.length <= 10 && '+ Upload'}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Upload Videos (Max 3)'
                                name='packageVideos'
                            >
                                <Upload
                                    listType="picture"
                                    fileList={videoList}
                                    onChange={onVideoChange}
                                    multiple={true}
                                    maxCount={3}
                                    beforeUpload={(file) => {
                                        return false;
                                    }}
                                >
                                    {videoList?.length < 3 && <Button icon={<PlusOutlined />}>Upload</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Upload Thumbnail Image'
                                name='thumbnailUrl'
                            >
                                <ImgCrop
                                    rotationSlider
                                    modalTitle='Crop Image'
                                    modalOk='Crop'
                                    modalCancel='Cancel'
                                    showReset={true}
                                    aspect={16 / 9}
                                >
                                    <Upload
                                        listType="picture-card"
                                        fileList={thumbnailFile}
                                        onChange={onThumbnailChange}
                                        multiple={false}
                                        beforeUpload={(file) => {
                                            return false;
                                        }}
                                    >
                                        {thumbnailFile.length < 1 && '+ Upload'}
                                    </Upload>
                                </ImgCrop>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Available Status'
                                name='availableStatus'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add available status'
                                    }
                                ]}
                            >
                                <Select
                                    mode='single'
                                    showSearch
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Add available status"
                                    options={[
                                        {
                                            label: 'Active',
                                            value: ACTIVE
                                        },
                                        {
                                            label: 'Inactive',
                                            value: INACTIVE
                                        },
                                        {
                                            label: 'Pending',
                                            value: PENDING
                                        }
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item>
                        <Button type='primary' htmlType='submit'>
                            Add Tour Package
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>

            {previewImage && (
                <Image
                    wrapperStyle={{
                        display: 'none',
                    }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </div>
    )
}

export default AddTourPackage