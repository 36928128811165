import { Button, Col, Form, Input, notification, Row, Select, Spin, Upload } from 'antd';
import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';

import './styles.scss';
import useMyProfile from '../../../../hooks/useMyProfile';
import { fetchDestinationNames } from '../../../../../redux/api/destinations.api';
import { fetchAttractionsNames } from '../../../../../redux/api/attractions.api';
import { getAllTourPackagsNameAndIds } from '../../../../../redux/api/tourPackages.api';
import { handleImageUpload, USER_POSTS } from '../../../../azure/blob';
import { addNewBlog } from '../../../../../redux/api/blogs.api';
import { TagOutlined } from '@ant-design/icons';

const quillModules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
    ]
};

const AddBlog = () => {
    const navigate = useNavigate()
    const myProfile = useMyProfile().userProfile;
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const [destinationOptions, setDestinationOptions] = useState([]);
    const [attractionOptions, setAttractionOptions] = useState([]);
    const [tourOptions, setTourOptions] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        handleGetOptions();
    }, [])

    const handleGetOptions = async () => {
        setLoading(true);

        const resDes = await fetchDestinationNames();
        setDestinationOptions(
            resDes.data.map(item => {
                return {
                    label: item.destinationName,
                    value: item.id
                }
            })
        );

        const resAttr = await fetchAttractionsNames();
        setAttractionOptions(
            resAttr.data.map(item => {
                return {
                    label: item.attractionName,
                    value: item.id
                }
            })
        );

        const res = await getAllTourPackagsNameAndIds();
        setTourOptions(
            res.data.map(item => {
                return {
                    label: item.packageName,
                    value: item.id
                }
            })
        );

        setLoading(false);
    }


    const handleSubmit = async (values) => {
        setLoading(true);

        let imageUrls = [];
        if (fileList.length > 0) {
            const name = `${myProfile?.userName}_}`;
            const imageUploadPromises = fileList.map((file, index) => {
                return handleImageUpload(
                    USER_POSTS,
                    file.originFileObj,
                    file.size,
                    `${name}_${file.name}`
                ).then(blobRes => {
                    imageUrls.push(blobRes.blockBlobClient.url);
                });
            });
            await Promise.all(imageUploadPromises);
            delete values.blogImages;
        }
        if (imageUrls.length > 0) {
            imageUrls = imageUrls.map(url => sanitizeBlobUrls(url));
            values.blogImages = imageUrls.join(',');
        }

        values.userId = myProfile?.id;
        values.blogTags = values.blogTags.join(',');
        values.tagedDestinations = values.tagedDestinations?.join(',');
        values.tagedAttractions = values.tagedAttractions?.join(',');
        values.taggedTourPackages = values.taggedTourPackages?.join(',');
        values.blogType = 'admin';

        const res = await addNewBlog(values);
        if (res.status === 200) {
            api.success({
                message: 'Blog Posted Successfully',
                description: 'Your blog has been successfully posted',
            });
            setTimeout(() => {
                navigate('/dashboard/blogs');
            }, 1500);
        } else {
            api.error({
                message: 'Failed to Post Blog',
                description: 'Something went wrong! Please try again later',
            });
        }

        setLoading(false);
    }

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const sanitizeBlobUrls = (blobUrl) => {
        const splitUrl = blobUrl.split('?')
        return splitUrl[0]
    }

    return (
        <div className='main__contaier custom_tour_plan_main evenet_plan'>
            {contextHolder}

            <div className='content_body_main section_main'>
                <h1 className='header_1'>
                    Write a Blog
                </h1>

                <div className='content_body'>
                    <p>
                        You can write a blog about your travel experiences, tips, and recommendations. Share your stories with the world and inspire others to travel.
                    </p>
                </div>

                <div className='support__container'>
                    <Spin spinning={loading} >
                        {!myProfile?.loading ?
                            <Form
                                layout='vertical'
                                onFinish={handleSubmit}
                            >
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            label='Blog Title'
                                            name='blogTitle'
                                            rules={[{
                                                required: true,
                                                message: 'Please enter blog title'
                                            }]}
                                        >
                                            <Input placeholder='Enter Blog Title'
                                                maxLength={200} showCount />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            label='Short Description'
                                            name='blogDescription'
                                            rules={[{ required: true, message: 'Please add short descripton' }]}
                                        >
                                            <Input.TextArea
                                                placeholder='Enter Short Description'
                                                maxLength={500}
                                                showCount
                                                rows={4}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            label='Tags'
                                            name='blogTags'
                                            rules={[{ required: true, message: 'Please add tags' }]}
                                        >
                                            <Select
                                                mode="tags"
                                                placeholder="Add Tags"
                                                options={[]}
                                                maxCount={5}
                                                dropdownStyle={{ display: 'none' }}
                                                showSearch={false}
                                                suffixIcon={<TagOutlined />}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label='Related Destinations'
                                            name='tagedDestinations'
                                        >
                                            <Select
                                                mode="multiple"
                                                placeholder="Select Destinations"
                                                options={destinationOptions}
                                                maxCount={5}
                                                showSearch
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            label='Related Attractions'
                                            name='tagedAttractions'
                                        >
                                            <Select
                                                mode="multiple"
                                                placeholder="Select Attractions"
                                                options={attractionOptions}
                                                maxCount={5}
                                                showSearch
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label='Related Tour Packages'
                                            name='taggedTourPackages'
                                        >
                                            <Select
                                                mode="multiple"
                                                placeholder="Select Tour Packages"
                                                options={tourOptions}
                                                maxCount={5}
                                                showSearch
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            label='Blog Content'
                                            name='blogContent'
                                            rules={[{ required: true, message: 'Please add blog content' }]}
                                        >
                                            <ReactQuill
                                                modules={quillModules}
                                                placeholder='Write your blog content here...'
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            label='Upload Images (Max 10)'
                                            name='blogImages'
                                        >
                                            <Upload
                                                listType="picture-card"
                                                fileList={fileList}
                                                onChange={handleChange}
                                                multiple={true}
                                                maxCount={10}
                                                beforeUpload={(file) => {
                                                    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/gif' || file.type === 'image/webp';
                                                    if (!isJpgOrPng) {
                                                        api.error({
                                                            message: 'Unsupported image format!',
                                                            description: 'Please upload a valid image format (jpeg, jpg, png, gif, webp)',
                                                        });
                                                    }
                                                    const isSizeValid = file.size / 1024 / 1024 < 2;
                                                    if (!isSizeValid) {
                                                        api.error({
                                                            message: 'Image must be smaller than 2MB!',
                                                        });
                                                    }

                                                    const isVaid = isJpgOrPng && isSizeValid;

                                                    return isVaid ? false : Upload.LIST_IGNORE;
                                                }}
                                            >
                                                {fileList.length <= 10 && '+ Upload'}
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        gap: '10px'
                                    }}
                                >
                                    <Button
                                        loading={loading}
                                        type='primary'
                                        htmlType='submit'
                                        className='custom_primary_btn_2'
                                    >
                                        Post Blog
                                    </Button>
                                </div>
                            </Form>
                            :
                            <div>Loading...</div>
                        }
                    </Spin>
                </  div>
            </div>
        </div>
    )
}

export default AddBlog