import axios from 'axios';

import { API_BASE_URL } from "../../App/azure/api";
import { getAccessToken } from "../../App/azure/auth";
import { msalInstance } from "../../App/azure/authConfig";

export const searchAccommodation = async (searchTerm) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/searchAccommodation?keyword=${searchTerm}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const addAccommodation = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/addAccommodation?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const fetchAllHotels = async (values) => {
    const token = await getAccessToken(msalInstance);
    const params = new URLSearchParams();

    if (values.page !== undefined && values.page !== null) params.append('page', values.page);
    params.append('pageSize', 5);

    if (values.nearestDestinations) params.append('nearestDestinations', values.nearestDestinations);
    if (values.starRating) params.append('starRating', values.starRating);
    if (values.accommodationType) params.append('accommodationType', values.accommodationType);
    if (values.roomType) params.append('roomType', values.roomType);

    const url = `${API_BASE_URL}/getAllAccommodations?${params.toString()}`;

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: url,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getAccommodationById = async (id) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getAccommodationById?accommodationId=${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const editAccommodation = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/editAccommodation?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const deleteAccommodation = async (id) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/deleteAccommodation?clientIp=${ipAddress}&accommodationId=${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const addAccommodationRooms = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/addAccommodationRooms?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getAccommodationRooms = async (accommodationId) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getAccommodationRooms?accommodationId=${accommodationId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const editAccommodationRooms = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/editAccommodationRooms?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const deleteAccommodationRooms = async (id) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/deleteAccommodationRooms?clientIp=${ipAddress}&id=${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}


export const addAccommodationOffer = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/addAccommodationOffer?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getAccomodationOffers = async (accommodationId) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getAccomodationOffers?accommodationId=${accommodationId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const deleteAccommodationOffer = async (id) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/deleteAccommodationOffer?clientIp=${ipAddress}&id=${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const editAccommodationOffer = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/editAccommodationOffer?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getAccommodationNames = async () => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getAccommodationNames`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}



export const addAccommodationFacility = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/addAccommodationFacilities?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getAccommodationFacilities = async (accommodationId) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getAccommodationFacilities?accommodationId=${accommodationId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const deleteAccommodationFacilities = async (id) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/deleteAccommodationFacilities?clientIp=${ipAddress}&id=${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}


// House Rules
export const getAccommodationHouseRules = async (accommodationId) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getAccommodationHouseRules?accommodationId=${accommodationId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const addAccommodationHouseRules = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/addAccommodationHouseRules?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const editAccommodationHouseRules = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/editAccommodationHouseRules?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const deleteAccommodationHouseRules = async (id) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/deleteAccommodationHouseRules?clientIp=${ipAddress}&id=${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}


// Meals
export const getAccommodationMeals = async (accommodationId) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getAccommodationMealInfo?accommodationId=${accommodationId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const addAccommodationMealInfo = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/addAccommodationMealInfo?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const deleteAccommodationMealInfo = async (id) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/deleteAccommodationMealInfo?clientIp=${ipAddress}&id=${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}


// Accommodation Packages
export const getAccommodationPackages = async (accommodationId) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getAccommodationPackages?accommodationId=${accommodationId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const addAccommodationPackage = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/addAccommodationPackage?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const editAccommodationPackage = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/editAccommodationPackage?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const deleteAccommodationPackage = async (id) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/deleteAccommodationPackage?clientIp=${ipAddress}&id=${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}