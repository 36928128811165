import { getPartnersByType } from '../../api/partners.api';
import {
    FETCH_PARTNERS,
    FETCH_PARTNERS_SUCCESS,
    FETCH_PARTNERS_FAIL,

} from '../../types';

export const fetchPartnersAC = (type, page) => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_PARTNERS
        })
        try {

            const attractionsResponse = await getPartnersByType(type, page)

            dispatch({
                type: FETCH_PARTNERS_SUCCESS,
                payload: attractionsResponse.data
            })
        } catch (error) {
            dispatch({
                type: FETCH_PARTNERS_FAIL,
                payload: error.message
            })
        }
    }
}