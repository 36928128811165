import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { Button, Input, Space, Table, Tag } from 'antd'

import { SearchOutlined } from '@ant-design/icons'

import './styles.scss'
import { PENDING, PUBLISHED, SUSPENDED } from '../../../../Constants/activeStatusType';
import { capitalizeFisrtLetterEachWord } from '../../../../helpers/nameRender';

const BlogsTable = ({
    blogs,
    handleFetchBlogs,
    loading,
    page,
    setPage,
    pageSize,
}) => {
    const [searchText, setSearchText] = useState('');
    const [tableData, setTableData] = useState([]);
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    useEffect(() => {
        if (blogs?.length > 0) {
            setTableData(blogs)
        } else {
            setTableData([])
        }
    }, [blogs])

    const getNameSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
    });

    const columns = [
        {
            title: 'Blog Title',
            dataIndex: 'blogTitle',
            key: 'blogTitle',
            width: '20%',
            ...getNameSearchProps('blogTitle'),
            render: (text, record) => <Link to={`/dashboard/blogs/${record.id}`}>{text}</Link>
        },
        {
            title: 'User Name',
            dataIndex: 'userName',
            key: 'userName',
            width: '20%',
            ...getNameSearchProps('userName'),
            render: (text, record) => <Link to={`/dashboard/users/all/${record.userId}`}>{text}</Link>
        },
        {
            title: 'Status',
            dataIndex: 'blogStatus',
            key: 'blogStatus',
            width: '15%',
            filters: [
                {
                    text: 'Published',
                    value: PUBLISHED,
                },
                {
                    text: 'Pending',
                    value: PENDING,
                },
                {
                    text: 'Suspended',
                    value: SUSPENDED,
                },
            ],
            onFilter: (value, record) => record.blogStatus.indexOf(value) === 0,
            render: (text) => {
                let color = 'geekblue';
                if (text === PUBLISHED) {
                    color = 'green';
                } else if (text === PENDING) {
                    color = 'volcano';
                } else if (text === SUSPENDED) {
                    color = 'red';
                }
                return (
                    <Tag color={color} key={text}>
                        {
                            capitalizeFisrtLetterEachWord(text)
                        }
                    </Tag>
                );
            }
        },
        {
            title: 'Updated At',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            width: '20%',
        },
    ];

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    return (
        <div className='users-table'>
            <Table
                className='table'
                loading={loading}
                columns={columns}
                dataSource={tableData}
                pagination={{
                    pageSize: 10,
                    position: ["bottomRight"],
                    current: page,
                    total: blogs[0]?.filteredBlogCount,
                    onChange: (page) => {
                        setPage(page)
                        handleFetchBlogs(page, pageSize)
                    },
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
            />
        </div>
    )
}

export default BlogsTable