import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { DEFAULT_THUMBNAIL } from '../../../../../../Constants/otherConstsnts';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Card } from 'react-bootstrap'

import './styles.scss'
import { Button, Collapse, Divider, Steps } from 'antd';
import ReactQuill from 'react-quill';
import { useNavigate } from 'react-router-dom';
import { ExportOutlined } from '@ant-design/icons';
import { BiDownload } from 'react-icons/bi';
import { captilizeFirstLetter, trimHTML } from '../../../../../../helpers/nameRender';
import LoadingComp from '../../../../../../components/LoadingComp';
import { getItinerariesByTourPackage, getTourPackageById } from '../../../../../../../redux/api/tourPackages.api';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

const TourDetails = ({ userTour }) => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [tourPackage, setTourPackage] = useState(null)
    const [itinerary, setItinerary] = useState([])
    const [loadingItinerary, setLoadingItinerary] = useState(false)

    useEffect(() => {
        handleGetTourPackage(userTour?.tourPackage?.id)
        handleGetItinerary(userTour?.tourPackage?.id)
    }, [])

    const handleGetTourPackage = async (id) => {
        setLoading(true)
        try {
            const tour = await getTourPackageById(id)
            setTourPackage(tour.data[0])
        } catch (error) {
            console.error('Error fetching destination', error)
        }
        setLoading(false)
    }

    const handleGetItinerary = async (id) => {
        setLoadingItinerary(true)
        try {
            const itineraries = await getItinerariesByTourPackage(id)
            setItinerary(itineraries.data)
        } catch (error) {
            console.error('Error fetching itineraries', error)
        }
        setLoadingItinerary(false)
    }

    const renderImages = (images) => {
        if (images?.length > 2) {
            const moreImages = images.slice(2)
            return (
                <div className='images_container more_than_two'>
                    <PhotoView src={images[0]}>
                        <LazyLoadImage
                            alt='image'
                            effect="blur"
                            wrapperProps={{
                                style: { transitionDelay: "0.1s" },
                            }}
                            src={images[0]}
                        />
                    </PhotoView>
                    <PhotoView src={images[1]}>
                        <LazyLoadImage
                            alt='image'
                            effect="blur"
                            wrapperProps={{
                                style: { transitionDelay: "0.1s" },
                            }}
                            src={images[1]}
                        />
                    </PhotoView>
                    <div className='more_images'>
                        {
                            moreImages.map((image, index) => (
                                <PhotoView key={index} src={image}>
                                    <LazyLoadImage
                                        alt='image'
                                        effect="blur"
                                        wrapperProps={{
                                            style: { transitionDelay: "0.1s" },
                                        }}
                                        src={image}
                                    />
                                </PhotoView>
                            ))
                        }
                    </div>
                </div>
            )
        } else {
            return (
                <div className='images_container'>
                    {
                        images?.map((image, index) => (
                            <PhotoView key={index} src={image}>
                                <LazyLoadImage
                                    alt='image'
                                    effect="blur"
                                    wrapperProps={{
                                        style: { transitionDelay: "0.1s" },
                                    }}
                                    src={image}
                                />
                            </PhotoView>
                        ))
                    }
                </div>
            )
        }
    }

    return (
        <div className='my_tour_booking_tour_details'>
            {
                loading ?
                    <div className='booking__loading_container'>
                        <LoadingComp />
                    </div>
                    :
                    <>
                        <div className='header'
                            style={{
                                display: 'flex',
                                gap: '5px',
                                alignItems: 'center'
                            }}
                        >
                            <h3 className='header_3'>{tourPackage?.packageName}</h3>
                            <Button type='link' onClick={() => navigate(`/tours/${tourPackage?.id}`)}>View Full Details<ExportOutlined /></Button>
                        </div>

                        <div className='image_gallery'>
                            <PhotoProvider>
                                <div className='left'>
                                    <PhotoView src={tourPackage?.thumbnailUrl}>
                                        <LazyLoadImage
                                            alt='image'
                                            effect="blur"
                                            wrapperProps={{
                                                style: { transitionDelay: "0.1s" },
                                            }}
                                            src={tourPackage && (tourPackage?.thumbnailUrl || DEFAULT_THUMBNAIL)}
                                        />
                                    </PhotoView>
                                </div>
                                <div className='right'>
                                    {
                                        renderImages(tourPackage?.packageImages?.split(','))
                                    }
                                </div>
                                <div className='total'>
                                    <span>{tourPackage?.packageImages?.split(',').length + (tourPackage?.thumbnailUrl ? 1 : 0)} photos</span>
                                </div>
                            </PhotoProvider>
                        </div>

                        <section className='sec__2'>
                            <h2 className='heder_2'>
                                Where you will go
                            </h2>
                            <div className='slider'>
                                <Carousel
                                    swipeable={true}
                                    draggable={false}
                                    showDots={true}
                                    responsive={responsive}
                                    ssr={true}
                                    infinite={false}
                                    autoPlaySpeed={1000}
                                    keyBoardControl={true}
                                    customTransition="all .5"
                                    transitionDuration={500}
                                    containerClass="carousel-container"
                                    dotListClass="custom-dot-list-style"
                                    itemClass="carousel-item-padding-40-px"
                                >
                                    {
                                        Array.isArray(tourPackage?.destinations) ? (
                                            tourPackage.destinations.map((destination, index) => (
                                                <Card key={index} className='destination_card'>
                                                    <Card.Img variant="top" src={destination.thumbnailUrl} className='card_image' />
                                                    <Card.Body className='card_body'>
                                                        <Card.Title className='card_title'>{destination.destinationName}</Card.Title>
                                                    </Card.Body>
                                                </Card>
                                            ))
                                        ) : (
                                            <p>No destinations available</p>
                                        )
                                    }
                                </Carousel>
                            </div>
                            <h2 className='heder_2'>
                                This is your route
                            </h2>

                            <div className='route'>
                                <iframe
                                    src={tourPackage?.routeMap}
                                    title="Route Map"
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                        </section>

                        <section className='sec__3' style={{ marginTop: '15px' }}>
                            <div className='header'
                                style={{
                                    display: 'flex',
                                    gap: '15px',
                                    alignItems: 'center'
                                }}
                            >
                                <h2 className='heder_2'>
                                    Itinerary
                                </h2>
                                <Button
                                    type='default'
                                    className='button link'
                                    onClick={() => {
                                        window.open(tourPackage?.itineraryDownloadUrl, '_blank')
                                    }}
                                >
                                    <BiDownload /> Download Brochure
                                </Button>
                            </div>

                            <div className='itinerary'>
                                <Steps
                                    direction="vertical"
                                    progressDot
                                    loading={loadingItinerary}
                                >
                                    {
                                        itinerary?.map((itinerary, index) => (
                                            <Steps.Step
                                                className='itinerary_step'
                                                key={index}
                                                title={
                                                    <div>
                                                        <Collapse
                                                            bordered={false}
                                                            expandIconPosition='right'
                                                            ghost
                                                        >
                                                            <Collapse.Panel
                                                                header={<span>
                                                                    <h2>Day {itinerary?.dayNumber}</h2>
                                                                    <p>{itinerary.dayTitle}</p>
                                                                </span>}
                                                                key={index}
                                                                className='itinerary_collapse'
                                                            >
                                                                <ReactQuill
                                                                    className='day_description'
                                                                    value={itinerary.dayDescription}
                                                                    readOnly={true}
                                                                    theme='bubble'
                                                                />
                                                                <div className='main__destination'>
                                                                    <div className='left'>
                                                                        <LazyLoadImage
                                                                            alt='image'
                                                                            effect="blur"
                                                                            wrapperProps={{
                                                                                style: { transitionDelay: "0.1s" },
                                                                            }}
                                                                            src={itinerary?.mainDestination?.thumbnailUrl}
                                                                            onClick={() => navigate(`/destinations/${itinerary?.mainDestination?.id}`)}
                                                                        />
                                                                    </div>
                                                                    <div className='right'>
                                                                        <h2>Main Destination</h2>
                                                                        <h3
                                                                            onClick={() => navigate(`/destinations/${itinerary?.mainDestination?.id}`)}
                                                                        >{itinerary?.mainDestination?.destinationName}</h3>
                                                                        <ReactQuill
                                                                            value={itinerary?.mainDestination?.destinationDescriptionShort ? trimHTML(itinerary?.mainDestination?.destinationDescriptionShort,
                                                                                window.innerWidth > 1366 ? 120 : 60
                                                                            ) : ''}
                                                                            readOnly={true}
                                                                            theme='bubble'
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <Divider />

                                                                <div className='experience'>
                                                                    <h2>You can experience</h2>
                                                                    <div className='itemss'>
                                                                        {
                                                                            itinerary?.items?.sort((a, b) => a.position - b.position)?.map((item, index) => (
                                                                                <div className='item_iti_dis'>
                                                                                    <div className='left'>
                                                                                        <LazyLoadImage
                                                                                            alt='image'
                                                                                            effect="blur"
                                                                                            wrapperProps={{
                                                                                                style: { transitionDelay: "0.1s" },
                                                                                            }}
                                                                                            src={item?.thumbnailUrl || DEFAULT_THUMBNAIL}
                                                                                        />
                                                                                    </div>

                                                                                    <div className='right'>
                                                                                        <h4>{item?.eventName || item?.activityName || item?.attractionName || item?.destinationName || 'N/A'}</h4>
                                                                                        <p>
                                                                                            <strong>Type: </strong> {item?.type ? captilizeFirstLetter(item?.type) : 'N/A'}
                                                                                        </p>
                                                                                        <p>
                                                                                            <strong>Short Description: </strong>
                                                                                            <ReactQuill
                                                                                                value={
                                                                                                    item?.eventDescriptionShort ?
                                                                                                        trimHTML(item?.eventDescriptionShort, 120) :
                                                                                                        item?.activityDescriptionShort ? trimHTML(item?.activityDescriptionShort, 120) :
                                                                                                            item?.attractionDescriptionShort ? trimHTML(item?.attractionDescriptionShort, 120) :
                                                                                                                item?.destinationDescriptionShort ? trimHTML(item?.destinationDescriptionShort, 120) :
                                                                                                                    'N/A'
                                                                                                }
                                                                                                readOnly={true}
                                                                                                theme='bubble'
                                                                                            />
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <Divider />

                                                                <div className='experience'>
                                                                    <h2>You will Stay at</h2>
                                                                    <p className='note'>
                                                                        <span>Please Note: </span> The following are the hotels that you will stay at during the tour. The hotels are subject to change and will be confirmed 4 weeks before the tour.
                                                                    </p>
                                                                    <div className='itemss'>
                                                                        <div className='item_iti_dis'>
                                                                            <div className='left'>
                                                                                <LazyLoadImage
                                                                                    alt='image'
                                                                                    effect="blur"
                                                                                    wrapperProps={{
                                                                                        style: { transitionDelay: "0.1s" },
                                                                                    }}
                                                                                    src={itinerary?.stay?.thumbnailUrl || DEFAULT_THUMBNAIL}
                                                                                    onClick={() => navigate(`/accommodations/${itinerary?.stay?.id}`)}
                                                                                />
                                                                            </div>

                                                                            <div className='right'>
                                                                                <h3
                                                                                    onClick={() => navigate(`/accommodations/${itinerary?.stay?.id}`)}
                                                                                >{itinerary?.stay?.accommodationName}</h3>
                                                                                <p>
                                                                                    <strong>Rating: </strong> {itinerary?.stay?.starRating + ' Stars' || itinerary?.stay?.grade + ' Grade'}
                                                                                </p>
                                                                                <p>
                                                                                    <strong>Short Description: </strong>
                                                                                    <ReactQuill
                                                                                        value={itinerary?.stay?.accommodationDescriptionShort && trimHTML(itinerary?.stay?.accommodationDescriptionShort, 120)}
                                                                                        readOnly={true}
                                                                                        theme='bubble'
                                                                                    />
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </Collapse.Panel>
                                                        </Collapse>
                                                    </div>
                                                }
                                            />
                                        ))
                                    }
                                </Steps>
                            </div>
                        </section>
                    </>
            }
        </div>
    )
}

export default TourDetails