import { fetchAllEvents, fetchEventsNames } from '../../api/events.api';
import {
    FETCH_EVENTS,
    FETCH_EVENTS_SUCCESS,
    FETCH_EVENTS_FAIL,

    FETCH_EVENT_NAMES,
    FETCH_EVENT_NAMES_SUCCESS,
    FETCH_EVENT_NAMES_FAIL
} from '../../types';

export const fetchEventsAC = (values) => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_EVENTS
        })
        try {

            const attractionsResponse = await fetchAllEvents(values)

            dispatch({
                type: FETCH_EVENTS_SUCCESS,
                payload: attractionsResponse.data
            })
        } catch (error) {
            dispatch({
                type: FETCH_EVENTS_FAIL,
                payload: error.message
            })
        }
    }
}

export const fetchEventNamesAC = () => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_EVENT_NAMES
        })
        try {
            const response = await fetchEventsNames()
            dispatch({
                type: FETCH_EVENT_NAMES_SUCCESS,
                payload: response.data
            })
        } catch (error) {
            dispatch({
                type: FETCH_EVENT_NAMES_FAIL,
                payload: error.message
            })
        }
    }
}