import React, { useEffect, useRef, useState } from 'react'
import 'react-quill/dist/quill.snow.css';
import ReactQuill, { Quill } from 'react-quill';
import { useNavigate } from 'react-router-dom';
import ImgCrop from 'antd-img-crop';

import useDestinations from '../../../../../hooks/useDestinations';

import { ACTIVE, INACTIVE, PENDING } from '../../../../../Constants/activeStatusType';
import { ACTIVITIES_CONTAINER, handleDeleteBlob, handleImageUpload } from '../../../../../azure/blob';

import { Button, Col, Form, Image, Input, notification, Row, Select, Spin, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons';

import { activityTypes, weatherTypes } from '../../../../../Constants/itemTypes';

import './styles.scss'
import { useDispatch } from 'react-redux';
import { fetchDestinationNamesAC } from '../../../../../../redux/actions/destinations/destinations.ac';
import useActivities from '../../../../../hooks/useActivities';
import { editActivity, getActivityById } from '../../../../../../redux/api/activities.api';

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};


const weatherOptions = Object.keys(weatherTypes).map(key => {
    return {
        label: getOptionName(weatherTypes[key]),
        value: key
    };
});

const activityOptions = Object.keys(activityTypes).map(key => {
    return {
        label: getOptionName(activityTypes[key]),
        value: key
    };
});

const quillModules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
        ["formula"],
        [{ size: ["small", false, "large", "huge"] }],
    ],
    clipboard: {
        matchVisual: false
    },
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
};

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });


const EditActivity = () => {
    const [shortDescriptionCharCount, setShortDescriptionCharCount] = useState(0)
    const [longDescriptionCharCount, setLongDescriptionCharCount] = useState(0)
    const [fileList, setFileList] = useState([])
    const [videoList, setVideoList] = useState([])
    const [thumbnailFile, setThumbnailFile] = useState([]);
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [loadingText, setLoadingText] = useState('')
    const topRef = useRef(null);
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();
    const destinations = useDestinations()
    const destinationNames = destinations.destinationNames
    const activities = useActivities().activities
    const url = window.location.pathname
    const [activity, setActivity] = useState()
    const activityId = url.split('/').pop()
    const formRef = useRef(null)
    const dispatch = useDispatch()

    useEffect(() => {
        if (activities?.length > 0) {
            const activity = activities.find(activity => activity.id === activityId)
            if (activity) {
                setActivity(activity)
            } else {
                handleGetActivitiy(activityId)
            }
        } else {
            handleGetActivitiy(activityId)
        }

        if (destinationNames?.length === 0) {
            dispatch(fetchDestinationNamesAC())
        }
    }, [])

    useEffect(() => {
        if (activity) {
            formRef.current.setFieldsValue({
                activityName: activity.activityName,
                destinationId: activity.destinationId,
                tags: activity.tags.split(','),
                activityDescriptionShort: activity.activityDescriptionShort,
                activityDescription: activity.activityDescription,
                rules: activity.rules,
                activityType: activity.activityType,
                minAge: activity.minAge,
                maxAge: activity.maxAge,
                activityCity: activity.activityCity,
                activityCoordinates: activity.activityCoordinates,
                mapLocation: activity.mapLocation,
                weather: activity.weather,
                ticketPrice: activity.ticketPrice,
                activeStatus: activity.activeStatus,
            })

            if (activity.activityImages) {
                const images = activity.activityImages.split(',')
                const fileList = images.map((url, index) => {
                    return {
                        uid: index,
                        name: `image_${index}.png`,
                        status: 'done',
                        url: url
                    }
                })
                setFileList(fileList)
            }

            if (activity.activityVideos) {
                const videos = activity.activityVideos.split(',')
                const fileList = videos.map((url, index) => {
                    return {
                        uid: index,
                        name: `video_${index}.mp4`,
                        status: 'done',
                        url: url
                    }
                })
                setVideoList(fileList)
            }

            if (activity.thumbnailUrl) {
                const fileList = [{
                    uid: 0,
                    name: 'thumbnail.png',
                    status: 'done',
                    url: activity.thumbnailUrl
                }]
                setThumbnailFile(fileList)
            }
        }
    }, [activity])

    const handleGetActivitiy = async (id) => {
        setLoading(true)
        try {
            const activity = await getActivityById(id)
            setActivity(activity.data[0])
        } catch (error) {
            console.error('Error fetching activity', error)
        }
        setLoading(false)
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const onVideoChange = ({ fileList: newFileList }) => setVideoList(newFileList);

    const onThumbnailChange = ({ fileList: newFileList }) => setThumbnailFile(newFileList);


    const onFinish = async (values) => {
        topRef.current.scrollTo(0, 0);
        setLoading(true);
        try {
            Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

            let imageUrls = [];
            let videoUrls = [];
            let thumbnailUrl = '';

            const name = values.activityName.replace(/\s/g, '_').toLowerCase();

            // find new images and list images need to be deleted
            const newImages = fileList.filter(file => !file.url);
            const deletedImages = activity.activityImages?.split(',').filter(image => !fileList.find(file => file.url === image));

            // find new videos and list videos need to be deleted
            const newVideos = videoList.filter(file => !file.url);
            const deletedVideos = activity.activityVideos?.split(',').filter(video => !videoList.find(file => file.url === video));

            // find new thumbnail and list thumbnail need to be deleted
            const newThumbnail = thumbnailFile.filter(file => !file.url);
            const deletedThumbnail = activity.thumbnailUrl?.split(',').filter(thumbnail => !thumbnailFile.find(file => file.url === thumbnail));

            if (newImages?.length > 0) {
                setLoadingText('Uploading Activity Images');
                const imageUploadPromises = newImages.map(async (file, index) => {
                    setLoadingText(`Uploading image ${index + 1}`);
                    return handleImageUpload(
                        ACTIVITIES_CONTAINER,
                        file.originFileObj,
                        file.size,
                        `${name}_${file.name}`
                    ).then((response) => {
                        imageUrls.push(response.blockBlobClient.url);
                    });
                });
                await Promise.all(imageUploadPromises);
            }

            if (newVideos?.length > 0) {
                setLoadingText('Uploading Activity Videos');
                const videoUploadPromises = newVideos.map(async (file, index) => {
                    setLoadingText(`Uploading video ${index + 1}`);
                    return handleImageUpload(
                        ACTIVITIES_CONTAINER,
                        file.originFileObj,
                        file.size,
                        `${name}_${file.name}`
                    ).then((response) => {
                        videoUrls.push(response.blockBlobClient.url);
                    });
                });
                await Promise.all(videoUploadPromises);
            }

            if (newThumbnail?.length > 0) {
                setLoadingText('Uploading Activity Thumbnail');
                const thumbnailUploadPromises = newThumbnail.map(async (file, index) => {
                    setLoadingText(`Uploading thumbnail ${index + 1}`);
                    return handleImageUpload(
                        ACTIVITIES_CONTAINER,
                        file.originFileObj,
                        file.size,
                        `${name}_${file.name}`
                    ).then((response) => {
                        thumbnailUrl = response.blockBlobClient.url;
                    });
                });
                await Promise.all(thumbnailUploadPromises);
            }

            // delete blobs
            if (deletedImages?.length > 0) {
                setLoadingText('Deleting Activity Images');
                const imageDeletePromises = deletedImages.map(async (image, index) => {
                    const blobName = getBlobName(image);
                    setLoadingText(`Deleting image ${index + 1}`);
                    return handleDeleteBlob(ACTIVITIES_CONTAINER, blobName);
                });
                await Promise.all(imageDeletePromises);
            }

            if (deletedVideos?.length > 0) {
                setLoadingText('Deleting Activity Videos');
                const videoDeletePromises = deletedVideos.map(async (video, index) => {
                    const blobName = getBlobName(video);
                    setLoadingText(`Deleting video ${index + 1}`);
                    return handleDeleteBlob(ACTIVITIES_CONTAINER, blobName);
                });
                await Promise.all(videoDeletePromises);
            }

            if (deletedThumbnail?.length > 0) {
                setLoadingText('Deleting Activity Thumbnail');
                const thumbnailDeletePromises = deletedThumbnail.map(async (thumbnail, index) => {
                    const blobName = getBlobName(thumbnail);
                    setLoadingText(`Deleting thumbnail ${index + 1}`);
                    return handleDeleteBlob(ACTIVITIES_CONTAINER, blobName);
                });
                await Promise.all(thumbnailDeletePromises);
            }

            let updatedImages = [
                ...fileList.filter((file) => file.url).map((file) => file.url),
                ...imageUrls
            ];

            let updatedVideos = [
                ...videoList.filter((file) => file.url).map((file) => file.url),
                ...videoUrls
            ];

            // Ensure thumbnailUrl is a single string
            let updatedThumbnail = thumbnailUrl || (thumbnailFile[0]?.url || '');

            // remove unwanted parts from urls
            updatedImages.forEach((url, index) => {
                updatedImages[index] = sanitizeBlobUrls(url);
            });

            updatedVideos.forEach((url, index) => {
                updatedVideos[index] = sanitizeBlobUrls(url);
            });

            updatedThumbnail = sanitizeBlobUrls(updatedThumbnail);

            const data = {
                id: activity.id,
                destinationId: values.destinationId,
                activityName: values.activityName,
                tags: values.tags.join(','),
                activityDescriptionShort: values.activityDescriptionShort,
                activityDescription: values.activityDescription,
                activityType: values.activityType.toString(),
                minAge: values.minAge.toString(),
                maxAge: values.maxAge.toString(),
                activityCity: values.activityCity,
                activityCoordinates: values.activityCoordinates,
                mapLocation: values.mapLocation,
                weather: values.weather,
                rules: values.rules,
                ticketPrice: values.ticketPrice.toString(),
                activeStatus: values.activeStatus,
                activityImages: updatedImages?.length > 0 ? updatedImages.join(',') : null,
                activityVideos: updatedVideos?.length > 0 ? updatedVideos.join(',') : null,
                thumbnailUrl: updatedThumbnail === '' ? null : updatedThumbnail
            }

            const response = await editActivity(data);
            if (response.status === 200) {
                api.success({
                    message: 'Activity Updated Successfully',
                    description: 'You have successfully updated the acitivity',
                });

                setTimeout(() => {
                    navigate(`/dashboard/tours/activities/${activity.id}`);
                }, 2000);
            } else {
                console.log('Error', response);
                api.error({
                    message: 'Error Updating Activity',
                    description: 'There was an error updating the acitivity. Please try again'
                });
            }
        } catch (error) {
            console.log('Error', error);
            api.error({
                message: 'Error!',
                description: 'Failed to update the acitivity. Please try again.',
            });
        }
        setLoading(false);
        setLoadingText('');
    };

    const getBlobName = (blobUrl) => {
        const splitUrl = blobUrl.split('/')
        return splitUrl[splitUrl.length - 1]
    }

    const sanitizeBlobUrls = (blobUrl) => {
        const splitUrl = blobUrl.split('?')
        return splitUrl[0]
    }

    const destinationOptions = Object.keys(destinationNames).map(key => {
        return {
            label: getOptionName(destinationNames[key].destinationName),
            value: destinationNames[key].id
        };
    });

    return (
        <div className='add__destination' ref={topRef}>
            {contextHolder}
            <h2>Edit Activity</h2>

            <Spin spinning={loading} tip={loadingText}>
                <Form
                    layout='vertical'
                    onFinish={onFinish}
                    ref={formRef}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Activity Name'
                                name='activityName'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter activity name'
                                    }
                                ]}
                            >
                                <Input placeholder='Activity Name' showCount maxLength={200} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Destination'
                                name='destinationId'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select destination'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Select Destination"
                                    options={destinationOptions}
                                    maxCount={1}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={`Activity Short Description ( ${shortDescriptionCharCount}/1000 characters)`}
                                name='activityDescriptionShort'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter activity short description'
                                    }
                                ]}
                            >
                                <ReactQuill
                                    theme="snow"
                                    limits={{ maxLength: 500 }}
                                    onChange={(content, delta, source, editor) => {
                                        setShortDescriptionCharCount(editor.getLength())
                                    }}
                                    modules={quillModules}
                                />
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={`Activity Description ( ${longDescriptionCharCount}/10000 characters)`}
                                name='activityDescription'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter activity description'
                                    }
                                ]}
                            >
                                <ReactQuill
                                    theme="snow"
                                    limits={{ maxLength: 10000 }}
                                    onChange={(content, delta, source, editor) => {
                                        setLongDescriptionCharCount(editor.getLength())
                                    }}
                                    modules={quillModules}
                                />
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={`Activity Rules ( ${longDescriptionCharCount}/500 characters)`}
                                name='rules'
                            >
                                <ReactQuill
                                    theme="snow"
                                    limits={{ maxLength: 500 }}
                                    onChange={(content, delta, source, editor) => {
                                        setLongDescriptionCharCount(editor.getLength())
                                    }}
                                    modules={quillModules}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Activity Type'
                                name='activityType'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select activity type'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Add Activity Type"
                                    options={activityOptions}
                                    maxCount={1}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Activity City'
                                name='activityCity'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add activity city'
                                    }
                                ]}
                            >
                                <Input placeholder='Activity City' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Activity Coordinates'
                                name='activityCoordinates'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input activity coordinates'
                                    }
                                ]}
                            >
                                <Input placeholder='Activity Coordinates' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Activity Map Location'
                                name='mapLocation'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add activity map location'
                                    }
                                ]}
                            >
                                <Input placeholder='Activity Map Location' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Activity Weather'
                                name='weather'
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Add weather"
                                    options={weatherOptions}
                                    maxCount={1}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Ticket Price (in USD). Add 0 if free'
                                name='ticketPrice'
                            >
                                <Input type='number' placeholder='Ticket Price' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Activity Tags'
                                name='tags'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add tags'
                                    }
                                ]}
                            >
                                <Select
                                    mode="tags"
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Add activity tags"
                                    options={[]}
                                    maxCount={5}
                                />
                            </Form.Item>
                        </Col>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '50%'
                            }}
                        >
                            <Col span={12}>
                                <Form.Item
                                    label='Min Age'
                                    name='minAge'
                                >
                                    <Input type='number' placeholder='Min Age' max={80} min={2} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label='Max Age'
                                    name='maxAge'
                                >
                                    <Input type='number' placeholder='Max Age' max={80} min={2} />
                                </Form.Item>
                            </Col>
                        </div>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Upload Activity Images (Max 10)'
                                name='activityImages'
                            >
                                <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                    multiple={true}
                                    maxCount={10}
                                    beforeUpload={(file) => {
                                        return false;
                                    }}
                                >
                                    {fileList.length <= 10 && '+ Upload'}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Upload Activity Videos (Max 3)'
                                name='activityVideos'
                            >
                                <Upload
                                    listType="picture"
                                    fileList={videoList}
                                    onChange={onVideoChange}
                                    multiple={true}
                                    maxCount={3}
                                    beforeUpload={(file) => {
                                        return false;
                                    }}
                                >
                                    {videoList?.length < 3 && <Button icon={<PlusOutlined />}>Upload</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Upload Activity Thumbnail Image'
                                name='thumbnailUrl'
                            >
                                <ImgCrop
                                    rotationSlider
                                    modalTitle='Crop Image'
                                    modalOk='Crop'
                                    modalCancel='Cancel'
                                    showReset={true}
                                    aspect={16 / 9}
                                >
                                    <Upload
                                        listType="picture-card"
                                        fileList={thumbnailFile}
                                        onChange={onThumbnailChange}
                                        multiple={false}
                                        beforeUpload={(file) => {
                                            return false;
                                        }}
                                    >
                                        {thumbnailFile.length < 1 && '+ Upload'}
                                    </Upload>
                                </ImgCrop>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Col span={12}>
                        <Form.Item
                            label='Active Status'
                            name='activeStatus'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select active status'
                                }
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Select Active Status"
                                options={[
                                    {
                                        label: 'Active',
                                        value: ACTIVE
                                    },
                                    {
                                        label: 'Inactive',
                                        value: INACTIVE
                                    },
                                    {
                                        label: 'Pending',
                                        value: PENDING
                                    }
                                ]}
                                maxCount={1}
                            />
                        </Form.Item>
                    </Col>

                    <Form.Item>
                        <Button type='primary' htmlType='submit'>
                            Update Activity
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>

            {previewImage && (
                <Image
                    wrapperStyle={{
                        display: 'none',
                    }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </div>
    )
}

export default EditActivity