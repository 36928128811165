import {
    FETCH_ATTRACTIONS,
    FETCH_ATTRACTIONS_SUCCESS,
    FETCH_ATTRACTIONS_FAIL,

    FETCH_ATTRACTION_NAMES,
    FETCH_ATTRACTION_NAMES_SUCCESS,
    FETCH_ATTRACTION_NAMES_FAIL
} from '../../types';

const initialState = {
    loading: false,
    attractions: [],
    attarctionNames: [],
    error: null
};

export const attractionsReducer = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case FETCH_ATTRACTIONS:
            return {
                ...state,
                loading: true
            };
        case FETCH_ATTRACTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                attractions: action.payload,
                error: null
            };
        case FETCH_ATTRACTIONS_FAIL:
            return {
                ...state,
                loading: false,
                attractions: [],
                error: action.payload
            };

        case FETCH_ATTRACTION_NAMES:
            return {
                ...state,
                loading: true
            };
        case FETCH_ATTRACTION_NAMES_SUCCESS:
            return {
                ...state,
                loading: false,
                attarctionNames: action.payload,
                error: null
            };
        case FETCH_ATTRACTION_NAMES_FAIL:
            return {
                ...state,
                loading: false,
                attarctionNames: [],
                error: action.payload
            };

        default:
            return state;
    }
}