import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { ACTIVE, ADDITIONAL_PAYMENT, BANK_TRANSFER, CANCELLED, CANCELLED_TOUR, CASH, COMPLETED, CONFIRMED, CREDIT_CARD, INSTALMENT, INSTALMENT_PAID, INSTALMENT_PENDING, NOT_STARTED, ONGOING, PAID, PAYPAL, PENDING, PENDING_PAYMENT, PENDING_VERIFICATION, REFUND, REJECTED, TOUR_PACKAGE_PAYMENT } from '../../../../Constants/finance';
import { capitalizeFisrtLetterEachWord } from '../../../../helpers/nameRender';
import dayjs from 'dayjs';

const PaymentsTable = ({
    payments,
    loading
}) => {
    const [searchText, setSearchText] = useState('');
    const [tableData, setTableData] = useState([]);
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    useEffect(() => {
        setTableData(payments)
    }, [payments])

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const getNameSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text, record) =>
            searchedColumn === dataIndex ? (
                <Link to={`/dashboard/payments/${record.id}`}>
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                </Link>
            ) : (
                <Link to={`/dashboard/payments/${record.id}`}>
                    <p
                        style={{
                            color: '#9373ff',
                            cursor: 'pointer',
                            margin: 0,
                        }}
                    >
                        {text}
                    </p>
                </Link>
            ),
    });

    const columns = [
        {
            title: 'Payment Type',
            dataIndex: 'paymentType',
            key: 'paymentType',
            width: '20%',
            filters: [
                {
                    text: 'Tour Package Payment',
                    value: TOUR_PACKAGE_PAYMENT,
                },
                {
                    text: 'Additional Payment',
                    value: ADDITIONAL_PAYMENT,
                },
                {
                    text: 'Refund',
                    value: REFUND
                }
            ],
            onFilter: (value, record) => record.paymentType.includes(value),
            render: (text) => {
                return (capitalizeFisrtLetterEachWord(text))
            }
        },
        {
            title: 'Payment ID',
            dataIndex: 'id',
            key: 'id',
            width: '20%',
            ...getNameSearchProps('id'),
        },
        {
            title: 'Paid Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '15%',
            render: (text) => {
                return dayjs(text).format('DD/MM/YYYY');
            }
        },
        {
            title: "Payment Method",
            dataIndex: "paymentMethod",
            key: "paymentMethod",
            width: "15%",
            filters: [
                {
                    text: 'Bank Transfer',
                    value: BANK_TRANSFER,
                },
                {
                    text: 'Credit Card',
                    value: CREDIT_CARD,
                },
                {
                    text: 'Paypal',
                    value: PAYPAL
                },
                {
                    text: 'Cash',
                    value: CASH
                },
                {
                    text: 'Instalment',
                    value: INSTALMENT
                }
            ],
            onFilter: (value, record) => record.paymentMethod.includes(value),
            render: (text) => {
                return (
                    <Tag color='geekblue' key={text}>
                        {capitalizeFisrtLetterEachWord(text)}
                    </Tag>
                );
            },
        },
        {
            title: 'Payment Status',
            dataIndex: 'paymentStatus',
            key: 'paymentStatus',
            width: '15%',
            filters: [
                {
                    text: 'Pending Payment',
                    value: PENDING_PAYMENT,
                },
                {
                    text: 'Pending Verification',
                    value: PENDING_VERIFICATION,
                },
                {
                    text: 'Paid',
                    value: PAID
                },
                {
                    text: 'Instalment Pending',
                    value: INSTALMENT_PENDING
                },
                {
                    text: 'Instalment Paid',
                    value: INSTALMENT_PAID
                },
                {
                    text: 'Cancelled',
                    value: CANCELLED
                }
            ],
            render: (text) => {
                let color = 'geekblue';
                if (text === PENDING_PAYMENT) {
                    color = 'volcano';
                } else if (text === PENDING_VERIFICATION) {
                    color = 'orange';
                } else if (text === PAID) {
                    color = 'green';
                } else if (text === INSTALMENT_PENDING) {
                    color = 'red';
                } else if (text === INSTALMENT_PAID) {
                    color = 'red';
                } else if (text === CANCELLED) {
                    color = 'red';
                }

                return (
                    <Tag color={color} key={text}>
                        {text ? capitalizeFisrtLetterEachWord(text) : ''}
                    </Tag>
                );
            }
        },
        {
            title: 'Amount',
            dataIndex: 'paymentAmount',
            key: 'paymentAmount',
            width: '15%',
            render: (text) => `${text} AUD`
        },
    ];

    return (
        <Table
            className='table'
            loading={loading}
            columns={columns}
            dataSource={tableData}
        />
    )
}

export default PaymentsTable