import {
    FETCH_NEW_NOTIFICATIONS_COUNT,
    FETCH_NEW_NOTIFICATIONS_COUNT_SUCCESS,
    FETCH_NEW_NOTIFICATIONS_COUNT_FAIL
} from '../../types';

const initialState = {
    loading: false,
    newNotificationsCount: 0,
    error: null
};

export const notificationsReducer = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case FETCH_NEW_NOTIFICATIONS_COUNT:
            return {
                ...state,
                loading: true
            };
        case FETCH_NEW_NOTIFICATIONS_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                newNotificationsCount: action.payload,
                error: null
            };
        case FETCH_NEW_NOTIFICATIONS_COUNT_FAIL:
            return {
                ...state,
                loading: false,
                newNotificationsCount: 0,
                error: action.payload
            };
        default:
            return state;
    }
};