import React, { useEffect, useState } from 'react'
import { getAllCustomTours } from '../../../../../redux/api/bookings.api'
import TourBookingsTable from './TourBookingsTable'

import './styles.scss'

const CustomTourBookings = () => {
    const [tourBookings, setTourBookings] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        handleFetchTourBookings()
    }, [])

    const handleFetchTourBookings = async () => {
        setLoading(true)
        const response = await getAllCustomTours()
        if (response.status === 200) {
            setTourBookings(response.data)
        }
        setLoading(false)
    }

    return (
        <div className='tour_bookings'>
            <h1>
                Custom Tour Bookings
            </h1>
            <div className='tour_bookings_table'>
                <TourBookingsTable
                    tourBookings={tourBookings}
                    loading={loading}
                />
            </div>
        </div>
    )
}

export default CustomTourBookings