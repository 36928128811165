export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';
export const PENDING = 'PENDING';
export const SUSPENDED = 'SUSPENDED';
export const DELETED = 'DELETED';
export const BLOCKED = 'BLOCKED';
export const REJECTED = 'REJECTED';
export const ONUSE = 'ONUSE';

export const PUBLISHED = 'PUBLISHED';
export const DRAFT = 'DRAFT';
export const ARCHIVED = 'ARCHIVED';
export const AVAILABLE = 'AVAILABLE';
export const UNAVAILABLE = 'UNAVAILABLE';
