import {
    FETCH_NEW_NOTIFICATIONS_COUNT,
    FETCH_NEW_NOTIFICATIONS_COUNT_SUCCESS,
    FETCH_NEW_NOTIFICATIONS_COUNT_FAIL
} from '../../types';

import { getUnreadMessageCount } from '../../api/messages.api';

export const fetchNewNotificationCountAC = (id) => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_NEW_NOTIFICATIONS_COUNT
        });

        try {
            const res = await getUnreadMessageCount(id);
            dispatch({
                type: FETCH_NEW_NOTIFICATIONS_COUNT_SUCCESS,
                payload: res.data
            });
        } catch (error) {
            console.error('Profile Fetch Error', error);
            dispatch({
                type: FETCH_NEW_NOTIFICATIONS_COUNT_FAIL,
                payload: error
            });
        }
    }
}