import { fetchUsers } from '../../api/users.api';
import {
    FETCH_USERS,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAIL,

    CHANGE_USER_TYPE
} from '../../types';

export const changeUserTypeAC = (userType) => {
    return {
        type: CHANGE_USER_TYPE,
        payload: userType
    }
}

export const fetchUsersAC = (page, type) => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_USERS
        })
        try {
            const usersResponse = await fetchUsers(page, type)

            dispatch({
                type: FETCH_USERS_SUCCESS,
                payload: usersResponse.data
            })
        } catch (error) {
            dispatch({
                type: FETCH_USERS_FAIL,
                payload: error.message
            })
        }
    }
}