import {
    FETCH_TOUR_PACKAGES,
    FETCH_TOUR_PACKAGES_SUCCESS,
    FETCH_TOUR_PACKAGES_FAIL,

} from '../../types';

const initialState = {
    loading: false,
    tourPackages: [],
    error: null
};

export const tourPackagesReducer = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case FETCH_TOUR_PACKAGES:
            return {
                ...state,
                loading: true
            };
        case FETCH_TOUR_PACKAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                tourPackages: action.payload,
                error: null
            };
        case FETCH_TOUR_PACKAGES_FAIL:
            return {
                ...state,
                loading: false,
                tourPackages: [],
                error: action.payload
            };
        default:
            return state;
    }
}
