import React, { useEffect, useState } from 'react'
import { deleteUserTourItinerary, getItinerariesByUserTour, updateUserTourItinery } from '../../../../../../../redux/api/bookings.api';
import useMyProfile from '../../../../../../hooks/useMyProfile';
import { ADMIN, SUPER_ADMIN } from '../../../../../../Constants/roles';
import { Button, Collapse, Empty, Form, Input, Popconfirm, Select, Steps } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import { DEFAULT_THUMBNAIL } from '../../../../../../Constants/otherConstsnts';

import './styles.scss'
import AddUserTourItineryItem from './AddUserTourItineryItem';
import EditUserTourItineryItem from './EditUserTourItineryItem';
import { capitalizeFisrtLetterEachWord } from '../../../../../../helpers/nameRender';

const UserTourItinery = ({ userTour, messageApi, handlegetUserTour }) => {
    const myProfile = useMyProfile().userProfile
    const [itinirary, setItinerary] = useState(null);
    const [loading, setLoading] = useState(false);
    const [addItineraryVisible, setAddItineraryVisible] = useState(false)
    const [editItineraryVisible, setEditItineraryVisible] = useState(false)
    const [selectedItinerary, setSelectedItinerary] = useState()
    const [isItineryPublished, setIsItineryPublished] = useState(false)
    const [loadingPublish, setLoadingPublish] = useState(false)
    const [loadingApprove, setLoadingApprove] = useState("")
    const [loaddingSend, setLoaddingSend] = useState("")

    useEffect(() => {
        handleGetItinirary();
    }, []);
    useEffect(() => {
        checkIsItineryPublished()
    }, [itinirary, userTour]);

    const checkIsItineryPublished = () => {
        if (!userTour?.isItinaryAdded) {
            setIsItineryPublished(false)
            return
        }

        let publishedItiniraryStr = userTour?.copyOfItineries
        let currentItiniraryStr = JSON.stringify(itinirary)

        if (publishedItiniraryStr === currentItiniraryStr) {
            setIsItineryPublished(true)
        } else {
            setIsItineryPublished(false)
        }
    }

    const handleGetItinirary = async () => {
        setLoading(true);
        const response = await getItinerariesByUserTour(userTour.id);
        if (response.status === 200) {
            setItinerary(response.data);
        }
        setLoading(false);
    }

    const handleDeleteItineryItem = async (iti) => {
        try {
            const res = await deleteUserTourItinerary(iti.id)

            if (res.status === 200) {
                messageApi.success({
                    message: 'Itinery Item deleted successfully',
                    description: 'The itinery item has been successfully deleted'
                })
                handleGetItinirary()
            } else {
                messageApi.error({
                    message: 'Error deleting itinery item',
                    description: 'An error occurred while deleting the itinery item'
                })
            }

        } catch (error) {
            console.error('Error deleting itinery item', error)
            messageApi.error({
                message: 'Error deleting itinery item',
                description: error.message
            })
        }
    }

    const handlePublishItinerary = async () => {
        setLoadingPublish(true)
        try {
            const data = {
                userTourId: userTour.id,
                isItinaryAdded: true,
                isUserAgreedToTheItinery: "pending",
                copyOfItineries: JSON.stringify(itinirary),
                itineryChangeRequests: userTour.itineryChangeRequests
            }

            const res = await updateUserTourItinery('custom', data)

            if (res.status === 200) {
                messageApi.success({
                    message: 'Itinery Published Successfully',
                    description: 'The itinery has been successfully published'
                })
                await handleGetItinirary()
                await handlegetUserTour()
                checkIsItineryPublished()
            } else {
                messageApi.error({
                    message: 'Error Publishing Itinery',
                    description: 'An error occurred while publishing the itinery'
                })
            }

        } catch (error) {
            console.error('Error publishing itinery', error)
            messageApi.error({
                message: 'Error Publishing Itinery',
                description: error.message
            })
        }
        setLoadingPublish(false)
    }

    const handleChangeRequestStatus = async (values, index) => {
        setLoadingApprove(index)
        try {
            const prevRequests = JSON.parse(userTour.itineryChangeRequests)
            const newRequests = prevRequests.map((req, i) => {
                if (i === index) {
                    return {
                        ...req,
                        ...values
                    }
                }
                return req
            })

            const data = {
                userTourId: userTour.id,
                itineryChangeRequests: JSON.stringify(newRequests),
                isItinaryAdded: true,
                isUserAgreedToTheItinery: userTour.isUserAgreedToTheItinery,
                copyOfItineries: userTour.copyOfItineries
            }
            const res = await updateUserTourItinery('custom', data)

            if (res.status === 200) {
                messageApi.success({
                    message: 'Change Request Status Updated Successfully',
                    description: 'The change request status has been successfully updated'
                })
                await handleGetItinirary()
                await handlegetUserTour()
            } else {
                messageApi.error({
                    message: 'Error Updating Change Request Status',
                    description: 'An error occurred while updating the change request status'
                })
            }

        } catch (error) {
            console.error('Error updating change request status', error)
            messageApi.error({
                message: 'Error Updating Change Request Status',
                description: error.message
            })
        }
        setLoadingApprove("")
    }

    const handleSendAdminMessage = async (values, index) => {
        setLoaddingSend(index)
        try {
            values.isClosed = true
            const prevRequests = JSON.parse(userTour.itineryChangeRequests)
            const newRequests = prevRequests.map((req, i) => {
                if (i === index) {
                    return {
                        ...req,
                        ...values
                    }
                }
                return req
            })

            const data = {
                userTourId: userTour.id,
                itineryChangeRequests: JSON.stringify(newRequests),
                isItinaryAdded: true,
                isUserAgreedToTheItinery: userTour.isUserAgreedToTheItinery,
                copyOfItineries: userTour.copyOfItineries
            }
            const res = await updateUserTourItinery('custom', data)

            if (res.status === 200) {
                messageApi.success({
                    message: 'Admin Message Sent Successfully',
                    description: 'The admin message has been successfully sent'
                })
                await handleGetItinirary()
                await handlegetUserTour()
            } else {
                messageApi.error({
                    message: 'Error Sending Admin Message',
                    description: 'An error occurred while sending the admin message'
                })
            }

        } catch (error) {
            console.error('Error sending admin message', error)
            messageApi.error({
                message: 'Error Sending Admin Message',
                description: error.message
            })
        }
        setLoaddingSend("")
    }

    return (
        <div className='user_tour_itinery'>
            <div className='main_actions'>
                <Button type='primary' onClick={() => setAddItineraryVisible(true)}>
                    <PlusOutlined />
                    Add Itinerary Item
                </Button>

                {itinirary?.length > 0 && userTour?.isUserAgreedToTheItinery !== 'agreed' &&
                    <>
                        {
                            isItineryPublished ?
                                <Button type='default' onClick={() => handleGetItinirary()} disabled={loading}>
                                    Published. Click to Refresh
                                </Button>
                                :
                                <Button type='primary' onClick={() => handlePublishItinerary()} disabled={loading} loading={loadingPublish}>
                                    <UploadOutlined />
                                    Publish Itinerary
                                </Button>

                        }
                    </>}
            </div>

            {
                !isItineryPublished && !loading &&
                <div className='not_published'>
                    <h2>Itinery Not Published</h2>
                    <p>
                        The itinery has not been published. Please publish the itinery to make it available to the user.
                    </p>
                </div>

            }

            <div className='itinery__status'>
                {itinirary?.length > 0 &&
                    <h3>User Agreement Status: <span style={{ color: userTour?.isUserAgreedToTheItinery === 'agreed' ? 'green' : userTour?.isUserAgreedToTheItinery === 'reject' ? 'red' : 'orange' }}>
                        {userTour?.isUserAgreedToTheItinery ? capitalizeFisrtLetterEachWord(userTour.isUserAgreedToTheItinery) : 'Pending'}
                    </span>
                    </h3>}

                {
                    userTour?.itineryChangeRequests &&
                    <h3>Change Requests</h3>
                }
                {
                    userTour?.itineryChangeRequests &&
                    (() => {
                        let parsedRequests;
                        try {
                            parsedRequests = JSON.parse(userTour.itineryChangeRequests);
                        } catch (error) {
                            return null;
                        }

                        if (!Array.isArray(parsedRequests)) {
                            return null;
                        }

                        return parsedRequests.map((req, index) => (
                            <div key={index}
                                className={`change_request ${userTour.isUserAgreedToTheItinery === 'agreed' && 'user_agreed'}`}
                            >
                                {userTour.isUserAgreedToTheItinery === 'agreed' &&
                                    <div className='overlay'>
                                        <h3>
                                            User Agreed!
                                        </h3>
                                        <p>
                                            The user has agreed to the itinery. You can igonre the change requests.
                                        </p>
                                    </div>}
                                <div className='header'>
                                    <h4>Change Request {index + 1} <span style={{ color: 'red' }}>{req.isClosed && ' - Closed'}</span></h4>
                                    <div className='actions'>
                                        <Form
                                            onFinish={(values) => handleChangeRequestStatus(values, index)}
                                            layout='inline'
                                        >
                                            <Form.Item
                                                label='Approve Status'
                                                name='itineryChangeApproveStatus'
                                                initialValue={req.itineryChangeApproveStatus}
                                            >
                                                <Select
                                                    defaultValue={req.itineryChangeApproveStatus}
                                                    style={{ width: 120 }}
                                                    disabled={req.isClosed}
                                                >
                                                    <Select.Option value='pending'>Pending</Select.Option>
                                                    <Select.Option value='approve'>Approve</Select.Option>
                                                    <Select.Option value='reject'>Reject</Select.Option>
                                                </Select>
                                            </Form.Item>

                                            <Button
                                                type='primary'
                                                htmlType='submit'
                                                loading={loadingApprove === index}
                                                disabled={req.isClosed}
                                            >
                                                Submit
                                            </Button>
                                        </Form>
                                    </div>
                                </div>
                                <p>
                                    <strong>Date Need to Change: </strong> {req.day}
                                </p>
                                <p>
                                    <strong>Requested: </strong> {req.changeRequest}
                                </p>
                                <p>
                                    <strong>Approve Status: </strong> {req.itineryChangeApproveStatus}
                                </p>
                                {
                                    req?.adminMessage ?
                                        <p>
                                            <strong>Admin Message: </strong> {req.adminMessage}
                                        </p>
                                        :
                                        <Form
                                            onFinish={(values) => handleSendAdminMessage(values, index)}
                                            layout='vertical'
                                        >
                                            <Form.Item
                                                label='Admin Message'
                                                name='adminMessage'
                                                initialValue={req.adminMessage}
                                            >
                                                <Input.TextArea
                                                    placeholder='Enter message'
                                                    style={{ width: '100%', margin: '0' }}
                                                />
                                            </Form.Item>

                                            <Button
                                                type='primary'
                                                htmlType='submit'
                                                loading={loaddingSend === index}
                                                style={{ marginTop: '0' }}
                                            >
                                                Send and Close Request
                                            </Button>
                                        </Form>
                                }
                            </div>
                        ));
                    })()
                }
            </div>

            {loading ? <p>Loading...</p> :
                <div className='items_list_acc'>
                    {itinirary?.length > 0 ? <>
                        {itinirary.map((item, index) => (
                            <div key={index} className='item'>
                                <div className='body'>
                                    <div className='detsils'>
                                        <div className='header'>
                                            <h3>
                                                {item.dayTitle}
                                            </h3>

                                            {(myProfile?.role === SUPER_ADMIN || myProfile?.role === ADMIN) &&
                                                <div className='actions'>
                                                    <Button type='default' icon={<EditOutlined />}
                                                        onClick={() => {
                                                            setSelectedItinerary(item)
                                                            setEditItineraryVisible(true)
                                                        }}
                                                    >
                                                        Edit
                                                    </Button>

                                                    <Popconfirm
                                                        title='Are you sure you want to delete this itinery item?'
                                                        description={<p
                                                            style={{
                                                                width: '300px',
                                                                color: 'red',
                                                            }}
                                                        >This action cannot be undone! This will permanently delete the itinery item</p>}
                                                        onConfirm={() => handleDeleteItineryItem(item)}
                                                        okText='Yes'
                                                        cancelText='No'
                                                    >
                                                        <Button danger type='primary' icon={<DeleteOutlined />}>
                                                            Delete
                                                        </Button>
                                                    </Popconfirm>
                                                </div>
                                            }
                                        </div>
                                        <ReactQuill
                                            value={item?.dayDescription}
                                            readOnly={true}
                                            theme='bubble'
                                        />
                                        <p>
                                            <strong>ID: </strong> {item?.id}
                                        </p>
                                        <div className='room__details'>
                                            <div>
                                                <p>
                                                    <strong>Main Destination: </strong> {item?.mainDestination?.destinationName}
                                                </p>
                                            </div>

                                            <div >
                                                <p>
                                                    <strong>Stay: </strong> {item.stay?.accommodationName}
                                                </p>
                                            </div>
                                            <div>
                                                <p>
                                                    <strong>Day: </strong> {item?.dayNumber}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='day__plan'>
                                            <Collapse>
                                                <Collapse.Panel header='Day Plan' key='1'>
                                                    <Steps
                                                        direction="vertical"
                                                        size="small"
                                                        items={
                                                            item?.items
                                                                ?.sort((a, b) => a.position - b.position) // Sort items by position
                                                                ?.map((item, index) => ({
                                                                    description:
                                                                        <div className='item_iti_dis'>
                                                                            <img src={item?.thumbnailUrl || DEFAULT_THUMBNAIL} alt='thumbnail' />

                                                                            <div>
                                                                                <h3>{item?.eventName || item?.activityName || item?.attractionName || item?.destinationName || 'N/A'}</h3>
                                                                                <p>
                                                                                    <strong>Short Description: </strong>
                                                                                    <ReactQuill
                                                                                        value={item?.attractionDescriptionShort || item?.activityDescriptionShort || item?.eventDescriptionShort || item?.destinationDescriptionShort || 'N/A'}
                                                                                        readOnly={true}
                                                                                        theme='bubble'
                                                                                    />
                                                                                </p>
                                                                                <p>
                                                                                    <strong>Type: </strong> {item?.type}
                                                                                </p>
                                                                            </div>
                                                                        </div>,
                                                                    status: 'process',
                                                                }))
                                                        }
                                                    />
                                                </Collapse.Panel>
                                            </Collapse>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                        : <>
                            {itinirary?.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        </>
                    }
                </div>
            }

            <AddUserTourItineryItem
                isVisible={addItineraryVisible}
                setIsVisible={setAddItineraryVisible}
                tourPackage={userTour}
                handleGetItinirary={handleGetItinirary}
            />

            <EditUserTourItineryItem
                isVisible={editItineraryVisible}
                setIsVisible={setEditItineraryVisible}
                selectedItinerary={selectedItinerary}
                handleGetItinirary={handleGetItinirary}
            />
        </div>
    )
}

export default UserTourItinery