import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Divider, Drawer, Form, Image, Input, Row, Select, Space, Spin, notification } from 'antd'
import { searchAccommodation } from '../../../../../../../../redux/api/hotels.api';
import ReactQuill, { Quill } from 'react-quill';
import useDestinations from '../../../../../../../hooks/useDestinations';
import useAttractions from '../../../../../../../hooks/useAttractions';
import useActivities from '../../../../../../../hooks/useActivities';
import useEvents from '../../../../../../../hooks/useEvents';
import { CloseOutlined } from '@ant-design/icons';
import { addTourItinerary } from '../../../../../../../../redux/api/tourPackages.api';
import { addUserTourItinerary } from '../../../../../../../../redux/api/bookings.api';
import { useDispatch } from 'react-redux';
import { fetchDestinationNamesAC } from '../../../../../../../../redux/actions/destinations/destinations.ac';
import { fetchAttractionNamesAC } from '../../../../../../../../redux/actions/attractions/attractions.ac';
import { fetchActivityNamesAC } from '../../../../../../../../redux/actions/activities/activities.ac';
import { fetchEventNamesAC } from '../../../../../../../../redux/actions/events/events.ac';

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};


const quillModules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
        ["formula"],
        [{ size: ["small", false, "large", "huge"] }],
    ],
    clipboard: {
        matchVisual: false
    },
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
};


const AddUserTourItineryItem = ({
    isVisible,
    setIsVisible,
    tourPackage,
    handleGetItinirary
}) => {
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [dayDescriptionWordCount, setDayDescriptionWordCount] = useState(0);
    const [selectedItemType, setSelectedItemType] = useState('destination');

    const destinationNames = useDestinations().destinationNames
    const attractionNames = useAttractions().attarctionNames
    const activityNames = useActivities().activityNames
    const eventNames = useEvents().eventNames

    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const dispatch = useDispatch();


    useEffect(() => {
        if (destinationNames?.length === 0) {
            dispatch(fetchDestinationNamesAC())
        }

        if (attractionNames?.length === 0) {
            dispatch(fetchAttractionNamesAC())
        }

        if (activityNames?.length === 0) {
            dispatch(fetchActivityNamesAC())
        }

        if (eventNames?.length === 0) {
            dispatch(fetchEventNamesAC())
        }

    }, [])

    const handleUserSearch = async (value) => {
        if (value === '') {
            setSearchResults([]);
            return;
        } else {
            setIsSearchLoading(true);
            try {
                const res = await searchAccommodation(value);
                if (res.status === 200) {
                    const data = res?.data?.map(hotel => ({
                        label: hotel.accommodationName,
                        value: hotel.id
                    }));
                    setSearchResults(data);
                }
            } catch (error) {
                console.log(error);
            }
            setIsSearchLoading(false);
        }
    };


    const destinationOptions = Object.keys(destinationNames).map(key => {
        return {
            label: getOptionName(destinationNames[key].destinationName),
            value: destinationNames[key].id
        };
    });

    const attractionOptions = Object.keys(attractionNames).map(key => {
        return {
            label: getOptionName(attractionNames[key].attractionName),
            value: attractionNames[key].id
        };
    });

    const activityOptions = Object.keys(activityNames).map(key => {
        return {
            label: getOptionName(activityNames[key].activityName),
            value: activityNames[key].id
        };
    });

    const eventOptions = Object.keys(eventNames).map(key => {
        return {
            label: getOptionName(eventNames[key].eventName),
            value: eventNames[key].id
        };
    })

    const onFinish = async (values) => {
        setIsLoading(true);
        try {
            Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

            values.items = values.items.map((item, index) => {
                return {
                    position: index + 1,
                    locationType: item.itemType,
                    location: item?.list[0]?.location
                }
            });

            values.items = JSON.stringify(values.items);
            values.userTourId = tourPackage.id;

            const res = await addUserTourItinerary(values);

            if (res.status === 200) {
                api.success({
                    message: 'Success',
                    description: 'Itinerary item added successfully'
                });
                setIsVisible(false);
                handleGetItinirary();
            } else {
                api.error({
                    message: 'Error',
                    description: 'An error occurred while adding the itinery item. Please try again.'
                });
            }

        } catch (error) {
            console.log(error);
            api.error({
                message: 'Error',
                description: `Please ensure all fields are filled correctly.`
            });
        }
        setIsLoading(false);
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Add New Itinerary Item"
                width={720}
                open={isVisible}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                onClose={() => setIsVisible(false)}
            >
                <Spin spinning={isLoading} tip='Please Wait'>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="dayTitle"
                                    label="Day Title"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the day title',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter the day title" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="dayNumber"
                                    label="Day Number (e.g. 1, 2, 3)"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the day number',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter the day number" type='number' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label={`Day Description ( ${dayDescriptionWordCount}/1000 characters)`}
                                    name='dayDescription'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the day description',
                                        }
                                    ]}
                                >
                                    <ReactQuill
                                        theme="snow"
                                        limits={{ maxLength: 1000 }}
                                        onChange={(content, delta, source, editor) => {
                                            setDayDescriptionWordCount(editor.getLength())
                                        }}
                                        modules={quillModules}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Divider orientation='left'>Day Plan</Divider>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label="Day's main destination"
                                    name='mainDestination'
                                >
                                    <Select
                                        mode="single"
                                        showSearch
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Add the day's main destination"
                                        options={destinationOptions}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.List name="items">
                                    {(fields, { add, remove }) => (
                                        <div
                                            style={{
                                                display: 'flex',
                                                rowGap: 16,
                                                flexDirection: 'column',
                                            }}
                                        >
                                            {fields.map((field) => (
                                                <Card
                                                    size="small"
                                                    title={`Location ${field.name + 1}`}
                                                    key={field.key}
                                                    extra={
                                                        <CloseOutlined
                                                            onClick={() => {
                                                                remove(field.name);
                                                            }}
                                                        />
                                                    }
                                                >
                                                    <Form.Item label="Location Type" name={[field.name, 'itemType']}>
                                                        <Select
                                                            placeholder="Select location type"
                                                            onChange={(value) => setSelectedItemType(value)}
                                                        >
                                                            <Select.Option value="destination">Destination</Select.Option>
                                                            <Select.Option value="attraction">Attraction</Select.Option>
                                                            <Select.Option value="activity">Activity</Select.Option>
                                                            <Select.Option value="event">Event</Select.Option>
                                                        </Select>
                                                    </Form.Item>

                                                    <Form.Item label={`Select the ${selectedItemType}`}>
                                                        <Form.List name={[field.name, 'list']}>
                                                            {(subFields, subOpt) => (
                                                                <div>
                                                                    {subFields.map((subField) => (
                                                                        <Space key={subField.key}>
                                                                            <Form.Item noStyle name={[subField.name, 'location']}>
                                                                                <Select
                                                                                    mode="single"
                                                                                    showSearch
                                                                                    style={{
                                                                                        width: '400px',
                                                                                    }}
                                                                                    placeholder={`Select ${selectedItemType}`}
                                                                                    options={
                                                                                        selectedItemType === 'destination'
                                                                                            ? destinationOptions
                                                                                            : selectedItemType === 'attraction'
                                                                                                ? attractionOptions
                                                                                                : selectedItemType === 'activity'
                                                                                                    ? activityOptions
                                                                                                    : eventOptions
                                                                                    }
                                                                                />
                                                                            </Form.Item>
                                                                            <CloseOutlined
                                                                                onClick={() => {
                                                                                    subOpt.remove(subField.name);
                                                                                }}
                                                                            />
                                                                        </Space>
                                                                    ))}
                                                                    {subFields.length < 1 &&
                                                                        <Button type="dashed" onClick={() => subOpt.add()} block>
                                                                            + Add {selectedItemType}
                                                                        </Button>}
                                                                </div>
                                                            )}
                                                        </Form.List>
                                                    </Form.Item>
                                                </Card>
                                            ))}

                                            <Button type="dashed" onClick={() => add()} block>
                                                + Add Item
                                            </Button>
                                        </div>
                                    )}
                                </Form.List>
                            </Col>
                        </Row>

                        <Divider orientation='left'>Accommodation</Divider>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label="Day's stay"
                                    name='stay'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the day stay',
                                        }
                                    ]}
                                >

                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Select a accommodation"
                                        notFoundContent={isSearchLoading ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        onSearch={handleUserSearch}
                                        options={searchResults}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>


                        <Form.Item>
                            <Button type='primary' htmlType='submit' loading={isLoading}>
                                Add Itinerary Item
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Drawer>


            {previewImage && (
                <Image
                    wrapperStyle={{
                        display: 'none',
                    }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </div>
    )
}

export default AddUserTourItineryItem