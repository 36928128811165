import React, { useEffect, useState } from 'react'

import { Button, Col, Collapse, Empty, Form, Input, List, Row, Select, Spin, Tooltip } from 'antd'
import { ExportOutlined, PlusOutlined } from '@ant-design/icons'
import { vehiclesTypes } from '../../../../Constants/itemTypes';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useTransports from '../../../../hooks/useTransports';
import { fetchTransportsAC } from '../../../../../redux/actions/transport/transport.ac';
import ReactQuill from 'react-quill';

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const vehicleOptions = Object.keys(vehiclesTypes).map(key => {
    return {
        label: getOptionName(vehiclesTypes[key]),
        value: key
    };
});

const Transports = () => {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1)
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [filterValues, setFilterValues] = useState({})
    const transports = useTransports().transports

    useEffect(() => {
        handleFetchTransport(1, {})
    }, [])

    const handleFetchTransport = async (page, values) => {
        setLoading(true)
        values.page = page
        await dispatch(fetchTransportsAC(values))
        setLoading(false)
    }

    const onFilterSubmit = async (values) => {
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);
        setFilterValues(values)
        await handleFetchTransport(1, values)
    }

    return (
        <div className='accommodations'>
            <div className='accommodations__header'>
                <div className='left'>
                    <h1>
                        Transport Packages
                    </h1>
                </div>
                <div className='destinations__header__actions'>
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => navigate('/dashboard/partners/transport/add')}>
                        Add Transport Package
                    </Button>
                </div>
            </div>

            <div className='filters'>
                <Collapse
                    items={[
                        {
                            key: '1',
                            label: 'Filters',
                            children:
                                <Form
                                    layout='inline'
                                    onFinish={onFilterSubmit}
                                >
                                    <Form.Item
                                        name='vehicleType'
                                    >
                                        <Select
                                            showSearchsa
                                            mode='single'
                                            allowClear
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Vehicle Type"
                                            options={vehicleOptions}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name='vehicleCapacity'
                                    >
                                        <Input placeholder='Vehicle Capacity' type='number' style={{ width: '150px' }} min={1} max={50} />
                                    </Form.Item>
                                    <Form.Item
                                        name='priceBetween'
                                    >
                                        <Tooltip title='Please use the format: 1000-2000'>
                                            <Input placeholder='Price Between' />
                                        </Tooltip>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type='primary' htmlType='submit' loading={loading}>
                                            Get Results
                                        </Button>
                                    </Form.Item>
                                </Form>
                        },
                    ]}
                />
            </div>

            <div className='destinations__list'>
                {transports && transports.length > 0 ?
                    <List
                        loading={loading}
                        pagination={{
                            position: 'bottom',
                            align: 'end',
                            pageSize: 5,
                            showSizeChanger: false,
                            total: transports[0]?.filteredTransportsCount,
                            current: page,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                            onChange: (page) => { setPage(page); handleFetchTransport(page, filterValues) }
                        }}
                        dataSource={transports}
                        renderItem={(item, index) => (
                            <List.Item key={index} className='destination__item'>
                                <div className='right'>
                                    <div className='destination__item__header'>
                                        <h3>{item?.packageName}</h3>
                                        <Button type='primary' onClick={() => navigate(`/dashboard/partners/transport/${item.id}`)} icon={<ExportOutlined />}>
                                            View
                                        </Button>
                                    </div>
                                    <ReactQuill
                                        value={item?.packageDescription}
                                        readOnly={true}
                                        theme='bubble'
                                    />
                                    <Row gutter={16} span={12}>
                                        <Col span={6}>
                                            <p><strong>Package Type: </strong>{item?.packageType}</p>
                                            <p><strong>Vehicle Type: </strong>{item?.vehicleType || 'N/A'}</p>
                                        </Col>
                                        <Col span={6}>
                                            <p><strong>Capacity: </strong>{item?.vehicleCapacity || 'N/A'}</p>
                                            <p><strong>Status: </strong>{item?.activeStatus || 'N/A'}</p>
                                        </Col>
                                    </Row>
                                </div>
                            </List.Item>
                        )}
                    />
                    :
                    <>
                        {
                            loading ?
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '500px'
                                }}>
                                    <Spin size='large' />
                                </div>
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default Transports