import React, { useEffect, useState } from 'react'

import './styles.scss'
import { Empty, List, Spin } from 'antd'
import { fatchUserActivityLog } from '../../../../../../redux/api/users.api'

const UserActivityLog = ({ userData }) => {
    const [loading, setLoading] = useState(false)
    const [logData, setLogData] = useState()
    const [page, setPage] = useState(1)

    useEffect(() => {
        if (!logData) {
            getLogData(page)
        }
    }, [])

    const getLogData = async (page) => {
        setLoading(true)
        try {
            const data = await fatchUserActivityLog(userData?.id, page)
            setLogData(data?.data)
        } catch (error) {
            console.error('Error getting user activity log', error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className='user__activity__log'>
            <h2>Activity Log</h2>

            {userData && logData ?
                <List
                    loading={loading}
                    pagination={{
                        position: 'bottom',
                        align: 'end',
                        pageSize: 6,
                        showSizeChanger: false,
                        total: logData[0]?.totalActivities,
                        current: page,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        onChange: (page) => { setPage(page); getLogData(page) }
                    }}
                    dataSource={logData}
                    renderItem={(item, index) => (
                        <List.Item key={index} className='activity__log__item'>
                            <h2>Activity Type: {item?.activityType}</h2>
                            <p>Activity Description: {item?.activityDescription}</p>
                            <span>Activity Date: {item?.createdAt}</span>
                        </List.Item>
                    )}
                />
                :
                <>
                    {
                        loading ?
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '500px'
                            }}>
                                <Spin size='large' />
                            </div>
                            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }
                </>
            }
        </div>
    )
}

export default UserActivityLog