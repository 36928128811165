import React, { useEffect, useState } from 'react'
import useDestinations from '../../../../../hooks/useDestinations'
import { useNavigate } from 'react-router-dom'
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

import { ACCOMMODATION_CONTAINER, handleDeleteBlob } from '../../../../../azure/blob'
import useHotels from '../../../../../hooks/useHotels';
import { getAccommodationById, getAccommodationRooms, deleteAccommodationRooms, deleteAccommodation, getAccomodationOffers, deleteAccommodationOffer, getAccommodationFacilities, deleteAccommodationFacilities, getAccommodationHouseRules, deleteAccommodationHouseRules, getAccommodationMeals, deleteAccommodationMealInfo, getAccommodationPackages, deleteAccommodationPackage } from '../../../../../../redux/api/hotels.api';

import { CaretRightOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Empty, Image, Popconfirm, Spin, Tag, notification, Rate, Skeleton, Collapse } from 'antd'


import './styles.scss'
import AddRoom from './AddRoom';
import { DEFAULT_THUMBNAIL } from '../../../../../Constants/otherConstsnts';
import EditRoom from './EditRoom';
import { useDispatch } from 'react-redux';
import { fetchDestinationNamesAC } from '../../../../../../redux/actions/destinations/destinations.ac';
import AddOffer from './AddOffer';
import EditOffer from './EditOffer';
import AddFacility from './AddFacility';
import { capitalizeFisrtLetterEachWord } from '../../../../../helpers/nameRender';
import AddHouseRule from './AddHouseRule';
import EditHouseRule from './EditHouseRule';
import AddMeal from './AddMeal';
import AddPackage from './AddPackage';
import EditPackage from './EditPackage';


const Hotel = () => {
    const destinations = useDestinations()
    const hotels = useHotels().hotels
    const destinationNames = destinations.destinationNames
    const url = window.location.pathname
    const hotelId = url.split('/').pop()
    const [loading, setLoading] = useState(false)
    const [hotel, setHotel] = useState()
    const navigate = useNavigate()
    const [api, contextHolder] = notification.useNotification();
    const [loadingText, setLoadingText] = useState('Please Wait...')

    const [addRoomVisible, setAddRoomVisible] = useState(false)
    const [accommodationRooms, setAccommodationRooms] = useState([])
    const [roomLoading, setRoomLoading] = useState(false)
    const [showAccommodationRooms, setShowAccommodationRooms] = useState(false)
    const [editRoomVisible, setEditRoomVisible] = useState(false)
    const [selectedEditRoom, setSelectedEditRoom] = useState()

    const [addOfferVisible, setAddOfferVisible] = useState(false)
    const [offers, setOffers] = useState([])
    const [loadingOffers, setLoadingOffers] = useState(false)
    const [editOfferVisible, setEditOfferVisible] = useState(false)
    const [selectedEditOffer, setSelectedEditOffer] = useState()

    const [addFacilityVisible, setAddFacilityVisible] = useState(false)
    const [showAccommodationFacilities, setShowAccommodationFacilities] = useState(false)
    const [facilities, setFacilities] = useState([])
    const [loadingFacilities, setLoadingFacilities] = useState(false)

    const [addHouseRuleVisible, setAddHouseRuleVisible] = useState(false)
    const [houseRules, setHouseRules] = useState()
    const [loadingHouseRules, setLoadingHouseRules] = useState(false)
    const [editHouseRuleVisible, setEditHouseRuleVisible] = useState(false)

    const [addMealInfoVisible, setAddMealInfoVisible] = useState(false)
    const [showAccommodationMeals, setShowAccommodationMeals] = useState(false)
    const [meals, setMeals] = useState([])
    const [loadingMeals, setLoadingMeals] = useState(false)

    const [addAccommodationPackgaeVisible, setAddAccommodationPackgaeVisible] = useState(false)
    const [showAccommodationPackages, setShowAccommodationPackages] = useState(false)
    const [accommodationPackages, setAccommodationPackages] = useState([])
    const [loadingAccommodationPackages, setLoadingAccommodationPackages] = useState(false)
    const [selectedEditPackage, setSelectedEditPackage] = useState()
    const [editAccommodationPackageVisible, setEditAccommodationPackageVisible] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        if (hotels?.length > 0) {
            const hotel = hotels.find(hotel => hotel.id === hotelId)
            if (hotel) {
                setHotel(hotel)
            } else {
                handleGetHotel(hotelId)
            }
        } else {
            handleGetHotel(hotelId)
        }

        if (destinationNames?.length === 0) {
            dispatch(fetchDestinationNamesAC())
        }

        handleGetOffers()
        getHouseRule()
    }, [])

    const handleGetHotel = async (id) => {
        setLoading(true)
        try {
            const hotel = await getAccommodationById(id)
            setHotel(hotel.data[0])
        } catch (error) {
            console.error('Error fetching activity', error)
        }
        setLoading(false)
    }

    const getBlobName = (blobUrl) => {
        const splitUrl = blobUrl.split('/')
        return splitUrl[splitUrl?.length - 1]
    }

    const handleGetOffers = async () => {
        setLoadingOffers(true)
        try {
            const offers = await getAccomodationOffers(hotelId)

            setOffers(offers.data)
        } catch (error) {
            console.error('Error fetching accommodation offers', error)
        }
        setLoadingOffers(false)
    }

    const handleGetRooms = async () => {
        setRoomLoading(true)
        try {
            const rooms = await getAccommodationRooms(hotelId)
            setAccommodationRooms(rooms.data)
        } catch (error) {
            console.error('Error fetching accommodation rooms', error)
        }
        setRoomLoading(false)
    }

    const getFacilities = async () => {
        setLoadingFacilities(true)
        try {
            const facilities = await getAccommodationFacilities(hotelId)
            setFacilities(facilities.data)
        } catch (error) {
            console.error('Error fetching accommodation facilities', error)
        }
        setLoadingFacilities(false)
    }

    const getHouseRule = async () => {
        setLoadingHouseRules(true)
        try {
            const rules = await getAccommodationHouseRules(hotelId)
            if (rules.data !== "") {
                setHouseRules(rules.data)
            } else {
                setHouseRules(null)
            }
        } catch (error) {
            console.log("Error fetching house rules", error)
        }
        setLoadingHouseRules(false)
    }

    const getMealInfo = async () => {
        setLoadingMeals(true)
        try {
            const meals = await getAccommodationMeals(hotelId)
            setMeals(meals.data)
        } catch (error) {
            console.error('Error fetching accommodation meals', error)
        }
        setLoadingMeals(false)
    }

    const fetchAccommodationPackages = async () => {
        setLoadingAccommodationPackages(true)
        try {
            const packages = await getAccommodationPackages(hotelId)
            setAccommodationPackages(packages.data)
        } catch (error) {
            console.error('Error fetching accommodation packages', error)
        }
        setLoadingAccommodationPackages(false)
    }

    const handleDeleteRoom = async (room) => {
        try {
            let roomImages = room?.roomImages?.split(',')

            if (roomImages?.length > 0) {
                setLoadingText('Deleting Images');
                const imageDeletePromises = roomImages.map(async (image, index) => {
                    const blobName = getBlobName(image);
                    setLoadingText(`Deleting image ${index + 1}`);
                    return handleDeleteBlob(ACCOMMODATION_CONTAINER, blobName);
                });
                await Promise.all(imageDeletePromises);
            }

            const res = await deleteAccommodationRooms(room.id)

            if (res.status === 200) {
                api.success({
                    message: 'Room deleted successfully',
                    description: 'The room has been successfully deleted'
                })
                handleGetRooms()
            } else {
                api.error({
                    message: 'Error deleting room',
                    description: 'An error occurred while deleting the room'
                })
            }

        } catch (error) {
            console.error('Error deleting room', error)
            api.error({
                message: 'Error deleting room',
                description: error.message
            })
        }
    }

    const handleDeleteOffer = async (offer) => {
        try {
            const res = await deleteAccommodationOffer(offer.id)

            if (res.status === 200) {
                api.success({
                    message: 'Offer deleted successfully',
                    description: 'The offer has been successfully deleted'
                })
                await handleGetOffers()
            } else {
                api.error({
                    message: 'Error deleting offer',
                    description: 'An error occurred while deleting the offer'
                })
            }

        } catch (error) {
            console.error('Error deleting offer', error)
            api.error({
                message: 'Error deleting offer',
                description: error.message
            })
        }
    }

    const handleDeleteHotel = async () => {
        setLoading(true)
        try {
            // get all blobs
            let accommodationImages = hotel.accommodationImages?.split(',')
            let accommodationVideos = hotel.accommodationVideos?.split(',')
            let thumbnailUrl = hotel.thumbnailUrl

            if (accommodationImages?.length > 0) {
                setLoadingText('Deleting Images');
                const imageDeletePromises = accommodationImages.map(async (image, index) => {
                    const blobName = getBlobName(image);
                    setLoadingText(`Deleting image ${index + 1}`);
                    return handleDeleteBlob(ACCOMMODATION_CONTAINER, blobName);
                });
                await Promise.all(imageDeletePromises);
            }

            if (accommodationVideos?.length > 0) {
                setLoadingText('Deleting Videos');
                const videoDeletePromises = accommodationVideos.map(async (video, index) => {
                    const blobName = getBlobName(video);
                    setLoadingText(`Deleting video ${index + 1}`);
                    return handleDeleteBlob(ACCOMMODATION_CONTAINER, blobName);
                });
                await Promise.all(videoDeletePromises);
            }

            if (thumbnailUrl) {
                setLoadingText('Deleting Thumbnail');
                const blobName = getBlobName(thumbnailUrl);
                setLoadingText(`Deleting thumbnail`);
                await handleDeleteBlob(ACCOMMODATION_CONTAINER, blobName);
            }

            setLoadingText('Deleting Accommodation from DB');
            const res = await deleteAccommodation(hotelId)

            if (res.status === 200) {
                api.success({
                    message: 'Accommodation deleted successfully',
                    description: 'The accommodation has been successfully deleted'
                })

                setTimeout(() => {
                    navigate('/dashboard/partners/hotels')
                }, 2000)

            } else {
                api.error({
                    message: 'Error deleting accommodation',
                    description: 'An error occurred while deleting the accommodation'
                })
            }

        } catch (error) {
            console.error('Error deleting accommodation', error)
            api.error({
                message: 'Error deleting accommodation',
                description: error.message
            })
        }
        setLoading(false)
    }

    const handleDeleteFacility = async (facility) => {
        try {
            const res = await deleteAccommodationFacilities(facility.id)

            if (res.status === 200) {
                api.success({
                    message: 'Facility deleted successfully',
                    description: 'The facility has been successfully deleted'
                })
                await getFacilities()
            } else {
                api.error({
                    message: 'Error deleting facility',
                    description: 'An error occurred while deleting the facility'
                })
            }
        } catch (error) {
            console.error('Error deleting facility', error)
            api.error({
                message: 'Error deleting facility',
                description: error.message
            })
        }
    }

    const handleDeleteHouseRule = async (rule) => {
        try {
            const res = await deleteAccommodationHouseRules(rule.id)
            if (res.status === 200) {
                api.success({
                    message: 'House rule deleted successfully',
                    description: 'The house rule has been successfully deleted'
                })
                getHouseRule()
            } else {
                api.error({
                    message: 'Error deleting house rule',
                    description: 'An error occurred while deleting the house rule'
                })
            }
        } catch (error) {
            console.error('Error deleting house rule', error)
            api.error({
                message: 'Error deleting house rule',
                description: error.message
            })
        }
    }

    const handleDeleteMeal = async (meal) => {
        try {
            const res = await deleteAccommodationMealInfo(meal.id)
            if (res.status === 200) {
                api.success({
                    message: 'Meal deleted successfully',
                    description: 'The meal has been successfully deleted'
                })
                getMealInfo()
            } else {
                api.error({
                    message: 'Error deleting meal',
                    description: 'An error occurred while deleting the meal'
                })
            }
        } catch (error) {
            console.error('Error deleting meal', error)
            api.error({
                message: 'Error deleting meal',
                description: error.message
            })
        }
    }

    const handleDeletePackage = async (accPack) => {
        try {
            let packageImages = accPack?.packageImages?.split(',')

            if (packageImages?.length > 0) {
                setLoadingText('Deleting Images');
                const imageDeletePromises = packageImages.map(async (image, index) => {
                    const blobName = getBlobName(image);
                    setLoadingText(`Deleting image ${index + 1}`);
                    return handleDeleteBlob(ACCOMMODATION_CONTAINER, blobName);
                });
                await Promise.all(imageDeletePromises);
            }

            const res = await deleteAccommodationPackage(accPack.id)
            if (res.status === 200) {
                api.success({
                    message: 'Package deleted successfully',
                    description: 'The package has been successfully deleted'
                })
                fetchAccommodationPackages()
            } else {
                api.error({
                    message: 'Error deleting package',
                    description: 'An error occurred while deleting the package'
                })
            }

        } catch (error) {
            console.error('Error deleting package', error)
            api.error({
                message: 'Error deleting package',
                description: error.message
            })
        }
    }

    const renderOfferExpiry = (date) => {
        // if expiry date in next 7 days, show in red, if 30 days, show in yellow, if more than 30 days, show in green and if expired, show in red strikethrough
        const expiryDate = new Date(date)
        const currentDate = new Date()
        const diffTime = expiryDate - currentDate
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

        if (diffDays <= 0) {
            return <span style={{ color: 'red' }}><span style={{ color: 'red', textDecoration: 'line-through' }}>{date}</span> - Expired</span>
        } else if (diffDays <= 7) {
            return <span style={{ color: 'red' }}>{date}</span>
        } else if (diffDays <= 30) {
            return <span style={{ color: 'orange' }}>{date}</span>
        } else {
            return <span style={{ color: 'green' }}>{date}</span>
        }
    }

    const calculateDisplayPrice = (price, margin) => {
        price = parseFloat(price);
        margin = parseFloat(margin);

        return (price * (margin / 100)) + price;
    }

    const renderPdfName = (url) => {
        const parts = url.split('/');
        const fileNameWithParams = parts.pop();
        const [fileName] = fileNameWithParams.split('?');
        return decodeURIComponent(fileName);
    }

    return (
        <div className='destination'>
            {contextHolder}
            <Spin
                spinning={loading}
                size='large'
                tip={loadingText}
            >
                <>
                    <div className='destination__header'>
                        <h1>
                            {hotel?.accommodationName}
                        </h1>
                        <div className='destination__header__actions'>
                            <Button type='primary' onClick={() => navigate(`/dashboard/partners/hotels/edit/${hotelId}`)} icon={<EditOutlined />}>
                                Edit
                            </Button>
                            <Popconfirm
                                title='Are you sure you want to delete this accommodation?'
                                description={<p
                                    style={{
                                        width: '300px',
                                        color: 'red',
                                    }}
                                >This action cannot be undone! This will permanently delete the accommodation, its images, videos, attractions and other related items.</p>}
                                onConfirm={handleDeleteHotel}
                                okText='Yes'
                                cancelText='No'
                            >
                                <Button danger type='primary' icon={<DeleteOutlined />}>
                                    Delete
                                </Button>
                            </Popconfirm>
                        </div>
                    </div>
                    <div className='destination__content_images'>
                        <div className='destination__content__image'>
                            <img src={hotel?.thumbnailUrl} alt='activity' />
                        </div>
                        <div className='destination__content__galery'>
                            <Image.PreviewGroup>
                                {hotel?.accommodationImages?.split(',').map((image, index) => (
                                    <Image
                                        key={index}
                                        width={160}
                                        height={100}
                                        src={image}
                                        className='destination__content__galery__image'
                                    />
                                ))}
                            </Image.PreviewGroup>
                        </div>
                    </div>

                    {hotel?.accommodationVideos && <>
                        <Divider />
                        <div className='destination__content_videos'>
                            <h2>Videos</h2>
                            <div className='destination__content__videos'>
                                {hotel?.accommodationVideos ? <>
                                    {hotel?.accommodationVideos?.split(',').map((video, index) => (
                                        <video key={index} controls>
                                            <source src={video} type='video/mp4' />
                                        </video>
                                    ))}
                                </>
                                    :
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                }
                            </div>
                        </div>
                    </>}

                    <Divider />

                    <div className='hotel__content_details'>
                        <h2>
                            Details
                        </h2>
                        <div className='destination__content__details'>
                            <div className='sec'>
                                <p><strong>City: </strong>{hotel?.accommodationCity || 'N/A'}</p>
                                <p><strong>Destination: </strong>
                                    {destinationNames?.find(destination => destination.id === hotel?.nearestDestinations)?.destinationName || 'N/A'}
                                </p>
                                {hotel?.starRating ? <p><strong>Star rating: </strong>
                                    <Rate disabled defaultValue={hotel?.starRating} />
                                </p>
                                    :
                                    <p><strong>Grade: </strong>
                                        {hotel?.grade || 'N/A'}
                                    </p>
                                }
                                <p><strong>Tags: </strong>
                                    {hotel?.tags?.split(',').map((tag, index) => (
                                        <Tag key={index}>{tag} </Tag>
                                    ))}
                                </p>
                                <p><strong>Pets Allowed: </strong>{hotel?.isPetsAllowed ? 'Yes' : 'No'}</p>
                            </div>
                            <div className='sec'>
                                <p><strong>Type: </strong>{hotel?.accommodationType || 'N/A'}</p>
                                <p><strong>Reg No: </strong>{hotel?.registartionNumber || 'N/A'}</p>
                                <p><strong>Licence No: </strong>{hotel?.licenceNumber || 'N/A'}</p>
                                <p><strong>Licence Exp: </strong>{hotel?.licenceExpiry || 'N/A'}</p>
                                <p><strong>Coordinates: </strong>{hotel?.accommodationCoordinates || 'N/A'}</p>
                            </div>
                        </div>

                        <Divider />
                        <h2>
                            Contact
                        </h2>
                        <div className='destination__content__details'>
                            <div className='sec'>
                                <p><strong>Contact 1: </strong>{hotel?.contact1} - {hotel?.contact1Name}</p>
                                <p><strong>Email: </strong>{hotel?.email || 'N/A'}</p>
                            </div>
                            <div className='sec'>
                                <p><strong>Contact 2: </strong>{hotel?.conatctNo2} - {hotel?.contact2Name}</p>
                                <p><strong>Address: </strong>{hotel?.accommodationAddress || 'N/A'}</p>
                            </div>
                        </div>
                    </div>

                    <Divider />
                    <div className='hotel__content_details'>
                        <Collapse
                            ghost
                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        >
                            <Collapse.Panel
                                header='Booking Process'
                                key='1'
                            >
                                <ReactQuill
                                    value={hotel?.bookingProcess}
                                    readOnly={true}
                                    theme='bubble'
                                />
                            </Collapse.Panel>
                        </Collapse>
                    </div>

                    <Divider />

                    <div className='hotel__content_details'>
                        <Collapse
                            ghost
                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        >
                            <Collapse.Panel
                                header='Accommodation Description'
                                key='1'
                            >
                                <div className='destination__content_description'>
                                    <h2>
                                        Descriptions
                                    </h2>
                                    <h3>
                                        Short Description
                                    </h3>
                                    <ReactQuill
                                        value={hotel?.accommodationDescriptionShort}
                                        readOnly={true}
                                        theme='bubble'
                                    />

                                    <h3>
                                        Full Description
                                    </h3>
                                    <ReactQuill
                                        value={hotel?.accommodationDescription}
                                        readOnly={true}
                                        theme='bubble'
                                    />
                                </div>
                            </Collapse.Panel>
                        </Collapse>
                    </div>

                    <Divider />
                    <div
                        className='hotel__content_details'
                        style={{ marginTop: '10px' }}
                    >
                        <Collapse
                            ghost
                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        >
                            <Collapse.Panel
                                header='Pricing Documents'
                                key='1'
                            >
                                {hotel?.pricingDocuments?.split(',').map((pdf, index) => (
                                    <div key={index} className='pdf'>
                                        <a href={pdf} target='_blank' rel='noreferrer'>
                                            {renderPdfName(pdf)}
                                        </a>
                                    </div>
                                ))}
                            </Collapse.Panel>
                        </Collapse>
                    </div>

                    <Divider />

                    {/* Offers */}
                    <div className='content'>
                        <div className='header'>
                            <h2>
                                Offers
                            </h2>
                            <div className='actions'>
                                <Button type='primary'
                                    onClick={() => setAddOfferVisible(true)} icon={<PlusOutlined />}
                                >
                                    Add Offer
                                </Button>
                            </div>
                        </div>

                        <div className='items_list_acc'>
                            {loadingOffers && <Skeleton active />}

                            {offers?.length > 0 ? <>
                                {offers.map((item, index) => (
                                    <div key={index} className='item'>
                                        <div className='detsils'>
                                            <div
                                                className='header'
                                                style={{
                                                    paddingBottom: '10px',
                                                    marginBottom: '10px',
                                                    borderBottom: '1px solid #f0f0f0',
                                                }}
                                            >
                                                <h3>
                                                    {item.name}
                                                </h3>

                                                <div className='actions'>
                                                    <Button type='default' icon={<EditOutlined />}
                                                        onClick={() => {
                                                            setSelectedEditOffer(item)
                                                            setEditOfferVisible(true)
                                                        }}
                                                    >
                                                    </Button>

                                                    <Popconfirm
                                                        title='Are you sure you want to delete this offer?'
                                                        description={<p
                                                            style={{
                                                                width: '300px',
                                                                color: 'red',
                                                            }}
                                                        >This action cannot be undone! This will permanently delete the offer.</p>}
                                                        onConfirm={() => handleDeleteOffer(item)}
                                                        okText='Yes'
                                                        cancelText='No'
                                                    >
                                                        <Button danger type='primary' icon={<DeleteOutlined />}>
                                                        </Button>
                                                    </Popconfirm>

                                                </div>
                                            </div>
                                            <h3>
                                                Description
                                            </h3>
                                            <ReactQuill
                                                value={item?.description}
                                                readOnly={true}
                                                theme='bubble'
                                            />
                                            <div className='room__details'>
                                                <div>
                                                    <p>
                                                        <strong>Amount: </strong> {item.amount} %
                                                    </p>
                                                    <p>
                                                        <strong>Start: </strong> {item.startDate}
                                                    </p>
                                                    <p>
                                                        <strong>End: </strong> {renderOfferExpiry(item.endDate)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                                : <>
                                    {!loadingOffers && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                </>
                            }
                        </div>
                    </div>

                    <Divider />

                    {/* Rooms */}
                    <div className='content'>
                        <div className='header'>
                            <h2>
                                Accommodation Rooms
                            </h2>
                            <div className='actions'>
                                <Button type='default'
                                    onClick={() => {
                                        if (!showAccommodationRooms) {
                                            handleGetRooms()
                                        }
                                        setShowAccommodationRooms(!showAccommodationRooms)
                                    }}
                                    loading={roomLoading}>
                                    {showAccommodationRooms ? 'Hide Rooms' : 'Show Rooms'}
                                </Button>
                                <Button type='primary' onClick={() => setAddRoomVisible(true)} icon={<PlusOutlined />}>
                                    Add Rooms
                                </Button>
                            </div>
                        </div>

                        <div className='items_list_acc'>
                            {roomLoading && <Skeleton active />}

                            {showAccommodationRooms && accommodationRooms?.length > 0 ? <>
                                {accommodationRooms.map((room, index) => (
                                    <div key={index} className='item'>
                                        <div className='body'>
                                            <div className='images'>
                                                <Image.PreviewGroup
                                                    items={room?.roomImages ? room?.roomImages?.split(',') : [DEFAULT_THUMBNAIL]}
                                                >
                                                    <Image
                                                        width={200}
                                                        src={room?.roomImages ? room?.roomImages?.split(',')[0] : DEFAULT_THUMBNAIL}
                                                    />
                                                </Image.PreviewGroup>
                                                <p>
                                                    {room?.roomImages ? room?.roomImages?.split(',')?.length : 0} Images
                                                </p>
                                            </div>
                                            <div className='detsils'>
                                                <div className='header'>
                                                    <h3>
                                                        {room.roomName}
                                                    </h3>

                                                    <div className='actions'>
                                                        <Button type='default' icon={<EditOutlined />}
                                                            onClick={() => {
                                                                setSelectedEditRoom(room)
                                                                setEditRoomVisible(true)
                                                            }}
                                                        >
                                                            Edit
                                                        </Button>

                                                        <Popconfirm
                                                            title='Are you sure you want to delete this room?'
                                                            description={<p
                                                                style={{
                                                                    width: '300px',
                                                                    color: 'red',
                                                                }}
                                                            >This action cannot be undone! This will permanently delete the room</p>}
                                                            onConfirm={() => handleDeleteRoom(room)}
                                                            okText='Yes'
                                                            cancelText='No'
                                                        >
                                                            <Button danger type='primary' icon={<DeleteOutlined />}>
                                                                Delete
                                                            </Button>
                                                        </Popconfirm>

                                                    </div>
                                                </div>
                                                <ReactQuill
                                                    value={room?.roomDescription}
                                                    readOnly={true}
                                                    theme='bubble'
                                                />
                                                <div className='room__details'>
                                                    <div>
                                                        <p>
                                                            <strong>Our Price: </strong> {room.ourPrice} AUD
                                                        </p>
                                                        <p>
                                                            <strong>Capacity: </strong> {room.roomCapacity}
                                                        </p>
                                                        <p>
                                                            <strong>Number of Rooms: </strong> {room.numOfRooms}
                                                        </p>
                                                    </div>

                                                    <div>
                                                        <p>
                                                            <strong>Margin: </strong> {room.margin}%
                                                        </p>
                                                        <p>
                                                            <strong>Room Type: </strong> {room.roomType}
                                                        </p>
                                                        <p>
                                                            <strong>Room ID: </strong> {room.id}
                                                        </p>
                                                    </div>
                                                </div>
                                                <p>
                                                    <strong>Display Price: </strong> {calculateDisplayPrice(room.ourPrice, room.margin)} AUD
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                                : <>
                                    {(showAccommodationRooms && !roomLoading) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                </>
                            }
                        </div>
                    </div>

                    <Divider />

                    {/* Facilities */}
                    <div className='content'>
                        <div className='header'>
                            <h2>
                                Accommodation Facilities
                            </h2>
                            <div className='actions'>
                                <Button type='default'
                                    onClick={() => {
                                        if (!showAccommodationFacilities) {
                                            handleGetRooms()
                                        }
                                        setShowAccommodationFacilities(!showAccommodationFacilities)
                                    }}
                                    loading={loadingFacilities}>
                                    {showAccommodationFacilities ? 'Hide Facilities' : 'Show Facilities'}
                                </Button>
                                <Button type='primary' onClick={() => setAddFacilityVisible(true)} icon={<PlusOutlined />}>
                                    Add Facility
                                </Button>
                            </div>
                        </div>

                        <div className='items_list_acc'>
                            {loadingFacilities && <Skeleton active />}

                            {showAccommodationFacilities && facilities?.length > 0 ? <>
                                {facilities.map((facility, index) => (
                                    <div key={index} className='item'>
                                        <div className='body'>
                                            <div className='detsils'>
                                                <div className='header'>
                                                    <h3>
                                                        {facility.facilityName} | {facility.facilityType && capitalizeFisrtLetterEachWord(facility.facilityType)}
                                                    </h3>

                                                    <div className='actions'>
                                                        <Popconfirm
                                                            title='Are you sure you want to delete this room?'
                                                            description={<p
                                                                style={{
                                                                    width: '300px',
                                                                    color: 'red',
                                                                }}
                                                            >This action cannot be undone! This will permanently delete the room</p>}
                                                            onConfirm={() => handleDeleteFacility(facility)}
                                                            okText='Yes'
                                                            cancelText='No'
                                                        >
                                                            <Button danger type='primary' icon={<DeleteOutlined />}>
                                                                Delete
                                                            </Button>
                                                        </Popconfirm>

                                                    </div>
                                                </div>
                                                <p>
                                                    {facility?.facilityDescription}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                                : <>
                                    {(showAccommodationFacilities && !loadingFacilities) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                </>
                            }
                        </div>
                    </div>

                    <Divider />

                    {/* House Rules */}
                    <div className='content'>
                        <div className='header'>
                            <h2>
                                House Rules
                            </h2>
                            {!houseRules ?
                                <div className='actions'>
                                    <Button type='primary' onClick={() => setAddHouseRuleVisible(true)} icon={<PlusOutlined />}>
                                        Add House Rule
                                    </Button>
                                </div>
                                :
                                <div className='actions'>
                                    <Button type='primary' onClick={() => setEditHouseRuleVisible(true)} icon={<EditOutlined />}>
                                        Edit Rules
                                    </Button>
                                    <Popconfirm
                                        title='Are you sure you want to delete these rules?'
                                        description={<p
                                            style={{
                                                width: '300px',
                                                color: 'red',
                                            }}
                                        >This action cannot be undone! This will permanently delete the rules</p>}
                                        onConfirm={() => handleDeleteHouseRule(houseRules)}
                                        okText='Yes'
                                        cancelText='No'
                                    >
                                        <Button type='primary' danger icon={<DeleteOutlined />}>
                                            Delete Rules
                                        </Button>
                                    </Popconfirm>
                                </div>
                            }
                        </div>

                        <div className='items_list_acc'>
                            {loadingHouseRules && <Skeleton active />}
                            {houseRules ? <>
                                <div className='item'>
                                    <div className='body'>
                                        <div className='detsils'>
                                            <div>
                                                <p>
                                                    <strong>Check In: </strong> {houseRules.checkInTime}
                                                </p>
                                                <p>
                                                    <strong>Check Out: </strong> {houseRules.checkOutTime}
                                                </p>
                                                <p>
                                                    <strong>Cancellation Policy: </strong> {houseRules.cancellationPolicy}
                                                </p>
                                                <p>
                                                    <strong>Payment Policy: </strong> {houseRules.paymentPolicy}
                                                </p>
                                                <p>
                                                    <strong>Child Policy: </strong> {houseRules.childPolicy}
                                                </p>
                                                <p>
                                                    <strong>Pet Policy: </strong> {houseRules.petPolicy}
                                                </p>
                                                <p>
                                                    <strong>Smoking Policy: </strong> {houseRules.smokingPolicy}
                                                </p>
                                                <p>
                                                    <strong>Other Policies: </strong> {houseRules.otherPolicy}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                                : <>
                                    {(showAccommodationRooms && !roomLoading) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                </>
                            }
                        </div>
                    </div>

                    <Divider />

                    {/* Meals */}
                    <div className='content'>
                        <div className='header'>
                            <h2>
                                Meal Info
                            </h2>
                            <div className='actions'>
                                <Button type='default'
                                    onClick={() => {
                                        if (!showAccommodationMeals) {
                                            getMealInfo()
                                        }
                                        setShowAccommodationMeals(!showAccommodationMeals)
                                    }}
                                    loading={loadingMeals}>
                                    {showAccommodationMeals ? 'Hide Meals' : 'Show Meals'}
                                </Button>
                                <Button type='primary' onClick={() => setAddMealInfoVisible(true)} icon={<PlusOutlined />}>
                                    Add Meal Info
                                </Button>
                            </div>
                        </div>

                        <div className='items_list_acc'>
                            {loadingMeals && <Skeleton active />}

                            {showAccommodationMeals && meals?.length > 0 ? <>
                                {meals.map((room, index) => (
                                    <div key={index} className='item'>
                                        <div className='body'>
                                            <div className='detsils'>
                                                <div className='header'>
                                                    <div className='actions'>
                                                        <Popconfirm
                                                            title='Are you sure you want to delete this meal?'
                                                            description={<p
                                                                style={{
                                                                    width: '300px',
                                                                    color: 'red',
                                                                }}
                                                            >This action cannot be undone! This will permanently delete the room</p>}
                                                            onConfirm={() => handleDeleteMeal(room)}
                                                            okText='Yes'
                                                            cancelText='No'
                                                        >
                                                            <Button danger type='primary' icon={<DeleteOutlined />}>
                                                                Delete
                                                            </Button>
                                                        </Popconfirm>

                                                    </div>
                                                </div>
                                                <div className='room__details'>
                                                    <div>
                                                        <p>
                                                            <strong>Meal Type: </strong> {room.mealType}
                                                        </p>
                                                        <p>
                                                            <strong>Meal Name: </strong> {room.mealName}
                                                        </p>
                                                        <p>
                                                            <strong>Description: </strong> {room.mealDescription}
                                                        </p>
                                                        <p>
                                                            <strong>Price: </strong> {room.mealPrice} AUD
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                                : <>
                                    {(showAccommodationMeals && !loadingMeals) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                </>
                            }
                        </div>
                    </div>

                    <Divider />

                    {/* Packages */}
                    <div className='content'>
                        <div className='header'>
                            <h2>
                                Accommodation Packages
                            </h2>
                            <div className='actions'>
                                <Button type='default'
                                    onClick={() => {
                                        if (!showAccommodationPackages) {
                                            fetchAccommodationPackages()
                                        }
                                        setShowAccommodationPackages(!showAccommodationPackages)
                                    }}
                                    loading={loadingAccommodationPackages}>
                                    {showAccommodationPackages ? 'Hide Packages' : 'Show Packages'}
                                </Button>
                                <Button type='primary' onClick={() => setAddAccommodationPackgaeVisible(true)} icon={<PlusOutlined />}>
                                    Add Package
                                </Button>
                            </div>
                        </div>

                        <div className='items_list_acc'>
                            {loadingAccommodationPackages && <Skeleton active />}

                            {accommodationPackages && accommodationPackages?.length > 0 ? <>
                                {accommodationPackages.map((room, index) => (
                                    <div key={index} className='item'>
                                        <div className='body'>
                                            <div className='images'>
                                                <Image.PreviewGroup
                                                    items={room?.packageImages ? room?.packageImages?.split(',') : [DEFAULT_THUMBNAIL]}
                                                >
                                                    <Image
                                                        width={200}
                                                        src={room?.packageImages ? room?.packageImages?.split(',')[0] : DEFAULT_THUMBNAIL}
                                                    />
                                                </Image.PreviewGroup>
                                                <p>
                                                    {room?.packageImages ? room?.packageImages?.split(',')?.length : 0} Images
                                                </p>
                                            </div>
                                            <div className='detsils'>
                                                <div className='header'>
                                                    <h3>
                                                        {room.packageName}
                                                    </h3>

                                                    <div className='actions'>
                                                        <Button type='default' icon={<EditOutlined />}
                                                            onClick={() => {
                                                                setSelectedEditPackage(room)
                                                                setEditAccommodationPackageVisible(true)
                                                            }}
                                                        >
                                                            Edit
                                                        </Button>

                                                        <Popconfirm
                                                            title='Are you sure you want to delete this package?'
                                                            description={<p
                                                                style={{
                                                                    width: '300px',
                                                                    color: 'red',
                                                                }}
                                                            >This action cannot be undone! This will permanently delete the package</p>}
                                                            onConfirm={() => handleDeletePackage(room)}
                                                            okText='Yes'
                                                            cancelText='No'
                                                        >
                                                            <Button danger type='primary' icon={<DeleteOutlined />}>
                                                                Delete
                                                            </Button>
                                                        </Popconfirm>

                                                    </div>
                                                </div>
                                                <ReactQuill
                                                    value={room?.packageDescription}
                                                    readOnly={true}
                                                    theme='bubble'
                                                />
                                                <div className='room__details'>
                                                    <div>
                                                        <p>
                                                            <strong>Package Type: </strong> {room.packageType}
                                                        </p>
                                                        <p>
                                                            <strong>Room Type: </strong> {room.packageRooms}
                                                        </p>
                                                        <p>
                                                            <strong>Facilities: </strong> {room.packageFacilities ? room.packageFacilities.split(',').map((facility, index) => (
                                                                capitalizeFisrtLetterEachWord(facility) + ', '
                                                            )) : 'N/A'}
                                                        </p>
                                                    </div>

                                                    <div>
                                                        <p>
                                                            <strong>Package Price: </strong> {room.packagePrice} AUD
                                                        </p>
                                                        <p>
                                                            <strong>Included Meals: </strong> {room.packageMeals ? room.packageMeals.split(',').map((meal, index) => (
                                                                capitalizeFisrtLetterEachWord(meal) + ', '
                                                            )) : 'N/A'}
                                                        </p>
                                                    </div>
                                                </div>
                                                <p>
                                                    <strong>Package ID: </strong> {room.id}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                                : <>
                                    {(showAccommodationPackages && !loadingAccommodationPackages) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                </>
                            }
                        </div>
                    </div>
                </>
            </Spin>


            <AddRoom
                addRoomVisible={addRoomVisible}
                setAddRoomVisible={setAddRoomVisible}
                hotel={hotel}
                handleGetRooms={handleGetRooms}
            />

            <EditRoom
                editRoomVisible={editRoomVisible}
                setEditRoomVisible={setEditRoomVisible}
                hotel={hotel}
                handleGetRooms={handleGetRooms}
                selectedEditRoom={selectedEditRoom}
                setSelectedEditRoom={setSelectedEditRoom}
            />

            <AddOffer
                addOfferVisible={addOfferVisible}
                setAddOfferVisible={setAddOfferVisible}
                hotel={hotel}
                handleGetOffers={handleGetOffers}
            />

            <EditOffer
                editOfferVisible={editOfferVisible}
                setEditOfferVisible={setEditOfferVisible}
                selectedEditOffer={selectedEditOffer}
                hotel={hotel}
                handleGetOffers={handleGetOffers}
            />

            <AddFacility
                addFacilityVisible={addFacilityVisible}
                setAddFacilityVisible={setAddFacilityVisible}
                hotel={hotel}
                getFacilities={getFacilities}
            />

            <AddHouseRule
                addHouseRuleVisible={addHouseRuleVisible}
                setAddHouseRuleVisible={setAddHouseRuleVisible}
                hotel={hotel}
                getHouseRule={getHouseRule}
            />

            <EditHouseRule
                editHouseRuleVisible={editHouseRuleVisible}
                setEditHouseRuleVisible={setEditHouseRuleVisible}
                hotel={hotel}
                houseRules={houseRules}
                getHouseRule={getHouseRule}
            />

            <AddMeal
                addMealInfoVisible={addMealInfoVisible}
                setAddMealInfoVisible={setAddMealInfoVisible}
                hotel={hotel}
                getMealInfo={getMealInfo}
            />

            <AddPackage
                addAccommodationPackgaeVisible={addAccommodationPackgaeVisible}
                setAddAccommodationPackgaeVisible={setAddAccommodationPackgaeVisible}
                hotel={hotel}
                fetchAccommodationPackages={fetchAccommodationPackages}
            />

            <EditPackage
                editAccommodationPackageVisible={editAccommodationPackageVisible}
                setEditAccommodationPackageVisible={setEditAccommodationPackageVisible}
                hotel={hotel}
                fetchAccommodationPackages={fetchAccommodationPackages}
                selectedEditPackage={selectedEditPackage}
            />
        </div>
    )
}

export default Hotel