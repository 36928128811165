import { Route } from 'react-router-dom';

import Home from '../Pages/Dashboard/Home';

import FlightBookings from '../Pages/Dashboard/Bookings/FlightBookings';
import HotelBookings from '../Pages/Dashboard/Bookings/HotelBookings';
import TourPackageBookings from '../Pages/Dashboard/Bookings/TourPackageBookings';

import AllPartners from '../Pages/Dashboard/Partners/AllPartners';
import Drivers from '../Pages/Dashboard/Partners/Drivers';
import Hotels from '../Pages/Dashboard/Partners/Hotels';
import Resturents from '../Pages/Dashboard/Partners/Resturents';
import Shops from '../Pages/Dashboard/Partners/Shops';
import TourGuides from '../Pages/Dashboard/Partners/TourGuides';
import Transports from '../Pages/Dashboard/Partners/Transports';

import Activities from '../Pages/Dashboard/Tours/Activities';
import Attractions from '../Pages/Dashboard/Tours/Attractions';
import Destinations from '../Pages/Dashboard/Tours/Destinations';
import Events from '../Pages/Dashboard/Tours/Events';
import TourPackages from '../Pages/Dashboard/Tours/TourPackages';

import Blogs from '../Pages/Dashboard/Blogs';

import Support from '../Pages/Dashboard/Support';

import Payments from '../Pages/Dashboard/Payments';

import Earnings from '../Pages/Dashboard/Earnings';
import Users from '../Pages/Dashboard/Users';
import User from '../Pages/Dashboard/Users/User';
import EditUser from '../Pages/Dashboard/Users/User/EditUser';
import AddDestination from '../Pages/Dashboard/Tours/Destinations/AddDestination';
import Destination from '../Pages/Dashboard/Tours/Destinations/Destination';
import EditDestination from '../Pages/Dashboard/Tours/Destinations/EditDestination';
import AddAttraction from '../Pages/Dashboard/Tours/Attractions/AddAttraction';
import Attraction from '../Pages/Dashboard/Tours/Attractions/Attraction';
import EditAttraction from '../Pages/Dashboard/Tours/Attractions/EditAttraction';
import AddActivity from '../Pages/Dashboard/Tours/Activities/AddActivity';
import Activity from '../Pages/Dashboard/Tours/Activities/Activity';
import EditActivity from '../Pages/Dashboard/Tours/Activities/EditActivity';
import AddEvent from '../Pages/Dashboard/Tours/Events/AddEvent';
import Event from '../Pages/Dashboard/Tours/Events/Event';
import EditEvent from '../Pages/Dashboard/Tours/Events/EditEvent';
import AddHotel from '../Pages/Dashboard/Partners/Hotels/AddHotel';
import Hotel from '../Pages/Dashboard/Partners/Hotels/Hotel';
import EditHotel from '../Pages/Dashboard/Partners/Hotels/EditHotel';
import PartnerProfile from '../Pages/Dashboard/Partners/AllPartners/PartnerProfile';
import AddTransport from '../Pages/Dashboard/Partners/Transports/AddTransport';
import TransportPackage from '../Pages/Dashboard/Partners/Transports/TransportPackage';
import EditTransport from '../Pages/Dashboard/Partners/Transports/EditTransport';
import Guide from '../Pages/Dashboard/Partners/TourGuides/Guide';
import AddTourPackage from '../Pages/Dashboard/Tours/TourPackages/AddTourPackage';
import TourPackage from '../Pages/Dashboard/Tours/TourPackages/TourPackage';
import EditTourPackage from '../Pages/Dashboard/Tours/TourPackages/EditTourPackage';
import GuideSchedule from '../Pages/Dashboard/Partners/TourGuides/Guide/GuideSchedule';
import Inbox from '../Pages/Dashboard/Inbox';
import TourPackageBooking from '../Pages/Dashboard/Bookings/TourPackageBookings/TourPackageBooking';
import CustomTourBookings from '../Pages/Dashboard/Bookings/CustomTourBookings';
import CustomTourBooking from '../Pages/Dashboard/Bookings/CustomTourBookings/CustomTourBooking';
import Payment from '../Pages/Dashboard/Payments/Payment';
import SupportTicket from '../Pages/Dashboard/Support/SupportTicket';
import Reviews from '../Pages/Dashboard/Reviews';
import Review from '../Pages/Dashboard/Reviews/Review';
import CustomEventBookings from '../Pages/Dashboard/Bookings/CustomEventBookings';
import CustomEventBooking from '../Pages/Dashboard/Bookings/CustomEventBookings/CustomEventBooking';
import Blog from '../Pages/Dashboard/Blogs/Blog';
import AddBlog from '../Pages/Dashboard/Blogs/AddBlog';
import EditBlog from '../Pages/Dashboard/Blogs/EditBlog';

export const HOME_ROUTE = <Route path="/home" element={<Home />} />;

export const USERS_ROUTE = <Route path="/users" element={<Users />} />;
export const USERS_SUB_ROUTE = <Route path="/users/:type" element={<Users />} />;
export const USER_ROUTE = <Route path="/users/:type/:id" element={<User />} />;
export const USER_EDIT_ROUTE = <Route path="/users/edit/:id" element={<EditUser />} />;
export const MY_PROFILE_ROUTE = <Route path="/myprofile" element={<User />} />;



export const FLIGHT_BOOKINGS_ROUTE = <Route path="/bookings/flights" element={<FlightBookings />} />;
export const HOTEL_BOOKINGS_ROUTE = <Route path="/bookings/hotels" element={<HotelBookings />} />;



export const TOUR_PACKAGE_BOOKINGS_ROUTE = <Route path="/bookings/tour-packages" element={<TourPackageBookings />} />;
export const TOUR_PACKAGE_BOOKING_ROUTE = <Route path="/bookings/tour-packages/:id" element={<TourPackageBooking />} />;
export const CUSTOM_TOUR_BOOKINGS_ROUTE = <Route path="/bookings/custom-tours" element={<CustomTourBookings />} />;
export const CUSTOM_TOUR_BOOKING_ROUTE = <Route path="/bookings/custom-tours/:id" element={<CustomTourBooking />} />;
export const CUSTOM_EVENTS_BOOKINGS_ROUTE = <Route path="/bookings/custom-events" element={<CustomEventBookings />} />;
export const CUSTOM_EVENT_BOOKING_ROUTE = <Route path="/bookings/custom-events/:id" element={<CustomEventBooking />} />;



export const ALL_PARTNERS_ROUTE = <Route path="/partners/all" element={<AllPartners />} />;
export const PARTNER_ROUTE = <Route path="/partners/all/partnerprofile/:id" element={<PartnerProfile />} />;

export const DRIVERS_ROUTE = <Route path="/partners/drivers" element={<Drivers />} />;



export const HOTELS_ROUTE = <Route path="/partners/hotels" element={<Hotels />} />;
export const HOTELS_ADD_ROUTE = <Route path="/partners/hotels/add" element={<AddHotel />} />;
export const HOTEL_ROUTE = <Route path="/partners/hotels/:id" element={<Hotel />} />;
export const HOTELS_EDIT_ROUTE = <Route path="/partners/hotels/edit/:id" element={<EditHotel />} />;



export const RESTURENTS_ROUTE = <Route path="/partners/restaurants" element={<Resturents />} />;
export const SHOPS_ROUTE = <Route path="/partners/shops" element={<Shops />} />;



export const TOUR_GUIDES_ROUTE = <Route path="/partners/tour-guides" element={<TourGuides />} />;
export const TOUR_GUIDE_ROUTE = <Route path="/partners/tour-guides/:id" element={<Guide />} />;
export const GUIDE_MY_PROFILE_ROUTE = <Route path="/guide-profile" element={<Guide />} />;
export const GUIDE_SCHEDULE_ROUTE = <Route path="/guide-schedule" element={<GuideSchedule />} />;



export const TRANSPORTS_ROUTE = <Route path="/partners/transport" element={<Transports />} />;
export const TRANSPORTS_ADD_ROUTE = <Route path="/partners/transport/add" element={<AddTransport />} />;
export const TRANSPORT_ROUTE = <Route path="/partners/transport/:id" element={<TransportPackage />} />;
export const TRANSPORTS_EDIT_ROUTE = <Route path="/partners/transport/edit/:id" element={<EditTransport />} />;



export const ATTRACTTIONS_ROUTE = <Route path="/tours/attractions" element={<Attractions />} />;
export const ATTRACTTIONS_ADD_ROUTE = <Route path="/tours/attractions/add" element={<AddAttraction />} />;
export const ATTRACTTION_ROUTE = <Route path="/tours/attractions/:id" element={<Attraction />} />;
export const ATTRACTTIONS_EDIT_ROUTE = <Route path="/tours/attractions/edit/:id" element={<EditAttraction />} />;



export const DESTINATIONS_ROUTE = <Route path="/tours/destinations" element={<Destinations />} />;
export const DESTINATION_ROUTE = <Route path="/tours/destinations/:id" element={<Destination />} />;
export const DESTINATIONS_EDIT_ROUTE = <Route path="/tours/destinations/edit/:id" element={<EditDestination />} />;
export const DESTINATIONS_ADD_ROUTE = <Route path="/tours/destinations/add" element={<AddDestination />} />;



export const ACTIVITES_ROUTE = <Route path="/tours/activities" element={<Activities />} />;
export const ACTIVITIES_ADD_ROUTE = <Route path="/tours/activities/add" element={<AddActivity />} />;
export const ACTIVITY_ROUTE = <Route path="/tours/activities/:id" element={<Activity />} />;
export const ACTIVITY_EDIT_ROUTE = <Route path="/tours/activities/edit/:id" element={<EditActivity />} />;



export const EVENTS_ROUTE = <Route path="/tours/events" element={<Events />} />;
export const EVENTS_ADD_ROUTE = <Route path="/tours/events/add" element={<AddEvent />} />;
export const EVENT_ROUTE = <Route path="/tours/events/:id" element={<Event />} />;
export const EVENT_EDIT_ROUTE = <Route path="/tours/events/edit/:id" element={<EditEvent />} />;



export const TOUR_PACKAGES_ROUTES = <Route path="/tours/tour-packages" element={<TourPackages />} />;
export const TOUR_PACKAGES_ADD_ROUTE = <Route path="/tours/tour-packages/add" element={<AddTourPackage />} />;
export const TOUR_PACKAGE_ROUTE = <Route path="/tours/tour-packages/:id" element={<TourPackage />} />;
export const TOUR_PACKAGE_EDIT_ROUTE = <Route path="/tours/tour-packages/edit/:id" element={<EditTourPackage />} />;



export const BLOGS_ROUTE = <Route path="/blogs" element={<Blogs />} />;
export const BLOG_ROUTE = <Route path="/blogs/:id" element={<Blog />} />;
export const BLOGS_ADD_ROUTE = <Route path="/blogs/add" element={<AddBlog />} />;
export const BLOGS_EDIT_ROUTE = <Route path="/blogs/edit/:id" element={<EditBlog />} />;

export const SUPPORT_ROUTE = <Route path="/support-tickets" element={<Support />} />;
export const SUPPORT_TICKET_ROUTE = <Route path="/support-tickets/:id" element={<SupportTicket />} />;


export const REVIEWS_ROUTE = <Route path="/reviews" element={<Reviews />} />;
export const REVIEW_ROUTE = <Route path="/reviews/:id" element={<Review />} />;


export const PAYMENTS_ROUTE = <Route path="/payments" element={<Payments />} />;
export const PAYMENT_ROUTE = <Route path="/payments/:id" element={<Payment />} />;



export const EARNINGS_ROUTE = <Route path="/earnings" element={<Earnings />} />;



export const INBOX_ROUTE = <Route path="/messages" element={<Inbox />} />;