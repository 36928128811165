import React, { useState } from 'react'
import { Button, Col, Drawer, Form, Input, Row, Spin, notification } from 'antd'
import { addTourPackageHighlights, addTourPackageInclude } from '../../../../../../../redux/api/tourPackages.api';
import ReactQuill, { Quill } from 'react-quill';

const quillModules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
        ["formula"],
        [{ size: ["small", false, "large", "huge"] }],
    ],
    clipboard: {
        matchVisual: false
    },
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
};

const AddPackageHighlight = ({
    isVisible,
    setIsVisible,
    tourPackage,
    handleGetHighlights
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const onFinish = async (values) => {
        setIsLoading(true);
        try {
            Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

            values.packageId = tourPackage.id;

            const res = await addTourPackageHighlights(values);

            if (res.status === 200) {
                api.success({
                    message: 'Success',
                    description: 'Highlight added successfully'
                });
                setIsVisible(false);
                handleGetHighlights();
            } else {
                api.error({
                    message: 'Error',
                    description: 'An error occurred while adding the package highlight. Please try again.'
                });
            }

        } catch (error) {
            console.log(error);
            api.error({
                message: 'Error',
                description: `An error occurred while adding the package highlight. Please try again. ${error.message}`
            });
        }
        setIsLoading(false);
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Add Highlight"
                width={720}
                open={isVisible}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                onClose={() => setIsVisible(false)}
            >
                <Spin spinning={isLoading} tip='Please Wait...'>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="highlight"
                                    label="Tour Package Highlight"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the tour package highlight',
                                        },
                                    ]}
                                >
                                    <Input.TextArea
                                        rows={4}
                                        placeholder="Please enter the tour package highlight"
                                        maxLength={400}
                                        showCount
                                    />

                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item>
                            <Button type='primary' htmlType='submit' loading={isLoading}>
                                Add Highlight
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default AddPackageHighlight