import React, { useEffect, useRef, useState } from 'react'
import 'react-quill/dist/quill.snow.css';
import ReactQuill, { Quill } from 'react-quill';
import { useNavigate } from 'react-router-dom';
import ImgCrop from 'antd-img-crop';
import { useDispatch } from 'react-redux';

import { fetchDestinationNamesAC } from '../../../../../../redux/actions/destinations/destinations.ac';
import useDestinations from '../../../../../hooks/useDestinations';

import { ACTIVE, INACTIVE, PENDING } from '../../../../../Constants/activeStatusType';
import { ACCOMMODATION_CONTAINER, handleImageUpload } from '../../../../../azure/blob';

import { Button, Col, Divider, Form, Image, Input, notification, Radio, Rate, Row, Select, Spin, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons';

import { accommodationTypes } from '../../../../../Constants/itemTypes';

import './styles.scss'
import { addAccommodation } from '../../../../../../redux/api/hotels.api';

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const accommodationOptions = Object.keys(accommodationTypes).map(key => {
    return {
        label: getOptionName(accommodationTypes[key]),
        value: key
    };
});

const quillModules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
        ["formula"],
        [{ size: ["small", false, "large", "huge"] }],
    ],
    clipboard: {
        matchVisual: false
    },
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
};

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });


const AddHotel = () => {
    const [shortDescriptionCharCount, setShortDescriptionCharCount] = useState(0)
    const [longDescriptionCharCount, setLongDescriptionCharCount] = useState(0)
    const [fileList, setFileList] = useState([])
    const [videoList, setVideoList] = useState([])
    const [pricingFiles, setPricingFiles] = useState([])
    const [thumbnailFile, setThumbnailFile] = useState([]);
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [loadingText, setLoadingText] = useState('')
    const topRef = useRef(null);
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const destinations = useDestinations()
    const destinationNames = destinations.destinationNames

    useEffect(() => {
        if (destinationNames?.length === 0) {
            dispatch(fetchDestinationNamesAC())
        }
    }, [])

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const onVideoChange = ({ fileList: newFileList }) => setVideoList(newFileList);

    const onThumbnailChange = ({ fileList: newFileList }) => setThumbnailFile(newFileList);

    const onPricingFilesChange = ({ fileList: newFileList }) => setPricingFiles(newFileList);


    const onFinish = async (values) => {
        topRef.current.scrollTo(0, 0);
        setLoading(true);
        try {
            Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

            let imageUrls = [];
            let videoUrls = [];
            let pricingUrls = [];
            let thumbnailUrl = '';

            const name = values.accommodationName.replace(/\s/g, '_').toLowerCase();

            // Upload images
            if (fileList.length > 0) {
                setLoadingText('Uploading Images');
                const imageUploadPromises = fileList.map((file, index) => {
                    setLoadingText(`Uploading image ${index + 1}`);
                    return handleImageUpload(
                        ACCOMMODATION_CONTAINER,
                        file.originFileObj,
                        file.size,
                        `${name}_${file.name}`
                    ).then(blobRes => {
                        imageUrls.push(blobRes.blockBlobClient.url);
                        setLoadingText(`Uploaded image ${index + 1}`);
                    });
                });
                await Promise.all(imageUploadPromises);
            }

            // Upload videos
            if (videoList.length > 0) {
                setLoadingText('Uploading Videos');
                const videoUploadPromises = videoList.map((file, index) => {
                    setLoadingText(`Uploading video ${index + 1}`);
                    return handleImageUpload(
                        ACCOMMODATION_CONTAINER,
                        file.originFileObj,
                        file.size,
                        `${name}_${file.name}`
                    ).then(blobRes => {
                        videoUrls.push(blobRes.blockBlobClient.url);
                        setLoadingText(`Uploaded video ${index + 1}`);
                    });
                });
                await Promise.all(videoUploadPromises);
            }

            // Upload pricing documents
            if (pricingFiles.length > 0) {
                setLoadingText('Uploading Pricing Documents');
                const pricingUploadPromises = pricingFiles.map((file, index) => {
                    setLoadingText(`Uploading pricing document ${index + 1}`);
                    return handleImageUpload(
                        ACCOMMODATION_CONTAINER,
                        file.originFileObj,
                        file.size,
                        `${file.name}`
                    ).then(blobRes => {
                        pricingUrls.push(blobRes.blockBlobClient.url);
                        setLoadingText(`Uploaded pricing document ${index + 1}`);
                    });
                });
                await Promise.all(pricingUploadPromises);
            }

            // Upload thumbnail
            if (thumbnailFile.length > 0) {
                setLoadingText('Uploading Thumbnail');
                const blobRes = await handleImageUpload(
                    ACCOMMODATION_CONTAINER,
                    thumbnailFile[0].originFileObj,
                    thumbnailFile[0].size,
                    `${name}_${thumbnailFile[0].name}`
                );
                thumbnailUrl = blobRes.blockBlobClient.url;
            }

            delete values.accommodationImages;
            delete values.accommodationVideos;
            delete values.pricingDocuments;
            delete values.thumbnailUrl;

            if (values?.tags && values?.tags.length > 0) {
                values.tags = values.tags.join(',');
            }

            if (imageUrls.length > 0) {
                imageUrls = imageUrls.map(url => sanitizeBlobUrls(url));
                values.accommodationImages = imageUrls.join(',');
            }

            if (videoUrls.length > 0) {
                videoUrls = videoUrls.map(url => sanitizeBlobUrls(url));
                values.accommodationVideos = videoUrls.join(',');
            }

            if (pricingUrls.length > 0) {
                pricingUrls = pricingUrls.map(url => sanitizeBlobUrls(url));
                values.pricingDocuments = pricingUrls.join(',');
            }

            if (thumbnailUrl) {
                thumbnailUrl = sanitizeBlobUrls(thumbnailUrl);
                values.thumbnailUrl = thumbnailUrl;
            }

            values.approveStatus = true;
            values.starRating = values.starRating.toString();

            setLoadingText('Adding to Database');
            const response = await addAccommodation(values);
            if (response.status === 200) {
                api.success({
                    message: 'Accommodation Added Successfully',
                    description: 'You have successfully added a new accommodation'
                });

                setTimeout(() => {
                    navigate('/dashboard/partners/hotels');
                }, 2000);
            } else {
                api.error({
                    message: 'Error Adding Accommodation',
                    description: 'There was an error adding the accommodation. Please try again'
                });
            }
        } catch (error) {
            console.log('Error Adding Accommodation', error);
            api.error({
                message: 'Error Adding Accommodation',
                description: 'There was an error adding the accommodation. Please try again'
            });
        }
        setLoading(false);
    };


    const sanitizeBlobUrls = (blobUrl) => {
        const splitUrl = blobUrl.split('?')
        return splitUrl[0]
    }

    const destinationOptions = Object.keys(destinationNames).map(key => {
        return {
            label: getOptionName(destinationNames[key].destinationName),
            value: destinationNames[key].id
        };
    });

    return (
        <div className='add__destination' ref={topRef}>
            {contextHolder}
            <h2>
                Add New Accommodation
            </h2>

            <Spin spinning={loading} tip={loadingText}>
                <Form
                    layout='vertical'
                    onFinish={onFinish}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Accommodation Name'
                                name='accommodationName'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter accommodation name'
                                    }
                                ]}
                            >
                                <Input placeholder='Accommodation Name' showCount maxLength={200} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Nearest Destination'
                                name='nearestDestinations'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select nearest destination'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Select Nearest Destination"
                                    options={destinationOptions}
                                    maxCount={1}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Accommodation Type'
                                name='accommodationType'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select accommodation type'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Add Accommodation Type"
                                    options={accommodationOptions}
                                    maxCount={1}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Accommodation City'
                                name='accommodationCity'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add accommodation city'
                                    }
                                ]}
                            >
                                <Input placeholder='Accommodation City' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Star Rating'
                                name='starRating'
                            >
                                <Rate />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Grade (If not has star rating)'
                                name='grade'
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Add Grade"
                                    options={[
                                        {
                                            label: 'A Grade',
                                            value: 'A'
                                        },
                                        {
                                            label: 'B Grade',
                                            value: 'B'
                                        },
                                        {
                                            label: 'C Grade',
                                            value: 'C'
                                        },
                                        {
                                            label: 'D Grade',
                                            value: 'D'
                                        },
                                        {
                                            label: 'E Grade',
                                            value: 'E'
                                        },
                                        {
                                            label: 'F Grade',
                                            value: 'F'
                                        }
                                    ]}
                                    maxCount={1}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Accommodation Coordinates'
                                name='accommodationCoordinates'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input accommodation coordinates'
                                    }
                                ]}
                            >
                                <Input placeholder='Accommodation Coordinates' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Accommodation Map Location'
                                name='mapLocation'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add accommodation map location'
                                    }
                                ]}
                            >
                                <Input placeholder='Accommodation Map Location' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Is Pets Allowed'
                                name='isPetsAllowed'
                            >
                                <Radio.Group>
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Accommodation Registration Number'
                                name='registartionNumber'
                            >
                                <Input placeholder='Accommodation Registration Number' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Accommodation Licence Number'
                                name='licenceNumber'
                            >
                                <Input placeholder='Accommodation Licence Number' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Accommodation Licence Expiry'
                                name='licenceExpiry'
                            >
                                <Input placeholder='Accommodation Licence Expiry' type='date' />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label='Search Tags'
                                name='tags'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add tags'
                                    }
                                ]}
                            >
                                <Select
                                    mode="tags"
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Add accommodation tags"
                                    options={[]}
                                    maxCount={5}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Accommodation email'
                                name='email'
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid email'
                                    }
                                ]}
                            >
                                <Input placeholder='Accommodation email' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Accommodation Address'
                                name='accommodationAddress'
                            >
                                <Input placeholder='Accommodation Address' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Accommodation Contact Number 1'
                                name='contact1'
                            >
                                <Input placeholder='Accommodation Contact Number 1' showCount maxLength={50} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label='Contact Person Name 1'
                                name='contact1Name'
                            >
                                <Input placeholder='Accommodation Contact Person 2' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Accommodation Contact Number 2'
                                name='conatctNo2'
                            >
                                <Input placeholder='Accommodation Contact Number 1' showCount maxLength={50} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label='Contact Person Name 2'
                                name='contact2Name'
                            >
                                <Input placeholder='Accommodation Contact Person 2' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Booking Process'
                                name='bookingProcess'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter booking process'
                                    }
                                ]}
                            >
                                <ReactQuill
                                    theme="snow"
                                    limits={{ maxLength: 500 }}
                                    modules={quillModules}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={`Accommodation Short Description ( ${shortDescriptionCharCount}/1000 characters)`}
                                name='accommodationDescriptionShort'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter accommodation short description'
                                    }
                                ]}
                            >
                                <ReactQuill
                                    theme="snow"
                                    limits={{ maxLength: 500 }}
                                    onChange={(content, delta, source, editor) => {
                                        setShortDescriptionCharCount(editor.getLength())
                                    }}
                                    modules={quillModules}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={`Accommodation Description ( ${longDescriptionCharCount}/10000 characters)`}
                                name='accommodationDescription'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter accommodation description'
                                    }
                                ]}
                            >
                                <ReactQuill
                                    theme="snow"
                                    limits={{ maxLength: 10000 }}
                                    onChange={(content, delta, source, editor) => {
                                        setLongDescriptionCharCount(editor.getLength())
                                    }}
                                    modules={quillModules}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Upload Pricing Documents (Max 15)'
                                name='pricingDocuments'
                            >
                                <Upload
                                    listType="picture"
                                    fileList={pricingFiles}
                                    onChange={onPricingFilesChange}
                                    multiple={true}
                                    maxCount={3}
                                    beforeUpload={(file) => {
                                        return false;
                                    }}
                                >
                                    {videoList?.length < 15 && <Button icon={<PlusOutlined />}>Upload</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Upload Images (Max 10)'
                                name='accommodationImages'
                            >
                                <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                    multiple={true}
                                    maxCount={10}
                                    beforeUpload={(file) => {
                                        return false;
                                    }}
                                >
                                    {fileList.length <= 10 && '+ Upload'}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Upload Videos (Max 3)'
                                name='accommodationVideos'
                            >
                                <Upload
                                    listType="picture"
                                    fileList={videoList}
                                    onChange={onVideoChange}
                                    multiple={true}
                                    maxCount={3}
                                    beforeUpload={(file) => {
                                        return false;
                                    }}
                                >
                                    {videoList?.length < 3 && <Button icon={<PlusOutlined />}>Upload</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Upload Thumbnail Image'
                                name='thumbnailUrl'
                            >
                                <ImgCrop
                                    rotationSlider
                                    modalTitle='Crop Image'
                                    modalOk='Crop'
                                    modalCancel='Cancel'
                                    showReset={true}
                                    aspect={16 / 9}
                                >
                                    <Upload
                                        listType="picture-card"
                                        fileList={thumbnailFile}
                                        onChange={onThumbnailChange}
                                        multiple={false}
                                        beforeUpload={(file) => {
                                            return false;
                                        }}
                                    >
                                        {thumbnailFile.length < 1 && '+ Upload'}
                                    </Upload>
                                </ImgCrop>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Col span={12}>
                        <Form.Item
                            label='Active Status'
                            name='activeStatus'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select active status'
                                }
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Select Active Status"
                                options={[
                                    {
                                        label: 'Active',
                                        value: ACTIVE
                                    },
                                    {
                                        label: 'Inactive',
                                        value: INACTIVE
                                    },
                                    {
                                        label: 'Pending',
                                        value: PENDING
                                    }
                                ]}
                                maxCount={1}
                            />
                        </Form.Item>
                    </Col>

                    <Form.Item>
                        <Button type='primary' htmlType='submit'>
                            Add Accommodation
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>

            {previewImage && (
                <Image
                    wrapperStyle={{
                        display: 'none',
                    }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </div>
    )
}

export default AddHotel