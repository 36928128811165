import {
    FETCH_USERS,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAIL,

    CHANGE_USER_TYPE,
    CHANGE_USER_TYPE_SUCCESS,
    CHANGE_USER_TYPE_FAIL
} from '../../types';

const initialState = {
    loading: false,
    users: [],
    error: null,

    selectedUsersType: 'all',
};

export const usersReducer = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case FETCH_USERS:
            return {
                ...state,
                loading: true
            };
        case FETCH_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.payload,
                error: null
            };
        case FETCH_USERS_FAIL:
            return {
                ...state,
                loading: false,
                users: [],
                error: action.payload
            };

        // ----------------------------------
        // Change User Type
        // ----------------------------------
        case CHANGE_USER_TYPE:
            return {
                ...state,
                loading: true
            };
        case CHANGE_USER_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                selectedUsersType: action.payload,
                error: null
            };
        case CHANGE_USER_TYPE_FAIL:
            return {
                ...state,
                loading: false,
                selectedUsersType: 'all',
                error: action.payload
            };
        default:
            return state;
    }
}