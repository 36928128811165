import React, { useState } from 'react'
import { getAllBlogs } from '../../../../redux/api/blogs.api'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import BlogsTable from './BlogsTable'
import { useNavigate } from 'react-router-dom'

const PAGE_SIZE = 15

const Blogs = () => {
    const [blogs, setBlogs] = useState([])
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const navigate = useNavigate();

    const handleFetchBlogs = async (page) => {
        const response = await getAllBlogs(page, PAGE_SIZE)
        if (response.data) {
            setBlogs(response.data)
        }
        setLoading(false)
    }

    useState(() => {
        handleFetchBlogs(page)
    }, [])

    return (
        <div className='users'>
            <div className='users__header'>
                <h1>All Blogs</h1>
                <div className='users__header__actions'>
                    <Button type='primary'
                        icon={<PlusOutlined />}
                        onClick={() => {
                            navigate('/dashboard/blogs/add')
                        }}
                    >
                        Add Blog
                    </Button>
                </div>
            </div>

            <BlogsTable
                blogs={blogs}
                loading={loading}
                handleFetchBlogs={handleFetchBlogs}
                page={page}
                setPage={setPage}
            />
        </div>
    )
}

export default Blogs