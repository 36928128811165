import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import useMyProfile from '../../../../../hooks/useMyProfile'

import { Tabs } from 'antd';

import './styles.scss'
import EditBasicProfile from './EditBasicProfile';
import EditUserPreferences from './EditUserPreferences';


const EditUser = () => {
    const myProfile = useMyProfile().userProfile
    const navigate = useNavigate();
    const { instance } = useMsal();
    const [selectedTab, setSelectedTab] = useState('basic-profile')
    const url = window.location.href

    useEffect(() => {
        if (url.includes('settings')) {
            setSelectedTab('preferences')
        }
    }, [url])

    const items = [
        {
            key: 'basic-profile',
            label: 'Basic Profile',
            children: <EditBasicProfile />
        },
        {
            key: 'preferences',
            label: 'Preferences',
            children: <EditUserPreferences />
        }
    ]

    useEffect(() => {
        if (myProfile) {
            const oid = instance.getActiveAccount().idTokenClaims.oid
            if (myProfile.id !== oid) {
                navigate('/dashboard/home')
            }
        }
    }, [myProfile])

    const onTabChange = (key) => {
        setSelectedTab(key)
    }

    return (
        <div className='edit__user'>
            <div className='edit__user__header'>
                <h1>Edit User</h1>
            </div>

            <Tabs
                defaultActiveKey={selectedTab}
                activeKey={selectedTab}
                items={items}
                onChange={onTabChange}
            />
        </div>
    )
}

export default EditUser