import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Image, Popconfirm, Row, Skeleton, Spin, Tag, notification } from 'antd'
import 'react-quill/dist/quill.snow.css';

import './styles.scss'
import useGuides from '../../../../../hooks/useGuides'
import { deleteGuideProfile, getGuideById, getGuideByUserId, getGuideVehicle, removeGuideVehicle } from '../../../../../../redux/api/guides.api'
import { DEFAULT_PROFILE_IMAGE } from '../../../../../Constants/otherConstsnts'
import EditGuide from './EditGuide'
import { handleDeleteBlob, PARTNERS_BLOB } from '../../../../../azure/blob';
import AddVehicle from './AddVehicle';
import EditVehicle from './EditVehicle';
import useMyProfile from '../../../../../hooks/useMyProfile';
import { ADMIN, SUPER_ADMIN, TOUR_GUIDE } from '../../../../../Constants/roles';
import GuideSchedule from './GuideSchedule';
import GuideScheduleAdmin from './GuideScheduleAdmin';


const Guide = () => {
    const guides = useGuides().guides
    const url = window.location.pathname
    let guideId = url.split('/').pop()
    const [loading, setLoading] = useState(false)
    const [guide, setGuide] = useState()
    const navigate = useNavigate()
    const [api, contextHolder] = notification.useNotification();
    const [isEditVisible, setIsEditVisible] = useState(false)
    const [addVehicleVisible, setAddVehicleVisible] = useState(false)
    const [loadingText, setLoadingText] = useState('')
    const [vehicles, setVehicles] = useState([]);
    const [loadingVehicles, setLoadingVehicles] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [editVehicleVisible, setEditVehicleVisible] = useState(false);
    const [loadingDeleteVehicle, setLoadingDeleteVehicle] = useState(false);
    const myProfile = useMyProfile().userProfile

    useEffect(() => {
        if (myProfile.role === TOUR_GUIDE) {
            handleGetGuide(myProfile.id)
        } else {
            if (guides?.length > 0) {
                const guide = guides.find(guide => guide.id === guideId)
                if (guide) {
                    setGuide(guide)
                } else {
                    handleGetGuide(guideId)
                }
            } else {
                handleGetGuide(guideId)
            }
        }
    }, [])

    useEffect(() => {
        if (guide) {
            hadleGetGuideVehicles()
        }
    }, [guide])

    const hadleGetGuideVehicles = async () => {
        setLoadingVehicles(true);
        try {
            if (myProfile.role === TOUR_GUIDE) {
                const vehicles = await getGuideVehicle(guide.id);
                setVehicles(vehicles.data);
            } else {
                const vehicles = await getGuideVehicle(guideId);
                setVehicles(vehicles.data);
            }
        } catch (error) {
            console.error('Error fetching guide vehicles', error);
        }
        setLoadingVehicles(false);
    }

    const handleGetGuide = async (id) => {
        setLoading(true)
        try {
            if (myProfile.role === TOUR_GUIDE) {
                const guide = await getGuideByUserId(myProfile?.id)
                setGuide(guide.data)
            } else {
                const guide = await getGuideById(id)
                setGuide(guide.data)
            }
        } catch (error) {
            console.error('Error fetching transport package', error)
        }
        setLoading(false)
    }

    const getBlobName = (blobUrl) => {
        const splitUrl = blobUrl.split('/')
        return splitUrl[splitUrl.length - 1]
    }

    const handleDeleteTourGuide = async () => {
        setLoading(true)
        try {
            let introVideoUrl = guide.introVideoUrl

            if (introVideoUrl) {
                const blobName = getBlobName(introVideoUrl);
                setLoadingText(`Deleting video...`);
                await handleDeleteBlob(PARTNERS_BLOB, blobName);
            }

            const res = await deleteGuideProfile(guideId)

            if (res.status === 200) {
                api.success({
                    message: 'Guide deleted successfully',
                    description: 'The guide has been successfully deleted'
                })

                setLoadingText('')
                setTimeout(() => {
                    navigate('/dashboard/partners/tour-guides')
                }, 2000)

            } else {
                api.error({
                    message: 'Error deleting tour guide',
                    description: 'An error occurred while deleting the tour guide'
                })
            }

        } catch (error) {
            console.error('Error deleting tour guide', error)
            api.error({
                message: 'Error deleting tour guide',
                description: error.message
            })
        }
        setLoading(false)
    }

    const handleDeleteVehicle = async (vehicle) => {
        setLoadingDeleteVehicle(true);
        try {
            let vehicleImages = vehicle.vehicleImages?.split(',')

            if (vehicleImages?.length > 0) {
                setLoadingText('Deleting Images');
                const imageDeletePromises = vehicleImages?.map(async (image, index) => {
                    const blobName = getBlobName(image);
                    setLoadingText(`Deleting image ${index + 1}`);
                    return handleDeleteBlob(PARTNERS_BLOB, blobName);
                });
                await Promise.all(imageDeletePromises);
            }

            const res = await removeGuideVehicle(vehicle.id);

            if (res.status === 200) {
                api.success({
                    message: 'Vehicle deleted successfully',
                    description: 'The vehicle has been successfully deleted'
                })
                hadleGetGuideVehicles();
            } else {
                api.error({
                    message: 'Error deleting vehicle',
                    description: 'An error occurred while deleting the vehicle'
                })
            }

        } catch (error) {
            console.error('Error deleting vehicle', error)
            api.error({
                message: 'Error deleting vehicle',
                description: error.message
            })
        }
        setLoadingDeleteVehicle(false);
    }

    return (
        <div className='destination'>
            {contextHolder}
            <Spin
                spinning={loading}
                size='large'
                tip={loadingText}
            >
                <>
                    <div className='destination__header'>
                        <h1>
                            {guide?.guideName}
                        </h1>
                        <div className='destination__header__actions'>
                            <Button type='default'
                                icon={<EditOutlined />}
                                onClick={() => setIsEditVisible(true)}
                            >
                                Edit
                            </Button>
                            {(myProfile?.role === SUPER_ADMIN || myProfile?.role === ADMIN) &&
                                <Popconfirm
                                    title='Are you sure you want to delete this tour guide?'
                                    description={<p
                                        style={{
                                            width: '300px',
                                            color: 'red',
                                        }}
                                    >This action cannot be undone! This will permanently delete the tour guide profile, its images, videos, attractions and other related items.</p>}
                                    onConfirm={handleDeleteTourGuide}
                                    okText='Yes'
                                    cancelText='No'
                                >
                                    <Button danger type='primary' icon={<DeleteOutlined />}>
                                        Delete
                                    </Button>
                                </Popconfirm>
                            }
                        </div>
                    </div>
                    <div className='destination__content_images'>
                        <div className='destination__content__image'
                            style={{
                                display: 'flex',
                                gap: '20px',
                                alignItems: 'center'
                            }}
                        >
                            <img src={guide?.profilePicUrl || DEFAULT_PROFILE_IMAGE} alt='activity' style={{
                                width: '100px',
                                height: '100px',
                                borderRadius: '20px'
                            }} />

                            <div>
                                <p><strong>User Name: </strong>{guide?.userName || 'N/A'}</p>
                                <p><strong>Email: </strong>{guide?.email || 'N/A'}</p>
                            </div>
                        </div>
                        <div className='destination__content__galery'>
                            <Image.PreviewGroup>
                                {guide?.vehicleImages?.split(',')?.map((image, index) => (
                                    <Image
                                        key={index}
                                        width={160}
                                        height={100}
                                        src={image}
                                        className='destination__content__galery__image'
                                    />
                                ))}
                            </Image.PreviewGroup>
                        </div>
                    </div>
                    <Divider />

                    <div className='destination__content_details'>
                        <h2>
                            Details
                        </h2>
                        <div className='destination__content__details'>
                            <div className='left'>
                                <p><strong>Guide Type: </strong>{guide?.guideType || 'N/A'}</p>
                                <p><strong>Area: </strong>{guide?.guideArea || 'N/A'}</p>
                                <p><strong>Languages: </strong>{
                                    guide?.guideLanguages?.split(',')?.map((language, index) => (
                                        <Tag key={index}>{language}</Tag>
                                    ))
                                }</p>
                                <p><strong>Active Status: </strong>
                                    <Tag color={guide?.activeStatus === 'ACTIVE' ? 'green' : 'red'}>
                                        {guide?.activeStatus || 'N/A'}
                                    </Tag>
                                </p>
                            </div>
                            <div className='right'>
                                <p><strong>Guide ID: </strong>{guide?.guideId || 'N/A'}</p>
                                <p><strong>Vehicle Available: </strong>{
                                    guide?.vehicleAvailable === true ? <Tag color='blue'>Yes</Tag> : <Tag color='red'>No</Tag>
                                }</p>
                                <p><strong>Created At: </strong>{guide?.createdAt || 'N/A'}</p>
                                <p><strong>DB Id: </strong>{guide?.id || 'N/A'}</p>
                            </div>
                        </div>
                    </div>

                    <div className='destination__content_description'>
                        <h3>
                            Package Description
                        </h3>
                        <p>{guide?.guideDescription}</p>
                    </div>

                    {guide?.introVideoUrl &&
                        <div className='destination__content_description'>
                            <h3>
                                Intro Video
                            </h3>
                            <video
                                width='400px'
                                controls
                                src={guide?.introVideoUrl}
                            />
                        </div>}

                    <Divider />
                    <div className='destination__content_reviews'>
                        <div className='header'
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                margin: 0
                            }}
                        >
                            <h2 style={{ margin: 0 }}>
                                Vehicle Details
                            </h2>

                            <Button type='default' icon={<PlusOutlined />} onClick={() => setAddVehicleVisible(true)}>
                                Add Vehicle
                            </Button>
                        </div>

                        <div className='destination__content__details'
                            style={{ marginTop: '20px' }}
                        >
                            {
                                loadingVehicles ? <Skeleton active={true} /> :
                                    <div>
                                        {vehicles?.map((vehicle, index) => (
                                            <div key={index} className='vehicle'
                                                style={{
                                                    padding: '20px',
                                                    border: '1px solid #f0f0f0',
                                                    borderRadius: '5px',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                <Row gutter={16}>
                                                    <Col>
                                                        <p><strong>Vehicle Type: </strong>{vehicle.vehicleType || 'N/A'}</p>
                                                        <p><strong>Plate Number: </strong>{vehicle.vehiclePlateNumber || 'N/A'}</p>
                                                        <p><strong>Color: </strong>{vehicle.vehicleColor || 'N/A'}</p>
                                                    </Col>
                                                    <Col>
                                                        <p><strong>Brand: </strong>{vehicle.vehicleBrand || 'N/A'}</p>
                                                        <p><strong>Model: </strong>{vehicle.vehicleModel || 'N/A'}</p>
                                                        <p><strong>Year: </strong>{vehicle.vehicleYear || 'N/A'}</p>
                                                    </Col>
                                                    <Col>
                                                        <p><strong>Capacity: </strong>{vehicle.vehicleCapacity || 'N/A'}</p>
                                                        <p><strong>Baggage Capacity: </strong>{vehicle.baggageCapacity || 'N/A'}</p>
                                                        <p><strong>Status: </strong><Tag>{vehicle?.activeStatus || 'N/A'}</Tag></p>
                                                    </Col>
                                                    <Col>
                                                        <p><strong>Price Per KM: </strong>{vehicle.pricePerKm || 'N/A'}</p>
                                                        <p><strong>Price Per Day: </strong>{vehicle.pricePerDay || 'N/A'}</p>
                                                        <p><strong>Margin: </strong>{vehicle.priceMargin || 'N/A'}</p>
                                                    </Col>
                                                    <Col>
                                                        <p><strong>Min Milage: </strong>{vehicle.minMilage || 'N/A'}</p>
                                                        <p><strong>Max Milage: </strong>{vehicle.maxMilage || 'N/A'}</p>
                                                    </Col>
                                                </Row>
                                                <p>{vehicle.vehicleDescription}</p>
                                                <div>
                                                    {vehicle.vehicleImages ? <Image.PreviewGroup>
                                                        {vehicle.vehicleImages?.split(',')?.map((image, index) => (
                                                            <Image
                                                                key={index}
                                                                height={100}
                                                                src={image}
                                                                className='destination__content__galery__image'
                                                            />
                                                        ))}
                                                    </Image.PreviewGroup>
                                                        :
                                                        <p>No images available</p>
                                                    }
                                                </div>
                                                <div className='actions'
                                                    style={{
                                                        marginTop: '10px',
                                                        display: 'flex',
                                                        gap: '10px',
                                                    }}
                                                >
                                                    <Button type='default' icon={<EditOutlined />}
                                                        onClick={() => {
                                                            setSelectedVehicle(vehicle);
                                                            setEditVehicleVisible(true);
                                                        }}>
                                                        Edit
                                                    </Button>
                                                    <Popconfirm
                                                        title='Are you sure you want to delete this vehicle?'
                                                        description={<p
                                                            style={{
                                                                width: '300px',
                                                                color: 'red',
                                                            }}
                                                        >This action cannot be undone! This will permanently delete the vehicle profile, its images, videos, attractions and other related items.</p>}
                                                        onConfirm={() => handleDeleteVehicle(vehicle)}
                                                        okText='Yes'
                                                        cancelText='No'
                                                    >
                                                        <Button danger type='primary' icon={<DeleteOutlined />} loading={loadingDeleteVehicle}>
                                                            Delete
                                                        </Button>
                                                    </Popconfirm>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                            }
                            {
                                (vehicles?.length === 0 && !loadingVehicles) &&
                                <p>No vehicles available</p>
                            }
                        </div>
                    </div>

                    <Divider />
                    <div className='destination__content_reviews'>
                        <h2>
                            Guide Schedule
                        </h2>
                        <GuideScheduleAdmin guide={guide} />
                    </div>

                    <Divider />
                    <div className='destination__content_reviews'>
                        <h2>
                            Reviews
                        </h2>
                    </div>
                </>
            </Spin>

            <EditGuide
                isVisible={isEditVisible}
                setIsVisible={setIsEditVisible}
                guide={guide}
                handleGetGuide={handleGetGuide}
            />

            <AddVehicle
                isVisible={addVehicleVisible}
                setIsVisible={setAddVehicleVisible}
                guide={guide}
                hadleGetGuideVehicles={hadleGetGuideVehicles}
            />

            <EditVehicle
                isVisible={editVehicleVisible}
                setIsVisible={setEditVehicleVisible}
                guide={guide}
                hadleGetGuideVehicles={hadleGetGuideVehicles}
                selectedVehicle={selectedVehicle}
            />
        </div>
    )
}

export default Guide