import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { capitalizeFisrtLetterEachWord } from '../../../../helpers/nameRender';
import dayjs from 'dayjs';
import { supporRequestTypes, supportRequestStatus } from '../../../../Constants/itemTypes';

const SupportRequestsTable = ({
    requests,
    loading
}) => {
    const [searchText, setSearchText] = useState('');
    const [tableData, setTableData] = useState([]);
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    useEffect(() => {
        setTableData(requests)
    }, [requests])

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getNameSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text, record) =>
            searchedColumn === dataIndex ? (
                <Link to={`/dashboard/support-tickets/${record.id}`}>
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                </Link>
            ) : (
                <Link to={`/dashboard/support-tickets/${record.id}`}>
                    <p
                        style={{
                            color: '#9373ff',
                            cursor: 'pointer',
                            margin: 0,
                        }}
                    >
                        {text}
                    </p>
                </Link>
            ),
    });

    const columns = [
        {
            title: 'Support Type',
            dataIndex: 'supportType',
            key: 'supportType',
            width: '10%',
            filters: [
                {
                    text: 'General',
                    value: supporRequestTypes.GENERAL,
                },
                {
                    text: 'Problem',
                    value: supporRequestTypes.PROBLEM,
                },
                {
                    text: 'Question',
                    value: supporRequestTypes.QUESTION,
                },
                {
                    text: 'Booking',
                    value: supporRequestTypes.BOOKING,
                },
                {
                    text: 'Payment',
                    value: supporRequestTypes.PAYMENT,
                },
                {
                    text: 'Cancellation',
                    value: supporRequestTypes.CANCELLATION,
                },
                {
                    text: 'Refund',
                    value: supporRequestTypes.REFUND,
                },
                {
                    text: 'Complaint',
                    value: supporRequestTypes.COMPLAINT,
                },
                {
                    text: 'Suggestion',
                    value: supporRequestTypes.SUGGESTION,
                },
                {
                    text: 'Other',
                    value: supporRequestTypes.OTHER,
                },
                {
                    text: 'Feedback',
                    value: supporRequestTypes.FEEDBACK,
                },
                {
                    text: 'Request Change',
                    value: supporRequestTypes.REQUEST_CHANGE,
                }
            ],
            onFilter: (value, record) => record?.supportType?.indexOf(value) === 0,
            render: (text) => {
                let color = 'cyan';
                if (text === supporRequestTypes.GENERAL) {
                    color = 'green';
                } else if (text === supporRequestTypes.BOOKING) {
                    color = 'orange';
                } else if (text === supporRequestTypes.PAYMENT) {
                    color = 'blue';
                } else if (text === supporRequestTypes.CANCELLATION) {
                    color = 'red';
                } else if (text === supporRequestTypes.REFUND) {
                    color = 'purple';
                } else if (text === supporRequestTypes.COMPLAINT) {
                    color = 'volcano';
                } else if (text === supporRequestTypes.SUGGESTION) {
                    color = 'cyan';
                } else if (text === supporRequestTypes.OTHER) {
                    color = 'magenta';
                } else if (text === supporRequestTypes.FEEDBACK) {
                    color = 'gold';
                } else if (text === supporRequestTypes.REQUEST_CHANGE) {
                    color = 'lime';
                } else if (text === supporRequestTypes.PROBLEM) {
                    color = 'red';
                } else if (text === supporRequestTypes.QUESTION) {
                    color = 'blue';
                }

                return (
                    <Tag color={color} key={text}>
                        {text ? capitalizeFisrtLetterEachWord(text) : 'General Un Auth'}
                    </Tag>
                );
            }
        },
        {
            title: 'Request ID',
            dataIndex: 'id',
            key: 'id',
            width: '20%',
            ...getNameSearchProps('id'),
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '15%',
            render: (text) => {
                return dayjs(text, 'MMM D YYYY h:mma').format('DD/MM/YYYY');
            }
        },
        {
            title: "Impact Level",
            dataIndex: "impactLevel",
            key: "impactLevel",
            width: "15%",
            filters: [
                {
                    text: 'Low',
                    value: 'low',
                },
                {
                    text: 'Medium',
                    value: 'medium',
                },
                {
                    text: 'High',
                    value: 'high',
                }
            ],
            onFilter: (value, record) => record?.impactLevel?.indexOf(value) === 0,
            render: (text) => {
                let color = 'geekblue';
                if (text === 'low') {
                    color = 'green';
                } else if (text === 'medium') {
                    color = 'orange';
                } else if (text === 'high') {
                    color = 'red';
                }
                return (
                    <Tag color={color} key={text}>
                        {text ? capitalizeFisrtLetterEachWord(text) : 'Not Set'}
                    </Tag>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '15%',
            filters: [
                {
                    text: 'Pending',
                    value: supportRequestStatus.PENDING,
                },
                {
                    text: 'In Progress',
                    value: supportRequestStatus.IN_PROGRESS,
                },
                {
                    text: 'Resolved',
                    value: supportRequestStatus.RESOLVED,
                },
                {
                    text: 'Closed',
                    value: supportRequestStatus.CLOSED,
                }
            ],
            onFilter: (value, record) => record.status.includes(value),
            render: (text) => {
                let color = 'geekblue';
                if (text === supportRequestStatus.PENDING) {
                    color = 'blue';
                } else if (text === supportRequestStatus.IN_PROGRESS) {
                    color = 'orange';
                } else if (text === supportRequestStatus.RESOLVED) {
                    color = 'green';
                } else if (text === supportRequestStatus.CLOSED) {
                    color = 'red';
                }
                return (
                    <Tag color={color} key={text}>
                        {text ? capitalizeFisrtLetterEachWord(text) : ''}
                    </Tag>
                );
            }
        },
    ];

    return (
        <Table
            className='table'
            loading={loading}
            columns={columns}
            dataSource={tableData}
        />
    )
}

export default SupportRequestsTable