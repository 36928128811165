import React from 'react'

import './styles.scss'

const LoadingComp = () => {
    return (
        <div className='dot_three_loader'>
            <div class="three-body">
                <div class="three-body__dot dot_1"></div>
                <div class="three-body__dot dot_2"></div>
                <div class="three-body__dot dot_3"></div>
            </div>
        </div>
    )
}

export default LoadingComp