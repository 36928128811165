import React from 'react'

const NotFound = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '80vh',
                width: '100%'
            }}
        >
            <img
                src="https://visitpearl8abf.blob.core.windows.net/static-assets/404-admin.png"
                alt="404"
                style={{
                    width: '500px'
                }}
            />
        </div>
    )
}

export default NotFound