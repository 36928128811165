import React, { useState } from 'react'
import { Button, Col, Drawer, Form, Input, Row, Select, Spin, notification } from 'antd'
import { mealTypes } from '../../../../../../Constants/itemTypes';
import { addAccommodationMealInfo } from '../../../../../../../redux/api/hotels.api';

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const mealOptions = Object.keys(mealTypes).map(key => {
    return {
        label: getOptionName(mealTypes[key]),
        value: key
    };
});

const AddMeal = ({
    addMealInfoVisible,
    setAddMealInfoVisible,
    hotel,
    getMealInfo
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('Adding Meal...');
    const [api, contextHolder] = notification.useNotification();

    const onFinish = async (values) => {
        setIsLoading(true);
        try {
            Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

            values.accommodationId = hotel.id;

            setLoadingText('Adding to DB...');
            const response = await addAccommodationMealInfo(values);
            if (response.status === 200) {
                api.success({
                    message: 'Success',
                    description: 'Meal added successfully'
                });
                getMealInfo();
                setTimeout(() => {
                    setAddMealInfoVisible(false);
                }, 2000);

            } else {
                api.error({
                    message: 'Error',
                    description: 'An error occurred while adding the meal. Please try again.'
                });
            }

        } catch (error) {
            console.log(error);
            api.error({
                message: 'Error',
                description: `An error occurred while adding the meal. Please try again. ${error.message}`
            });
        }
        setIsLoading(false);
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Add a new Meal Info"
                width={720}
                open={addMealInfoVisible}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                onClose={() => setAddMealInfoVisible(false)}
            >
                <Spin spinning={isLoading} tip={loadingText}>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="mealType"
                                    label="Meal Type"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a meal type',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select a meal type"
                                        options={mealOptions}
                                        maxCount={1}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="mealName"
                                    label="Meal Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter meal name',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter meal name" showCount maxLength={50} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="mealDescription"
                                    label="Meal Description"
                                >
                                    <Input.TextArea
                                        limits={{ maxLength: 500 }}
                                        placeholder="Please enter meal description"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="mealPrice"
                                    label="Meal Price"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter meal price',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter meal price" type='number' suffix='AUD' />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item>
                            <Button type='primary' htmlType='submit' loading={isLoading}>
                                Add Meal
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default AddMeal