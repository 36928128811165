import React, { useEffect, useState } from 'react'

import { Layout, Menu } from 'antd';

import { useNavigate } from "react-router-dom";

import { ADMIN, CUSTOMER_RELATIONS_MANAGER, HOTEL_MANAGER, MANAGER, PARTNERSHIP_MANAGER, SUPER_ADMIN, SUPPORT_AGENT, TOUR_GUIDE } from '../../../../Constants/roles';

import { ADMIN_MENUS, CUSTOMER_RELATIONS_MANAGER_MENUS, HOTEL_MANAGER_MENUS, MANAGER_MENUS, PARTNERSHIP_MANAGER_MENUS, SUPER_ADMIN_MENUS, SUPPORT_AGENT_MENUS, TOUR_GUIDE_MENUS } from '../../../../Constants/roleBaseMenus';

import './styles.scss'

const { Sider } = Layout;

const SideBar = ({ collapsed, setCollapse, userProfile }) => {
    const [items, setItems] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (userProfile) {
            switch (userProfile.role) {
                case SUPER_ADMIN:
                    setItems(SUPER_ADMIN_MENUS);
                    break;
                case ADMIN:
                    setItems(ADMIN_MENUS);
                    break;
                case MANAGER:
                    setItems(MANAGER_MENUS);
                    break;
                case PARTNERSHIP_MANAGER:
                    setItems(PARTNERSHIP_MANAGER_MENUS);
                    break;
                case CUSTOMER_RELATIONS_MANAGER:
                    setItems(CUSTOMER_RELATIONS_MANAGER_MENUS);
                    break;
                case SUPPORT_AGENT:
                    setItems(SUPPORT_AGENT_MENUS);
                    break;
                case HOTEL_MANAGER:
                    setItems(HOTEL_MANAGER_MENUS);
                    break;
                case TOUR_GUIDE:
                    setItems(TOUR_GUIDE_MENUS);
                    break;
                default:
                    break;
            }
        }
    }, [userProfile]);

    const navigateToContent = (item) => {
        const path = item?.props?.href;
        if (path) {
            navigate(`/dashboard${path}`);
        }
    }

    const getSelectedKeys = () => {
        const path = window.location.pathname;

        const pathMapping = {
            'users': 'users',
            'home': 'home',
            'bookings/tour-packages': 'bookings/tour-packages',
            'bookings/flights': 'bookings/flights',
            'bookings/hotels': 'bookings/hotels',
            'bookings/transport': 'bookings/transport',
            'partners/all': 'partners/all',
            'partners/hotels': 'partners/hotels',
            'partners/transport': 'partners/transport',
            'partners/tour-guides': 'partners/tour-guides',
            'partners/restaurants': 'partners/restaurants',
            'partners/shops': 'partners/shops',
            'tours/tour-packages': 'tours/tour-packages',
            'tours/itineraries': 'tours/itineraries',
            'tours/destinations': 'tours/destinations',
            'tours/attractions': 'tours/attractions',
            'tours/activities': 'tours/activities',
            'tours/events': 'tours/events',
            'blogs': 'blogs',
            'support-tickets': 'support-tickets',
            'payments': 'payments',
            'earnings': 'earnings',
            'guide-profile': 'guide-profile',
            'guide-schedule': 'guide-schedule',
        };

        for (const key in pathMapping) {
            if (path.includes(key)) {
                return [pathMapping[key]];
            }
        }

        return [''];
    };

    return (
        <Sider
            breakpoint='md'
            collapsedWidth='0'
            collapsed={collapsed}
            onCollapse={(collapsed) => setCollapse(collapsed)}
            className='sidebar'
        >
            <div className='logo'>
                <img loading='lazy' src='https://visitpearl8abf.blob.core.windows.net/static-assets/logo.png' alt='logo' />
            </div>
            <Menu
                theme='light'
                mode='inline'
                defaultSelectedKeys={getSelectedKeys()}
                className='menu'
                style={{
                    height: '94vh',
                    overflowY: 'auto',
                }}
                items={items}
                onSelect={({ item }) => navigateToContent(item)}
            />
        </Sider>
    )
}

export default SideBar