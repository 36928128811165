import React from 'react'

import './styles.scss'

const Footer = () => {
    return (
        <div className='footer'>
            <p>
                © 2024
                <a
                    href='https://visitpearl.com.au/'
                    target='_blank'
                    rel='noreferrer'
                > Visit Pearl</a> All Rights Reserved.
                Developed by
                <a
                    href='https://www.isuruariyarathna.me/'
                    target='_blank'
                    rel='noreferrer'
                > Isuru.</a>
            </p>
        </div>
    )
}

export default Footer