import axios from 'axios';

import { API_BASE_URL } from "../../App/azure/api";
import { getAccessToken } from "../../App/azure/auth";
import { msalInstance } from "../../App/azure/authConfig";

export const searchAttractions = async (searchTerm) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/searchAttraction?keyword=${searchTerm}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const fetchAllAttractions = async (values) => {
    const token = await getAccessToken(msalInstance);
    const params = new URLSearchParams();

    if (values.page !== undefined && values.page !== null) params.append('page', values.page);
    params.append('pageSize', 5);

    if (values.destinationId) params.append('destinationId', values.destinationId);
    if (values.attractionType) params.append('attractionType', values.attractionType);
    if (values.attractionCity) params.append('attractionCity', values.attractionCity);
    if (values.weather) params.append('weather', values.weather);

    const url = `${API_BASE_URL}/getAllAttraction?${params.toString()}`;

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: url,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}


export const addAttraction = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/addAttraction?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getAttractionById = async (id) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getAttractionById?attractionId=${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const editAttraction = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/editAttraction?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const deleteAttraction = async (id) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/deleteAttraction?clientIp=${ipAddress}&attactionId=${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const fetchAttractionsNames = async () => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getAllAttractionNamesAndIds`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}