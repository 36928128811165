import React, { useEffect, useRef, useState } from 'react'

import './styles.scss'
import { Button, Checkbox, Col, Divider, Drawer, Empty, Form, Input, Row, Select } from 'antd'
import { countryList } from '../../../../../../Constants/itemTypes'
import { editCustomTour } from '../../../../../../../redux/api/bookings.api'

const TravelerDetails = ({
    userTour,
    handlegetUserTour,
    messageApi
}) => {
    const [openTravelerDetailsDrawer, setOpenTravelerDetailsDrawer] = useState(false)
    const [loadingAddTraveler, setLoadingAddTraveler] = useState(false)
    const travelerDetailsFormRef = useRef()

    const [openAddEmergencyContactDrawer, setOpenAddEmergencyContactDrawer] = useState(false)
    const [loadingAddEmergencyContact, setLoadingAddEmergencyContact] = useState(false)
    const emergencyContactFormRef = useRef()

    const [openEditTravelerCountDrawer, setOpenEditTravelerCountDrawer] = useState(false)
    const [loadingEditTravelerCount, setLoadingEditTravelerCount] = useState(false)
    const travelerCountFormRef = useRef()

    useEffect(() => {
        if (travelerDetailsFormRef?.current) {
            const fields = {};

            userTour?.travellersInfromation?.adults?.forEach((adult, index) => {
                fields[`adults[${index}].firstName`] = adult.firstName;
                fields[`adults[${index}].lastName`] = adult.lastName;
                fields[`adults[${index}].email`] = adult.email;
                fields[`adults[${index}].phone`] = adult.phone;
                fields[`adults[${index}].country`] = adult.country;
                fields[`adults[${index}].passportNumber`] = adult.passportNumber;
                fields[`adults[${index}].allergies`] = adult.allergies;
            });

            userTour?.travellersInfromation?.children?.forEach((child, index) => {
                fields[`children[${index}].firstName`] = child.firstName;
                fields[`children[${index}].lastName`] = child.lastName;
                fields[`children[${index}].email`] = child.email;
                fields[`children[${index}].phone`] = child.phone;
                fields[`children[${index}].country`] = child.country;
                fields[`children[${index}].passportNumber`] = child.passportNumber;
                fields[`children[${index}].allergies`] = child.allergies;
            });

            userTour?.travellersInfromation?.infants?.forEach((infant, index) => {
                fields[`infants[${index}].firstName`] = infant.firstName;
                fields[`infants[${index}].lastName`] = infant.lastName;
                fields[`infants[${index}].email`] = infant.email;
                fields[`infants[${index}].phone`] = infant.phone;
                fields[`infants[${index}].country`] = infant.country;
                fields[`infants[${index}].passportNumber`] = infant.passportNumber;
                fields[`infants[${index}].allergies`] = infant.allergies;
            });

            travelerDetailsFormRef.current.setFieldsValue(fields);
        }
    }, [openTravelerDetailsDrawer, travelerDetailsFormRef, userTour]);

    useEffect(() => {
        emergencyContactFormRef?.current?.setFieldsValue({
            name: userTour?.emergencyInfomation?.emergencyContactName,
            phone: userTour?.emergencyInfomation?.emergencyContactNumber,
            email: userTour?.emergencyInfomation?.emergencyEmail
        })
    }, [openAddEmergencyContactDrawer, emergencyContactFormRef, userTour])

    useEffect(() => {
        travelerCountFormRef?.current?.setFieldsValue({
            adults: userTour?.travellers?.adults,
            children: userTour?.travellers?.children,
            infants: userTour?.travellers?.infants
        })
    }, [openEditTravelerCountDrawer, travelerCountFormRef])

    const handleAddTravelerDetails = async (values) => {
        setLoadingAddTraveler(true)

        let travellersInfromation = {
            adults: userTour?.travellers?.adults > 0 ?
                Array.from({ length: userTour?.travellers?.adults }, (_, index) => index).map((index) => ({
                    firstName: values[`adults[${index}].firstName`],
                    lastName: values[`adults[${index}].lastName`],
                    email: values[`adults[${index}].email`],
                    phone: values[`adults[${index}].phone`],
                    country: values[`adults[${index}].country`],
                    passportNumber: values[`adults[${index}].passportNumber`],
                    allergies: values[`adults[${index}].allergies`],
                })) : [],
            children: userTour?.travellers?.children > 0 ?
                Array.from({ length: userTour?.travellers?.children }, (_, index) => index).map((index) => ({
                    firstName: values[`children[${index}].firstName`],
                    lastName: values[`children[${index}].lastName`],
                    email: values[`children[${index}].email`],
                    phone: values[`children[${index}].phone`],
                    country: values[`children[${index}].country`],
                    passportNumber: values[`children[${index}].passportNumber`],
                    allergies: values[`children[${index}].allergies`],
                })) : [],
            infants: userTour?.travellers?.infants > 0 ?
                Array.from({ length: userTour?.travellers?.infants }, (_, index) => index).map((index) => ({
                    firstName: values[`infants[${index}].firstName`],
                    lastName: values[`infants[${index}].lastName`],
                    email: values[`infants[${index}].email`],
                    phone: values[`infants[${index}].phone`],
                    country: values[`infants[${index}].country`],
                    passportNumber: values[`infants[${index}].passportNumber`],
                    allergies: values[`infants[${index}].allergies`],
                })) : [],
        }

        let travellersInfromationJSON = JSON.stringify(travellersInfromation)

        const data = {
            id: userTour?.id,
            travellersInfromation: travellersInfromationJSON
        }

        const response = await editCustomTour(data)
        if (response.status === 200) {
            messageApi.success({
                message: 'Success',
                description: 'Flight details added successfully.'
            })
            setOpenTravelerDetailsDrawer(false)
            handlegetUserTour()
        } else {
            messageApi.error({
                message: 'Error',
                description: 'Something went wrong! Try again later.'
            })
        }

        setLoadingAddTraveler(false)
    }

    const handleAddEmergencyContact = async (values) => {
        setLoadingAddEmergencyContact(true)

        const emergencyInfomation = {
            emergencyContactName: values.name,
            emergencyContactNumber: values.phone,
            emergencyEmail: values.email
        }

        const data = {
            id: userTour?.id,
            emergencyInfomation: JSON.stringify(emergencyInfomation)
        }

        const response = await editCustomTour(data)
        if (response.status === 200) {
            messageApi.success({
                message: 'Success',
                description: 'Emergency contact added successfully.'
            })
            setOpenAddEmergencyContactDrawer(false)
            handlegetUserTour()
        } else {
            messageApi.error({
                message: 'Error',
                description: 'Something went wrong! Try again later.'
            })
        }

        setLoadingAddEmergencyContact(false)
    }

    const handleEditTravelerCount = async (values) => {
        setLoadingEditTravelerCount(true)

        const travellers = {
            adults: values.adults,
            children: values.children,
            infants: values.infants
        }

        const data = {
            id: userTour?.id,
            travellers: JSON.stringify(travellers)
        }

        const response = await editCustomTour(data)
        if (response.status === 200) {
            messageApi.success({
                message: 'Success',
                description: 'Travelers count updated successfully.'
            })
            setOpenEditTravelerCountDrawer(false)
            handlegetUserTour()
        } else {
            messageApi.error({
                message: 'Error',
                description: 'Something went wrong! Try again later.'
            })
        }

        setLoadingEditTravelerCount(false)
    }

    return (
        <div className='traver_details'>
            <div className='travelr_count'>
                <div className='heading'>
                    <h2>
                        Travelers Count
                    </h2>
                    <Button
                        type='primary'
                        onClick={() => setOpenEditTravelerCountDrawer(true)}
                    >
                        Change Travelers Count
                    </Button>
                </div>
                <div className='p_container'>
                    <p><span>Adults: </span>{userTour?.travellers?.adults}</p>
                    <p><span>Children: </span>{userTour?.travellers?.children}</p>
                    <p><span>Infants: </span>{userTour?.travellers?.infants}</p>
                </div>
            </div>
            <div className='travellers_info'>
                <div className='heading'>
                    <h2>
                        Traveler Details
                    </h2>
                    <Button
                        type='primary'
                        onClick={() => setOpenTravelerDetailsDrawer(true)}
                    >
                        Add / Edit Traveler Details
                    </Button>
                </div>
                {
                    userTour?.travellersInfromation?.adults?.length > 0
                    &&
                    <div className='travellers_cont'>
                        <h3>Adults</h3>
                        {
                            userTour?.travellersInfromation?.adults.map((traveler, index) => (
                                <div key={index} className='p_container traveler'>
                                    <h4>Adult {index + 1} Details</h4>
                                    <p><span>First Name: </span>{traveler.firstName}</p>
                                    <p><span>Last Name: </span>{traveler.lastName}</p>
                                    <p><span>Email: </span>{traveler.email}</p>
                                    <p><span>Phone: </span>{traveler.phone}</p>
                                    <p><span>Country: </span>{traveler.country}</p>
                                    <p><span>Passport No: </span>{traveler.passportNumber}</p>
                                    <p><span>Allergies & Dietary Requirements: </span>{traveler.allergies || 'None'}</p>
                                </div>
                            ))
                        }
                    </div>
                }
                {
                    userTour?.travellersInfromation?.children?.length > 0
                    &&
                    <div className='travellers_cont'>
                        <h3>Children</h3>
                        {
                            userTour?.travellersInfromation?.children.map((traveler, index) => (
                                <div key={index} className='p_container traveler'>
                                    <h4>Child {index + 1} Details</h4>
                                    <p><span>First Name: </span>{traveler.firstName}</p>
                                    <p><span>Last Name: </span>{traveler.lastName}</p>
                                    <p><span>Email: </span>{traveler.email}</p>
                                    <p><span>Phone: </span>{traveler.phone}</p>
                                    <p><span>Country: </span>{traveler.country}</p>
                                    <p><span>Passport No: </span>{traveler.passportNumber}</p>
                                    <p><span>Allergies & Dietary Requirements: </span>{traveler.allergies || 'None'}</p>
                                </div>
                            ))
                        }
                    </div>
                }
                {
                    userTour?.travellersInfromation?.infants?.length > 0
                    &&
                    <div className='travellers_cont'>
                        <h3>Infants</h3>
                        {
                            userTour?.travellersInfromation?.infants.map((traveler, index) => (
                                <div key={index} className='p_container traveler'>
                                    <h4>Infant {index + 1} Details</h4>
                                    <p><span>First Name: </span>{traveler.firstName}</p>
                                    <p><span>Last Name: </span>{traveler.lastName}</p>
                                    <p><span>Email: </span>{traveler.email}</p>
                                    <p><span>Phone: </span>{traveler.phone}</p>
                                    <p><span>Country: </span>{traveler.country}</p>
                                    <p><span>Passport No: </span>{traveler.passportNumber}</p>
                                    <p><span>Allergies & Dietary Requirements: </span>{traveler.allergies || 'None'}</p>
                                </div>
                            ))
                        }
                    </div>
                }
                {
                    !userTour?.travellersInfromation
                    &&
                    <Empty
                        description='You have not added any traveler details.'
                    />
                }
            </div>
            <Divider />
            <div className='heading'>
                <h2>
                    Emergency Contact Details
                </h2>
                <Button
                    type='primary'
                    onClick={() => setOpenAddEmergencyContactDrawer(true)}
                >
                    Add / Edit Emergency Contact
                </Button>
            </div>
            <div className='p_container'>
                <p><span>Name: </span>{userTour?.emergencyInfomation?.emergencyContactName || 'Add Emergency Contact Name'}</p>
                <p><span>Phone: </span>{userTour?.emergencyInfomation?.emergencyContactNumber || 'Add Emergency Contact Number'}</p>
                <p><span>Email: </span>{userTour?.emergencyInfomation?.emergencyEmail || 'Add Emergency Contact Email'}</p>
            </div>

            <Drawer
                title="Add Traveler Details"
                width={720}
                onClose={() => setOpenTravelerDetailsDrawer(false)}
                open={openTravelerDetailsDrawer}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
            >
                <Form
                    layout="vertical"
                    onFinish={handleAddTravelerDetails}
                    ref={travelerDetailsFormRef}
                >
                    <div className='travelers_wrapper'>
                        {
                            userTour?.travellers?.adults > 0 && Array.from({ length: userTour?.travellers?.adults }).map((_, index) => (
                                <div className='traveler'>
                                    <h4>Traveler {index + 1} Details - Adult</h4>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='First Name'
                                                    name={`adults[${index}].firstName`}
                                                    rules={[
                                                        { required: true, message: 'Please input your first name!' }
                                                    ]}
                                                >
                                                    <Input type='text' placeholder='John' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Last Name'
                                                    name={`adults[${index}].lastName`}
                                                    rules={[{ required: true, message: 'Please input your last name!' }]}
                                                >
                                                    <Input type='text' placeholder='Doe' />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Email'
                                                    name={`adults[${index}].email`}
                                                    rules={[{ required: true, message: 'Please input your email!' }, { type: 'email' }]}
                                                >
                                                    <Input placeholder='example@example.com' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Phone'
                                                    name={`adults[${index}].phone`}
                                                    rules={[
                                                        { required: true, message: 'Please input your phone!' },
                                                        { pattern: /^[0-9]{10}$/, message: 'Please input valid phone number!' },
                                                    ]}
                                                >
                                                    <Input type='tel' placeholder='0400000000' />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Country'
                                                    name={`adults[${index}].country`}
                                                    rules={[{ required: true, message: 'Please input your country!' }]}
                                                >
                                                    <Select
                                                        placeholder='Select your country'
                                                        allowClear
                                                    >
                                                        {
                                                            countryList.map((country) => (
                                                                <Select.Option value={country}>{country}</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Passport Number'
                                                    name={`adults[${index}].passportNumber`}
                                                    rules={
                                                        [
                                                            {
                                                                required: true,
                                                                message: 'Please input your passport number!',
                                                            },
                                                            {
                                                                pattern: /^[A-Z0-9]{9}$/,
                                                                message: 'Please input valid passport number!',
                                                            },
                                                        ]
                                                    }
                                                >
                                                    <Input placeholder='A12345678' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <Form.Item
                                                    label='Allergies & Dietary Requirements'
                                                    name={`adults[${index}].allergies`}
                                                    rules={[{ required: false }]}
                                                >
                                                    <Input.TextArea placeholder='Allergies & Dietary Requirements' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <div className='travelers_wrapper'>
                        {userTour?.travellers?.children > 0 && <Divider />}
                        {
                            userTour?.travellers?.children > 0 && Array.from({ length: userTour?.travellers?.children }).map((_, index) => (
                                <div className='traveler'>
                                    <h4>Traveler {index + 1} Details - Children</h4>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='First Name'
                                                    name={`children[${index}].firstName`}
                                                    rules={[
                                                        { required: true, message: 'Please input your first name!' }
                                                    ]}
                                                >
                                                    <Input type='text' placeholder='John' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Last Name'
                                                    name={`children[${index}].lastName`}
                                                    rules={[{ required: true, message: 'Please input your last name!' }]}
                                                >
                                                    <Input type='text' placeholder='Doe' />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Email'
                                                    name={`children[${index}].email`}
                                                    rules={[{ required: true, message: 'Please input your email!' }, { type: 'email' }]}
                                                >
                                                    <Input type='email' placeholder='example@example.com' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Phone'
                                                    name={`children[${index}].phone`}
                                                    rules={[
                                                        { required: true, message: 'Please input your phone!' },
                                                        { pattern: /^[0-9]{10}$/, message: 'Please input valid phone number!' },
                                                    ]}
                                                >
                                                    <Input type='tel' placeholder='0400000000' />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Country'
                                                    name={`children[${index}].country`}
                                                    rules={[{ required: true, message: 'Please input your country!' }]}
                                                >
                                                    <Select
                                                        placeholder='Select your country'
                                                        allowClear
                                                    >
                                                        {
                                                            countryList.map((country) => (
                                                                <Select.Option value={country}>{country}</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Passport Number'
                                                    name={`children[${index}].passportNumber`}
                                                    rules={
                                                        [
                                                            {
                                                                required: true,
                                                                message: 'Please input your passport number!',
                                                            },
                                                            {
                                                                pattern: /^[A-Z0-9]{9}$/,
                                                                message: 'Please input valid passport number!',
                                                            },
                                                        ]
                                                    }
                                                >
                                                    <Input placeholder='A12345678' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <Form.Item
                                                    label='Allergies & Dietary Requirements'
                                                    name={`children[${index}].allergies`}
                                                    rules={[{ required: false }]}
                                                >
                                                    <Input.TextArea placeholder='Allergies & Dietary Requirements' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <div className='travelers_wrapper'>
                        {userTour?.travellers?.infants > 0 && <Divider />}
                        {
                            userTour?.travellers?.infants > 0 && Array.from({ length: userTour?.travellers?.infants }).map((_, index) => (
                                <div className='traveler'>
                                    <h4>Traveler {index + 1} Details - Infant</h4>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='First Name'
                                                    name={`infants[${index}].firstName`}
                                                    rules={[
                                                        { required: true, message: 'Please input your first name!' }
                                                    ]}
                                                >
                                                    <Input type='text' placeholder='John' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Last Name'
                                                    name={`infants[${index}].lastName`}
                                                    rules={[{ required: true, message: 'Please input your last name!' }]}
                                                >
                                                    <Input type='text' placeholder='Doe' />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Email (Parent)'
                                                    name={`infants[${index}].email`}
                                                    rules={[{ required: true, message: 'Please input your email!' }, { type: 'email' }]}
                                                >
                                                    <Input type='email' placeholder='example@example.com' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Phone (Parent)'
                                                    name={`infants[${index}].phone`}
                                                    rules={[
                                                        { required: true, message: 'Please input your phone!' },
                                                        { pattern: /^[0-9]{10}$/, message: 'Please input valid phone number!' },
                                                    ]}
                                                >
                                                    <Input type='tel' placeholder='0400000000' />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Country'
                                                    name={`infants[${index}].country`}
                                                    rules={[{ required: true, message: 'Please input your email!' }]}
                                                >
                                                    <Select
                                                        placeholder='Select your country'
                                                        allowClear
                                                    >
                                                        {
                                                            countryList.map((country) => (
                                                                <Select.Option value={country}>{country}</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Passport Number (Parent)'
                                                    name={`infants[${index}].passportNumber`}
                                                    rules={
                                                        [
                                                            {
                                                                required: true,
                                                                message: 'Please input your passport number!',
                                                            },
                                                            {
                                                                pattern: /^[A-Z0-9]{9}$/,
                                                                message: 'Please input valid passport number!',
                                                            },
                                                        ]
                                                    }
                                                >
                                                    <Input placeholder='A12345678' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <Form.Item
                                                    label='Allergies & Dietary Requirements'
                                                    name={`infants[${index}].allergies`}
                                                    rules={[{ required: false }]}
                                                >
                                                    <Input.TextArea placeholder='Allergies & Dietary Requirements' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <Form.Item
                        name="terms_and_conditions"
                        valuePropName="checked"
                        rules={[{ required: true, message: 'Please accept the terms and conditions' }]}
                        style={{ marginTop: 10 }}
                    >
                        <div className='terms_and_conditions'>
                            <Checkbox >
                                Please check above details are correct before submitting.
                            </Checkbox>
                        </div>
                    </Form.Item>

                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loadingAddTraveler}
                        style={{ marginTop: 10 }}
                    >
                        Submit
                    </Button>
                </Form>
            </Drawer>

            <Drawer
                title="Add Emergency Contact"
                width={720}
                onClose={() => setOpenAddEmergencyContactDrawer(false)}
                open={openAddEmergencyContactDrawer}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
            >
                <Form
                    layout="vertical"
                    onFinish={handleAddEmergencyContact}
                    ref={emergencyContactFormRef}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Name'
                                name='name'
                                rules={[{ required: true, message: 'Please input your name!' }]}
                            >
                                <Input type='text' placeholder='John Doe' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Phone'
                                name='phone'
                                rules={[
                                    { required: true, message: 'Please input your phone!' },
                                    { pattern: /^[0-9]{10}$/, message: 'Please input valid phone number!' },
                                ]}
                            >
                                <Input type='tel' placeholder='0400000000' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Email'
                                name='email'
                                rules={[{ required: true, message: 'Please input your email!' }, { type: 'email' }]}
                            >
                                <Input
                                    type='email'
                                    placeholder='sample@sample.com' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loadingAddEmergencyContact}
                        style={{ marginTop: 10 }}
                    >
                        Submit
                    </Button>
                </Form>
            </Drawer>

            <Drawer
                title="Travelers Count"
                width={720}
                onClose={() => setOpenEditTravelerCountDrawer(false)}
                open={openEditTravelerCountDrawer}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
            >
                <Form
                    layout="vertical"
                    onFinish={handleEditTravelerCount}
                    ref={travelerCountFormRef}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Adults'
                                name='adults'
                            >
                                <Input type='number' min={0} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Children'
                                name='children'
                            >
                                <Input type='number' min={0} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Infants'
                                name='infants'
                            >
                                <Input type='number' min={0} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loadingEditTravelerCount}
                        style={{ marginTop: 10 }}
                    >
                        update
                    </Button>
                </Form>
            </Drawer>
        </div>
    )
}

export default TravelerDetails