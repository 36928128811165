import React, { useState } from 'react'
import { Button, Col, Drawer, Form, Input, Row, Spin, notification } from 'antd'
import { addTourInstalmentPlans } from '../../../../../../../redux/api/tourPackages.api';

const AddPackageInstalments = ({
    isVisible,
    setIsVisible,
    tourPackage,
    handleGetInstalmentPlans
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const onFinish = async (values) => {
        setIsLoading(true);
        try {
            Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

            values.packageId = tourPackage.id;

            const res = await addTourInstalmentPlans(values);

            if (res.status === 200) {
                api.success({
                    message: 'Success',
                    description: 'Package instalment added successfully'
                });
                setIsVisible(false);
                handleGetInstalmentPlans();
            } else {
                api.error({
                    message: 'Error',
                    description: 'An error occurred while adding the package instalment. Please try again.'
                });
            }

        } catch (error) {
            console.log(error);
            api.error({
                message: 'Error',
                description: `An error occurred while adding the package instalment. Please try again. ${error.message}`
            });
        }
        setIsLoading(false);
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Add Instalment"
                width={720}
                open={isVisible}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                onClose={() => setIsVisible(false)}
            >
                <Spin spinning={isLoading} tip='Please Wait...'>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="installmentType"
                                    label="Package Instalment Type"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the package instalment type',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Eg:- Monthly, Weekly, etc." />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="months"
                                    label="Number of Months/Weeks"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the number of months/weeks',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter the number of months/weeks" type='number' />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item>
                            <Button type='primary' htmlType='submit' loading={isLoading}>
                                Add Instalment
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default AddPackageInstalments