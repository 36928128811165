import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { AutoComplete, Button, Collapse, Empty, Form, Input, List, Select, Spin } from 'antd'
import { PlusOutlined, ExportOutlined } from '@ant-design/icons'
import { searchAccommodation } from '../../../../../redux/api/hotels.api';
import { accommodationTypes, hotelRoomTypes } from '../../../../Constants/itemTypes';
import { useDispatch } from 'react-redux';
import useDestinations from '../../../../hooks/useDestinations';
import { fetchDestinationNamesAC } from '../../../../../redux/actions/destinations/destinations.ac';

import './styles.scss'
import { fetchHotelsAC } from '../../../../../redux/actions/hotels/hotels.ac';
import useHotels from '../../../../hooks/useHotels';
import { DEFAULT_THUMBNAIL } from '../../../../Constants/otherConstsnts';
import ReactQuill from 'react-quill';

const { Search } = Input;

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const accommodationsOptions = Object.keys(accommodationTypes).map(key => {
    return {
        label: getOptionName(accommodationTypes[key]),
        value: key
    };
});

const roomsOptions = Object.keys(hotelRoomTypes).map(key => {
    return {
        label: getOptionName(hotelRoomTypes[key]),
        value: key
    };
});

const Hotels = () => {
    const [searchResults, setSearchResults] = useState([]);
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [page, setPage] = useState(1)
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const destinations = useDestinations()
    const destinationNames = destinations.destinationNames
    const hotels = useHotels().hotels
    const dispatch = useDispatch()
    const [filterValues, setFilterValues] = useState({})

    useEffect(() => {
        if (destinationNames?.length === 0) {
            dispatch(fetchDestinationNamesAC())
        }

        handleFetchHotels(1, {})
    }, [])

    const handleSearch = async (value) => {
        setIsSearchLoading(true)
        try {
            const response = await searchAccommodation(value)
            addSearchResult(response.data)
        } catch (error) {
            console.error('Search Error', error)
        }

        setIsSearchLoading(false)
    }

    const destinationOptions = Object.keys(destinationNames).map(key => {
        return {
            label: getOptionName(destinationNames[key].destinationName),
            value: destinationNames[key].id
        };
    });

    const addSearchResult = (accommodations) => {
        const results = accommodations.map(accommodation => {
            return {
                value: accommodation.id,
                label:
                    <div className='result-row' onClick={() => navigate(`/dashboard/partners/hotels/${accommodation.id}`)}>
                        <div className='result-row-info'>
                            <h3>{accommodation.accommodationName}</h3>
                            <p>
                                {accommodation.accommodationCity}
                            </p>
                        </div>
                    </div>
            }
        })
        setSearchResults(results)
    }

    const onChangeSearch = (value) => {
        if (value === '') {
            setSearchResults([])
        }

        if (value.length < 3) {
            return
        }

        handleSearch(value)
    }

    const handleFetchHotels = async (page, values) => {
        setLoading(true)
        values.page = page
        await dispatch(fetchHotelsAC(values))
        setLoading(false)
    }

    const onFilterSubmit = async (values) => {
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);
        setFilterValues(values)
        await handleFetchHotels(1, values)
    }

    return (
        <div className='accommodations'>
            <div className='accommodations__header'>
                <div className='left'>
                    <h1>
                        Accommodations
                    </h1>
                    <AutoComplete
                        dropdownClassName='certain-category-search-dropdown-destinations'
                        dropdownMatchSelectWidth={350}
                        style={{ width: 350 }}
                        options={searchResults}
                    >
                        <Search
                            placeholder="Search"
                            onSearch={handleSearch}
                            enterButton
                            loading={isSearchLoading}
                            onChange={(e) => onChangeSearch(e.target.value)}
                        />
                    </AutoComplete>
                </div>
                <div className='destinations__header__actions'>
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => navigate('/dashboard/partners/hotels/add')}>
                        Add Accommodation
                    </Button>
                </div>
            </div>

            <div className='filters'>
                <Collapse
                    items={[
                        {
                            key: '1',
                            label: 'Filters',
                            children:
                                <Form
                                    layout='inline'
                                    onFinish={onFilterSubmit}
                                >
                                    <Form.Item
                                        name='accommodationType'
                                    >
                                        <Select
                                            showSearchsa
                                            mode='single'
                                            allowClear
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Accommodation Type"
                                            options={accommodationsOptions}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name='nearestDestinations'
                                    >
                                        <Select
                                            showSearchsa
                                            mode='single'
                                            allowClear
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Nearest Destination"
                                            options={destinationOptions}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name='starRating'
                                    >
                                        <Input placeholder='Star Rating' type='number' max={5} min={1} style={{ width: '120px' }} />
                                    </Form.Item>
                                    <Form.Item
                                        name='roomType'
                                    >
                                        <Select
                                            showSearchsa
                                            mode='single'
                                            allowClear
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Room Type"
                                            options={roomsOptions}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type='primary' htmlType='submit' loading={loading}>
                                            Get Results
                                        </Button>
                                    </Form.Item>
                                </Form>
                        },
                    ]}
                />
            </div>

            <div className='destinations__list'>
                {hotels && hotels.length > 0 ?
                    <List
                        loading={loading}
                        pagination={{
                            position: 'bottom',
                            align: 'end',
                            pageSize: 5,
                            showSizeChanger: false,
                            total: hotels[0]?.filteredHotelsCount,
                            current: page,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                            onChange: (page) => { setPage(page); handleFetchHotels(page, filterValues) }
                        }}
                        dataSource={hotels}
                        renderItem={(item, index) => (
                            <List.Item key={index} className='destination__item'>
                                <div className='left'>
                                    <img src={item?.thumbnailUrl || DEFAULT_THUMBNAIL} alt='attraction' />
                                </div>
                                <div className='right'>
                                    <div className='destination__item__header'>
                                        <h3>{item?.accommodationName}</h3>
                                        <Button type='primary' onClick={() => navigate(`/dashboard/partners/hotels/${item.id}`)} icon={<ExportOutlined />}>
                                            View
                                        </Button>
                                    </div>
                                    <ReactQuill
                                        value={item?.accommodationDescriptionShort}
                                        readOnly={true}
                                        theme='bubble'
                                    />
                                    <p><strong>City: </strong>{item?.accommodationCity}</p>
                                    <p><strong>Type: </strong>{item?.accommodationType || 'N/A'}</p>
                                    <p><strong>Star Rating: </strong>{item?.starRating || 'N/A'}</p>
                                </div>
                            </List.Item>
                        )}
                    />
                    :
                    <>
                        {
                            loading ?
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '500px'
                                }}>
                                    <Spin size='large' />
                                </div>
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default Hotels