import { getAllTourGuides } from '../../api/guides.api';
import {
    FETCH_TOUR_GUIDES,
    FETCH_TOUR_GUIDES_SUCCESS,
    FETCH_TOUR_GUIDES_FAIL,

} from '../../types';

export const fetchGuidesAC = (values) => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_TOUR_GUIDES
        })
        try {
            const guidesResponse = await getAllTourGuides(values)
            dispatch({
                type: FETCH_TOUR_GUIDES_SUCCESS,
                payload: guidesResponse.data
            })
        } catch (error) {
            dispatch({
                type: FETCH_TOUR_GUIDES_FAIL,
                payload: error.message
            })
        }
    }
}