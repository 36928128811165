import { fetchAllAttractions, fetchAttractionsNames } from '../../api/attractions.api';
import {
    FETCH_ATTRACTIONS,
    FETCH_ATTRACTIONS_SUCCESS,
    FETCH_ATTRACTIONS_FAIL,

    FETCH_ATTRACTION_NAMES,
    FETCH_ATTRACTION_NAMES_SUCCESS,
    FETCH_ATTRACTION_NAMES_FAIL
} from '../../types';

export const fetchAttractionsAC = (values) => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_ATTRACTIONS
        })
        try {

            const attractionsResponse = await fetchAllAttractions(values)

            dispatch({
                type: FETCH_ATTRACTIONS_SUCCESS,
                payload: attractionsResponse.data
            })
        } catch (error) {
            dispatch({
                type: FETCH_ATTRACTIONS_FAIL,
                payload: error.message
            })
        }
    }
}

export const fetchAttractionNamesAC = () => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_ATTRACTION_NAMES
        })
        try {
            const response = await fetchAttractionsNames()
            dispatch({
                type: FETCH_ATTRACTION_NAMES_SUCCESS,
                payload: response.data
            })
        } catch (error) {
            dispatch({
                type: FETCH_ATTRACTION_NAMES_FAIL,
                payload: error.message
            })
        }
    }
}