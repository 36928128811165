// Login
export const FETCH_PROFILE = '@user/data/fetch';
export const FETCH_PROFILE_SUCCESS = '@user/data/success';
export const FETCH_PROFILE_FAIL = '@user/data/failed';

// Users 
export const FETCH_USERS = '@users/data/fetch';
export const FETCH_USERS_SUCCESS = '@users/data/success';
export const FETCH_USERS_FAIL = '@users/data/failed';

// User types
export const CHANGE_USER_TYPE = '@user/type/change';
export const CHANGE_USER_TYPE_SUCCESS = '@user/type/success';
export const CHANGE_USER_TYPE_FAIL = '@user/type/failed';

// Destinations
export const FETCH_DESTINATIONS = '@destinations/data/fetch';
export const FETCH_DESTINATIONS_SUCCESS = '@destinations/data/success';
export const FETCH_DESTINATIONS_FAIL = '@destinations/data/failed';

// Destination Name & ID
export const FETCH_DESTINATION_NAMES = '@destination/name/fetch';
export const FETCH_DESTINATION_NAMES_SUCCESS = '@destination/name/success';
export const FETCH_DESTINATION_NAMES_FAIL = '@destination/name/failed';

// Attractions
export const FETCH_ATTRACTIONS = '@attractions/data/fetch';
export const FETCH_ATTRACTIONS_SUCCESS = '@attractions/data/success';
export const FETCH_ATTRACTIONS_FAIL = '@attractions/data/failed';

// Attraction Name & ID
export const FETCH_ATTRACTION_NAMES = '@attraction/name/fetch';
export const FETCH_ATTRACTION_NAMES_SUCCESS = '@attraction/name/success';
export const FETCH_ATTRACTION_NAMES_FAIL = '@attraction/name/failed';

// Activities
export const FETCH_ACTIVITIES = '@activities/data/fetch';
export const FETCH_ACTIVITIES_SUCCESS = '@activities/data/success';
export const FETCH_ACTIVITIES_FAIL = '@activities/data/failed';

// Activity Name & ID
export const FETCH_ACTIVITY_NAMES = '@activity/name/fetch';
export const FETCH_ACTIVITY_NAMES_SUCCESS = '@activity/name/success';
export const FETCH_ACTIVITY_NAMES_FAIL = '@activity/name/failed';

// Events
export const FETCH_EVENTS = '@events/data/fetch';
export const FETCH_EVENTS_SUCCESS = '@events/data/success';
export const FETCH_EVENTS_FAIL = '@events/data/failed';

// Event Name & ID
export const FETCH_EVENT_NAMES = '@event/name/fetch';
export const FETCH_EVENT_NAMES_SUCCESS = '@event/name/success';
export const FETCH_EVENT_NAMES_FAIL = '@event/name/failed';

// Hotels
export const FETCH_HOTELS = '@hotels/data/fetch';
export const FETCH_HOTELS_SUCCESS = '@hotels/data/success';
export const FETCH_HOTELS_FAIL = '@hotels/data/failed';

// Partners
export const FETCH_PARTNERS = '@partners/data/fetch';
export const FETCH_PARTNERS_SUCCESS = '@partners/data/success';
export const FETCH_PARTNERS_FAIL = '@partners/data/failed';

// Transports
export const FETCH_TRANSPORTS = '@transports/data/fetch';
export const FETCH_TRANSPORTS_SUCCESS = '@transports/data/success';
export const FETCH_TRANSPORTS_FAIL = '@transports/data/failed';

// Tour Guides
export const FETCH_TOUR_GUIDES = '@tour-guides/data/fetch';
export const FETCH_TOUR_GUIDES_SUCCESS = '@tour-guides/data/success';
export const FETCH_TOUR_GUIDES_FAIL = '@tour-guides/data/failed';

// Tour Packages
export const FETCH_TOUR_PACKAGES = '@tour-packages/data/fetch';
export const FETCH_TOUR_PACKAGES_SUCCESS = '@tour-packages/data/success';
export const FETCH_TOUR_PACKAGES_FAIL = '@tour-packages/data/failed';

// Notifications
export const FETCH_NEW_NOTIFICATIONS_COUNT = '@notifications/new/fetch';
export const FETCH_NEW_NOTIFICATIONS_COUNT_SUCCESS = '@notifications/new/success';
export const FETCH_NEW_NOTIFICATIONS_COUNT_FAIL = '@notifications/new/failed';