import {
    FETCH_EVENTS,
    FETCH_EVENTS_SUCCESS,
    FETCH_EVENTS_FAIL,

    FETCH_EVENT_NAMES,
    FETCH_EVENT_NAMES_SUCCESS,
    FETCH_EVENT_NAMES_FAIL
} from '../../types';

const initialState = {
    loading: false,
    events: [],
    eventNames: [],
    error: null
};

export const eventsReducer = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case FETCH_EVENTS:
            return {
                ...state,
                loading: true
            };
        case FETCH_EVENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                events: action.payload,
                error: null
            };
        case FETCH_EVENTS_FAIL:
            return {
                ...state,
                loading: false,
                events: [],
                error: action.payload
            };
        case FETCH_EVENT_NAMES:
            return {
                ...state,
                loading: true
            };
        case FETCH_EVENT_NAMES_SUCCESS:
            return {
                ...state,
                loading: false,
                eventNames: action.payload,
                error: null
            };
        case FETCH_EVENT_NAMES_FAIL:
            return {
                ...state,
                loading: false,
                eventNames: [],
                error: action.payload
            }

        default:
            return state;
    }
}