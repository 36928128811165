import { Button, Calendar, notification, Spin } from 'antd';
import React, { useEffect, useState } from 'react';

import './styles.scss';
import { CloseCircleOutlined } from '@ant-design/icons';
import { addTourGuideSchedule, getGuideByUserId, getTourGuideScheduleById, updateTourGuideSchedule } from '../../../../../../../redux/api/guides.api';
import useMyProfile from '../../../../../../hooks/useMyProfile';

const GuideSchedule = () => {
    const [selectedDates, setSelectedDates] = useState([]);
    const [currentSelectedOnDb, setCurrentSelectedOnDb] = useState([]);
    const [scheduleId, setScheduleId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [guide, setGuide] = useState(null);
    const [api, contextHolder] = notification.useNotification();
    const myProfile = useMyProfile().userProfile

    useEffect(() => {
        handleGetGuide(myProfile?.id)
    }, []);

    useEffect(() => {
        if (guide) {
            handleGetGuideSchedule()
        }
    }, [guide]);

    useEffect(() => {
        if (currentSelectedOnDb) {
            setSelectedDates(currentSelectedOnDb)
        }
    }, [currentSelectedOnDb]);

    const handleGetGuide = async (id) => {
        setIsLoading(true)
        try {
            const guide = await getGuideByUserId(myProfile?.id)
            setGuide(guide.data)
        } catch (error) {
            console.error('Error fetching transport package', error)
        }
        setIsLoading(false)
    }

    const handleGetGuideSchedule = async () => {
        setIsLoading(true)
        try {
            const sechdule = await getTourGuideScheduleById(guide?.id)

            if (sechdule?.data.length === 0) {
                await handleCreateSchedule()
                return;
            }

            setCurrentSelectedOnDb(sechdule?.data[0]?.schedule?.split(',') || [])
            setScheduleId(sechdule?.data[0]?.id)
        } catch (error) {
            console.error('Error fetching transport package', error)
        }
        setIsLoading(false)
    }

    const handleCreateSchedule = async () => {
        setIsLoading(true)
        try {
            const values = {
                guideId: guide?.id,
                schedule: selectedDates?.join(','),
            }

            const res = await addTourGuideSchedule(values)
            if (res.status === 200) {
                api.success({
                    message: 'Sucess',
                    description: 'Schedule created successfully',
                })
                await handleGetGuideSchedule()
            } else {
                api.error({
                    message: 'Error',
                    description: 'Error creating schedule'
                })
            }

        } catch (error) {
            console.error('Error creating schedule package', error)
            api.error({
                message: 'Error',
                description: `Error creating schedule: ${error}`
            })
        }

        setIsLoading(false)
    }

    const onSelect = (date) => {
        const formattedDate = date.format('YYYY-MM-DD');
        const isSelected = selectedDates.includes(formattedDate);

        if (isSelected) {
            setSelectedDates(selectedDates.filter((d) => d !== formattedDate));
        }
        else {
            setSelectedDates([...selectedDates, formattedDate]);
        }
    };


    const dateCellRender = (value) => {
        const formattedDate = value.format('YYYY-MM-DD');
        const isSelected = selectedDates.includes(formattedDate);
        const isCurrentSelectedOnDb = currentSelectedOnDb.includes(formattedDate);

        return isCurrentSelectedOnDb ? (
            <CloseCircleOutlined
                style={{
                    color: '#ffaaaa',
                    fontSize: '25px',
                }}
            />
        ) :
            isSelected ? (
                <CloseCircleOutlined
                    style={{
                        color: '#ff1f1f',
                        fontSize: '25px',
                    }}
                />
            ) :
                <CloseCircleOutlined
                    style={{
                        color: 'transparent',
                        fontSize: '25px',
                    }}
                />
            ;
    };

    const handleSave = async () => {
        setIsLoading(true)
        try {
            const values = {
                id: scheduleId,
                schedule: selectedDates?.join(','),
            }

            const res = await updateTourGuideSchedule(values)
            if (res.status === 200) {
                api.success({
                    message: 'Sucess',
                    description: 'Schedule updated successfully',
                })
                await handleGetGuideSchedule()
            } else {
                api.error({
                    message: 'Error',
                    description: 'Error updating schedule'
                })
            }

        } catch (error) {
            console.error('Error fetching transport package', error)
            api.error({
                message: 'Error',
                description: `Error updating schedule: ${error}`
            })
        }

        setIsLoading(false)
    };

    const handleIgnoreChanges = () => {
        setSelectedDates(currentSelectedOnDb)
    }

    return (
        <div className='destinations'>
            {contextHolder}
            <div className='destinations__header'>
                <div className='left'
                    style={{
                        display: 'block'
                    }}
                >
                    <h1>My Schedule</h1>
                    <p>
                        Please keep your schedule up to date to avoid any conflicts with bookings.
                    </p>
                </div>
            </div>

            <div className='destinations__list'>
                <Spin spinning={isLoading} tip='Loading...'>
                    <div className='show_calendar'>
                        <div className='calendar'>
                            <Calendar
                                fullscreen={false}
                                onSelect={onSelect}
                                dateCellRender={dateCellRender}
                                style={{
                                    border: '1px solid #f0f0f0',
                                }}
                            />
                        </div>

                        <div className='guideence'>
                            <p>
                                <CloseCircleOutlined
                                    style={{
                                        color: '#ff1f1f',
                                        fontSize: '25px',
                                    }}
                                />  - Newly selected date
                            </p>

                            <p>
                                <CloseCircleOutlined
                                    style={{
                                        color: '#ffaaaa',
                                        fontSize: '25px',
                                    }}
                                /> - Currently selected date
                            </p>

                        </div>

                        <div className='actions'>
                            <Button onClick={handleIgnoreChanges}>
                                Ignore Changes
                            </Button>
                            <Button
                                type='primary'
                                onClick={handleSave}
                            >
                                Save Changes
                            </Button>
                        </div>
                    </div>
                </Spin>
            </div>
        </div>
    )
}

export default GuideSchedule;
