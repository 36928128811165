import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'

const pleaseWait = ({
    screenSize = 'full',
    message = 'Please wait...',
    loading = true,
}) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: screenSize === 'full' ? '100vh' : 'auto',
                width: '100%',
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 9999,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <LoadingOutlined
                    style={{
                        fontSize: '3rem',
                        color: '#1890ff',
                    }}
                />
                <p
                    style={{
                        fontSize: '1.5rem',
                        color: '#1890ff',
                        marginTop: '1rem',
                    }}
                >{message}</p>
            </div>
        </div>
    )
}

export default pleaseWait