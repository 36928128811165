import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';

import Highlighter from 'react-highlight-words';
import { Button, Input, Space, Table, Tag } from 'antd'

import { SearchOutlined } from '@ant-design/icons'
import { ADMIN, CUSTOMER_RELATIONS_MANAGER, MANAGER, PARTNERSHIP_MANAGER, SUPER_ADMIN, SUPPORT_AGENT, TOUR_GUIDE, TRIP_COORDINATOR, USER } from '../../../../Constants/roles';

import './styles.scss'
import { ACTIVE, INACTIVE, PENDING } from '../../../../Constants/activeStatusType';

const UserTable = ({
    users,
    handleFetchUsers,
    loading,
    page,
    setPage,
    type,
}) => {
    const [searchText, setSearchText] = useState('');
    const [tableData, setTableData] = useState([]);
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    useEffect(() => {
        if (users?.length > 0) {
            const data = users?.map((user) => {
                return {
                    key: user.id,
                    userName: user.userName,
                    id: user.id,
                    role: user.role,
                    email: user.email,
                    activeStatus: user.activeStatus,
                }
            })
            setTableData(data)
        } else {
            setTableData([])
        }
    }, [users])

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const getNameSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text, record) =>
            searchedColumn === dataIndex ? (
                <Link to={`/dashboard/users/${type}/${record.id}`}>
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                </Link>
            ) : (
                <Link to={`/dashboard/users/${type}/${record.id}`}>
                    <p
                        style={{
                            color: '#9373ff',
                            cursor: 'pointer',
                        }}
                    >
                        {text}
                    </p>
                </Link>
            ),
    });

    const getRoleName = (text) => {
        switch (text) {
            case SUPER_ADMIN:
                return 'Super Admin';
            case ADMIN:
                return 'Admin';
            case MANAGER:
                return 'Manager';
            case PARTNERSHIP_MANAGER:
                return 'Partnership Manager';
            case CUSTOMER_RELATIONS_MANAGER:
                return 'Customer Relations Manager';
            case SUPPORT_AGENT:
                return 'Support Agent';
            case TRIP_COORDINATOR:
                return 'Trip Coordinator';
            case USER:
                return 'User';
            case TOUR_GUIDE:
                return 'Tour Guide';
            default:
                return 'Unknown';
        }
    };

    const columns = [
        {
            title: 'User Name',
            dataIndex: 'userName',
            key: 'userName',
            width: '30%',
            ...getNameSearchProps('userName'),
        },
        {
            title: 'User ID',
            dataIndex: 'id',
            key: 'id',
            width: '20%',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            width: '15%',
            filters: [
                {
                    text: 'Super Admin',
                    value: SUPER_ADMIN,
                },
                {
                    text: 'Admin',
                    value: ADMIN,
                },
                {
                    text: 'Manager',
                    value: MANAGER,
                },
                {
                    text: 'Partnership Manager',
                    value: PARTNERSHIP_MANAGER,
                },
                {
                    text: 'Customer Relations Manager',
                    value: CUSTOMER_RELATIONS_MANAGER,
                },
                {
                    text: 'Support Agent',
                    value: SUPPORT_AGENT,
                },
                {
                    text: 'Trip Coordinator',
                    value: TRIP_COORDINATOR,
                },
                {
                    text: 'User',
                    value: USER,
                }
            ],
            onFilter: (value, record) => record.role.indexOf(value) === 0,
            render: (text) => {
                let color = 'geekblue';
                if (text === SUPER_ADMIN) {
                    color = 'volcano';
                } else if (text === ADMIN) {
                    color = 'green';
                } else if (text === MANAGER) {
                    color = 'blue';
                } else if (text === PARTNERSHIP_MANAGER) {
                    color = 'gold';
                } else if (text === CUSTOMER_RELATIONS_MANAGER) {
                    color = 'magenta';
                } else if (text === SUPPORT_AGENT) {
                    color = 'red';
                } else if (text === TRIP_COORDINATOR) {
                    color = 'cyan';
                } else if (text === USER) {
                    color = 'purple';
                }
                return (
                    <Tag color={color} key={text}>
                        {
                            getRoleName(text)
                        }
                    </Tag>
                );
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '20%',
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Active Status',
            dataIndex: 'activeStatus',
            key: 'activeStatus',
            width: '15%',
            filters: [
                {
                    text: 'Active',
                    value: ACTIVE,
                },
                {
                    text: 'Inactive',
                    value: INACTIVE,
                },
                {
                    text: 'Pending',
                    value: PENDING
                },
                {
                    text: 'Blocked',
                    value: 'BLOCKED'
                }
            ],
            onFilter: (value, record) => record.activeStatus.indexOf(value) === 0,
            render: (text) => {
                let color = 'geekblue';
                if (text === ACTIVE) {
                    color = 'green';
                } else if (text === INACTIVE) {
                    color = 'volcano';
                } else if (text === PENDING) {
                    color = 'gold';
                } else if (text === 'BLOCKED') {
                    color = 'red';
                }
                return (
                    <Tag color={color} key={text}>
                        {text?.toUpperCase()}
                    </Tag>
                );
            }
        }
    ];

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    return (
        <div className='users-table'>
            <Table
                className='table'
                loading={loading}
                columns={columns}
                dataSource={tableData}
                pagination={{
                    pageSize: 10,
                    position: ["bottomRight"],
                    current: page,
                    total: users[0]?.usersCount,
                    onChange: (page) => {
                        setPage(page)
                        handleFetchUsers(page, type)
                    },
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
            />
        </div>
    )
}

export default UserTable