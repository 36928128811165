import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Drawer, Form, Input, Row, Spin, notification } from 'antd'
import { editAccommodationOffer } from '../../../../../../../redux/api/hotels.api';
import ReactQuill from 'react-quill';


const quillModules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
        ["formula"],
        [{ size: ["small", false, "large", "huge"] }],
    ],
    clipboard: {
        matchVisual: false
    },
    imageResize: {
        modules: ['Resize', 'DisplaySize']
    }
};

const EditOffer = ({
    editOfferVisible,
    setEditOfferVisible,
    selectedEditOffer,
    hotel,
    handleGetOffers,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const formRef = useRef();

    useEffect(() => {
        if (selectedEditOffer && formRef?.current) {
            formRef.current.setFieldsValue({
                name: selectedEditOffer.name,
                description: selectedEditOffer.description,
                startDate: selectedEditOffer.startDate,
                endDate: selectedEditOffer.endDate,
                amount: selectedEditOffer.amount,
            });
        }
    }, [selectedEditOffer]);

    const onFinish = async (values) => {
        setIsLoading(true);
        try {
            Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

            const updatedOffer = {
                id: selectedEditOffer.id,
                ...values
            }

            const response = await editAccommodationOffer(updatedOffer

            );
            if (response.status === 200) {
                api.success({
                    message: 'Success',
                    description: 'Offer updated successfully'
                });
                handleGetOffers();
                setTimeout(() => {
                    setEditOfferVisible(false);
                }, 2000);

            } else {
                api.error({
                    message: 'Error',
                    description: 'An error occurred while updating the offer. Please try again.'
                });
            }

        } catch (error) {
            console.log(error);
            api.error({
                message: 'Error',
                description: `An error occurred while updating the offer. Please try again. ${error.message}`
            });
        }
        setIsLoading(false);
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Edit an offer"
                width={720}
                open={editOfferVisible}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                onClose={() => setEditOfferVisible(false)}
            >
                <Spin spinning={isLoading} tip='Please Wait...'>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        ref={formRef}
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="name"
                                    label="Offer Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please add a name for the offer',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please add a name for the offer" showCount maxLength={50} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="description"
                                    label="Offer Description (Max 1000 characters)"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Add a description for the offer',
                                        },
                                    ]}
                                >
                                    <ReactQuill
                                        theme="snow"
                                        limits={{ maxLength: 500 }}
                                        modules={quillModules}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="startDate"
                                    label="Offer Start Date"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the start date of the offer',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter the start date of the offer" type='date' />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="endDate"
                                    label="Offer End Date"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the end date of the offer',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter the end date of the offer" type='date' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="amount"
                                    label="Offer Percentage"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the offer percentage',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter the offer percentage" type='number' min={1} max={100} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Button type='primary' htmlType='submit' loading={isLoading}>
                                Update Offer
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default EditOffer