import React, { useEffect, useState } from 'react'

import './styles.scss'
import { useDispatch } from 'react-redux';
import useMyProfile from '../../../hooks/useMyProfile';
import { getNotificationsByUser, messageMarkAsRead } from '../../../../redux/api/messages.api';
import { fetchNewNotificationCountAC } from '../../../../redux/actions/notifications/notifications.ac';
import { Badge, Button, Empty, Spin } from 'antd';
import ReactQuill from 'react-quill';
import { CheckCircleOutlined } from '@ant-design/icons';

const Inbox = () => {
    const myProfile = useMyProfile();
    const [messages, setMessages] = useState([]);
    const [loadingMessages, setLoadingMessages] = useState(false);
    const [loadingMarkAsRead, setLoadingMarkAsRead] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        if (myProfile?.userProfile?.id) {
            fetchMessages();
        }
    }, [myProfile]);

    const fetchMessages = async () => {
        setLoadingMessages(true);
        try {
            const response = await getNotificationsByUser(myProfile?.userProfile?.id);
            setMessages(response.data);
        } catch (error) {
            console.log('ERROR FETCHING MESSAGES', error);
        }
        setLoadingMessages(false);
    }

    const markAsRead = async (messageId) => {
        setLoadingMarkAsRead(messageId);
        try {
            const response = await messageMarkAsRead(messageId);
            if (response.status === 200) {
                fetchMessages();
                dispatch(fetchNewNotificationCountAC(myProfile?.userProfile?.id));
            }
        } catch (error) {
            console.log('ERROR MARK AS READ', error);
        }
        setLoadingMarkAsRead(null);
    }

    return (
        <div className='messages'>
            <h1 className='messages__title'>Inbox</h1>

            <div className='messages_body'>
                {
                    loadingMessages && <div className='loading_container'>
                        <Spin
                            size='large'
                            tip='Loading messages...'
                        />
                    </div>
                }
                {
                    !loadingMessages && messages.length === 0 && <div className='no_messages'>
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                                <span>
                                    No messages
                                </span>
                            }
                        />
                    </div>
                }
                {
                    !loadingMessages && messages.length > 0 && messages.map((message, index) => {
                        return (
                            <Badge.Ribbon
                                text='New'
                                color='red'
                                key={index}
                                style={{
                                    display: message.notificationStatus === 'read' ? 'none' : 'block'
                                }}
                            >
                                <div
                                    className={`message ${message.notificationStatus}`}
                                    key={index}
                                >
                                    <div className='message__header'>
                                        <h3 >
                                            {message.notificationTitle}
                                        </h3>
                                        <p className='time'>
                                            {message.createdAt}
                                        </p>
                                    </div>
                                    <div className='message__content'>
                                        <ReactQuill
                                            value={message.notificationBody}
                                            readOnly={true}
                                            theme='bubble'
                                        />
                                    </div>
                                    {
                                        message.notificationStatus === 'unread' &&
                                        <div className='message__footer'>
                                            <Button
                                                type='primary'
                                                onClick={() => {
                                                    markAsRead(message.id);
                                                }}
                                                loading={
                                                    loadingMarkAsRead === message.id
                                                }
                                            >
                                                <CheckCircleOutlined />
                                                Mark as read
                                            </Button>
                                        </div>}
                                </div>
                            </Badge.Ribbon>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Inbox