import React, { useState } from 'react'
import { DEFAULT_PROFILE_IMAGE } from '../../../../../Constants/otherConstsnts'
import { Badge, Divider, Tag } from 'antd'

import { ADMIN, CUSTOMER_RELATIONS_MANAGER, HOTEL_MANAGER, MANAGER, PARTNERSHIP_MANAGER, SUPER_ADMIN, SUPPORT_AGENT, TOUR_GUIDE, TRANSPORT_AGENT, TRIP_COORDINATOR, USER } from '../../../../../Constants/roles'

import './styles.scss'

const UserDetails = ({ userData }) => {

    const getRoleName = (text) => {
        switch (text) {
            case SUPER_ADMIN:
                return 'Super Admin';
            case ADMIN:
                return 'Admin';
            case MANAGER:
                return 'Manager';
            case PARTNERSHIP_MANAGER:
                return 'Partnership Manager';
            case CUSTOMER_RELATIONS_MANAGER:
                return 'Customer Relations Manager';
            case SUPPORT_AGENT:
                return 'Support Agent';
            case TRIP_COORDINATOR:
                return 'Trip Coordinator';
            case USER:
                return 'User';
            case TOUR_GUIDE:
                return 'Tour Guide';
            case TRANSPORT_AGENT:
                return 'Transport Agent';
            case HOTEL_MANAGER:
                return 'Hotel Manager'
            default:
                return 'Unknown';
        }
    };

    const getRoleColor = (text) => {
        let color = 'geekblue';
        if (text === SUPER_ADMIN) {
            color = 'volcano';
        } else if (text === ADMIN) {
            color = 'green';
        } else if (text === MANAGER) {
            color = 'blue';
        } else if (text === PARTNERSHIP_MANAGER) {
            color = 'gold';
        } else if (text === CUSTOMER_RELATIONS_MANAGER) {
            color = 'magenta';
        } else if (text === SUPPORT_AGENT) {
            color = 'red';
        } else if (text === TRIP_COORDINATOR) {
            color = 'cyan';
        } else if (text === USER) {
            color = 'purple';
        }
        return color;
    }

    const getUserStatus = () => {
        switch (userData?.activeStatus) {
            case 'ACTIVE':
                return 'Active';
            case 'INACTIVE':
                return 'Inactive';
            case 'PENDING':
                return 'Pending';
            case 'SUSPENDED':
                return 'Suspended';
            case 'DELETED':
                return 'Deleted';
            case 'BLOCKED':
                return 'Blocked';
            case 'REJECTED':
                return 'Rejected';
            default:
                return 'Unknown';
        }
    }

    return (
        <div className='user__details'>
            <div className='user__details__header'>
                <Badge
                    count={getUserStatus()}
                    color={userData?.activeStatus === 'ACTIVE' ? 'green' : 'red'}
                >
                    <img src={userData?.profilePicUrl || DEFAULT_PROFILE_IMAGE} alt='profile' />
                </Badge>
                <div className='user__details__header__info'>
                    <h3>{userData?.userName}</h3>
                    <p>{userData?.email}</p>
                    <Tag
                        color={getRoleColor(userData?.role)}
                    >
                        {getRoleName(userData?.role)}
                    </Tag>
                </div>
            </div>
            <Divider />
            <div className='user__details__body'>
                <div className='user__details__body__info__left'>
                    <h3>Personal Information</h3>
                    <p><strong>User ID:</strong> {userData?.id || 'N/A'}</p>
                    <p><strong>First Name:</strong> {userData?.firstName || 'N/A'}</p>
                    <p><strong>Last Name:</strong> {userData?.lastName || 'N/A'}</p>
                    <p><strong>Phone:</strong> {userData?.contactNumber || 'N/A'}</p>
                    <p><strong>Date of Birth:</strong> {userData?.dateOfBirth || 'N/A'}</p>
                    <p><strong>Socail Name:</strong> {userData?.socialName || 'N/A'}</p>
                </div>
                <div className='user__details__body__info__right'>
                    <h3>Other Information</h3>
                    <p><strong>Joined date:</strong> {userData?.createdAt || 'N/A'}</p>
                    <p><strong>Identity Provider:</strong> {userData?.identityProvider || 'N/A'}</p>
                    {userData?.staffId && <p><strong>Staff ID:</strong> {userData?.staffId || 'N/A'}</p>}
                    <p><strong>Last Update:</strong> {userData?.updatedAt || 'N/A'}</p>
                </div>
            </div>
            <Divider />

            <div className='user__details__body'>
                <div className='user__details__body__info__left'>
                    <h3>User Favourites</h3>
                    <p><strong>Accommodation Type/s: </strong>
                        {
                            userData?.favouriteExperienceType ? userData?.favouriteExperienceType?.split(',')?.map((item, index) => (
                                <Tag key={index}>{item}</Tag>
                            ))
                                : 'N/A'
                        }
                    </p>
                    <p><strong>Favourite Experience Type/s: </strong>
                        {
                            userData?.favouriteExperienceType ? userData?.favouriteExperienceType?.split(',')?.map((item, index) => (
                                <Tag key={index}>{item}</Tag>
                            ))
                                : 'N/A'
                        }
                    </p>
                    <p><strong>Favourite Travel Type/s: </strong>
                        {
                            userData?.favouriteTravelTypes ? userData?.favouriteTravelTypes?.split(',')?.map((item, index) => (
                                <Tag key={index}>{item}</Tag>
                            ))
                                : 'N/A'
                        }
                    </p>
                </div>
            </div>

            <Divider />
            <div className='user__confedentials__body'>
                <h3>User Confedential Information </h3>
                <div className='user__confedentials__body__unlocked'>
                    <div className='left'>
                        <p><strong>Nationality:</strong> {userData?.nationality || 'N/A'}</p>
                        <p><strong>Gender:</strong> {userData?.gender || 'N/A'}</p>
                        <p><strong>Address:</strong>
                            {userData?.address ? userData?.address + ', ' : <span>N/A,</span>}
                            {userData?.addressCity ? userData?.addressCity + ', ' : <span>N/A,</span>}
                            {userData?.addressRegion ? userData?.addressRegion + ', ' : <span>N/A,</span>}
                            {userData?.addressZip ? userData?.addressZip + '.' : <span>N/A.</span>}
                        </p>
                    </div>
                    <div className='right'>
                        <p><strong>Passport First Name:</strong> {userData?.passportFName || 'N/A'}</p>
                        <p><strong>Passport Lats Name:</strong> {userData?.passportLName || 'N/A'}</p>
                        <p><strong>Issuing Country:</strong> {userData?.passportIssueingCountry || 'N/A'}</p>
                        <p><strong>Passport Number:</strong> {userData?.passportNumber || 'N/A'}</p>
                        <p><strong>Expiry Date:</strong> {userData?.passportExp || 'N/A'}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserDetails