import axios from 'axios';

import { API_BASE_URL } from "../../App/azure/api";
import { getAccessToken } from '../../App/azure/auth';
import { msalInstance } from '../../App/azure/authConfig';

export const getNotificationsByUser = async (id) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getNotificationsByUser?id=${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const messageMarkAsRead = async (id) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/messageMarkAsRead?id=${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        return error;
    }
}

export const getUnreadMessageCount = async (id) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getUnreadMessageCount?id=${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}