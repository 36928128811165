import { Button, Calendar, notification, Spin } from 'antd';
import React, { useEffect, useState } from 'react';

import './styles.scss';
import { CloseCircleOutlined } from '@ant-design/icons';
import { addTourGuideSchedule, getTourGuideScheduleById, updateTourGuideSchedule } from '../../../../../../../redux/api/guides.api';

const GuideScheduleAdmin = ({ guide }) => {
    const [selectedDates, setSelectedDates] = useState([]);
    const [currentSelectedOnDb, setCurrentSelectedOnDb] = useState([]);
    const [scheduleId, setScheduleId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (guide) {
            handleGetGuideSchedule()
        }
    }, [guide]);

    useEffect(() => {
        if (currentSelectedOnDb) {
            setSelectedDates(currentSelectedOnDb)
        }
    }, [currentSelectedOnDb]);


    const handleGetGuideSchedule = async () => {
        setIsLoading(true)
        try {
            const sechdule = await getTourGuideScheduleById(guide?.id)

            setCurrentSelectedOnDb(sechdule?.data[0]?.schedule?.split(',') || [])
            setScheduleId(sechdule?.data[0]?.id)
        } catch (error) {
            console.error('Error fetching transport package', error)
        }
        setIsLoading(false)
    }


    const dateCellRender = (value) => {
        const formattedDate = value.format('YYYY-MM-DD');
        const isSelected = selectedDates.includes(formattedDate);
        const isCurrentSelectedOnDb = currentSelectedOnDb.includes(formattedDate);

        return isCurrentSelectedOnDb ? (
            <CloseCircleOutlined
                style={{
                    color: '#ff1f1f',
                    fontSize: '25px',
                }}
            />
        ) :
            isSelected ? (
                <CloseCircleOutlined
                    style={{
                        color: '#ff1f1f',
                        fontSize: '25px',
                    }}
                />
            ) :
                <CloseCircleOutlined
                    style={{
                        color: 'transparent',
                        fontSize: '25px',
                    }}
                />
            ;
    };


    return (
        <div className='destinations'>
            <div className='destinations__list'>
                <Spin spinning={isLoading} tip='Loading...'>
                    <div className='show_calendar'>
                        <div className='calendar'>
                            <Calendar
                                fullscreen={false}
                                dateCellRender={dateCellRender}
                                style={{
                                    border: '1px solid #f0f0f0',
                                }}
                            />
                        </div>

                        <div className='guideence'>

                            <p>
                                <CloseCircleOutlined
                                    style={{
                                        color: '#ff1f1f',
                                        fontSize: '25px',
                                    }}
                                /> - Tour Guide Not Available
                            </p>

                        </div>
                    </div>
                </Spin>
            </div>
        </div>
    )
}

export default GuideScheduleAdmin;
