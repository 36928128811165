import { DeleteOutlined, EditFilled } from '@ant-design/icons'
import { Button, Divider, Form, message, Popconfirm, Select, Tag } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { PENDING, PUBLISHED, SUSPENDED } from '../../../../Constants/activeStatusType'

import './styles.scss'
import { changeBlogStatus, deleteBlog, getBlogById } from '../../../../../redux/api/blogs.api'
import LoadingComp from '../../../../components/LoadingComp'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import moment from 'moment'
import { DEFAULT_PROFILE_IMAGE, DEFAULT_PROFILE_IMAGE_VISIT_PEARL } from '../../../../Constants/otherConstsnts'
import ReactQuill from 'react-quill'
import { PhotoProvider } from 'react-photo-view'
import { useNavigate } from 'react-router-dom'

const { Option } = Select

const Blog = () => {
    const [loading, setLoading] = useState(true)
    const [laodingDelete, setLoadingDelete] = useState(false)
    const [loadingStatus, setLoadingStatus] = useState(false)
    const [blog, setBlog] = useState([])
    const blogId = window.location.pathname?.split('/').pop()
    const navigate = useNavigate()
    const statusFormRef = useRef()

    useEffect(() => {
        handleGetBlog()
    }, [blogId])

    useEffect(() => {
        if (blog?.blogStatus) {
            statusFormRef?.current?.setFieldsValue({ blogStatus: blog?.blogStatus })
        }
    }, [blog])

    const handleGetBlog = async () => {
        const response = await getBlogById(blogId)
        if (response.data) {
            setBlog(response.data)
        }
        setLoading(false)
    }

    const handleChnageStatus = async (values) => {
        setLoadingStatus(true)
        values.id = blogId
        const response = await changeBlogStatus(values)
        if (response.data) {
            message.success('Status updated successfully')
        } else {
            message.error('Failed to update status')
        }
        setLoadingStatus(false)
    }

    const handleDeleteBlog = async () => {
        setLoadingDelete(true)
        const response = await deleteBlog(blogId)
        if (response.data) {
            message.success('Blog deleted successfully')
            navigate('/dashboard/blogs')
        } else {
            message.error('Failed to delete blog')
        }
        setLoadingDelete(false)
    }

    return (
        <div className='blog_main'>
            {loading ?
                <div className='loading'>
                    <LoadingComp />
                </div>
                :
                <div className='blog_item'>
                    <div className='blog_header'>
                        <div className='blog_meta'>
                            <h1 className='header_1'>{blog?.blogTitle}</h1>
                            {blog?.blogType
                                === 'admin'
                                ?
                                <div className='author'>
                                    <div className='author_image'>
                                        <LazyLoadImage
                                            alt='author'
                                            src={DEFAULT_PROFILE_IMAGE_VISIT_PEARL}
                                            effect='blur'
                                        />
                                    </div>
                                    <div className='author_name'>
                                        <h3>Visit Pearl</h3>
                                        <p>{moment(blog?.createdAt, 'MMM D YYYY h:mma').format('MMMM Do YYYY')}</p>
                                    </div>
                                </div>
                                :
                                <div className='author'>
                                    <div className='author_image'>
                                        <LazyLoadImage
                                            alt='author'
                                            src={blog?.profilePicUrl || DEFAULT_PROFILE_IMAGE}
                                            effect='blur'
                                        />
                                    </div>
                                    <div className='author_name'>
                                        <h3>{blog?.userName}</h3>
                                        <p>{moment(blog?.createdAt, 'MMM D YYYY h:mma').format('MMMM Do YYYY')}</p>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='users__header__actions'>
                            <Button
                                type='primary'
                                icon={<EditFilled />}
                                onClick={() => navigate(`/dashboard/blogs/edit/${blogId}`)}
                            >
                                Edit
                            </Button>

                            <Popconfirm
                                title='Are you sure to delete this blog?'
                                onConfirm={handleDeleteBlog}
                                okText='Yes'
                                cancelText='No'
                            >
                                <Button
                                    loading={laodingDelete}
                                    type='primary' danger icon={<DeleteOutlined />} >
                                    Delete
                                </Button>
                            </Popconfirm>

                            <Form
                                layout='inline'
                                onFinish={handleChnageStatus}
                                ref={statusFormRef}
                            >
                                <Form.Item
                                    name='blogStatus'
                                >
                                    <Select
                                        style={{ width: 200 }}
                                        placeholder='Select a status'
                                    >
                                        <Option value={PUBLISHED}>Published</Option>
                                        <Option value={PENDING}>Pending</Option>
                                        <Option value={SUSPENDED}>Suspended</Option>
                                    </Select>
                                </Form.Item>
                                <Button type='primary' htmlType='submit' loading={loadingStatus}>
                                    Update
                                </Button>
                            </Form>
                        </div>
                    </div>
                    <div className='blog'>

                        <div className='blog_short'>
                            <p className='short_description'>{blog?.blogDescription}</p>
                            <div className='tags'>
                                {
                                    blog?.blogTags?.split(',')?.map(tag => (
                                        <Tag key={tag} color='cyan'>#{tag}</Tag>
                                    ))
                                }
                            </div>
                        </div>

                        {blog?.tagedDestinations && <div className='tagged_destinations' style={{ marginBottom: '10px' }}>
                            <h2 className='header_4'>Tagged Destinations</h2>
                            <div className='tags' style={{ marginTop: '10px' }}>
                                {
                                    blog?.tagedDestinations?.map(tag => (
                                        <Tag
                                            key={tag}
                                            color='geekblue'
                                            onClick={() => window.location.href = `/destinations/${tag?.id}`}
                                        >
                                            {tag?.destinationName}
                                        </Tag>
                                    ))
                                }
                            </div>
                        </div>}

                        {blog?.tagedAttractions && <div>
                            <h2 className='header_4'>Tagged Attractions</h2>
                            <div className='tags' style={{ marginTop: '10px' }}>
                                {
                                    blog?.tagedAttractions?.map(tag => (
                                        <Tag key={tag} color='geekblue'>{tag?.attractionName}</Tag>
                                    ))
                                }
                            </div>
                        </div>}

                        <Divider />

                        <div className='blog_content'>
                            <ReactQuill
                                value={blog?.blogContent}
                                readOnly={true}
                                theme='bubble'
                            />
                        </div>

                        {blog?.blogImages &&
                            <PhotoProvider>
                                <div className='blog_images'>
                                    {
                                        blog?.blogImages?.split(',')?.map(image => (
                                            <LazyLoadImage
                                                alt='blog_image'
                                                src={image}
                                                effect='blur'
                                            />
                                        ))
                                    }
                                </div>
                            </PhotoProvider>
                        }
                        {blog?.taggedTourPackages &&
                            <div className='packages'>
                                <Divider />
                                <h1 className='heder_2' style={{ marginBottom: '10px' }}>
                                    Related Tour Packages
                                </h1>
                                {blog?.taggedTourPackages?.map((item, idx) => (
                                    <div
                                        className='package_item'
                                        onClick={() => navigate(`/dashboard/tours/tour-packages/${item?.id}`)}

                                    >
                                        <LazyLoadImage
                                            src={item?.thumbnailUrl}
                                            alt={item?.packageName}
                                            effect='blur'
                                            className='package_image'
                                        />

                                        <div
                                            className='destination__card__content'
                                        >
                                            <h2>{item?.packageName}</h2>
                                            <div className='price'>
                                                <span className='price__from'>from </span>
                                                <span className='amount'>{item?.startingPrice}</span> AUD
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                </div>

            }
        </div>
    )
}

export default Blog