import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Drawer, Form, Image, Input, Row, Select, Spin, Upload, notification } from 'antd'
import ReactQuill, { Quill } from 'react-quill';
import { editTourTransport } from '../../../../../../../redux/api/tourPackages.api';
import { vehiclesTypes } from '../../../../../../Constants/itemTypes';
import { handleDeleteBlob, handleImageUpload, TOUR_PACKAGES_BLOB } from '../../../../../../azure/blob';

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const vehicleOptions = Object.keys(vehiclesTypes).map(key => {
    return {
        label: getOptionName(vehiclesTypes[key]),
        value: key
    };
});

const quillModules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
        ["formula"],
        [{ size: ["small", false, "large", "huge"] }],
    ],
    clipboard: {
        matchVisual: false
    },
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
};

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });



const EditTransportOptions = ({
    isVisible,
    setIsVisible,
    selectedTransportOption,
    handleGetTransports
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [dayDescriptionWordCount, setDayDescriptionWordCount] = useState(0);
    const [fileList, setFileList] = useState([])
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const formRef = useRef();

    useEffect(() => {
        if (selectedTransportOption) {
            formRef?.current?.setFieldsValue({
                transportationType: selectedTransportOption.transportationType,
                title: selectedTransportOption.title,
                description: selectedTransportOption.description,
                minGroup: selectedTransportOption.minGroup,
                maxGroup: selectedTransportOption.maxGroup,
                suitableFor: selectedTransportOption.suitableFor?.split(','),
                price: selectedTransportOption.price,
                baggageDetails: selectedTransportOption.baggageDetails
            });

            if (selectedTransportOption.vehicleImages) {
                const images = selectedTransportOption.vehicleImages.split(',');
                const fileList = images.map((url, index) => {
                    return {
                        uid: index,
                        name: `image-${index + 1}`,
                        status: 'done',
                        url: url
                    }
                });
                setFileList(fileList);
            }
        }
    }, [selectedTransportOption, isVisible]);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const onFinish = async (values) => {
        setIsLoading(true);
        try {
            Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

            let imageUrls = [];

            const name = values.title.replace(/\s/g, '_').toLowerCase();

            const newImages = fileList?.filter(file => !file.url);
            const deletedImages = selectedTransportOption.vehicleImages?.split(',').filter(image => !fileList.find(file => file.url === image));

            if (newImages.length > 0) {
                setLoadingText('Uploading Images');
                const imageUploadPromises = newImages.map((file, index) => {
                    setLoadingText(`Uploading image ${index + 1}`);
                    return handleImageUpload(
                        TOUR_PACKAGES_BLOB,
                        file.originFileObj,
                        file.size,
                        `${name}_${file.name}`
                    ).then(blobRes => {
                        imageUrls.push(blobRes.blockBlobClient.url);
                        setLoadingText(`Uploaded image ${index + 1}`);
                    });
                });
                await Promise.all(imageUploadPromises);
            }

            if (deletedImages?.length > 0) {
                setLoadingText('Deleting Event Images');
                const imageDeletePromises = deletedImages.map(async (image, index) => {
                    const blobName = getBlobName(image);
                    setLoadingText(`Deleting image ${index + 1}`);
                    return handleDeleteBlob(TOUR_PACKAGES_BLOB, blobName);
                });
                await Promise.all(imageDeletePromises);
            }

            let updatedImages = [
                ...fileList.filter((file) => file.url).map((file) => file.url),
                ...imageUrls
            ];

            updatedImages.forEach((url, index) => {
                updatedImages[index] = sanitizeBlobUrls(url);
            });

            const data = {
                id: selectedTransportOption.id,
                packageId: selectedTransportOption.packageId,
                transportationType: values.transportationType,
                title: values.title,
                description: values.description,
                minGroup: values.minGroup,
                maxGroup: values.maxGroup,
                suitableFor: values.suitableFor?.join(','),
                price: values.price,
                baggageDetails: values.baggageDetails,
                vehicleImages: updatedImages?.length > 0 ? updatedImages.join(',') : null,
            };


            setLoadingText('Updating Transport Option in Database');
            const res = await editTourTransport(data);

            if (res.status === 200) {
                api.success({
                    message: 'Success',
                    description: 'Transport option updated successfully'
                });
                setIsVisible(false);
                handleGetTransports();
            } else {
                api.error({
                    message: 'Error',
                    description: 'An error occurred while updating the transport option. Please try again.'
                });
            }

        } catch (error) {
            console.log(error);
            api.error({
                message: 'Error',
                description: `An error occurred while updating the transport option. Please try again. ${error.message}`
            });
        }
        setIsLoading(false);
    }

    const sanitizeBlobUrls = (blobUrl) => {
        const splitUrl = blobUrl.split('?')
        return splitUrl[0]
    }

    const getBlobName = (blobUrl) => {
        const splitUrl = blobUrl.split('/')
        return splitUrl[splitUrl.length - 1]
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Edit Transport Option"
                width={720}
                open={isVisible}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                onClose={() => setIsVisible(false)}
            >
                <Spin spinning={isLoading} tip={loadingText}>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        ref={formRef}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="transportationType"
                                    label="Transportation Type"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select the transportation type',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Add vehicle type"
                                        options={vehicleOptions}
                                        maxCount={1}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="title"
                                    label="Title"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter title',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter the titlr" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label={`Vehicle Description ( ${dayDescriptionWordCount}/1000 characters)`}
                                    name='description'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the vehicle description',
                                        }
                                    ]}
                                >
                                    <ReactQuill
                                        theme="snow"
                                        limits={{ maxLength: 1000 }}
                                        onChange={(content, delta, source, editor) => {
                                            setDayDescriptionWordCount(editor.getLength())
                                        }}
                                        modules={quillModules}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="minGroup"
                                    label="Minimum Group"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the minimum group',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter the minimum groups" type='number' />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="maxGroup"
                                    label="Maximum Group"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the maximum group',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter the maximum group" type='number' />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="suitableFor"
                                    label="This is suitable for"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter suitable for',
                                        },
                                    ]}
                                >
                                    <Select
                                        mode="tags"
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Eg:- Family / Couple ..."
                                        options={[]}
                                        maxCount={5}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="price"
                                    label="Transportation Price"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the transportation price',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter the transportation price" type='number' />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label='Baggage Details'
                                    name='baggageDetails'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the baggage details',
                                        }
                                    ]}
                                >
                                    <Input.TextArea
                                        placeholder="Please enter the baggage details (Eg:- Up to 2 bags per person)"
                                        showCount
                                        maxLength={800}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label='Upload Vehicle Images (Max 10)'
                                    name='vehicleImages'
                                >
                                    <Upload
                                        listType="picture-card"
                                        fileList={fileList}
                                        onPreview={handlePreview}
                                        onChange={handleChange}
                                        multiple={true}
                                        maxCount={10}
                                        beforeUpload={(file) => {
                                            return false;
                                        }}
                                    >
                                        {fileList.length <= 10 && '+ Upload'}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item>
                            <Button type='primary' htmlType='submit' loading={isLoading}>
                                Update Transport Option
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Drawer>


            {previewImage && (
                <Image
                    wrapperStyle={{
                        display: 'none',
                    }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </div>
    )
}

export default EditTransportOptions