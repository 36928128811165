import { Button, Col, DatePicker, Divider, Drawer, Form, Input, Rate, Row, Select, Space, Spin } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { capitalizeFisrtLetterEachWord } from '../../../../../../helpers/nameRender'
import { ACTIVE, CANCELLED, CANCELLED_TOUR, COMPLETED, CONFIRMED, INSTALMENT_PAID, INSTALMENT_PENDING, NOT_STARTED, ONGOING, PAID, PENDING, PENDING_APPROVAL, PENDING_PAYMENT, PENDING_VERIFICATION, REJECTED, TAX_PERCENTAGE } from '../../../../../../Constants/finance'
import moment from 'moment'

import './styles.scss'
import { accommodationTypes } from '../../../../../../Constants/itemTypes'
import { getAllGuideNames } from '../../../../../../../redux/api/guides.api'
import dayjs from 'dayjs'
import { confirmCustomTour, editCustomTour } from '../../../../../../../redux/api/bookings.api'
import useMyProfile from '../../../../../../hooks/useMyProfile'

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const accommodationOptions = Object.keys(accommodationTypes).map(key => {
    return {
        label: getOptionName(accommodationTypes[key]),
        value: key
    };
});

const BookingDetails = ({
    userTour,
    handlegetUserTour,
    messageApi
}) => {
    const [editTourOpen, setEditTourOpen] = useState(false)
    const editFromRef = useRef(null)
    const [loadingEditTour, setLoadingEditTour] = useState(false)
    const [approveTourOpen, setApproveTourOpen] = useState(false)
    const approveFromRef = useRef(null)
    const [loadingApproveTour, setLoadingApproveTour] = useState(false)
    const [allGuides, setAllGuides] = useState([])
    const [loadingAllGuides, setLoadingAllGuides] = useState(false)
    const myProfile = useMyProfile();

    const [totalAmount, setTotalAmount] = useState(0)
    const [discount, setDiscount] = useState(0)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (editTourOpen) {
            handleGetallGuides()
        }
    }, [editTourOpen])

    useEffect(() => {
        if (editTourOpen) {
            calculateFinalAmount(totalAmount, discount)
        }
    }, [totalAmount, discount, editTourOpen])

    const calculateFinalAmount = (totalAmount, discount) => {
        var total = totalAmount ? parseFloat(totalAmount) : 0
        var dicountPercentage = discount ? parseFloat(discount) : 0
        var afterDiscount = total - (total * dicountPercentage) / 100
        var tax = (afterDiscount * TAX_PERCENTAGE) / 100
        var finalAmount = Math.round(afterDiscount + tax)
        if (finalAmount > 0) {
            editFromRef?.current?.setFieldsValue({
                finalAmount: finalAmount,
                tax: TAX_PERCENTAGE
            })
        }
    }

    useEffect(() => {
        editFromRef?.current?.setFieldsValue({
            tourStatus: userTour?.tourStatus,
            vehicleType: userTour?.vehicleType,
            accommodationType: userTour?.accommodationType,
            accommodationRating: userTour?.accommodationRating,
            tourGuideLanguage: userTour?.tourGuideLanguage,
            roomType: userTour?.roomType,
            startDate: dayjs(userTour?.startDate, 'YYYY-MM-DD'),
            totalAmount: userTour?.totalAmount,
            discountAmount: userTour?.discountAmount,
            tax: userTour?.totalAmount && TAX_PERCENTAGE,
            // finalAmount: userTour?.finalAmount,
            transportVehicleType: userTour?.vehicleInfo?.vehicleType,
            vehicleBrand: userTour?.vehicleInfo?.vehicleBrand,
            vehicleplateNumber: userTour?.vehicleInfo?.vehicleplateNumber,
            vehicleMaxPassenger: userTour?.vehicleInfo?.vehicleMaxPassenger,
            guideId: userTour?.guideId,
            durationNights: userTour.durationNights,
        })

        setTotalAmount(userTour?.totalAmount)
        setDiscount(userTour?.discountAmount)

        calculateFinalAmount(userTour?.totalAmount, userTour?.discountAmount)

    }, [editFromRef, allGuides])

    useEffect(() => {
        approveFromRef?.current?.setFieldsValue({
            bookingStatus: userTour?.bookingStatus,
            paymentStatus: userTour?.paymentStatus,
            isConfirmedByAdmin: userTour?.isConfirmedByAdmin,
            tourStatus: userTour?.tourStatus,
        })
    }, [approveFromRef, approveTourOpen])

    const handleGetallGuides = async () => {
        setLoadingAllGuides(true)
        try {
            const response = await getAllGuideNames()
            if (response.status === 200) {
                setAllGuides(response.data)
            }
        } catch (error) {
            console.log(error)
        }
        setLoadingAllGuides(false)
    }

    const isPriceChanged = (prev, newPrice) => {
        if (!newPrice) return false
        return prev !== newPrice
    }

    const handleEditTour = async (values) => {
        setLoadingEditTour(true)

        const vehicleInfo = {
            vehicleType: values?.transportVehicleType,
            vehicleBrand: values?.vehicleBrand,
            vehicleplateNumber: values?.vehicleplateNumber,
            vehicleMaxPassenger: values?.vehicleMaxPassenger,
        }

        let isFinalAmountChanged = isPriceChanged(userTour?.finalAmount, values?.finalAmount)

        const data = {
            id: userTour?.id,
            pickupLocation: values?.pickupLocation,
            dropLocation: values?.dropLocation,
            transportOption: values?.transportOption,
            accommodationOption: values?.accommodationOption,
            startDate: dayjs(values?.startDate).format('YYYY-MM-DD'),
            durationNights: values?.durationNights,
            totalAmount: values?.totalAmount || null,
            discountAmount: values?.discountAmount || null,
            tax: values?.tax || null,
            finalAmount: values?.finalAmount || null,
            guideId: values?.guideId,
            vehicleInfo: JSON.stringify(vehicleInfo),
            paymentStatus: isFinalAmountChanged ? PENDING_PAYMENT : userTour?.paymentStatus,
        }

        const response = await editCustomTour(data)
        if (response.status === 200) {
            messageApi.success({
                message: 'Success',
                description: 'Tour details has been updated successfully',
            })
            handlegetUserTour()
            setEditTourOpen(false)
        } else {
            messageApi.error({
                message: 'Error',
                description: 'Failed to update tour details',
            })
        }

        setLoadingEditTour(false)
    }

    const handleApproveTour = async (values) => {
        setLoadingApproveTour(true)

        const data = {
            id: userTour?.id,
            bookingStatus: values?.bookingStatus,
            paymentStatus: values?.paymentStatus,
            isConfirmedByAdmin: values?.isConfirmedByAdmin,
            tourStatus: values?.tourStatus,
            confirmedBy: values?.isConfirmedByAdmin ? myProfile?.userProfile?.id : null,
        }

        const response = await confirmCustomTour(data)
        if (response.status === 200) {
            messageApi.success({
                message: 'Success',
                description: 'Booking status has been updated successfully',
            })
            handlegetUserTour()
            setApproveTourOpen(false)
        } else {
            messageApi.error({
                message: 'Error',
                description: 'Failed to update booking status',
            })
        }

        setLoadingApproveTour(false)
    }

    return (
        <>
            <div className='booking_details'>
                <div
                    className='actions'
                    style={{ display: 'flex', gap: '10px' }}
                >
                    <Button
                        type='primary'
                        onClick={() => setEditTourOpen(true)}
                    >
                        Edit Booking
                    </Button>
                    <Button
                        onClick={() => setApproveTourOpen(true)}
                    >
                        Change Status
                    </Button>
                </div>
                <Divider
                    orientation="left"
                    orientationMargin="0"
                >
                    Basic Information
                </Divider>
                <div className='p_container'>
                    <p><span>Booking ID: </span>{userTour?.id}</p>
                    <p><span>Start Date: </span>{userTour?.startDate}</p>
                    <p><span>Duration: </span>{userTour?.durationNights} Nights</p>
                    <p><span>Booked Date: </span>{userTour?.createdAt ? moment(userTour?.createdAt).format('DD/MM/YYYY HH:mm') : 'Not yet'}</p>
                    <p>
                        <span>Customer: </span>
                        <a href={`/dashboard/users/all/${userTour?.userId}`} target='_blank' rel='noreferrer'>
                            {userTour?.userName}
                        </a>
                    </p>
                </div>
                <Divider
                    orientation="left"
                    orientationMargin="0"
                >
                    Status
                </Divider>
                <div className='p_container'>
                    <p
                        className={userTour?.isConfirmedByAdmin ? 'green' : 'red'}
                    ><span>Approve Status: </span>{userTour?.isConfirmedByAdmin ? 'Approved' : 'Pending Approval'}</p>
                    <p
                        className={userTour?.bookingStatus === PENDING_PAYMENT ? 'red' :
                            userTour?.bookingStatus === PENDING_VERIFICATION ? 'yellow' :
                                userTour?.bookingStatus === CONFIRMED ? 'green' :
                                    'red'}
                    ><span>Booking Status: </span>{userTour?.bookingStatus && userTour?.bookingStatus !== "" ? capitalizeFisrtLetterEachWord(userTour?.bookingStatus) : 'Not Set'}
                    </p>
                    <p
                        className={userTour?.paymentStatus === PENDING_PAYMENT ? 'red' :
                            userTour?.paymentStatus === PENDING_VERIFICATION ? 'yellow' :
                                userTour?.paymentStatus === PAID ? 'green' :
                                    'red'}
                    ><span>Payment Status: </span>{userTour?.paymentStatus && userTour?.paymentStatus !== "" ? capitalizeFisrtLetterEachWord(userTour?.paymentStatus) : 'Not Set'}
                    </p>
                    <p><span>Tour Status: </span>{userTour?.tourStatus && userTour?.tourStatus !== "" ? capitalizeFisrtLetterEachWord(userTour?.tourStatus) : 'Not Set'}
                    </p>
                </div>
                <Divider
                    orientation="left"
                    orientationMargin="0"
                >
                    Customer Selections
                </Divider>
                <div className='p_container'>
                    <p><span>Transport Option: </span>{userTour?.vehicleType}</p>
                    <p><span>Accommodation Type: </span>{userTour?.accommodationType}</p>
                    <p><span>Accommodation Room Type: </span>{userTour?.roomType}</p>
                    <p><span>Accommodation Star rating: </span><Rate disabled defaultValue={userTour?.accommodationRating} /></p>
                </div>
                <Divider
                    orientation="left"
                    orientationMargin="0"
                >
                    Destinations Requested
                </Divider>
                {
                    userTour?.destinations?.map((destination, index) => (
                        <div className='destination_card'>
                            <p><span>Destination Name: </span>{destination?.destinationName}</p>
                            <p><span>Destination ID: </span>{destination?.id}</p>
                        </div>
                    ))
                }
                <Divider
                    orientation="left"
                    orientationMargin="0"
                >
                    Guide & Transport
                </Divider>
                <div className='p_container'>
                    <p><span>Requested Guide Language: </span>{userTour?.tourGuideLanguage}</p>
                    <p><span>Tour Guide ID: </span>{userTour?.guideId || 'Not yet assigned'}</p>
                    {userTour?.guide &&
                        <p><span>Guide Name: </span>{userTour?.guide?.guideName}</p>}
                    <p><span>Vehicle: </span>{!userTour?.vehicleInfo ? 'Not yet assigned' : 'Assigned'}</p>
                    {
                        userTour?.vehicleInfo && <div className='p_container'>
                            <p><span>Vehicle Type: </span>{userTour?.vehicleInfo?.vehicleType}</p>
                            <p><span>Vehicle Brand & Model: </span>{userTour?.vehicleInfo?.vehicleBrand}</p>
                            <p><span>Plate Number: </span>{userTour?.vehicleInfo?.vehicleplateNumber}</p>
                            <p><span>Max Passenger Count: </span>{userTour?.vehicleInfo?.vehicleMaxPassenger}</p>
                        </div>
                    }
                </div>
                <Divider
                    orientation="left"
                    orientationMargin="0"
                >
                    Preferences
                </Divider>
                <div className='p_container'>
                    <p><span>Beaches: </span>{userTour?.favItems?.beaches} /5</p>
                    <p><span>Wildlife: </span>{userTour?.favItems?.wildlife} /5</p>
                    <p><span>Historical Sites: </span>{userTour?.favItems?.historialSites} /5</p>
                    <p><span>Adventure: </span>{userTour?.favItems?.adventure} /5</p>
                    <p><span>Relaxation: </span>{userTour?.favItems?.relaxation} /5</p>
                    <p><span>Cultural: </span>{userTour?.favItems?.cultural} /5</p>
                    <p><span>wellness & Spa: </span>{userTour?.favItems?.wellnessAndSpa} /5</p>
                    <p><span>Events & Festivals: </span>{userTour?.favItems?.festivalAndEvents} /5</p>
                </div>
                <Divider
                    orientation="left"
                    orientationMargin="0"
                >
                    Special Requests
                </Divider>
                <div className='p_container'>
                    <p><span>Special Notes: </span>{userTour?.specialNotes || 'None'}</p>
                    <p><span>Destination Specifications: </span>{userTour?.destinationSpecifications || 'None'}</p>
                    <p><span>Transport Specifications: </span>{userTour?.transportSpecifications || 'None'}</p>
                    <p><span>Other Specifications: </span>{userTour?.otherSpesifications || 'None'}</p>
                </div>
                <Divider
                    orientation="left"
                    orientationMargin="0"
                >
                    Flight Details
                </Divider>
                <div className='p_container'>
                    <p><span>Flight Booked: </span>{userTour?.flightDetails?.flightBooked ? 'Yes' : 'Not Yet'}</p>
                    {userTour?.flightDetails?.flightBooked && <>
                        <p><span>Flight No: </span>{userTour?.flightDetails?.flightNumber}</p>
                        <p><span>Departure Airport: </span>{userTour?.flightDetails?.departureAirport}</p>
                        <p><span>Arrival Date & Time: </span>{
                            moment(userTour?.flightDetails?.arrivalDateTime).format('DD/MM/YYYY HH:mm')
                        }</p>
                    </>}
                </div>
            </div>

            <Drawer
                title="Edit Booking"
                width={720}
                onClose={() => setEditTourOpen(false)}
                open={editTourOpen}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                loading={loadingAllGuides}
                extra={
                    <Space>
                        <Button onClick={() => setEditTourOpen(false)}>
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                editFromRef.current.submit()
                            }}
                        >
                            Update
                        </Button>
                    </Space>
                }
            >
                <Spin spinning={loadingEditTour} tip='Updating booking details...'>
                    <Form
                        layout="vertical"
                        ref={editFromRef}
                        onFinish={handleEditTour}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="vehicleType"
                                    label="Transport Option"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select transport option'
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Please select transport option"
                                        allowClear
                                    >
                                        <Select.Option value='Hatchback'>Hatchback</Select.Option>
                                        <Select.Option value='Sedan'>Sedan</Select.Option>
                                        <Select.Option value='SUV'>SUV</Select.Option>
                                        <Select.Option value='Van'>Van</Select.Option>
                                        <Select.Option value='Coster'>Coster</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="accommodationType"
                                    label="Accommodation Type"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select accommodation option'
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Please select accommodation option"
                                        allowClear
                                    >
                                        {
                                            accommodationOptions.map((option) => (
                                                <Select.Option value={option.value}>{option.label}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="accommodationRating"
                                    label="Accommodation Rating"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select accommodation rating'
                                        },
                                    ]}
                                >
                                    <Rate />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="roomType"
                                    label="Room Type"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select room option'
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Please select room option"
                                        allowClear
                                    >
                                        <Select.Option value='Single'>Single</Select.Option>
                                        <Select.Option value='Double'>Double</Select.Option>
                                        <Select.Option value='Triple'>Triple</Select.Option>
                                        <Select.Option value='Family'>Family</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="startDate"
                                    label="Tour Start date"
                                >
                                    <DatePicker
                                        type='date'
                                        style={{ width: '100%' }}
                                        showNow={false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="durationNights"
                                    label="Tour Duration (Nights)"
                                >
                                    <Input type='number' min={1} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Divider>Price</Divider>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name='totalAmount'
                                    label="Total Amount"
                                >
                                    <Input
                                        type='number'
                                        min={0}
                                        placeholder="Please enter total amount"
                                        suffix={'AUD'}
                                        onChange={(e) => setTotalAmount(e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name='discountAmount'
                                    label="Discount Percentage"
                                >
                                    <Input
                                        type='number'
                                        min={0}
                                        max={100}
                                        placeholder="Please enter discount amount"
                                        suffix={'%'}
                                        onChange={(e) => setDiscount(e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name='tax'
                                    label="Tax + Service Charge"
                                >
                                    <Input
                                        type='number'
                                        min={0}
                                        placeholder="Please enter tax amount"
                                        suffix={'%'}
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name='finalAmount'
                                    label="Final Amount"
                                >
                                    <Input
                                        type='number'
                                        min={0}
                                        placeholder="Please enter final amount"
                                        suffix={'AUD'}
                                        readOnly
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Divider>Pick-up & Drop</Divider>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name='pickupLocation'
                                    label="Pick-up Location"
                                >
                                    <Input
                                        placeholder="Please enter pick-up location"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name='dropLocation'
                                    label="Drop Location"
                                >
                                    <Input
                                        placeholder="Please enter drop location"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Divider>Transport Vehicle</Divider>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="transportVehicleType"
                                    label="Transport Vehicle Type"
                                >
                                    <Select
                                        placeholder="Please select transport vehicle type"
                                        allowClear
                                    >
                                        <Select.Option value='Hatchback'>Hatchback</Select.Option>
                                        <Select.Option value='Sedan'>Sedan</Select.Option>
                                        <Select.Option value='SUV'>SUV</Select.Option>
                                        <Select.Option value='Van'>Van</Select.Option>
                                        <Select.Option value='Coster'>Coster</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name='vehicleBrand'
                                    label="Vehicle Brand & Model"
                                >
                                    <Input
                                        placeholder="Please enter vehicle brand & model"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name='vehicleplateNumber'
                                    label="Vehicle Plate Number"
                                >
                                    <Input
                                        placeholder="Please enter vehicle plate number"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name='vehicleMaxPassenger'
                                    label="Vehicle Max Passenger"
                                >
                                    <Input
                                        type='number'
                                        placeholder="Please enter vehicle max passenger"
                                        min={0}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider>Tour Guide</Divider>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name='guideId'
                                    label="Guide"
                                >
                                    <Select
                                        placeholder="Please select guide"
                                        allowClear
                                    >
                                        {
                                            allGuides.map((guide) => (
                                                <Select.Option value={guide.id}>{guide.guideName}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Drawer>

            <Drawer
                title="Change Status"
                width={720}
                onClose={() => setApproveTourOpen(false)}
                open={approveTourOpen}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={() => setApproveTourOpen(false)}>
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                approveFromRef.current.submit()
                            }}
                        >
                            Update
                        </Button>
                    </Space>
                }
            >
                <Spin spinning={loadingApproveTour} tip='Updating booking status...'>
                    <Form
                        layout="vertical"
                        ref={approveFromRef}
                        onFinish={handleApproveTour}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name='bookingStatus'
                                    label='Booking Status'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select booking status'
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Please select booking status"
                                        allowClear
                                    >
                                        <Select.Option value={CONFIRMED}>
                                            Confirmed
                                        </Select.Option>
                                        <Select.Option value={PENDING}>
                                            Pending
                                        </Select.Option>
                                        <Select.Option value={REJECTED}>
                                            Rejected
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name='tourStatus'
                                    label='Tour Status'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select tour status'
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Please select tour status"
                                        allowClear
                                    >
                                        <Select.Option value={ACTIVE}>
                                            Active
                                        </Select.Option>
                                        <Select.Option value={NOT_STARTED}>
                                            Not Started
                                        </Select.Option>
                                        <Select.Option value={ONGOING}>
                                            Ongoing
                                        </Select.Option>
                                        <Select.Option value={COMPLETED}>
                                            Completed
                                        </Select.Option>
                                        <Select.Option value={CANCELLED_TOUR}>
                                            Cancelled
                                        </Select.Option>
                                        <Select.Option value={PENDING_APPROVAL}>
                                            Pending Approval
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name='paymentStatus'
                                    label='Payment Status'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select payment status'
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Please select payment status"
                                        allowClear
                                    >
                                        <Select.Option value={PENDING_PAYMENT}>
                                            Pending Payment
                                        </Select.Option>
                                        <Select.Option value={PENDING_VERIFICATION}>
                                            Pending Verification
                                        </Select.Option>
                                        <Select.Option value={PAID}>
                                            Paid
                                        </Select.Option>
                                        <Select.Option value={INSTALMENT_PENDING}>
                                            Instalment Pending
                                        </Select.Option>
                                        <Select.Option value={INSTALMENT_PAID}>
                                            Instalment Paid
                                        </Select.Option>
                                        <Select.Option value={CANCELLED}>
                                            Cancelled
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name='isConfirmedByAdmin'
                                    label='Approve Status'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select approve status'
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Please select approve status"
                                        allowClear
                                    >
                                        <Select.Option value={true}>
                                            Approved
                                        </Select.Option>
                                        <Select.Option value={false}>
                                            Pending Approval
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Drawer>
        </>
    )
}

export default BookingDetails