import React, { useEffect, useRef, useState } from 'react'
import { editPayment, getPaymentById } from '../../../../../redux/api/payments.api'
import { capitalizeFisrtLetterEachWord } from '../../../../helpers/nameRender'
import { Button, Col, Divider, Drawer, Form, message, Row, Select, Space, Spin, Tag } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { DENIED, PAID, PENDING_PAYMENT, PENDING_VERIFICATION, REFUNDED } from '../../../../Constants/finance'

const Payment = () => {
    const url = window.location.pathname
    const paymentId = url.split('/').pop()
    const [payment, setPayment] = useState()
    const [loading, setLoading] = useState(true)
    const [editStatusDrawerOpen, setEditStatusDrawerOpen] = useState(false)
    const [loadingUpdate, setLoadingUpdate] = useState(false)
    const approveFromRef = useRef()
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        handleGetPayment()
    }, [])

    useEffect(() => {
        if (payment) {
            approveFromRef?.current?.setFieldsValue({
                paymentStatus: payment.paymentStatus
            })
        }
    }, [payment, approveFromRef, editStatusDrawerOpen])

    const handleGetPayment = async () => {
        const response = await getPaymentById(paymentId)
        if (response.status === 200) {
            setPayment(response.data)
        }
        setLoading(false)
    }

    const handleChangeStatus = async (values) => {
        setLoadingUpdate(true)
        values.id = paymentId
        const response = await editPayment(values)
        if (response.status === 200) {
            handleGetPayment()
            setEditStatusDrawerOpen(false)
            messageApi.open({
                type: 'success',
                content: 'Payment status updated successfully',
            })
        }
        setLoadingUpdate(false)
    }

    return (
        <div>
            {contextHolder}
            {loading ? <h1>Loading...</h1> : (
                <div>
                    <div
                        className='header'
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <h1>Payment</h1>
                        <Button
                            type='primary'
                            onClick={() => setEditStatusDrawerOpen(true)}
                        >
                            <EditOutlined />
                            Update Status
                        </Button>
                    </div>
                    <div className='p_container'>
                        <p><strong>Payment ID: </strong>{payment?.id}</p>
                        <p><strong>Paid Type: </strong>{payment?.paymentType && capitalizeFisrtLetterEachWord(payment?.paymentType)}</p>
                        <p><strong>Paid For (id): </strong>{payment?.paidFor}</p>
                        <p><strong>Payment Method: </strong>{payment?.paymentMethod ? capitalizeFisrtLetterEachWord(payment?.paymentMethod) : 'N/A'}</p>
                        <p><strong>Payment Status: </strong>{
                            <Tag
                                color={payment?.paymentStatus === PAID ? 'green' : 'red'}
                            >
                                {payment?.paymentStatus ? capitalizeFisrtLetterEachWord(payment?.paymentStatus) : 'N/A'}
                            </Tag>
                        }</p>
                        <Divider />
                        <p><strong>Payment paymentAmount: </strong>{payment?.paymentAmount} AUD</p>
                        <p><strong>Paid At: </strong>{payment?.paymentDate}</p>
                        <p><strong>Payment Reference: </strong>{payment?.paymentReference}</p>
                        <p><strong>Payment Proof: </strong>{payment?.proof ?
                            <a href={payment?.proof} target='_blank' rel='noreferrer'>View Proof</a> : 'N/A'
                        }</p>
                        <p><strong>Payment ID: </strong>{payment?.id}</p>
                        {payment?.paymentGateway &&
                            <>
                                <Divider />
                                <p><strong>Payment Gateway: </strong>{payment?.paymentGateway}</p>
                                <p><strong>Gateway Id: </strong>{payment?.paymentGatewayId}</p>
                                <p><strong>Gateway Response: </strong>{payment?.paymentGatewayResponse}</p>
                                <p><strong>Gateway Status: </strong>{payment?.paymentGatewayStatus}</p>
                                <p><strong>Gateway Message: </strong>{payment?.paymentGatewayMessage}</p>
                                <p><strong>Gateway Code: </strong>{payment?.paymentGatewayCode}</p>
                                <p><strong>Gateway Ref: </strong>{payment?.paymentGatewayReference}</p>
                                <p><strong>Gateway Amount: </strong>{payment?.paymentGatewayAmount}</p>
                                <p><strong>Gateway Currency: </strong>{payment?.paymentGatewayCurrency}</p>
                                <p><strong>Date: </strong>{payment?.paymentGatewayDate}</p>
                                <p><strong>Time: </strong>{payment?.paymentGatewayTime}</p>
                            </>
                        }
                        <Divider />
                        <p><strong>Paid By: </strong>
                            <a href={`/dashboard/users/all/${payment?.userId}`} target='_blank' rel='noreferrer'>
                                {payment?.userName}
                            </a>
                        </p>
                        <p><strong>User Id: </strong>
                            <a href={`/dashboard/users/all/${payment?.userId}`} target='_blank' rel='noreferrer'>
                                {payment?.userId}
                            </a>
                        </p>
                    </div>
                </div>
            )}

            <Drawer
                title="Change Status"
                width={720}
                onClose={() => setEditStatusDrawerOpen(false)}
                open={editStatusDrawerOpen}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={() => setEditStatusDrawerOpen(false)}>
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                approveFromRef.current.submit()
                            }}
                        >
                            Update
                        </Button>
                    </Space>
                }
            >
                <Spin spinning={loadingUpdate} tip='Updating payment status...'>
                    <Form
                        layout="vertical"
                        ref={approveFromRef}
                        onFinish={handleChangeStatus}
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name='paymentStatus'
                                    label='Payment Status'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select payment status'
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Please select payment status"
                                        allowClear
                                    >
                                        <Select.Option value={PENDING_PAYMENT}>
                                            Pending Payment
                                        </Select.Option>
                                        <Select.Option value={PENDING_VERIFICATION}>
                                            Pending Verification
                                        </Select.Option>
                                        <Select.Option value={PAID}>
                                            Paid
                                        </Select.Option>
                                        <Select.Option value={REFUNDED}>
                                            Refunded
                                        </Select.Option>
                                        <Select.Option value={DENIED}>
                                            Denied
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default Payment