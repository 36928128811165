import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { capitalizeFisrtLetterEachWord } from '../../../../helpers/nameRender';
import dayjs from 'dayjs';
import { reviewTypes } from '../../../../Constants/reviewTypes';

const PaymentsTable = ({
    reviews,
    loading
}) => {
    const [searchText, setSearchText] = useState('');
    const [tableData, setTableData] = useState([]);
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    useEffect(() => {
        setTableData(reviews)
    }, [reviews])

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };


    const getNameSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text, record) =>
            searchedColumn === dataIndex ? (
                <Link to={`/dashboard/reviews/${record.id}`}>
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                </Link>
            ) : (
                <Link to={`/dashboard/reviews/${record.id}`}>
                    <p
                        style={{
                            color: '#9373ff',
                            cursor: 'pointer',
                            margin: 0,
                        }}
                    >
                        {text}
                    </p>
                </Link>
            ),
    });

    const columns = [
        {
            title: 'Review Type',
            dataIndex: 'ratingType',
            key: 'ratingType',
            width: '15%',
            filters: [
                {
                    text: 'Tour Package',
                    value: reviewTypes.REV_TOUR_PACKAGE,
                },
                {
                    text: 'Destination',
                    value: reviewTypes.REV_DESTINATION,
                },
                {
                    text: 'Attraction',
                    value: reviewTypes.REV_ATTRACTION
                },
                {
                    text: 'Activity',
                    value: reviewTypes.REV_ACTIVITY
                },
                {
                    text: 'Event',
                    value: reviewTypes.REV_EVENT
                }
            ],
            onFilter: (value, record) => record.ratingType.includes(value),
            render: (text) => {
                return (text && capitalizeFisrtLetterEachWord(text))
            }
        },
        {
            title: 'Review ID',
            dataIndex: 'id',
            key: 'id',
            width: '20%',
            ...getNameSearchProps('id'),
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '15%',
            render: (text) => {
                return dayjs(text, 'MMM D YYYY h:mma').format('DD/MM/YYYY');
            }
        },
        {
            title: "Rating",
            dataIndex: "ratingValue",
            key: "ratingValue",
            width: "15%",
            filters: [
                {
                    text: '1 Star',
                    value: 1,
                },
                {
                    text: '2 Star',
                    value: 2,
                },
                {
                    text: '3 Star',
                    value: 3
                },
                {
                    text: '4 Star',
                    value: 4
                },
                {
                    text: '5 Star',
                    value: 5
                }
            ],
            onFilter: (value, record) => {
                const ratingValue = record.ratingValue;
                if (typeof ratingValue === 'string' || Array.isArray(ratingValue)) {
                    return ratingValue.includes(value);
                }
                return ratingValue.toString().includes(value);
            },
            render: (text) => {
                return (
                    <Tag color='geekblue' key={text}>
                        {text} Star
                    </Tag>
                );
            },
        },
        {
            title: 'Is Verified',
            dataIndex: 'isVerified',
            key: 'isVerified',
            width: '15%',
            filters: [
                {
                    text: 'Verified',
                    value: true,
                },
                {
                    text: 'Not Verified',
                    value: false,
                },
            ],
            onFilter: (value, record) => {
                const isVerified = record.isVerified;
                if (typeof isVerified === 'string' || Array.isArray(isVerified)) {
                    return isVerified.includes(value);
                }
                return isVerified.toString().includes(value);
            },
            render: (text) => {
                let color = 'geekblue';
                if (text === false) {
                    color = 'volcano';
                } else {
                    color = 'green';
                }

                return (
                    <Tag color={color} key={text}>
                        {text === true ? 'Verified' : 'Not Verified'}
                    </Tag>
                );
            }
        },
    ];

    return (
        <Table
            className='table'
            loading={loading}
            columns={columns}
            dataSource={tableData}
        />
    )
}

export default PaymentsTable