import React, { useEffect, useRef, useState } from 'react'
import 'react-quill/dist/quill.snow.css';
import ReactQuill, { Quill } from 'react-quill';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid'
import { handleDeleteBlob, handleImageUpload, PARTNERS_BLOB } from '../../../../../azure/blob';

import { Button, Col, Divider, Form, Image, Input, notification, Row, Select, Spin, Upload } from 'antd'

import { transportPackageTypes, vehiclesTypes } from '../../../../../Constants/itemTypes';

import { editTransportPackage, getTransportPackageById } from '../../../../../../redux/api/transport.api';
import useTransports from '../../../../../hooks/useTransports';
import { ACTIVE, BLOCKED, INACTIVE, ONUSE, PENDING } from '../../../../../Constants/activeStatusType';

import './styles.scss'

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const vehicleOptions = Object.keys(vehiclesTypes).map(key => {
    return {
        label: getOptionName(vehiclesTypes[key]),
        value: key
    };
});
const packageOptions = Object.keys(transportPackageTypes).map(key => {
    return {
        label: getOptionName(transportPackageTypes[key]),
        value: key
    };
});

const quillModules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
        ["formula"],
        [{ size: ["small", false, "large", "huge"] }],
    ],
    clipboard: {
        matchVisual: false
    },
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
};

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });


const EditTransport = () => {
    const [shortDescriptionCharCount, setShortDescriptionCharCount] = useState(0)
    const [fileList, setFileList] = useState([])
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [loadingText, setLoadingText] = useState('')
    const topRef = useRef(null);
    const [api, contextHolder] = notification.useNotification();
    const [transportPackage, setTransportPackage] = useState();
    const url = window.location.pathname
    const packageId = url.split('/').pop()
    const formRef = useRef(null)
    const transports = useTransports().transports
    const navigate = useNavigate();

    useEffect(() => {
        if (transports?.length > 0) {
            const transportPackage = transports.find(transportPackage => transportPackage.id === packageId)
            if (transportPackage) {
                setTransportPackage(transportPackage)
            } else {
                handleGetTransportPackage(packageId)
            }
        } else {
            handleGetTransportPackage(packageId)
        }
    }, [])

    useEffect(() => {
        if (transportPackage) {
            formRef.current.setFieldsValue({
                packageName: transportPackage.packageName,
                packageType: transportPackage.packageType,
                vehicleType: transportPackage.vehicleType,
                vehicleBrand: transportPackage.vehicleBrand,
                vehicleModel: transportPackage.vehicleModel,
                vehicleYear: transportPackage.vehicleYear,
                vehiclePlateNumber: transportPackage.vehiclePlateNumber,
                vehicleColor: transportPackage.vehicleColor,
                vehicleCapacity: transportPackage.vehicleCapacity,
                baggageCapacity: transportPackage.baggageCapacity,
                priceMargin: transportPackage.priceMargin,
                minMilage: transportPackage.minMilage,
                maxMilage: transportPackage.maxMilage,
                pricePerKm: transportPackage.pricePerKm,
                pricePerDay: transportPackage.pricePerDay,
                packageDescription: transportPackage.packageDescription,
                activeStatus: transportPackage.activeStatus
            })

            if (transportPackage.vehicleImages) {
                const imageUrls = transportPackage.vehicleImages.split(',')
                const fileList = imageUrls.map((url, index) => {
                    return {
                        uid: index,
                        name: 'image.png',
                        status: 'done',
                        url
                    }
                })
                setFileList(fileList)
            }
        }

    }, [transportPackage])

    const handleGetTransportPackage = async (id) => {
        setLoading(true)
        try {
            const transportPackage = await getTransportPackageById(id)
            setTransportPackage(transportPackage.data[0])
        } catch (error) {
            console.error('Error fetching transport package', error)
        }
        setLoading(false)
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const onFinish = async (values) => {
        topRef.current.scrollTo(0, 0);
        setLoading(true);
        try {
            Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

            let imageUrls = [];

            const newImages = fileList?.filter(file => !file.url);
            const deletedImages = transportPackage.vehicleImages?.split(',').filter(image => !fileList.find(file => file.url === image));

            // Upload images
            if (newImages.length > 0) {
                setLoadingText('Uploading Images');
                const imageUploadPromises = newImages.map(async (file, index) => {
                    setLoadingText(`Uploading image ${index + 1}`);

                    let id = uuidv4();
                    id = id.replace(/-/g, '');
                    let extention = file?.name?.split('.').pop();
                    let name = `${id}.${extention}`;

                    return handleImageUpload(
                        PARTNERS_BLOB,
                        file.originFileObj,
                        file.size,
                        name
                    ).then(blobRes => {
                        imageUrls.push(blobRes.blockBlobClient.url);
                        setLoadingText(`Uploaded image ${index + 1}`);
                    });
                });
                await Promise.all(imageUploadPromises);
            }

            // Delete images
            if (deletedImages?.length > 0) {
                setLoadingText('Deleting Images');
                const imageDeletePromises = deletedImages.map(async (image, index) => {
                    const blobName = getBlobName(image);
                    setLoadingText(`Deleting image ${index + 1}`);
                    return handleDeleteBlob(PARTNERS_BLOB, blobName);
                });
                await Promise.all(imageDeletePromises);
            }

            let updatedImages = [
                ...fileList.filter((file) => file.url).map((file) => file.url),
                ...imageUrls
            ];

            updatedImages.forEach((url, index) => {
                updatedImages[index] = sanitizeBlobUrls(url);
            });

            const data = {
                id: transportPackage.id,
                ...values,
                vehicleImages: updatedImages?.length > 0 ? updatedImages.join(',') : null
            };

            setLoadingText('Updating to Database');
            const response = await editTransportPackage(data);
            if (response.status === 200) {
                api.success({
                    message: 'Transport Package Updated Successfully',
                    description: 'You have successfully updated the transport package'
                });

                setTimeout(() => {
                    navigate(`/dashboard/partners/transport/${transportPackage.id}`);
                }, 2000);
            } else {
                api.error({
                    message: 'Error Updating Transport Package',
                    description: 'There was an error updating the transport package. Please try again'
                });
            }
        } catch (error) {
            console.log('Error Updating Transport Package', error);
            api.error({
                message: 'Error Updating Transport Package',
                description: 'There was an error updating the transport package. Please try again'
            });
        }
        setLoading(false);
    };

    const getBlobName = (blobUrl) => {
        const splitUrl = blobUrl.split('/')
        return splitUrl[splitUrl.length - 1]
    }

    const sanitizeBlobUrls = (blobUrl) => {
        const splitUrl = blobUrl.split('?')
        return splitUrl[0]
    }

    return (
        <div className='add__destination' ref={topRef}>
            {contextHolder}
            <h2>
                Edit Transport Package
            </h2>

            <Spin spinning={loading} tip={loadingText}>
                <Form
                    layout='vertical'
                    onFinish={onFinish}
                    ref={formRef}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Package Name'
                                name='packageName'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter package name'
                                    }
                                ]}
                            >
                                <Input placeholder='Transport Package Name' showCount maxLength={200} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Active Status'
                                name='activeStatus'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select active status'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Select Active Status"
                                    options={[
                                        {
                                            label: 'Active',
                                            value: ACTIVE
                                        },
                                        {
                                            label: 'Inactive',
                                            value: INACTIVE
                                        },
                                        {
                                            label: 'Pending',
                                            value: PENDING
                                        },
                                        {
                                            label: 'On Use',
                                            value: ONUSE
                                        },
                                        {
                                            label: 'Block',
                                            value: BLOCKED
                                        }
                                    ]}
                                    maxCount={1}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Package Type'
                                name='packageType'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select package type'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Add package type"
                                    options={packageOptions}
                                    maxCount={1}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Vehicle Type'
                                name='vehicleType'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select vehicle type'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Add vehicle type"
                                    options={vehicleOptions}
                                    maxCount={1}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Vehicle Brand'
                                name='vehicleBrand'
                            >
                                <Input placeholder='Vehicle Brand' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Enter Vehicle Model'
                                name='vehicleModel'
                            >
                                <Input placeholder='Vehicle Model' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Vehicle Year'
                                name='vehicleYear'
                            >
                                <Input placeholder='Vehicle Year' type='number' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Vehicle Plate Number'
                                name='vehiclePlateNumber'
                            >
                                <Input placeholder='Vehicle Plate Number' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='vehicleColor'
                                name='vehicleColor'
                            >
                                <Input placeholder='Vehicle Color' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Vehicle Capacity'
                                name='vehicleCapacity'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter vehicle capacity'
                                    }
                                ]}
                            >
                                <Input placeholder='Vehicle Capacity' type='number' min={1} max={50} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Baggage Capacity'
                                name='baggageCapacity'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter baggage capacity'
                                    }
                                ]}
                            >
                                <Input placeholder='Baggage Capacity' type='number' min={1} max={50} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label='Price Margin (%)'
                                name='priceMargin'
                            >
                                <Input placeholder='Price Margin' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Min Milage'
                                name='minMilage'
                            >
                                <Input placeholder='Min Milage' type='number' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Max Milage'
                                name='maxMilage'
                            >
                                <Input placeholder='Max Milage' type='number' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Price Per Km'
                                name='pricePerKm'
                            >
                                <Input placeholder='Price Per Km' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Price Per Day'
                                name='pricePerDay'
                            >
                                <Input placeholder='Price Per Day' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={`Package Description ( ${shortDescriptionCharCount}/1000 characters)`}
                                name='packageDescription'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter package description'
                                    }
                                ]}
                            >
                                <ReactQuill
                                    theme="snow"
                                    limits={{ maxLength: 500 }}
                                    onChange={(content, delta, source, editor) => {
                                        setShortDescriptionCharCount(editor.getLength())
                                    }}
                                    modules={quillModules}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Upload Vehicle Images (Max 10)'
                                name='vehicleImages'
                            >
                                <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                    multiple={true}
                                    maxCount={10}
                                    beforeUpload={(file) => {
                                        return false;
                                    }}
                                >
                                    {fileList.length <= 10 && '+ Upload'}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item>
                        <Button type='primary' htmlType='submit'>
                            Update Transport Package
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>

            {previewImage && (
                <Image
                    wrapperStyle={{
                        display: 'none',
                    }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </div>
    )
}

export default EditTransport