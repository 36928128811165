import React, { useEffect, useState } from 'react'
import useUsers from '../../../../hooks/useUsers';
import { useNavigate } from 'react-router-dom';
import useMyProfile from '../../../../hooks/useMyProfile';
import { useMsal } from '@azure/msal-react';

import { changeUserActiveStatus, changeUserRoleApi, deleteUserPermanently, fetchUser } from '../../../../../redux/api/users.api';

import { Button, Form, Modal, Select, Spin, Tabs, notification } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import { ACTIVE, BLOCKED, INACTIVE, PENDING, SUSPENDED } from '../../../../Constants/activeStatusType';
import { ADMIN, CUSTOMER_RELATIONS_MANAGER, MANAGER, PARTNERSHIP_MANAGER, SUPER_ADMIN, SUPPORT_AGENT, TRIP_COORDINATOR, TRANSPORT_AGENT, TOUR_GUIDE, HOTEL_MANAGER } from '../../../../Constants/roles';

import UserDetails from './UserDetails';
import UserBookings from './UserBookings';
import UserBlogs from './UserBlogs';
import UserSupportTickets from './UserSupportTickets';
import UserPayments from './UserPayments';
import UserRatings from './UserRatings';
import UserActivityLog from './UserActivityLog';

import './styles.scss'

const User = () => {
    const users = useUsers();
    const url = window.location.pathname
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [userData, setUserData] = useState(null)
    const [selectedTab, setSelectedTab] = useState()
    const [changeStatusLoading, setChangeStatusLoading] = useState(false)
    const [isChangeStatusModalVisible, setIsChangeStatusModalVisible] = useState(false)
    const [api, contextHolder] = notification.useNotification();
    const [isChangeRoleModalVisible, setIsChangeRoleModalVisible] = useState(false)
    const [changeRoleLoading, setChangeRoleLoading] = useState(false)
    const [isDeleteUserModalVisible, setIsDeleteUserModalVisible] = useState(false)
    const [deleteUserLoading, setDeleteUserLoading] = useState(false)
    const myProfile = useMyProfile().userProfile
    const { instance } = useMsal();
    const [oid, setOid] = useState(null)

    useEffect(() => {
        if (url.includes('myprofile')) {
            setUserData(myProfile)
            setSelectedTab('user-details')
        } else {
            if (users?.users?.length > 0) {
                const id = url?.split('/').pop()
                const user = users.users.find(user => user.id === id)
                setUserData(user)
            } else {
                const id = url?.split('/').pop()
                getUser(id)
            }
        }

        const activeAccount = instance.getActiveAccount();
        if (activeAccount) {
            setOid(activeAccount?.idTokenClaims?.oid)
        }

    }, []);

    const getUser = async (userId) => {
        setLoading(true)
        try {
            const user = await fetchUser(userId)
            setUserData(user?.data)
        } catch (error) {
            console.error('Error getting user', error)
        }
        setLoading(false)
    }

    const items = [
        {
            key: 'user-details',
            label: 'User Details',
            children: <UserDetails userData={userData} />
        },
        // {
        //     key: 'bookings',
        //     label: 'Bookings',
        //     children: <UserBookings userData={userData} />
        // },
        // {
        //     key: 'blogs',
        //     label: 'Blogs',
        //     children: <UserBlogs userData={userData} />
        // },
        // {
        //     key: 'support-tickets',
        //     label: 'Support Tickets',
        //     children: <UserSupportTickets userData={userData} />
        // },
        // {
        //     key: 'payments',
        //     label: 'Payments',
        //     children: <UserPayments userData={userData} />
        // },
        // {
        //     key: 'ratings',
        //     label: 'Ratings & Reviews',
        //     children: <UserRatings userData={userData} />
        // },
        {
            key: 'activity-log',
            label: 'Activity Log',
            children: <UserActivityLog userData={userData} />
        },
    ];

    const onTabChange = (key) => {
        setSelectedTab(key)
    }

    const changeUserStatus = async (values) => {
        setChangeStatusLoading(true)
        try {
            const status = values?.status
            const res = await changeUserActiveStatus(userData?.id, status)
            if (res?.status === 200) {
                openNotification('success', 'Success', 'User status changed successfully')
                setIsChangeStatusModalVisible(false)
                setIsDeleteUserModalVisible(false)
                await getUser(userData?.id)
            } else {
                openNotification('error', 'Error', 'Error changing user status')
            }
        } catch (error) {
            console.error('Error changing user status', error)
            openNotification('error', 'Error', 'Error changing user status')

        }
        setChangeStatusLoading(false)
    }

    const changeUserRole = async (values) => {
        setChangeRoleLoading(true)
        try {
            const role = values?.role
            const res = await changeUserRoleApi(userData?.id, role)
            if (res?.status === 200) {
                openNotification('success', 'Success', 'User role changed successfully')
                setIsChangeRoleModalVisible(false)
                await getUser(userData?.id)
            } else {
                openNotification('error', 'Error', 'Error changing user role')
            }
        } catch (error) {
            console.error('Error changing user role', error)
            openNotification('error', 'Error', 'Error changing user role')
        }
        setChangeRoleLoading(false)
    }

    const deleteUser = async () => {
        setDeleteUserLoading(true)
        try {
            const res = await deleteUserPermanently(userData?.id)
            if (res?.status === 200) {
                openNotification('success', 'Success', 'User deleted successfully')
                setIsDeleteUserModalVisible(false)
                setTimeout(() => {
                    navigate('/dashboard/users')
                }, 2000)
            } else {
                openNotification('error', 'Error', 'Error deleting user')
            }
        } catch (error) {
            console.error('Error deleting user', error)
            openNotification('error', 'Error', 'Error deleting user')
        }
        setDeleteUserLoading(false)
    }

    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    };

    const navigateToEditAccount = () => {
        navigate(`/dashboard/users/edit/${userData?.id}`)
    }

    return (
        <div className='user'>
            {contextHolder}
            <Spin spinning={loading}>
                <div className='user__header'>
                    <div className='user__header__left'>
                        {!url.includes('myprofile') && <h1>User</h1>}
                        {userData && <h1
                            className={!url.includes('myprofile') ? 'user__name' : 'user_name'}
                        >{userData?.userName}</h1>}
                    </div>
                    <div className='user__actions'>
                        {((myProfile?.role === SUPER_ADMIN) || (myProfile?.role === ADMIN)) && <>
                            <Button type='default' icon={<EditOutlined />} onClick={() => setIsChangeRoleModalVisible(true)}>
                                Change Role
                            </Button>
                            <Button type='primary' icon={<EditOutlined />} onClick={() => setIsChangeStatusModalVisible(true)}>
                                Change User Status
                            </Button>
                            <Button type='primary' danger icon={<DeleteOutlined />} onClick={() => setIsDeleteUserModalVisible(true)}>
                                Dlete User
                            </Button>
                        </>}
                        {
                            oid === userData?.id &&
                            <Button type='primary' onClick={navigateToEditAccount} icon={<EditOutlined />}>
                                Edit Account
                            </Button>
                        }
                    </div>
                </div>

                <Tabs
                    defaultActiveKey={selectedTab}
                    activeKey={selectedTab}
                    items={items}
                    onChange={onTabChange}
                />
            </Spin>

            <Modal
                title="Change User Status"
                open={isChangeStatusModalVisible}
                onCancel={() => setIsChangeStatusModalVisible(false)}
                footer={null}
            >
                <Form
                    layout='vertical'
                    onFinish={changeUserStatus}
                >
                    <Form.Item
                        label='Status'
                        name='status'
                        rules={[{ required: true, message: 'Please select a status' }]}
                    >
                        <Select
                            loading={changeStatusLoading}
                            defaultValue={userData?.activeStatus}
                        >
                            <Select.Option value={ACTIVE}>Active</Select.Option>
                            <Select.Option value={INACTIVE}>Inactive</Select.Option>
                            <Select.Option value={PENDING}>Pending</Select.Option>
                            <Select.Option value={SUSPENDED}>Suspend</Select.Option>
                            <Select.Option value={BLOCKED}>Block</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type='primary' htmlType='submit' loading={changeStatusLoading}>
                            Change Status
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="Change User Role"
                open={isChangeRoleModalVisible}
                onCancel={() => setIsChangeRoleModalVisible(false)}
                footer={null}
            >
                <Form
                    layout='vertical'
                    onFinish={changeUserRole}
                >
                    <Form.Item
                        label='Role'
                        name='role'
                        rules={[{ required: true, message: 'Please select a status' }]}
                    >
                        <Select
                            loading={changeRoleLoading}
                            defaultValue={userData?.role}
                        >
                            <Select.Option value={SUPER_ADMIN}>Super Admin</Select.Option>
                            <Select.Option value={ADMIN}>Admin</Select.Option>
                            <Select.Option value={MANAGER}>Manager</Select.Option>
                            <Select.Option value={PARTNERSHIP_MANAGER}>Partnership Manager</Select.Option>
                            <Select.Option value={CUSTOMER_RELATIONS_MANAGER}>Customer Relations Manager</Select.Option>
                            <Select.Option value={SUPPORT_AGENT}>Support Agent</Select.Option>
                            <Select.Option value={TRIP_COORDINATOR}>Trip Coordinator</Select.Option>
                            <Select.Option value={TRANSPORT_AGENT}>Transport Agent</Select.Option>
                            <Select.Option value={TOUR_GUIDE}>Tour Guide</Select.Option>
                            <Select.Option value={HOTEL_MANAGER}>Hotel Manager</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type='primary' htmlType='submit' loading={changeRoleLoading}>
                            Change Role
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>


            <Modal
                title="Are you sure you want to delete this user?"
                open={isDeleteUserModalVisible}
                onCancel={() => setIsDeleteUserModalVisible(false)}
                footer={null}
            >
                <p style={{
                    color: 'red',
                    fontSize: '14px'
                }}>This action is irreversible!
                    <br />
                    Highly recommended to block the user instead of deleting.
                </p>
                <div
                    className='user__delete__actions'
                    style={{
                        display: 'flex',
                        gap: '10px',
                    }}
                >
                    <Button type='primary' danger onClick={deleteUser} loading={deleteUserLoading}>
                        Delete User
                    </Button>
                    <Button
                        type='default'
                        onClick={() => {
                            changeUserStatus({ status: BLOCKED })
                        }}
                        loading={changeStatusLoading}
                    >
                        Block User
                    </Button>
                </div>
            </Modal>

        </div>
    )
}

export default User