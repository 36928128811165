import React, { useEffect, useState } from 'react'
import { Button, Input, AutoComplete } from 'antd';
import { useDispatch } from 'react-redux';

import { PlusOutlined } from '@ant-design/icons'

import './styles.scss'
import PartnersTable from './PartnersTable';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_PROFILE_IMAGE } from '../../../../Constants/otherConstsnts';
import { searchPartner } from '../../../../../redux/api/partners.api';
import { fetchPartnersAC } from '../../../../../redux/actions/partners/partners.ac';
import usePartners from '../../../../hooks/usePartners';
import CreatePartnerProfile from './CreatePartnerProfile';

const { Search } = Input;

const AllPartners = () => {
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [searchResults, setSearchResults] = useState([])
    const [isSearchLoading, setIsSearchLoading] = useState(false)
    const [isAddProfileVisible, setIsAddProfileVisible] = useState(false)
    const partners = usePartners().partners;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        handleFetchPartners('all', page)
    }, [])

    const handleFetchPartners = async (type, page) => {
        if (page && type) {
            setLoading(true)
            await dispatch(fetchPartnersAC('all', page))
            setLoading(false)
        }
    }

    const handleSearch = async (value) => {
        setIsSearchLoading(true)
        try {
            const response = await searchPartner(value)
            addSearchResult(response.data)
        } catch (error) {
            console.error('Search Error', error)
        }

        setIsSearchLoading(false)
    }

    const onChangeSearch = (value) => {
        if (value === '') {
            setSearchResults([])
        }

        if (value.length < 3) {
            return
        }

        handleSearch(value)
    }

    const addSearchResult = (partners) => {
        const results = partners.map(partner => {
            return {
                value: partner.userName,
                label: <div className='result-row' onClick={() => navigate(`/dashboard/partners/${partner.id}`)}>
                    <img src={partner.companyNameLogoUrl || DEFAULT_PROFILE_IMAGE} alt='profile' />
                    <div className='result-row-info'>
                        <h3>{partner.companyName}</h3>
                        <p>{partner.partnerType}</p>
                    </div>
                </div>
            }
        })

        setSearchResults(results)
    }

    return (
        <div className='users'>
            <div className='users__header'>
                <h1>All Partners</h1>
                <div className='users__header__actions'>
                    <AutoComplete
                        dropdownClassName='certain-category-search-dropdown'
                        dropdownMatchSelectWidth={350}
                        style={{ width: 350 }}
                        options={searchResults}
                    >
                        <Search
                            placeholder="Search"
                            onSearch={handleSearch}
                            enterButton
                            loading={isSearchLoading}
                            onChange={(e) => onChangeSearch(e.target.value)}
                        />
                    </AutoComplete>

                    <Button type='primary' icon={<PlusOutlined />} onClick={() => setIsAddProfileVisible(true)} >
                        Add Partner Profile
                    </Button>
                </div>
            </div>

            <PartnersTable
                partners={partners}
                handleFetchPartners={handleFetchPartners}
                loading={loading}
                page={page}
                setPage={setPage}
            />

            <CreatePartnerProfile
                isVisible={isAddProfileVisible}
                setIsVisible={setIsAddProfileVisible}
                handleFetchPartners={handleFetchPartners}
            />
        </div>
    )
}

export default AllPartners