import {
    HomeOutlined,
    TeamOutlined,
    BookOutlined,
    FileDoneOutlined,
    IdcardOutlined,
    NodeIndexOutlined,
    CalendarOutlined,
    FileTextOutlined,
    DollarOutlined,
    CustomerServiceOutlined,
    DeploymentUnitOutlined,
} from "@ant-design/icons";


export const HOME = {
    key: 'home',
    icon: <HomeOutlined />,
    label: 'Home',
    href: '/home',
}
export const USERS = {
    key: 'users',
    icon: <TeamOutlined />,
    label: 'Users',
    href: '/users',
};
export const BOOKINGS = {
    key: 'bookings',
    icon: <BookOutlined />,
    label: 'Bookings',
    href: '/bookings',
    children: [
        {
            key: 'bookings/tour-packages',
            label: 'Tour Packages',
            href: '/bookings/tour-packages',
        },
        {
            key: 'bookings/custom-tours',
            label: 'Custom Tours',
            href: '/bookings/custom-tours',
        },
        {
            key: 'bookings/custom-events',
            label: 'Custom Events',
            href: '/bookings/custom-events',
        },
        {
            key: 'bookings/flights',
            label: 'Flights',
            href: '/bookings/flights',
        },
        {
            key: 'bookings/hotels',
            label: 'Hotels',
            href: '/bookings/hotels',
        },
    ]
}
export const PARTNERS = {
    key: 'partners',
    icon: <DeploymentUnitOutlined />,
    label: 'Partners',
    href: '/partners',
    children: [
        {
            key: 'partners/all',
            label: 'All',
            href: '/partners/all',
        },
        {
            key: 'partners/hotels',
            label: 'Hotels',
            href: '/partners/hotels',
        },
        {
            key: 'partners/transport',
            label: 'Transport',
            href: '/partners/transport',
        },
        {
            key: 'partners/tour-guides',
            label: 'Tour Guides',
            href: '/partners/tour-guides',
        },
    ]
};

export const TOURS = {
    key: 'tours',
    icon: <NodeIndexOutlined />,
    label: 'Tours',
    href: '/tours',
    children: [
        {
            key: 'tours/tour-packages',
            label: 'Tour Packages',
            href: '/tours/tour-packages',
        },
        {
            key: 'tours/destinations',
            label: 'Destinations',
            href: '/tours/destinations',
        },
        {
            key: 'tours/attractions',
            label: 'Attractions',
            href: '/tours/attractions',
        },
        {
            key: 'tours/activities',
            label: 'Activities',
            href: '/tours/activities',
        },
        {
            key: 'tours/events',
            label: 'Events',
            href: '/tours/events',
        }
    ]
};
export const BLOGS = {
    key: 'blogs',
    icon: <FileTextOutlined />,
    label: 'Blogs',
    href: '/blogs',
};
export const SUPPORT_TICKETS = {
    key: 'support-tickets',
    icon: <CustomerServiceOutlined />,
    label: 'Support Tickets',
    href: '/support-tickets',
};
export const PAYMENTS = {
    key: 'payments',
    icon: <DollarOutlined />,
    label: 'Payments',
    href: '/payments',
};
export const REVIEWS = {
    key: 'reviews',
    icon: <FileDoneOutlined />,
    label: 'Reviews',
    href: '/reviews',
};

export const EARNINGS = {
    key: 'earnings',
    icon: <FileDoneOutlined />,
    label: 'Eernings',
    href: '/earnings',
}

export const GUIDE_PROFILE = {
    key: 'guide-profile',
    icon: <IdcardOutlined />,
    label: 'Guide Profile',
    href: '/guide-profile',
};

export const GUIDE_TOURS = {
    key: 'guide-tours',
    icon: <NodeIndexOutlined />,
    label: 'My Tours',
    href: '/guide-tours',
};

export const GUIDE_SCHEDULE = {
    key: 'guide-schedule',
    icon: <CalendarOutlined />,
    label: 'My Schedule',
    href: '/guide-schedule',
};