import { fetchAllHotels } from '../../api/hotels.api';
import {
    FETCH_HOTELS,
    FETCH_HOTELS_SUCCESS,
    FETCH_HOTELS_FAIL,

} from '../../types';

export const fetchHotelsAC = (values) => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_HOTELS
        })
        try {

            const hotelsResponse = await fetchAllHotels(values)

            dispatch({
                type: FETCH_HOTELS_SUCCESS,
                payload: hotelsResponse.data
            })
        } catch (error) {
            dispatch({
                type: FETCH_HOTELS_FAIL,
                payload: error.message
            })
        }
    }
}