import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Drawer, Form, Image, Input, Row, Select, Spin, Upload, notification } from 'antd'
import { accommodationPackageTypes, hotelFacilityTypes, hotelRoomTypes, mealTypes } from '../../../../../../Constants/itemTypes';
import { ACCOMMODATION_CONTAINER, handleDeleteBlob, handleImageUpload } from '../../../../../../azure/blob';
import { editAccommodationPackage } from '../../../../../../../redux/api/hotels.api';
import ReactQuill from 'react-quill';

const quillModules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
        ["formula"],
        [{ size: ["small", false, "large", "huge"] }],
    ],
    clipboard: {
        matchVisual: false
    },
    imageResize: {
        modules: ['Resize', 'DisplaySize']
    }
};

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const packageOptions = Object.keys(accommodationPackageTypes).map(key => {
    return {
        label: getOptionName(accommodationPackageTypes[key]),
        value: key
    };
});


const EditPackage = ({
    editAccommodationPackageVisible,
    setEditAccommodationPackageVisible,
    hotel,
    fetchAccommodationPackages,
    selectedEditPackage,
}) => {
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('Updating package...');
    const [api, contextHolder] = notification.useNotification();
    const formref = useRef();

    useEffect(() => {
        if (selectedEditPackage) {
            formref?.current?.setFieldsValue({
                packageName: selectedEditPackage.packageName,
                packageType: selectedEditPackage.packageType,
                packageDescription: selectedEditPackage.packageDescription,
                packagePrice: selectedEditPackage.packagePrice,
                packageRooms: selectedEditPackage.packageRooms,
                packageMeals: selectedEditPackage.packageMeals?.split(','),
                packageFacilities: selectedEditPackage.packageFacilities?.split(','),
            });

            const packageImages = selectedEditPackage.packageImages?.split(',');
            if (packageImages?.length > 0) {
                const newFileList = packageImages.map((image, index) => {
                    return {
                        uid: index,
                        name: image,
                        status: 'done',
                        url: image,
                    };
                });
                setFileList(newFileList);
            }
        }
    }, [selectedEditPackage]);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const onFinish = async (values) => {
        setIsLoading(true);
        try {
            Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

            let imageUrls = [];

            const name = hotel?.accommodationName?.replace(/\s/g, '_').toLowerCase() + '_' + values.packageName.replace(/\s/g, '_').toLowerCase();

            const newImages = fileList.filter(file => !file.url);
            const deletedImages = selectedEditPackage.packageImages?.split(',').filter(image => !fileList.find(file => file.url === image));

            if (newImages?.length > 0) {
                setLoadingText('Uploading images...');
                const imageUploadPromises = newImages.map((file, index) => {
                    setLoadingText(`Uploading image ${index + 1}`);
                    return handleImageUpload(
                        ACCOMMODATION_CONTAINER,
                        file.originFileObj,
                        file.size,
                        `${name}_${file.name}`
                    ).then(blobRes => {
                        imageUrls.push(blobRes.blockBlobClient.url);
                        setLoadingText(`Uploaded image ${index + 1}`);
                    });
                });
                await Promise.all(imageUploadPromises);
            }


            // delete blobs
            if (deletedImages?.length > 0) {
                setLoadingText('Deleting Images');
                const imageDeletePromises = deletedImages.map(async (image, index) => {
                    const blobName = getBlobName(image);
                    setLoadingText(`Deleting image ${index + 1}`);
                    return handleDeleteBlob(ACCOMMODATION_CONTAINER, blobName);
                });
                await Promise.all(imageDeletePromises);
            }

            let updatedImages = [
                ...fileList.filter((file) => file.url).map((file) => file.url),
                ...imageUrls
            ];

            delete values.packageImages;

            if (updatedImages?.length > 0) {
                updatedImages = updatedImages.map(url => sanitizeBlobUrls(url));
            }

            values.id = selectedEditPackage.id;
            values.accommodationType = hotel.accommodationType;
            values.accommodationCity = hotel.accommodationCity;
            values.accommodationRating = hotel.starRating;
            values.accommodationTags = hotel.tags;
            values.packageMeals = values.packageMeals.join(',');
            values.packageFacilities = values.packageFacilities.join(',');
            values.packageImages = updatedImages?.length > 0 ? updatedImages.join(',') : null;

            setLoadingText('Updating package...');
            const response = await editAccommodationPackage(values);
            if (response.status === 200) {
                api.success({
                    message: 'Success',
                    description: 'Package updated successfully'
                });
                fetchAccommodationPackages();
                setTimeout(() => {
                    setEditAccommodationPackageVisible(false);
                }, 2000);

            } else {
                api.error({
                    message: 'Error',
                    description: 'An error occurred while updating the pakage. Please try again.'
                });
            }

        } catch (error) {
            console.log(error);
            api.error({
                message: 'Error',
                description: `An error occurred while updating the pakage. Please try again. ${error.message}`
            });
        }
        setIsLoading(false);
    }

    const sanitizeBlobUrls = (blobUrl) => {
        const splitUrl = blobUrl.split('?')
        return splitUrl[0]
    }

    const getBlobName = (blobUrl) => {
        const splitUrl = blobUrl.split('/')
        return splitUrl[splitUrl.length - 1]
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Edit Package"
                width={720}
                open={editAccommodationPackageVisible}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                onClose={() => setEditAccommodationPackageVisible(false)}
            >
                <Spin spinning={isLoading} tip={loadingText}>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        ref={formref}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="packageName"
                                    label="Package Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter package name',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter package name" showCount maxLength={50} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="packageType"
                                    label="Package Type"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a package type',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select a package type"
                                        options={packageOptions}
                                        maxCount={1}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="packageDescription"
                                    label="Package Description"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Add a description for the package',
                                        },
                                    ]}
                                >
                                    <ReactQuill
                                        theme="snow"
                                        limits={{ maxLength: 500 }}
                                        modules={quillModules}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="packagePrice"
                                    label="Package Price (USD)"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the package price',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter the package price (in USD)" type='number' />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="packageRooms"
                                    label="Package Room"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter abot room',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select a room type"
                                        options={
                                            Object.keys(hotelRoomTypes).map(key => {
                                                return {
                                                    label: getOptionName(hotelRoomTypes[key]),
                                                    value: key
                                                };
                                            })
                                        }
                                        maxCount={1}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="packageMeals"
                                    label="Included Meal Info"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter meal info'
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        mode='multiple'
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select included meals"
                                        options={
                                            Object.keys(mealTypes).map(key => {
                                                return {
                                                    label: getOptionName(mealTypes[key]),
                                                    value: key
                                                };
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="packageFacilities"
                                    label="Package Facilities"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter package facilities',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        mode='multiple'
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select package facilities"
                                        options={
                                            Object.keys(hotelFacilityTypes).map(key => {
                                                return {
                                                    label: getOptionName(hotelFacilityTypes[key]),
                                                    value: key
                                                };
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label='Upload Images (Max 10)'
                                    name='packageImages'
                                >
                                    <Upload
                                        listType="picture-card"
                                        fileList={fileList}
                                        onPreview={handlePreview}
                                        onChange={handleChange}
                                        multiple={true}
                                        maxCount={10}
                                        beforeUpload={(file) => {
                                            return false;
                                        }}
                                    >
                                        {fileList.length <= 10 && '+ Upload'}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item>
                            <Button type='primary' htmlType='submit' loading={isLoading}>
                                Update Package
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Drawer>


            {previewImage && (
                <Image
                    wrapperStyle={{
                        display: 'none',
                    }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </div>
    )
}

export default EditPackage