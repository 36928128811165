import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import ImgCrop from 'antd-img-crop';

import { BLOB_BASE_URL, handleImageUpload, USER_PROFILE_CONTAINER } from '../../../../../../azure/blob';
import { accommodationTypes, experienceTypes, TravelTypes } from '../../../../../../Constants/itemTypes';
import useMyProfile from '../../../../../../hooks/useMyProfile'

import { Button, Divider, notification, Upload, Form, Row, Col, Input, Tooltip, Select, Spin } from 'antd';
import {
    CloseOutlined, SaveOutlined, CheckCircleTwoTone, ExclamationCircleTwoTone
} from '@ant-design/icons';

import './styles.scss'
import { editUserProfile } from '../../../../../../../redux/api/users.api';
import { useDispatch } from 'react-redux';
import { fetchProfileAC } from '../../../../../../../redux/actions/myProfile/myProfile.ac';

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const accommodationOptions = Object.keys(accommodationTypes).map(key => {
    return {
        label: getOptionName(accommodationTypes[key]),
        value: key
    };
});

const experienceOptions = Object.keys(experienceTypes).map(key => {
    return {
        label: getOptionName(experienceTypes[key]),
        value: key
    };
});

const travelOptions = Object.keys(TravelTypes).map(key => {
    return {
        label: getOptionName(TravelTypes[key]),
        value: key
    };
});

const EditBasicProfile = () => {
    const myProfile = useMyProfile().userProfile
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [api, contextHolder] = notification.useNotification();
    const { instance } = useMsal();
    const [fileList, setFileList] = useState([]);
    const [snythingChanged, setAnythingChanged] = useState(false)
    const [isprofilePicChanged, setIsProfilePicChanged] = useState(false)
    const formRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        if (myProfile) {
            const oid = instance.getActiveAccount().idTokenClaims.oid
            if (myProfile.id !== oid) {
                navigate('/dashboard/home')
            }

            formRef.current.setFieldsValue({
                firstName: myProfile.firstName,
                lastName: myProfile.lastName,
                userName: myProfile.userName,
                socialName: myProfile.socialName,
                contactNumber: myProfile.contactNumber,
                favouriteAccommodationTypes: myProfile.favouriteAccommodationTypes?.split(','),
                favouriteExperienceType: myProfile.favouriteExperienceType?.split(','),
                favouriteTravelTypes: myProfile.favouriteTravelTypes?.split(','),
            })

            if (myProfile.profilePicUrl) {
                setFileList([
                    {
                        uid: '-1',
                        name: 'image.png',
                        status: 'done',
                        url: myProfile.profilePicUrl,
                    }
                ])
            }
        }
    }, [myProfile])


    const onChange = ({ fileList: newFileList }) => {
        setAnythingChanged(true)
        setIsProfilePicChanged(true)
        setFileList(newFileList);
    };
    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const handleUpdate = async (values) => {
        setLoading(true)
        let profilePicUrl = myProfile?.profilePicUrl

        if (isprofilePicChanged) {
            const blobRes = await handleImageUpload(
                USER_PROFILE_CONTAINER,
                fileList[0].originFileObj,
                fileList[0].size,
                `${myProfile?.id}.jpg`
            )
            if (blobRes?.blockBlobClient.url) {
                profilePicUrl = `${BLOB_BASE_URL}/${USER_PROFILE_CONTAINER}/${myProfile?.id}.jpg`
                values.profilePicUrl = profilePicUrl
            }
        }

        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

        if (values?.favouriteAccommodationTypes && values?.favouriteAccommodationTypes.length > 0) {
            values.favouriteAccommodationTypes = values.favouriteAccommodationTypes.join(',')
        }
        if (values?.favouriteExperienceType && values?.favouriteExperienceType.length > 0) {
            values.favouriteExperienceType = values.favouriteExperienceType.join(',')
        }
        if (values?.favouriteTravelTypes && values?.favouriteTravelTypes.length > 0) {
            values.favouriteTravelTypes = values.favouriteTravelTypes.join(',')
        }

        setLoading(true)
        try {
            const res = await editUserProfile(values)
            if (res?.status === 200) {
                api.success({
                    message: 'Profile Updated Successfully',
                    placement: 'topRight',
                });
                dispatch(fetchProfileAC())
            } else {
                api.error({
                    message: 'Profile Update Failed',
                    placement: 'topRight',
                });
            }
        } catch (error) {
            api.error({
                message: 'Profile Update Failed',
                placement: 'topRight',
            });
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className='edit__user__content'>
            {contextHolder}
            <Spin spinning={loading} size='large' tip='Updating Profile...'>
                <Form
                    name='basic'
                    onFinish={handleUpdate}
                    layout='vertical'
                    ref={formRef}
                    onChange={() => setAnythingChanged(true)}
                >
                    <div className='edit__user__content__header'>
                        <h2>Personal Information</h2>
                        <div className='edit__user__header__actions'>
                            <Button type='default' icon={<CloseOutlined />} onClick={() => navigate('/dashboard/home')}>Discard</Button>
                            <Button
                                type='primary'
                                icon={<SaveOutlined />}
                                htmlType='submit'
                                disabled={!snythingChanged}
                                loading={loading}
                            >
                                Update
                            </Button>
                        </div>
                    </div>

                    <div className='user__profile__pic'>
                        <ImgCrop rotationSlider>
                            <Upload
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onChange}
                                onPreview={onPreview}
                                multiple={false}
                                beforeUpload={(file) => {
                                    return false;
                                }}
                            >
                                {fileList.length < 1 && '+ Upload'}
                            </Upload>
                        </ImgCrop>
                    </div>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label='First Name'
                                name='firstName'
                            >
                                <Input placeholder='First Name' />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label='Last Name'
                                name='lastName'
                            >
                                <Input placeholder='Last Name' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label='User Name'
                                name='userName'
                                rules={[{ required: true, message: 'Please input your User Name!' }]}
                            >
                                <Input placeholder='User Name' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Social Name'
                                name='socialName'
                            >
                                <Input placeholder='Social Name' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label='Phone Number'
                                name='contactNumber'
                            >
                                <Input
                                    placeholder='Phone Number'
                                    suffix={
                                        myProfile?.contactNumber &&
                                        <Tooltip title={myProfile?.isPhoneVerified ? 'Phone Verified' : 'Phone Not Verified'}>
                                            {myProfile?.isPhoneVerified ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <ExclamationCircleTwoTone twoToneColor="#eb2f96" />}
                                        </Tooltip>
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />
                    <h2>Favourites</h2>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label='Favourite Accommodation Type'
                                name='favouriteAccommodationTypes'
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Please select favourite accommodation types"
                                    options={accommodationOptions}
                                    maxCount={5}
                                    onChange={() => setAnythingChanged(true)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Favourite Experience Type'
                                name='favouriteExperienceType'
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Please select favourite experience types"
                                    options={experienceOptions}
                                    maxCount={5}
                                    onChange={() => setAnythingChanged(true)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label='Favourite Travel Type'
                                name='favouriteTravelTypes'
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Please select favourite travel types"
                                    options={travelOptions}
                                    maxCount={5}
                                    onChange={() => setAnythingChanged(true)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </div>
    )
}

export default EditBasicProfile