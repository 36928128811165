import axios from 'axios';

import { API_BASE_URL } from '../../App/azure/api';
import { msalInstance } from '../../App/azure/authConfig';
import { getAccessToken } from '../../App/azure/auth';

export const addNewBlog = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/addNewBlog?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const editBlog = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/editBlog?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}


export const getAllBlogs = async (page, pageSize) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getAllBlogs?page=${page}&pageSize=${pageSize}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getBlogById = async (blogId) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getBlogById?blogId=${blogId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const changeBlogStatus = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/changeBlogStatus?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const deleteBlog = async (id) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/deleteBlog?id=${id}&clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}