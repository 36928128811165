import { EditOutlined, SendOutlined, SyncOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Empty, Form, Modal, notification, Row, Select, Tag, Upload } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { addSupportRequestMessage, changeSupportTicketStatus, getSupportRequestById, getSupportRequestMessages } from '../../../../../redux/api/support.api'
import LoadingComp from '../../../../components/LoadingComp'

import './styles.scss'
import ReactQuill from 'react-quill'
import { capitalizeFisrtLetterEachWord } from '../../../../helpers/nameRender'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { DEFAULT_PROFILE_IMAGE } from '../../../../Constants/otherConstsnts'
import useMyProfile from '../../../../hooks/useMyProfile'
import { handleImageUpload, SUPPORT_CONTAINER } from '../../../../azure/blob'
import { supportRequestStatus } from '../../../../Constants/itemTypes'

const quillModules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        ["clean"],
    ],
};

const SupportTicket = () => {
    const url = window.location.pathname
    const ticketId = url.split('/').pop()
    const [supportTicket, setSupportTicket] = useState(null)
    const [loading, setLoading] = useState(false)
    const [messages, setMessages] = useState([])
    const [loadingMessages, setLoadingMessages] = useState(false)
    const [fileList, setFileList] = useState([])
    const [loadingSendMsg, setLoadingSendMsg] = useState(false)
    const [api, contextHolder] = notification.useNotification();
    const myProfile = useMyProfile();
    const formRef = useRef()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const statusFormRef = useRef()
    const [loadingStatus, setLoadingStatus] = useState(false)

    useEffect(() => {
        handleGetSupportTicket()
    }, [])

    useEffect(() => {
        if (supportTicket) {
            handleGetMessages()
            const interval = setInterval(() => {
                handleGetMessages()
            }
                , 60000)
            return () => clearInterval(interval)
        }
    }, [supportTicket])

    useEffect(() => {
        if (supportTicket) {
            statusFormRef?.current?.setFieldsValue({
                status: supportTicket.status
            })
        }
    }, [supportTicket, isModalOpen])

    const handleGetSupportTicket = async () => {
        setLoading(true)
        const response = await getSupportRequestById(ticketId)
        if (response.status === 200) {
            setSupportTicket(response.data[0])
        }
        setLoading(false)
    }

    const handleGetMessages = async () => {
        setLoadingMessages(true)
        const response = await getSupportRequestMessages(ticketId)
        if (response.status === 200) {
            setMessages(response.data)
        }
        setLoadingMessages(false)
    }

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const handleSubmitReply = async (values) => {
        setLoadingSendMsg(true)
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

        try {
            let imageUrls = [];
            const name = 'support_' + myProfile?.userProfile?.id + '_' + new Date().getTime();
            values.userOrAdmin = 'user';
            values.supportRequestId = ticketId;

            if (fileList.length > 0) {
                const totalSize = fileList.reduce((acc, file) => acc + file.size, 0)
                console.log('totalSize', totalSize)
                if (totalSize > 10485760) {
                    api.error({
                        message: 'Total size of files should not exceed 10MB'
                    })
                    setLoadingSendMsg(false)
                    return
                }

                const imageUploadPromises = fileList.map((file, index) => {
                    return handleImageUpload(
                        SUPPORT_CONTAINER,
                        file.originFileObj,
                        file.size,
                        `${name}_${file.name}`
                    ).then(blobRes => {
                        imageUrls.push(blobRes.blockBlobClient.url);
                    });
                });
                await Promise.all(imageUploadPromises);
            }

            delete values.attachments;

            if (imageUrls.length > 0) {
                imageUrls = imageUrls.map(url => sanitizeBlobUrls(url));
                values.attachments = imageUrls.join(',');
            }

            const response = await addSupportRequestMessage(values);
            if (response.status === 200) {
                api.success({
                    message: 'Success',
                    description: 'Message sent successfully'
                });

                formRef.current.resetFields();
                setFileList([]);
                handleGetMessages();

            } else {
                console.log(response);
                api.error({
                    message: 'Error',
                    description: 'An error occurred while sending the message. Please try again later.'
                });
            }
        } catch (error) {
            console.log(error);
            api.error({
                message: 'Error',
                description: 'An error occurred while sending the message. Please try again later.'
            });
        }
        setLoadingSendMsg(false)
    }

    const sanitizeBlobUrls = (blobUrl) => {
        const splitUrl = blobUrl.split('?')
        return splitUrl[0]
    }

    const handleChangeStatus = async (values) => {
        setLoadingStatus(true)
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);
        values.id = ticketId;

        const response = await changeSupportTicketStatus(values);
        if (response.status === 200) {
            api.success({
                message: 'Success',
                description: 'Status changed successfully'
            });
            setIsModalOpen(false)
            handleGetSupportTicket()
        } else {
            console.log(response);
            api.error({
                message: 'Error',
                description: 'An error occurred while changing the status. Please try again later.'
            });
        }

        setLoadingStatus(false)
    }

    return (
        <div className='support section_main support_ticket_single' style={{ marginBottom: '60px' }}>
            {contextHolder}
            <div className='top_padding_container'>
                {
                    loading ?
                        <div className='loading_container'>
                            <LoadingComp />
                        </div>
                        :
                        <>
                            <div className='ticket_header'>
                                <div className='ticket_header__left'>
                                    <h1 className='heder_2'>
                                        {supportTicket?.subject}
                                    </h1>
                                    <Tag
                                        color={
                                            supportTicket?.status === 'PENDING' ? 'orange' :
                                                supportTicket?.status === 'CLOSED' ? 'red' :
                                                    supportTicket?.status === 'IN_PROGRESS' ? 'blue' :
                                                        'green'
                                        }
                                    >
                                        {supportTicket?.status ? capitalizeFisrtLetterEachWord(supportTicket?.status) : 'Pending'}
                                    </Tag>
                                </div>
                                <Button
                                    type='primary'
                                    onClick={() => setIsModalOpen(true)}
                                >
                                    <EditOutlined />
                                    Change Status
                                </Button>
                            </div>
                            <div className='meta'>
                                <p>
                                    Crated on: {supportTicket?.createdAt}
                                </p>
                                <p>
                                    Support Type: {supportTicket?.supportType}
                                </p>
                                <p>
                                    User Type: {supportTicket?.userType ? capitalizeFisrtLetterEachWord(supportTicket?.userType) : 'User'}
                                </p>
                                <p>
                                    Impact:  <Tag
                                        color={supportTicket?.impactLevel === 'low' ? 'green' : supportTicket?.impactLevel === 'medium' ? 'orange' : 'red'}
                                    >
                                        {supportTicket?.impactLevel ? capitalizeFisrtLetterEachWord(supportTicket?.impactLevel) : 'Low'}
                                    </Tag>
                                </p>
                            </div>
                            <div className='meta'>
                                {supportTicket?.preferedContactMethod &&
                                    <p>
                                        Prefered Contact Method
                                        : {supportTicket?.preferedContactMethod && capitalizeFisrtLetterEachWord(supportTicket?.preferedContactMethod)}
                                    </p>}
                                {supportTicket?.preferedTimeToContact && <p>
                                    Prefered Time to Contact
                                    : {supportTicket?.preferedTimeToContact && capitalizeFisrtLetterEachWord(supportTicket?.preferedTimeToContact)}
                                </p>}
                                {supportTicket?.contactNo && <p>
                                    Contact No
                                    : {supportTicket?.contactNo && capitalizeFisrtLetterEachWord(supportTicket?.contactNo)}
                                </p>}
                                {supportTicket?.origin && <p>
                                    Origin (Page)
                                    : {supportTicket?.origin && capitalizeFisrtLetterEachWord(supportTicket?.origin)}
                                </p>}
                                {supportTicket?.email && <p>
                                    Email
                                    : {supportTicket?.email}
                                </p>}
                                {supportTicket?.firstName && <p>
                                    User First Name
                                    : {supportTicket?.firstName}
                                </p>}
                                {supportTicket?.lastName && <p>
                                    User Last Name
                                    : {supportTicket?.lastName}
                                </p>}
                            </div>
                            <ReactQuill
                                value={supportTicket?.message}
                                readOnly
                                theme='bubble'
                            />
                            {supportTicket?.userType === 'AUTH' &&
                                <div className='attachments'>
                                    <p>Attachments: </p>
                                    {
                                        supportTicket?.attachments?.split(',')?.map((attachment, index) => (
                                            <div key={index} className='attachment'>
                                                <a href={attachment} target='_blank' rel='noreferrer'>
                                                    attachment {index + 1}
                                                </a>
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                            {supportTicket?.userType === 'AUTH' &&
                                <>
                                    <Divider />


                                    <div className='messages'>
                                        <h2>Messages</h2>

                                        {
                                            loadingMessages &&
                                            <div className='loading'>
                                                <SyncOutlined spin /> Loading messages...
                                            </div>
                                        }
                                        {
                                            !loadingMessages && messages.length === 0 &&
                                            <div className='no_messages'>
                                                <Empty description='No messages found' />
                                            </div>
                                        }
                                        {
                                            messages.length > 0 &&
                                            <div className='messages_container'>
                                                {
                                                    messages.map((message, index) => (
                                                        <div className='message_card'
                                                            key={index}
                                                        >
                                                            <div className='message_card__header'>
                                                                <div className='sender'>
                                                                    <LazyLoadImage
                                                                        src={message?.user?.profilePicUrl || DEFAULT_PROFILE_IMAGE}
                                                                        alt='avatar'
                                                                        effect='blur'
                                                                    />
                                                                </div>
                                                                <div className='sender_details'>
                                                                    <p className='author'>
                                                                        {message?.user?.userName}
                                                                    </p>
                                                                    <p>
                                                                        {message.createdAt}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <ReactQuill
                                                                value={message.message}
                                                                readOnly
                                                                theme='bubble'
                                                            />
                                                            {message?.attachments &&
                                                                <div className='attachments'>
                                                                    <p>Attachments: </p>
                                                                    {
                                                                        message.attachments?.split(',')?.map((attachment, index) => (
                                                                            <div key={index} className='attachment'>
                                                                                <a href={attachment} target='_blank' rel='noreferrer'>
                                                                                    attachment {index + 1}
                                                                                </a>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        }
                                        {(supportTicket?.status !== supportRequestStatus.RESOLVED && supportTicket?.status !== supportRequestStatus.CLOSED) &&
                                            <div className='your_reply'>
                                                <h2>Reply</h2>
                                                <Form
                                                    layout='vertical'
                                                    onFinish={handleSubmitReply}
                                                    ref={formRef}
                                                >
                                                    <Row gutter={16}>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                label='Message'
                                                                name='message'
                                                                rules={[{ required: true, message: 'Please enter message' }]}
                                                            >
                                                                <ReactQuill
                                                                    modules={quillModules}
                                                                    placeholder='Enter your message here...'
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Form.Item
                                                        label='Upload Attachments (Max 3)'
                                                        name='attachments'
                                                    >
                                                        <Upload
                                                            listType="picture-card"
                                                            fileList={fileList}
                                                            onChange={handleChange}
                                                            multiple={true}
                                                            maxCount={10}
                                                            beforeUpload={(file) => {
                                                                return false;
                                                            }}
                                                        >
                                                            {fileList.length <= 3 && '+ Upload'}
                                                        </Upload>
                                                    </Form.Item>

                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <Button
                                                            loading={loadingSendMsg}
                                                            type='primary'
                                                            htmlType='submit'
                                                        >
                                                            Send Reply
                                                            <SendOutlined />
                                                        </Button>
                                                    </div>

                                                </Form>
                                            </div>
                                        }
                                    </div>
                                </>
                            }
                        </>
                }
            </div>

            <Modal
                title="Change Ticket Status"
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
            >
                <Form
                    layout='vertical'
                    onFinish={handleChangeStatus}
                    ref={statusFormRef}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Status'
                                name='status'
                                rules={[{ required: true, message: 'Please select status' }]}
                            >
                                <Select
                                    placeholder='Select status'
                                >
                                    <Select.Option value='PENDING'>Pending</Select.Option>
                                    <Select.Option value='IN_PROGRESS'>In Progress</Select.Option>
                                    <Select.Option value='RESOLVED'>Resolved</Select.Option>
                                    <Select.Option value='CLOSED'>Closed</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Button
                            loading={loadingStatus}
                            type='primary'
                            htmlType='submit'
                        >
                            Change Status
                        </Button>
                    </div>
                </Form>
            </Modal>
        </div>
    )
}

export default SupportTicket