import {
    FETCH_PARTNERS,
    FETCH_PARTNERS_SUCCESS,
    FETCH_PARTNERS_FAIL,

} from '../../types';

const initialState = {
    loading: false,
    partners: [],
    error: null
};

export const partnersReducer = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case FETCH_PARTNERS:
            return {
                ...state,
                loading: true
            };
        case FETCH_PARTNERS_SUCCESS:
            return {
                ...state,
                loading: false,
                partners: action.payload,
                error: null
            };
        case FETCH_PARTNERS_FAIL:
            return {
                ...state,
                loading: false,
                partners: [],
                error: action.payload
            };
        default:
            return state;
    }
}

