import { PublicClientApplication } from "@azure/msal-browser";

export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_signupsignin"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://visitpearl.b2clogin.com/visitpearl.onmicrosoft.com/B2C_1_signin",
        },
    },
    authorityDomain: "visitpearl.b2clogin.com"
};


export const MSAL_CONFIG = {
    auth: {
        clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
}

const LOGIN_REQUEST = {
    scopes: ["openid", "profile", "offline_access", "https://visitpearl.onmicrosoft.com/api/Read", "https://visitpearl.onmicrosoft.com/api/Write"],
};

const TOKEN_REQUEST = {
    scopes: ["User.Read.All", "user.read", "https://visitpearl.onmicrosoft.com/api/Read", "https://visitpearl.onmicrosoft.com/api/Write"],
};

const GRAPH_CONFIG = {
    graphUsersEndpoint: "https://graph.microsoft.com/v1.0/me"
};

const msalInstance = new PublicClientApplication(MSAL_CONFIG);
msalInstance.initialize();

export {
    LOGIN_REQUEST,
    TOKEN_REQUEST,
    GRAPH_CONFIG,
    msalInstance
};