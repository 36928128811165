import axios from 'axios';

import { API_BASE_URL } from '../../App/azure/api';
import { msalInstance } from '../../App/azure/authConfig';
import { getAccessToken } from '../../App/azure/auth';

export const fetchProfile = async () => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getProfile`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}