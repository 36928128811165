import { useEffect, useState } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch } from 'react-redux';

import { fetchProfileAC } from '../redux/actions/myProfile/myProfile.ac';

import { ALLOWED_ROLES } from './Constants/roles';
import { LOGIN_REQUEST } from './azure/authConfig';

import Dashboard from './Pages/Dashboard';
import NotAllowed from './Pages/NotAllowed';
import PleaseWait from './components/PleaseWait';

const MsalView = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authError, setAuthError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeAccount) {
      autherizeUser();
    }

  }, [instance, activeAccount]);

  useEffect(() => {
    if (isAuthenticated) {
      handleProfileRequest();
    }
  }, [isAuthenticated]);

  const handleLoginRedirect = () => {
    instance.handleRedirectPromise()
      .then((response) => {
        if (response) {
          instance.setActiveAccount(response.account);
          checkRoles(response);
        } else {
          instance.loginRedirect(LOGIN_REQUEST)
            .catch((e) => {
              console.error('User Login Error', e);
            });
        }
      })
      .catch((error) => {
        console.error('Redirect Handling Error', error);
        if (error.toString().includes('AADB2C99002')) {
          setAuthError('not-allowed');
        }
      });
  }

  const autherizeUser = () => {
    instance.acquireTokenSilent({
      ...LOGIN_REQUEST,
    })
      .then((response) => {
        checkRoles(response);
      })
      .catch((e) => {
        console.error('User Autherization Error', e);
      });
  }

  const checkRoles = async (response) => {
    if (response.account.idTokenClaims.extension_Roles) {
      const role = response.account.idTokenClaims.extension_Roles;
      if (ALLOWED_ROLES.includes(role)) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
        window.location.href = '/not-allowed';
      }
    } else {
      setIsAuthenticated(false);
      window.location.href = '/not-allowed';
    }
  }

  const handleProfileRequest = async () => {
    await dispatch(fetchProfileAC());
  }

  return (
    <div className='App'>
      <AuthenticatedTemplate>
        {activeAccount ? () => <>
          {isAuthenticated ? <Dashboard /> : < PleaseWait />}
        </> : handleLoginRedirect}
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        {
          !authError ? < PleaseWait /> : authError === 'not-allowed' ? <NotAllowed /> : null
        }
        {handleLoginRedirect()}
      </UnauthenticatedTemplate>
    </div>
  )
}

function App() {
  useEffect(() => {
    getIpAdress();
  }, []);

  const getIpAdress = async () => {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    localStorage.setItem('vp-ip', data.ip);
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MsalView />} />
        <Route path="/not-allowed" element={<NotAllowed />} />
        <Route path='dashboard/*' element={<Dashboard />} />
        <Route path='*' element={<MsalView />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
