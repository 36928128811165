import React, { useRef, useState } from 'react';
import { Button, Col, Divider, Drawer, Form, Image, Input, Row, Select, Spin, Upload, notification } from 'antd';
import { vehiclesTypes } from '../../../../../../Constants/itemTypes';
import { handleImageUpload, PARTNERS_BLOB } from '../../../../../../azure/blob';
import { v4 as uuidv4 } from 'uuid'
import { addGuideVehicle } from '../../../../../../../redux/api/guides.api';

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const vehicleOptions = Object.keys(vehiclesTypes).map(key => {
    return {
        label: getOptionName(vehiclesTypes[key]),
        value: key
    };
});

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const AddVehicle = ({ isVisible, setIsVisible, guide, hadleGetGuideVehicles }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const formRef = useRef(null);
    const [fileList, setFileList] = useState([])
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const [shortDescriptionCharCount, setShortDescriptionCharCount] = useState(0)

    const onFinish = async (values) => {
        setIsLoading(true);
        try {
            Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

            let imageUrls = [];

            // Upload images
            if (fileList.length > 0) {
                setLoadingText('Uploading Images');
                const imageUploadPromises = fileList.map(async (file, index) => {
                    setLoadingText(`Uploading image ${index + 1}`);

                    let id = uuidv4();
                    id = id.replace(/-/g, '');
                    let extention = file?.name?.split('.').pop();
                    let name = `${id}.${extention}`;

                    return handleImageUpload(
                        PARTNERS_BLOB,
                        file.originFileObj,
                        file.size,
                        name
                    ).then(blobRes => {
                        imageUrls.push(blobRes.blockBlobClient.url);
                        setLoadingText(`Uploaded image ${index + 1}`);
                    });
                });
                await Promise.all(imageUploadPromises);
            }

            delete values.vehicleImages;
            values.userId = guide.userId;
            values.tourGuideId = guide.id;

            if (imageUrls.length > 0) {
                imageUrls = imageUrls.map(url => sanitizeBlobUrls(url));
                values.vehicleImages = imageUrls.join(',');
            }

            setLoadingText('Adding to Database');
            const response = await addGuideVehicle(values);
            if (response.status === 200) {
                api.success({
                    message: 'Vehicle Added Successfully',
                    description: 'You have successfully added a new vehicle to the guide'
                });

                hadleGetGuideVehicles();
                setIsVisible(false);
            } else {
                api.error({
                    message: 'Error Adding Vehicle',
                    description: 'There was an error adding the vehicle. Please try again'
                });
            }
        } catch (error) {
            console.log(error);
            api.error({
                message: 'Error',
                description: `An error occurred while adding the vehicle. Please try again. ${error.message}`
            });
        }
        setIsLoading(false);
    };

    const sanitizeBlobUrls = (blobUrl) => {
        const splitUrl = blobUrl.split('?')
        return splitUrl[0]
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Add Tour Guide Vehicle"
                width={720}
                open={isVisible}
                onClose={() => setIsVisible(false)}
            >
                <Spin spinning={isLoading} tip={loadingText}>
                    <Form layout="vertical" onFinish={onFinish} ref={formRef}>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label='Vehicle Type'
                                    name='vehicleType'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select vehicle type'
                                        }
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Add vehicle type"
                                        options={vehicleOptions}
                                        maxCount={1}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label='Vehicle Brand'
                                    name='vehicleBrand'
                                >
                                    <Input placeholder='Vehicle Brand' showCount maxLength={50} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label='Enter Vehicle Model'
                                    name='vehicleModel'
                                >
                                    <Input placeholder='Vehicle Model' showCount maxLength={50} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label='Vehicle Year'
                                    name='vehicleYear'
                                >
                                    <Input placeholder='Vehicle Year' type='number' />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label='Vehicle Plate Number'
                                    name='vehiclePlateNumber'
                                >
                                    <Input placeholder='Vehicle Plate Number' showCount maxLength={50} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label='vehicleColor'
                                    name='vehicleColor'
                                >
                                    <Input placeholder='Vehicle Color' showCount maxLength={50} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label='Vehicle Capacity'
                                    name='vehicleCapacity'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter vehicle capacity'
                                        }
                                    ]}
                                >
                                    <Input placeholder='Vehicle Capacity' type='number' min={1} max={50} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label='Baggage Capacity'
                                    name='baggageCapacity'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter baggage capacity'
                                        }
                                    ]}
                                >
                                    <Input placeholder='Baggage Capacity' type='number' min={1} max={50} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Divider />

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label='Min Milage'
                                    name='minMilage'
                                >
                                    <Input placeholder='Min Milage' type='number' />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label='Max Milage'
                                    name='maxMilage'
                                >
                                    <Input placeholder='Max Milage' type='number' />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label='Price Per Km'
                                    name='pricePerKm'
                                >
                                    <Input placeholder='Price Per Km' />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label='Price Per Day'
                                    name='pricePerDay'
                                >
                                    <Input placeholder='Price Per Day' />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label='Price Margin (%)'
                                    name='priceMargin'
                                >
                                    <Input placeholder='Price Margin' />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Divider />

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label={`Vehicle Description ( ${shortDescriptionCharCount}/1000 characters)`}
                                    name='vehicleDescription'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter vehicle description'
                                        }
                                    ]}
                                >
                                    <Input.TextArea placeholder='Vehicle Description' showCount maxLength={1000} onChange={(e) => setShortDescriptionCharCount(e.target.value.length)} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label='Upload Vehicle Images (Max 10)'
                                    name='vehicleImages'
                                >
                                    <Upload
                                        listType="picture-card"
                                        fileList={fileList}
                                        onPreview={handlePreview}
                                        onChange={handleChange}
                                        multiple={true}
                                        maxCount={10}
                                        beforeUpload={(file) => {
                                            return false;
                                        }}
                                    >
                                        {fileList.length <= 10 && '+ Upload'}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item>
                            <Button type='primary' htmlType='submit'>
                                Add Tour Guide Vehicle
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Drawer>

            {previewImage && (
                <Image
                    wrapperStyle={{
                        display: 'none',
                    }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </div>
    );
};

export default AddVehicle;
