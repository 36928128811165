const SUPER_ADMIN = 'super_admin';
const ADMIN = 'admin';
const MANAGER = 'manager';
const PARTNERSHIP_MANAGER = 'partnership_manager';
const CUSTOMER_RELATIONS_MANAGER = 'customer_relations_manager';
const SUPPORT_AGENT = 'support_agent';
const TRIP_COORDINATOR = 'trip_coordinator';

const HOTEL_MANAGER = 'hotel_manager';
const TRANSPORT_AGENT = 'transport_agent';
const TOUR_GUIDE = 'tour_guide';
const DRIVER = 'driver';

const USER = 'user';

export {
    SUPER_ADMIN,
    ADMIN,
    MANAGER,
    PARTNERSHIP_MANAGER,
    CUSTOMER_RELATIONS_MANAGER,
    SUPPORT_AGENT,
    TRIP_COORDINATOR,
    HOTEL_MANAGER,
    TRANSPORT_AGENT,
    TOUR_GUIDE,
    DRIVER,
    USER
}

export const ALLOWED_ROLES = [
    SUPER_ADMIN,
    ADMIN,
    MANAGER,
    PARTNERSHIP_MANAGER,
    CUSTOMER_RELATIONS_MANAGER,
    SUPPORT_AGENT,
    TRIP_COORDINATOR,
    HOTEL_MANAGER,
    TRANSPORT_AGENT,
    TOUR_GUIDE,
    DRIVER,
]