import { Layout } from 'antd'
import React, { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { fetchProfileAC } from '../../../redux/actions/myProfile/myProfile.ac'
import useMyProfile from '../../hooks/useMyProfile'

import SideBar from './Common/SideBar'
import DashboardHeader from './Common/DashboardHeader'
import DashboardContent from './Common/DashboardContent'
import PleaseWait from '../../components/PleaseWait'

import Footer from './Common/Footer'

import { LOGIN_REQUEST } from '../../azure/authConfig'
import { ALLOWED_ROLES } from '../../Constants/roles'

import './styles.scss'


const Dashboard = () => {
    const [collapsed, setCollapse] = useState(false)
    const myProfile = useMyProfile().userProfile
    const { instance } = useMsal();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const url = window.location.pathname;

    useEffect(() => {
        if (instance.getActiveAccount() && !myProfile) {
            handleProfileRequest();
        }
    }, [instance, myProfile]);

    useEffect(() => {
        if (instance.getActiveAccount() && url === '/') {
            navigate('/dashboard/home');
        }
    }, [url])

    const handleProfileRequest = async () => {
        await dispatch(fetchProfileAC());
    }


    useEffect(() => {
        if (!instance.getActiveAccount()) {
            instance.handleRedirectPromise()
                .then((response) => {
                    if (response) {
                        instance.setActiveAccount(response.account);
                        checkRoles(response);
                        handleProfileRequest();
                    } else {
                        instance.loginRedirect(LOGIN_REQUEST)
                            .catch((e) => {
                                console.error('User Login Error', e);
                            });
                    }
                })
                .catch((error) => {
                    console.error('Redirect Handling Error', error);
                });
        }
    }, [instance]);

    const checkRoles = async (response) => {
        if (response.account.idTokenClaims.extension_Roles) {
            const role = response.account.idTokenClaims.extension_Roles;
            if (ALLOWED_ROLES.includes(role)) {
            } else {
                window.location.href = '/not-allowed';
            }
        } else {
            window.location.href = '/not-allowed';
        }
    }

    return (
        <>
            {myProfile ?
                <Layout className='dashboard'>
                    <SideBar collapsed={collapsed} setCollapse={setCollapse} userProfile={myProfile} />
                    <Layout>
                        <div
                            className={!collapsed ? 'dark-overlay' : 'dark-overlay-active'}
                        ></div>
                        <DashboardHeader />
                        <DashboardContent myProfile={myProfile} />
                        <Footer />
                    </Layout>
                </Layout>
                :
                <PleaseWait />
            }
        </>
    )
}

export default Dashboard