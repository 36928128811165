import { useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react'

const NotAllowed = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();

    useEffect(() => {
        if (!activeAccount) {
            window.location.href = '/';
        } else {
            setTimeout(async () => {
                await instance.logout();
            }, 1000);
        }
    }, []);

    return (
        <div>
            <h1>Not Allowed</h1>
        </div>
    )
}

export default NotAllowed