import React, { useState } from 'react'
import { Button, Col, Drawer, Form, Input, Row, Select, Spin, notification } from 'antd'
import { hotelFacilityTypes } from '../../../../../../Constants/itemTypes';
import { addAccommodationFacility } from '../../../../../../../redux/api/hotels.api';

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const hotelFacilityOPtions = Object.keys(hotelFacilityTypes).map(key => {
    return {
        label: getOptionName(hotelFacilityTypes[key]),
        value: key
    };
});

const AddFacility = ({
    addFacilityVisible,
    setAddFacilityVisible,
    hotel,
    getFacilities
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('Adding facility...');
    const [api, contextHolder] = notification.useNotification();

    const onFinish = async (values) => {
        setIsLoading(true);
        try {
            Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

            values.accommodationId = hotel.id;

            setLoadingText('Adding to DB...');
            const response = await addAccommodationFacility(values);
            if (response.status === 200) {
                api.success({
                    message: 'Success',
                    description: 'Facility added successfully'
                });
                getFacilities();
                setTimeout(() => {
                    setAddFacilityVisible(false);
                }, 2000);

            } else {
                api.error({
                    message: 'Error',
                    description: 'An error occurred while adding the facility. Please try again.'
                });
            }

        } catch (error) {
            console.log(error);
            api.error({
                message: 'Error',
                description: `An error occurred while adding the facility. Please try again. ${error.message}`
            });
        }
        setIsLoading(false);
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Add a new facility"
                width={720}
                open={addFacilityVisible}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                onClose={() => setAddFacilityVisible(false)}
            >
                <Spin spinning={isLoading} tip={loadingText}>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="facilityType"
                                    label="Facility Type"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a facility type',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select a facility type"
                                        options={hotelFacilityOPtions}
                                        maxCount={1}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="facilityName"
                                    label="Facility Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter facility name',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter facility name" showCount maxLength={50} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="facilityDescription"
                                    label="Facility Description"
                                >
                                    <Input.TextArea
                                        limits={{ maxLength: 500 }}
                                        placeholder="Please enter facility description"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item>
                            <Button type='primary' htmlType='submit' loading={isLoading}>
                                Add Facility
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default AddFacility