import React, { useEffect, useRef, useState } from 'react'
import { editPayment, getPaymentById } from '../../../../../redux/api/payments.api'
import { capitalizeFisrtLetterEachWord } from '../../../../helpers/nameRender'
import { Button, Col, Divider, Drawer, Form, message, Row, Select, Space, Spin, Tag } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { DENIED, PAID, PENDING_PAYMENT, PENDING_VERIFICATION, REFUNDED } from '../../../../Constants/finance'
import { getReviewById, verifyReview } from '../../../../../redux/api/reviews.api'

const Review = () => {
    const url = window.location.pathname
    const reviewId = url.split('/').pop()
    const [review, setReview] = useState()
    const [loading, setLoading] = useState(true)
    const [editStatusDrawerOpen, setEditStatusDrawerOpen] = useState(false)
    const [loadingUpdate, setLoadingUpdate] = useState(false)
    const approveFromRef = useRef()
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        handleGetPayment()
    }, [])

    useEffect(() => {
        if (review) {
            approveFromRef?.current?.setFieldsValue({
                isVerified: review.isVerified
            })
        }
    }, [review, approveFromRef, editStatusDrawerOpen])

    const handleGetPayment = async () => {
        const response = await getReviewById(reviewId)
        if (response.status === 200) {
            setReview(response.data)
        }
        setLoading(false)
    }

    const handleChangeStatus = async (values) => {
        setLoadingUpdate(true)
        values.id = reviewId
        const response = await verifyReview(values)
        if (response.status === 200) {
            handleGetPayment()
            setEditStatusDrawerOpen(false)
            messageApi.open({
                type: 'success',
                content: 'Review status updated successfully',
            })
        }
        setLoadingUpdate(false)
    }

    return (
        <div>
            {contextHolder}
            {loading ? <h1>Loading...</h1> : (
                <div>
                    <div
                        className='header'
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <h1>
                            Review
                        </h1>
                        <Button
                            type='primary'
                            onClick={() => setEditStatusDrawerOpen(true)}
                        >
                            <EditOutlined />
                            Update Status
                        </Button>
                    </div>
                    <div className='p_container'>
                        <p><strong>Review ID: </strong>{review?.id}</p>
                        <p><strong>Rating Type: </strong>{review?.ratingType && capitalizeFisrtLetterEachWord(review?.ratingType)}</p>
                        <p><strong>Rated For (id): </strong>{review?.ratedFor}</p>
                        <p><strong>rating Value: </strong>{review?.ratingValue}</p>
                        <p><strong>Is Verified: </strong>{review?.isVerified ? <Tag color='green'>Yes</Tag> : <Tag color='red'>No</Tag>}</p>
                        <Divider />
                        <p><strong>Review: </strong>{review?.ratingDescription}</p>
                        <Divider />
                        <p><strong>Review By: </strong>
                            <a
                                href={`/dashboard/users/all/${review?.userId}`}
                                target='_blank'
                                rel='noreferrer'
                            >
                                {review?.userName}
                            </a>
                        </p>
                    </div>
                </div>
            )}

            <Drawer
                title="Verify Review"
                width={720}
                onClose={() => setEditStatusDrawerOpen(false)}
                open={editStatusDrawerOpen}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={() => setEditStatusDrawerOpen(false)}>
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                approveFromRef.current.submit()
                            }}
                        >
                            Update
                        </Button>
                    </Space>
                }
            >
                <Spin spinning={loadingUpdate} tip='Updating review status...'>
                    <Form
                        layout="vertical"
                        ref={approveFromRef}
                        onFinish={handleChangeStatus}
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name='isVerified'
                                    label='Is Verified'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select verification status',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Please select verification status"
                                        allowClear
                                    >
                                        <Select.Option value={true}>
                                            Verified
                                        </Select.Option>
                                        <Select.Option value={false}>
                                            Not Verified
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default Review