import React, { useState } from 'react';
import { Button, Col, Drawer, Form, Input, Row, Select, Spin, notification } from 'antd';
import { searchUsers } from '../../../../../../redux/api/users.api';
import { guideLanguages, tourGuideTypes } from '../../../../../Constants/itemTypes';
import { registerAsGuide } from '../../../../../../redux/api/guides.api';

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const guideTypeOptions = Object.keys(tourGuideTypes).map(key => {
    return {
        label: getOptionName(tourGuideTypes[key]),
        value: key
    };
});

const guideLanguageOptions = Object.keys(guideLanguages).map(key => {
    return {
        label: getOptionName(guideLanguages[key]),
        value: key
    };
});

const InviteGuide = ({ isVisble, setIsVisible, handleFetchGuides }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    const onFinish = async (values) => {
        setIsLoading(true);
        try {
            Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

            if (values.vehicleAvailable === 'true') {
                values.vehicleAvailable = true;
            } else {
                values.vehicleAvailable = false;
            }

            if (values.guideLanguages) {
                values.guideLanguages = values.guideLanguages.join(',');
            }

            const response = await registerAsGuide(values);
            if (response.status === 200) {
                api.success({
                    message: 'Success',
                    description: 'Guide added successfully'
                });
                handleFetchGuides(1, {});
                setIsVisible(false);
            } else {
                api.error({
                    message: 'Error',
                    description: response?.response?.data || 'An error occurred while adding the guide. Please try again.'
                });
            }

        } catch (error) {
            console.log(error);
            api.error({
                message: 'Error',
                description: `An error occurred while adding the guide. Please try again. ${error.message}`
            });
        }
        setIsLoading(false);
    };

    const handleUserSearch = async (value) => {
        setIsSearchLoading(true);
        try {
            const res = await searchUsers(value, 'all');
            if (res.status === 200) {
                const data = res?.data?.map(user => ({
                    label: user.userName,
                    value: user.id
                }));
                setSearchResults(data);
            }
        } catch (error) {
            console.log(error);
        }
        setIsSearchLoading(false);
    };

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Add Tour Guide"
                width={720}
                open={isVisble}
                onClose={() => setIsVisible(false)}
            >
                <Spin spinning={isLoading} tip='Please wait...'>
                    <Form layout="vertical" onFinish={onFinish}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="userId"
                                    label="Select User"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a user',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Select a user"
                                        notFoundContent={isSearchLoading ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        onSearch={handleUserSearch}
                                        options={searchResults}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="guideType"
                                    label="Select Guide Type"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select guide type',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearchsa
                                        mode='single'
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Guide Type"
                                        options={guideTypeOptions}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="guideName"
                                    label="Guide Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter guide name',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Guide Name" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="guideArea"
                                    label="Select Guide Area"
                                >
                                    <Input placeholder="Guide Area" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="guideLanguages"
                                    label="Guide Spoken Languages"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select guide languages',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        mode='multiple'
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Guide Languages"
                                        options={guideLanguageOptions}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="vehicleAvailable"
                                    label="Select Vehicle Available"
                                >
                                    <Select
                                        showSearch
                                        mode='single'
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Vehicle Available"
                                        options={[
                                            {
                                                label: 'Yes',
                                                value: 'true'
                                            },
                                            {
                                                label: 'No',
                                                value: 'false'
                                            }
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>


                        <Form.Item>
                            <Button type='primary' htmlType='submit' loading={isLoading}>
                                Add Guide
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Drawer>
        </div>
    );
};

export default InviteGuide;
