import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useDestinations from '../../../../hooks/useDestinations';
import { fetchDestinationNamesAC } from '../../../../../redux/actions/destinations/destinations.ac';

import { AutoComplete, Button, Empty, Form, Input, List, Select, Spin } from 'antd';
import {
    PlusOutlined, ExportOutlined
} from '@ant-design/icons';

import { eventsTypes } from '../../../../Constants/itemTypes';
import ReactQuill from 'react-quill';
import { DEFAULT_THUMBNAIL } from '../../../../Constants/otherConstsnts';
import useEvents from '../../../../hooks/useEvents';
import { searchEvents } from '../../../../../redux/api/events.api';
import { fetchEventsAC } from '../../../../../redux/actions/events/events.ac';
import useMyProfile from '../../../../hooks/useMyProfile';
import { ADMIN, SUPER_ADMIN } from '../../../../Constants/roles';

const { Search } = Input;

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const eventOptions = Object.keys(eventsTypes).map(key => {
    return {
        label: getOptionName(eventsTypes[key]),
        value: key
    };
});

const Events = () => {
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [searchResults, setSearchResults] = useState([])
    const [isSearchLoading, setIsSearchLoading] = useState(false)
    const destinations = useDestinations()
    const destinationNames = destinations.destinationNames
    const events = useEvents().events
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [filterValues, setFilterValues] = useState({})
    const myProfile = useMyProfile().userProfile

    useEffect(() => {
        if (destinationNames?.length === 0) {
            dispatch(fetchDestinationNamesAC())
        }

        handleFetchEvents(1, {})
    }, [])

    const destinationOptions = Object.keys(destinationNames).map(key => {
        return {
            label: getOptionName(destinationNames[key].destinationName),
            value: destinationNames[key].id
        };
    });

    const handleSearch = async (value) => {
        setIsSearchLoading(true)
        try {
            const response = await searchEvents(value)
            addSearchResult(response.data)
        } catch (error) {
            console.error('Search Error', error)
        }

        setIsSearchLoading(false)
    }

    const addSearchResult = (activities) => {
        const results = activities.map(activity => {
            return {
                value: activity.id,
                label:
                    <div className='result-row' onClick={() => navigate(`/dashboard/tours/events/${activity.id}`)}>
                        <div className='result-row-info'>
                            <h3>{activity.eventName}</h3>
                            <p>
                                {activity.eventType}
                            </p>
                        </div>
                    </div>
            }
        })
        setSearchResults(results)
    }

    const onChangeSearch = (value) => {
        if (value === '') {
            setSearchResults([])
        }

        if (value.length < 3) {
            return
        }

        handleSearch(value)
    }

    const handleFetchEvents = async (page, values) => {
        setLoading(true)
        values.page = page
        await dispatch(fetchEventsAC(values))
        setLoading(false)
    }

    const onFilterSubmit = async (values) => {
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

        setFilterValues(values)
        await handleFetchEvents(1, values)
    }

    return (
        <div className='destinations'>
            <div className='destinations__header'>
                <div className='left'>
                    <h1>Events</h1>
                    <AutoComplete
                        dropdownClassName='certain-category-search-dropdown-destinations'
                        dropdownMatchSelectWidth={350}
                        style={{ width: 350 }}
                        options={searchResults}
                    >
                        <Search
                            placeholder="Search"
                            onSearch={handleSearch}
                            enterButton
                            loading={isSearchLoading}
                            onChange={(e) => onChangeSearch(e.target.value)}
                        />
                    </AutoComplete>
                </div>
                {(myProfile?.role === SUPER_ADMIN || myProfile?.role === ADMIN) &&
                    <div className='destinations__header__actions'>
                        <Button type='primary' icon={<PlusOutlined />} onClick={() => navigate('/dashboard/tours/events/add')}>
                            Add Event
                        </Button>
                    </div>
                }
            </div>

            <div className='destinations__filters'>
                <Form
                    layout='inline'
                    onFinish={onFilterSubmit}
                >
                    <Form.Item
                        name='destinationId'
                    >
                        <Select
                            showSearchsa
                            mode='single'
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            placeholder="Destination Name"
                            options={destinationOptions}
                        />
                    </Form.Item>
                    <Form.Item
                        name='eventType'
                    >
                        <Select
                            showSearchsa
                            mode='single'
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            placeholder="Event Type"
                            options={eventOptions}
                        />
                    </Form.Item>
                    <Form.Item
                        name='eventStartDate'
                    >
                        <Input placeholder='Start Date' type='date' allowClear />
                    </Form.Item>
                    <Form.Item
                        name='eventEndDate'
                    >
                        <Input placeholder='End Date' type='date' allowClear />
                    </Form.Item>

                    <Form.Item>
                        <Button type='primary' htmlType='submit' loading={loading}>
                            Get Results
                        </Button>
                    </Form.Item>
                </Form>
            </div>


            <div className='destinations__list'>
                {events && events.length > 0 ?
                    <List
                        loading={loading}
                        pagination={{
                            position: 'bottom',
                            align: 'end',
                            pageSize: 5,
                            showSizeChanger: false,
                            total: events[0]?.filteredEventsCount,
                            current: page,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                            onChange: (page) => { setPage(page); handleFetchEvents(page, filterValues) }
                        }}
                        dataSource={events}
                        renderItem={(item, index) => (
                            <List.Item key={index} className='destination__item'>
                                <div className='left'>
                                    <img src={item?.thumbnailUrl || DEFAULT_THUMBNAIL} alt='attraction' />
                                </div>
                                <div className='right'>
                                    <div className='destination__item__header'>
                                        <h3>{item?.eventName}</h3>
                                        <Button type='primary' onClick={() => navigate(`/dashboard/tours/events/${item.id}`)} icon={<ExportOutlined />}>
                                            View
                                        </Button>
                                    </div>
                                    <ReactQuill
                                        value={item?.eventDescriptionShort}
                                        readOnly={true}
                                        theme='bubble'
                                    />
                                    <p><strong>Destination: </strong>{
                                        destinationNames.find(destination => destination.id === item?.destinationId)?.destinationName
                                    }</p>
                                    <p><strong>City: </strong>{item?.eventCity}</p>
                                    <p><strong>Type: </strong>{item?.eventType || 'N/A'}</p>
                                    <p><strong>Start Date: </strong>{item?.eventStartDate || 'N/A'}
                                        <strong
                                            style={{ marginLeft: '10px' }}
                                        >End Date: </strong>{item?.eventEndDate || 'N/A'}
                                    </p>
                                </div>
                            </List.Item>
                        )}
                    />
                    :
                    <>
                        {
                            loading ?
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '500px'
                                }}>
                                    <Spin size='large' />
                                </div>
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default Events