import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { handleDeleteBlob, PARTNERS_BLOB } from '../../../../../azure/blob'

import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Divider, Image, Popconfirm, Spin, Tag, notification } from 'antd'
import 'react-quill/dist/quill.snow.css';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';

import './styles.scss'
import { deleteTransportPackage, getTransportPackageById } from '../../../../../../redux/api/transport.api'
import useTransports from '../../../../../hooks/useTransports'
import { getPartnerProfileById } from '../../../../../../redux/api/partners.api'
import moment from 'moment'

Quill.register('modules/imageResize', ImageResize);

const modules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
        ["formula"],
        [{ size: ["small", false, "large", "huge"] }],
    ],
    clipboard: {
        matchVisual: false
    },
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
};

const TransportPackage = () => {
    const transports = useTransports().transports
    const url = window.location.pathname
    const transportId = url.split('/').pop()
    const [loading, setLoading] = useState(false)
    const [transportPackage, setTransportPackage] = useState()
    const navigate = useNavigate()
    const [api, contextHolder] = notification.useNotification();
    const [loadingText, setLoadingText] = useState('Please Wait...')
    const [partner, setPartner] = useState()

    useEffect(() => {
        if (transports?.length > 0) {
            const transportPackage = transports.find(transportPackage => transportPackage.id === transportId)
            if (transportPackage) {
                setTransportPackage(transportPackage)
            } else {
                handleGetTransportPackage(transportId)
            }
        } else {
            handleGetTransportPackage(transportId)
        }
    }, [])

    useEffect(() => {
        if (transportPackage) {
            handleGetPartner(transportPackage.partnerId)
        }
    }, [transportPackage])

    const handleGetPartner = async (id) => {
        setLoading(true)
        setLoadingText('Fetching Partner')
        try {
            const partner = await getPartnerProfileById(id)
            setPartner(partner.data)
        } catch (error) {
            console.error('Error fetching partner', error)
        }
        setLoading(false)
        setLoadingText('Please Wait...')
    }

    const handleGetTransportPackage = async (id) => {
        setLoading(true)
        try {
            const transportPackage = await getTransportPackageById(id)
            setTransportPackage(transportPackage.data[0])
        } catch (error) {
            console.error('Error fetching transport package', error)
        }
        setLoading(false)
    }

    const getBlobName = (blobUrl) => {
        const splitUrl = blobUrl.split('/')
        return splitUrl[splitUrl.length - 1]
    }

    const handleDeleteTourPackage = async () => {
        setLoading(true)
        try {
            let vehicleImages = transportPackage.vehicleImages?.split(',')

            if (vehicleImages?.length > 0) {
                setLoadingText('Deleting Images');
                const imageDeletePromises = vehicleImages.map(async (image, index) => {
                    const blobName = getBlobName(image);
                    setLoadingText(`Deleting image ${index + 1}`);
                    return handleDeleteBlob(PARTNERS_BLOB, blobName);
                });
                await Promise.all(imageDeletePromises);
            }

            const res = await deleteTransportPackage(transportId)

            if (res.status === 200) {
                api.success({
                    message: 'Transport Package deleted successfully',
                    description: 'The transport package has been successfully deleted'
                })

                setTimeout(() => {
                    navigate('/dashboard/partners/transport')
                }, 2000)

            } else {
                api.error({
                    message: 'Error deleting transport package',
                    description: 'An error occurred while deleting the transport package'
                })
            }

        } catch (error) {
            console.error('Error deleting transport package', error)
            api.error({
                message: 'Error deleting transport package',
                description: error.message
            })
        }
        setLoading(false)
    }

    return (
        <div className='destination'>
            {contextHolder}
            <Spin
                spinning={loading}
                size='large'
                tip={loadingText}
            >
                <>
                    <div className='destination__header'>
                        <h1>
                            {transportPackage?.packageName}
                        </h1>
                        <div className='destination__header__actions'>
                            <Button type='primary' onClick={() => navigate(`/dashboard/partners/transport/edit/${transportId}`)} icon={<EditOutlined />}>
                                Edit
                            </Button>
                            <Popconfirm
                                title='Are you sure you want to delete this transport package?'
                                description={<p
                                    style={{
                                        width: '300px',
                                        color: 'red',
                                    }}
                                >This action cannot be undone! This will permanently delete the transport package, its images, videos, attractions and other related items.</p>}
                                onConfirm={handleDeleteTourPackage}
                                okText='Yes'
                                cancelText='No'
                            >
                                <Button danger type='primary' icon={<DeleteOutlined />}>
                                    Delete
                                </Button>
                            </Popconfirm>
                        </div>
                    </div>
                    <div className='destination__content_images'>
                        <div className='destination__content__galery'>
                            <Image.PreviewGroup>
                                {transportPackage?.vehicleImages?.split(',').map((image, index) => (
                                    <Image
                                        key={index}
                                        width={160}
                                        height={100}
                                        src={image}
                                        className='destination__content__galery__image'
                                    />
                                ))}
                            </Image.PreviewGroup>
                        </div>
                    </div>
                    <Divider />

                    <div className='destination__content_details'>
                        <h2>
                            Details
                        </h2>
                        <div className='destination__content__details'>
                            <div className='left'>
                                <p><strong>Partner: </strong>{partner?.companyName || 'N/A'}</p>
                                <p><strong>Package Type: </strong>{transportPackage?.packageType || 'N/A'}</p>
                                <p><strong>Vehicle Type: </strong>{transportPackage?.vehicleType || 'N/A'}</p>
                                <p><strong>Vehicle Brand: </strong>{transportPackage?.vehicleBrand || 'N/A'}</p>
                                <p><strong>Vehicle Model: </strong>{transportPackage?.vehicleModel || 'N/A'}</p>
                                <p><strong>Vehicle Year: </strong>{transportPackage?.vehicleYear || 'N/A'}</p>
                                <p><strong>Vehicle Plate No: </strong>{transportPackage?.vehiclePlateNumber || 'N/A'}</p>
                                <p><strong>Vehicle Color: </strong>{transportPackage?.vehicleColor || 'N/A'}</p>
                            </div>
                            <div className='right'>
                                <p><strong>Capacity: </strong>{partner?.vehicleCapacity || 'N/A'}</p>
                                <p><strong>Baggage Capacity: </strong>{transportPackage?.baggageCapacity || 'N/A'}</p>
                                <p><strong>Min Milage: </strong>{transportPackage?.minMilage || 'N/A'}</p>
                                <p><strong>Price Per KM: </strong>{transportPackage?.pricePerKm || 'N/A'}</p>
                                <p><strong>Price Per Day: </strong>{transportPackage?.pricePerDay || 'N/A'}</p>
                                <p><strong>Price Margin: </strong>{transportPackage?.priceMargin || 'N/A'}</p>
                                <p><strong>Active Status: </strong>
                                    <Tag color={transportPackage?.activeStatus === 'ACTIVE' ? 'green' : 'red'}>
                                        {transportPackage?.activeStatus || 'N/A'}
                                    </Tag>
                                </p>
                                <p><strong>Added Date: </strong>{transportPackage?.createdAt ? moment(transportPackage?.createdAt).format('MMMM Do YYYY, h:mm:ss a') : 'N/A'}</p>
                            </div>
                        </div>
                    </div>

                    <div className='destination__content_description'>
                        <h3>
                            Package Description
                        </h3>
                        <ReactQuill
                            modules={modules}
                            value={transportPackage?.packageDescription}
                            readOnly={true}
                            theme='bubble'
                        />
                    </div>

                    <Divider />
                    <div className='destination__content_reviews'>
                        <h2>
                            Reviews
                        </h2>
                    </div>
                </>
            </Spin>
        </div>
    )
}

export default TransportPackage