import React, { useEffect, useState } from 'react'
import { Button, Tabs, Input, AutoComplete } from 'antd';
import { useDispatch } from 'react-redux';
import useUsers from '../../../hooks/useUsers';

import { changeUserTypeAC, fetchUsersAC } from '../../../../redux/actions/users/users.ac';
import { searchUsers } from '../../../../redux/api/users.api';

import { PlusOutlined } from '@ant-design/icons'

import './styles.scss'
import { DEFAULT_PROFILE_IMAGE } from '../../../Constants/otherConstsnts';
import UserTable from './UsersTable';
import { useNavigate } from 'react-router-dom';
import AddUser from './AddUser';

const { Search } = Input;

const items = [
    {
        key: 'all',
        label: 'All',
    },
    {
        key: 'staff',
        label: 'Staff',
    },
    {
        key: 'customers',
        label: 'Customers',
    },
    {
        key: 'trip_coordinator',
        label: 'Trip Coordinators',
    }
];

const Users = () => {
    const [selectedTab, setSelectedTab] = useState()
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [searchResults, setSearchResults] = useState([])
    const [isSearchLoading, setIsSearchLoading] = useState(false)
    const users = useUsers();
    const dispatch = useDispatch();
    const url = window.location.pathname
    const navigate = useNavigate();
    const [isAddUserVisible, setIsAddUserVisible] = useState(false)

    useEffect(() => {
        const tab = url.split('/').pop()
        if (url === '/dashboard/users' || url === '/dashboard/users/') {
            navigate('/dashboard/users/all')
            setSelectedTab('all')
            setPage(1)
        } else if (tab === 'customers') {
            setSelectedTab('customers')
        } else if (tab === 'staff') {
            setSelectedTab('staff')
        } else if (tab === 'trip_coordinator') {
            setSelectedTab('trip_coordinator')
        } else {
            setSelectedTab('all')
        }
    }, [])

    useEffect(() => {
        if (selectedTab) {
            handleFetchUsers(1, selectedTab)
            navigate(`/dashboard/users/${selectedTab}`)
        }
    }, [selectedTab])

    const onTabChange = (key) => {
        setSelectedTab(key)
        setPage(1)
        dispatch(changeUserTypeAC(key))
    }

    const handleFetchUsers = async (page, type) => {
        if (page && type) {
            setLoading(true)
            await dispatch(fetchUsersAC(page, type))
            setLoading(false)
        }
    }

    const handleSearch = async (value) => {
        setIsSearchLoading(true)
        try {
            const response = await searchUsers(value, selectedTab)
            addSearchResult(response.data)
        } catch (error) {
            console.error('Search Error', error)
        }

        setIsSearchLoading(false)
    }

    const onChangeSearch = (value) => {
        if (value === '') {
            setSearchResults([])
        }

        if (value.length < 3) {
            return
        }

        handleSearch(value)
    }

    const addSearchResult = (user) => {
        const results = user.map(user => {
            return {
                value: user.userName,
                label: <div className='result-row' onClick={() => navigate(`/dashboard/users/${selectedTab}/${user.id}`)}>
                    <img src={user.profilePicUrl || DEFAULT_PROFILE_IMAGE} alt='profile' />
                    <div className='result-row-info'>
                        <h3>{user.userName}</h3>
                        <p>{user.email}</p>
                    </div>
                </div>
            }
        })

        setSearchResults(results)
    }


    return (
        <div className='users'>
            <div className='users__header'>
                <h1>All Users</h1>
                <div className='users__header__actions'>
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => setIsAddUserVisible(true)}>
                        Add User
                    </Button>
                </div>
            </div>

            <Tabs
                defaultActiveKey={selectedTab}
                activeKey={selectedTab}
                items={items}
                onChange={onTabChange}
            />
            <div className='search'>
                <AutoComplete
                    dropdownClassName='certain-category-search-dropdown'
                    dropdownMatchSelectWidth={300}
                    style={{ width: 300 }}
                    options={searchResults}
                >
                    <Search
                        placeholder="Search"
                        onSearch={handleSearch}
                        enterButton
                        loading={isSearchLoading}
                        onChange={(e) => onChangeSearch(e.target.value)}
                    />
                </AutoComplete>

                <div className='search__results'>
                    <h4>Number of users: {users?.users[0]?.usersCount || 0}</h4>
                </div>
            </div>

            <UserTable users={users?.users} handleFetchUsers={handleFetchUsers} loading={loading} page={page} setPage={setPage} type={selectedTab} />

            <AddUser
                isVisble={isAddUserVisible}
                setIsVisible={setIsAddUserVisible}
            />
        </div>
    )
}

export default Users