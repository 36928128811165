import React, { useEffect, useRef, useState } from 'react'
import 'react-quill/dist/quill.snow.css';
import ReactQuill, { Quill } from 'react-quill';
import { useNavigate } from 'react-router-dom';
import ImgCrop from 'antd-img-crop';
import { useDispatch } from 'react-redux';

import { fetchDestinationNamesAC } from '../../../../../../redux/actions/destinations/destinations.ac';
import useDestinations from '../../../../../hooks/useDestinations';

import { ACTIVE, INACTIVE, PENDING } from '../../../../../Constants/activeStatusType';
import { ACCOMMODATION_CONTAINER, handleDeleteBlob, handleImageUpload } from '../../../../../azure/blob';

import { Button, Col, Divider, Form, Image, Input, notification, Radio, Rate, Row, Select, Spin, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons';

import { accommodationTypes } from '../../../../../Constants/itemTypes';

import './styles.scss'
import { editAccommodation, getAccommodationById } from '../../../../../../redux/api/hotels.api';
import useHotels from '../../../../../hooks/useHotels';

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const accommodationOptions = Object.keys(accommodationTypes).map(key => {
    return {
        label: getOptionName(accommodationTypes[key]),
        value: key
    };
});

const quillModules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
        ["formula"],
        [{ size: ["small", false, "large", "huge"] }],
    ],
    clipboard: {
        matchVisual: false
    },
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
};

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });


const EditHotel = () => {
    const [shortDescriptionCharCount, setShortDescriptionCharCount] = useState(0)
    const [longDescriptionCharCount, setLongDescriptionCharCount] = useState(0)
    const [fileList, setFileList] = useState([])
    const [videoList, setVideoList] = useState([])
    const [pricingFiles, setPricingFiles] = useState([])
    const [thumbnailFile, setThumbnailFile] = useState([]);
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [loadingText, setLoadingText] = useState('')
    const topRef = useRef(null);
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const destinations = useDestinations()
    const destinationNames = destinations.destinationNames
    const [hotel, setHotel] = useState()
    const hotels = useHotels().hotels
    const url = window.location.pathname
    const hotelId = url.split('/').pop()
    const formRef = useRef(null)

    useEffect(() => {
        if (destinationNames?.length === 0) {
            dispatch(fetchDestinationNamesAC())
        }

        if (hotels?.length > 0) {
            const hotel = hotels.find(hotel => hotel.id === hotelId)
            if (hotel) {
                setHotel(hotel)
            } else {
                handleGetHotel(hotelId)
            }
        } else {
            handleGetHotel(hotelId)
        }
    }, [])

    const handleGetHotel = async (id) => {
        setLoading(true)
        try {
            const hotel = await getAccommodationById(id)
            setHotel(hotel.data[0])
        } catch (error) {
            console.error('Error fetching activity', error)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (hotel) {
            formRef.current.setFieldsValue({
                accommodationName: hotel.accommodationName,
                nearestDestinations: hotel.nearestDestinations,
                accommodationType: hotel.accommodationType,
                accommodationCity: hotel.accommodationCity,
                starRating: hotel.starRating,
                grade: hotel.grade,
                accommodationCoordinates: hotel.accommodationCoordinates,
                mapLocation: hotel.mapLocation,
                isPetsAllowed: hotel.isPetsAllowed,
                registartionNumber: hotel.registartionNumber,
                licenceNumber: hotel.licenceNumber,
                licenceExpiry: hotel.licenceExpiry,
                tags: hotel.tags ? hotel.tags.split(',') : [],
                email: hotel.email,
                accommodationAddress: hotel.accommodationAddress,
                contact1: hotel.contact1,
                contact1Name: hotel.contact1Name,
                conatctNo2: hotel.conatctNo2,
                contact2Name: hotel.contact2Name,
                bookingProcess: hotel.bookingProcess,
                accommodationDescriptionShort: hotel.accommodationDescriptionShort,
                accommodationDescription: hotel.accommodationDescription,
                activeStatus: hotel.activeStatus
            })

            if (hotel.accommodationImages) {
                const images = hotel.accommodationImages.split(',')
                const imageList = images.map((image, index) => {
                    return {
                        uid: index,
                        name: image,
                        status: 'done',
                        url: image
                    }
                })
                setFileList(imageList)
            }

            if (hotel.accommodationVideos) {
                const videos = hotel.accommodationVideos.split(',')
                const videoList = videos.map((video, index) => {
                    return {
                        uid: index,
                        name: video,
                        status: 'done',
                        url: video
                    }
                })
                setVideoList(videoList)
            }

            if (hotel?.pricingDocuments) {
                const pricingDocs = hotel.pricingDocuments.split(',')
                const pricingList = pricingDocs.map((doc, index) => {
                    return {
                        uid: index,
                        name: doc,
                        status: 'done',
                        url: doc
                    }
                })
                setPricingFiles(pricingList)
            }

            if (hotel.thumbnailUrl) {
                const thumbnail = hotel.thumbnailUrl
                const thumbnailList = [
                    {
                        uid: 0,
                        name: thumbnail,
                        status: 'done',
                        url: thumbnail
                    }
                ]
                setThumbnailFile(thumbnailList)
            }
        }
    }, [hotel])


    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const onVideoChange = ({ fileList: newFileList }) => setVideoList(newFileList);

    const onThumbnailChange = ({ fileList: newFileList }) => setThumbnailFile(newFileList);

    const onPricingFilesChange = ({ fileList: newFileList }) => setPricingFiles(newFileList);

    const onFinish = async (values) => {
        topRef.current.scrollTo(0, 0);
        setLoading(true);
        try {
            Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

            let imageUrls = [];
            let videoUrls = [];
            let pricingUrls = [];
            let thumbnailUrl = '';

            const name = values.accommodationName.replace(/\s/g, '_').toLowerCase();

            // find new images and list images need to be deleted
            const newImages = fileList.filter(file => !file.url);
            const deletedImages = hotel.accommodationImages?.split(',').filter(image => !fileList.find(file => file.url === image));

            // find new videos and list videos need to be deleted
            const newVideos = videoList.filter(file => !file.url);
            const deletedVideos = hotel.accommodationVideos?.split(',').filter(video => !videoList.find(file => file.url === video));

            // find new pricing files and list pricing files need to be deleted
            const newPricingFiles = pricingFiles.filter(file => !file.url);
            const deletedPricingFiles = hotel.pricingDocuments?.split(',').filter(doc => !pricingFiles.find(file => file.url === doc));

            // find new thumbnail and list thumbnail need to be deleted
            const newThumbnail = thumbnailFile.filter(file => !file.url);
            const deletedThumbnail = hotel.thumbnailUrl?.split(',').filter(thumbnail => !thumbnailFile.find(file => file.url === thumbnail));

            if (newImages?.length > 0) {
                setLoadingText('Uploading Images');
                const imageUploadPromises = newImages.map(async (file, index) => {
                    setLoadingText(`Uploading image ${index + 1}`);
                    return handleImageUpload(
                        ACCOMMODATION_CONTAINER,
                        file.originFileObj,
                        file.size,
                        `${name}_${file.name}`
                    ).then((response) => {
                        imageUrls.push(response.blockBlobClient.url);
                    });
                });
                await Promise.all(imageUploadPromises);
            }

            if (newVideos?.length > 0) {
                setLoadingText('Uploading Videos');
                const videoUploadPromises = newVideos.map(async (file, index) => {
                    setLoadingText(`Uploading video ${index + 1}`);
                    return handleImageUpload(
                        ACCOMMODATION_CONTAINER,
                        file.originFileObj,
                        file.size,
                        `${name}_${file.name}`
                    ).then((response) => {
                        videoUrls.push(response.blockBlobClient.url);
                    });
                });
                await Promise.all(videoUploadPromises);
            }

            if (newPricingFiles?.length > 0) {
                setLoadingText('Uploading Pricing Files');
                const pricingUploadPromises = newPricingFiles.map(async (file, index) => {
                    setLoadingText(`Uploading pricing file ${index + 1}`);
                    return handleImageUpload(
                        ACCOMMODATION_CONTAINER,
                        file.originFileObj,
                        file.size,
                        `${file.name}`
                    ).then((response) => {
                        pricingUrls.push(response.blockBlobClient.url);
                    });
                });
                await Promise.all(pricingUploadPromises);
            }

            if (newThumbnail?.length > 0) {
                setLoadingText('Uploading Thumbnail');
                const thumbnailUploadPromises = newThumbnail.map(async (file, index) => {
                    setLoadingText(`Uploading thumbnail ${index + 1}`);
                    return handleImageUpload(
                        ACCOMMODATION_CONTAINER,
                        file.originFileObj,
                        file.size,
                        `${name}_${file.name}`
                    ).then((response) => {
                        thumbnailUrl = response.blockBlobClient.url;
                    });
                });
                await Promise.all(thumbnailUploadPromises);
            }

            // delete blobs
            if (deletedImages?.length > 0) {
                setLoadingText('Deleting Images');
                const imageDeletePromises = deletedImages.map(async (image, index) => {
                    const blobName = getBlobName(image);
                    setLoadingText(`Deleting image ${index + 1}`);
                    return handleDeleteBlob(ACCOMMODATION_CONTAINER, blobName);
                });
                await Promise.all(imageDeletePromises);
            }

            if (deletedVideos?.length > 0) {
                setLoadingText('Deleting Videos');
                const videoDeletePromises = deletedVideos.map(async (video, index) => {
                    const blobName = getBlobName(video);
                    setLoadingText(`Deleting video ${index + 1}`);
                    return handleDeleteBlob(ACCOMMODATION_CONTAINER, blobName);
                });
                await Promise.all(videoDeletePromises);
            }

            if (deletedPricingFiles?.length > 0) {
                setLoadingText('Deleting Pricing Files');
                const pricingDeletePromises = deletedPricingFiles.map(async (doc, index) => {
                    const blobName = getBlobName(doc);
                    setLoadingText(`Deleting pricing file ${index + 1}`);
                    return handleDeleteBlob(ACCOMMODATION_CONTAINER, blobName);
                });
                await Promise.all(pricingDeletePromises);
            }

            if (deletedThumbnail?.length > 0) {
                setLoadingText('Deleting Thumbnail');
                const thumbnailDeletePromises = deletedThumbnail.map(async (thumbnail, index) => {
                    const blobName = getBlobName(thumbnail);
                    setLoadingText(`Deleting thumbnail ${index + 1}`);
                    return handleDeleteBlob(ACCOMMODATION_CONTAINER, blobName);
                });
                await Promise.all(thumbnailDeletePromises);
            }

            let updatedImages = [
                ...fileList.filter((file) => file.url).map((file) => file.url),
                ...imageUrls
            ];

            let updatedVideos = [
                ...videoList.filter((file) => file.url).map((file) => file.url),
                ...videoUrls
            ];

            let updatedPricingFiles = [
                ...pricingFiles.filter((file) => file.url).map((file) => file.url),
                ...pricingUrls
            ];

            // Ensure thumbnailUrl is a single string
            let updatedThumbnail = thumbnailUrl || (thumbnailFile[0]?.url || '');

            // remove unwanted parts from urls
            updatedImages.forEach((url, index) => {
                updatedImages[index] = sanitizeBlobUrls(url);
            });

            updatedVideos.forEach((url, index) => {
                updatedVideos[index] = sanitizeBlobUrls(url);
            });

            updatedThumbnail = sanitizeBlobUrls(updatedThumbnail);

            const data = {
                id: hotelId,
                accommodationName: values.accommodationName,
                nearestDestinations: values.nearestDestinations,
                accommodationType: values.accommodationType,
                accommodationCity: values.accommodationCity,
                starRating: values.starRating.toString(),
                grade: values.grade,
                accommodationCoordinates: values.accommodationCoordinates,
                mapLocation: values.mapLocation,
                isPetsAllowed: values.isPetsAllowed,
                registartionNumber: values.registartionNumber,
                licenceNumber: values.licenceNumber,
                licenceExpiry: values.licenceExpiry,
                tags: values.tags.join(','),
                email: values.email,
                accommodationAddress: values.accommodationAddress,
                contactNumber: values.contactNumber,
                contact1: values.contact1,
                contact1Name: values.contact1Name,
                conatctNo2: values.conatctNo2,
                contact2Name: values.contact2Name,
                bookingProcess: values.bookingProcess,
                accommodationDescriptionShort: values.accommodationDescriptionShort,
                accommodationDescription: values.accommodationDescription,
                accommodationImages: updatedImages?.length > 0 ? updatedImages.join(',') : null,
                accommodationVideos: updatedVideos?.length > 0 ? updatedVideos.join(',') : null,
                pricingDocuments: updatedPricingFiles?.length > 0 ? updatedPricingFiles.join(',') : null,
                thumbnailUrl: updatedThumbnail === '' ? null : updatedThumbnail,
                activeStatus: values.activeStatus
            };

            setLoadingText('Updating Accommodation in DB');
            const response = await editAccommodation(data);
            if (response.status === 200) {
                api.success({
                    message: 'Attraction Updated Successfully',
                    description: 'You have successfully updated the attraction',
                });

                setTimeout(() => {
                    navigate(`/dashboard/partners/hotels/${hotelId}`);
                }, 2000);
            } else {
                console.log('Error', response);
                api.error({
                    message: 'Error Adding Destination',
                    description: 'There was an error updating the attraction. Please try again'
                });
            }

        } catch (error) {
            console.log('Error Adding Accommodation', error);
            api.error({
                message: 'Error Adding Accommodation',
                description: 'There was an error adding the accommodation. Please try again'
            });
        }
        setLoading(false);
        setLoadingText('');
    };

    const getBlobName = (blobUrl) => {
        const splitUrl = blobUrl.split('/')
        return splitUrl[splitUrl.length - 1]
    }

    const sanitizeBlobUrls = (blobUrl) => {
        const splitUrl = blobUrl.split('?')
        return splitUrl[0]
    }

    const destinationOptions = Object.keys(destinationNames).map(key => {
        return {
            label: getOptionName(destinationNames[key].destinationName),
            value: destinationNames[key].id
        };
    });

    return (
        <div className='add__destination' ref={topRef}>
            {contextHolder}
            <h2>
                Edit Accommodation
            </h2>

            <Spin spinning={loading} tip={loadingText}>
                <Form
                    layout='vertical'
                    onFinish={onFinish}
                    ref={formRef}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Accommodation Name'
                                name='accommodationName'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter accommodation name'
                                    }
                                ]}
                            >
                                <Input placeholder='Accommodation Name' showCount maxLength={200} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Nearest Destination'
                                name='nearestDestinations'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select nearest destination'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Select Nearest Destination"
                                    options={destinationOptions}
                                    maxCount={1}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Accommodation Type'
                                name='accommodationType'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select accommodation type'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Add Accommodation Type"
                                    options={accommodationOptions}
                                    maxCount={1}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Accommodation City'
                                name='accommodationCity'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add accommodation city'
                                    }
                                ]}
                            >
                                <Input placeholder='Accommodation City' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Star Rating'
                                name='starRating'
                            >
                                <Rate />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Grade (If not has star rating)'
                                name='grade'
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Add Grade"
                                    options={[
                                        {
                                            label: 'A Grade',
                                            value: 'A'
                                        },
                                        {
                                            label: 'B Grade',
                                            value: 'B'
                                        },
                                        {
                                            label: 'C Grade',
                                            value: 'C'
                                        },
                                        {
                                            label: 'D Grade',
                                            value: 'D'
                                        },
                                        {
                                            label: 'E Grade',
                                            value: 'E'
                                        },
                                        {
                                            label: 'F Grade',
                                            value: 'F'
                                        }
                                    ]}
                                    maxCount={1}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Accommodation Coordinates'
                                name='accommodationCoordinates'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input accommodation coordinates'
                                    }
                                ]}
                            >
                                <Input placeholder='Accommodation Coordinates' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Accommodation Map Location'
                                name='mapLocation'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add accommodation map location'
                                    }
                                ]}
                            >
                                <Input placeholder='Accommodation Map Location' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Is Pets Allowed'
                                name='isPetsAllowed'
                            >
                                <Radio.Group>
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Accommodation Registration Number'
                                name='registartionNumber'
                            >
                                <Input placeholder='Accommodation Registration Number' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Accommodation Licence Number'
                                name='licenceNumber'
                            >
                                <Input placeholder='Accommodation Licence Number' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Accommodation Licence Expiry'
                                name='licenceExpiry'
                            >
                                <Input placeholder='Accommodation Licence Expiry' type='date' />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label='Search Tags'
                                name='tags'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add tags'
                                    }
                                ]}
                            >
                                <Select
                                    mode="tags"
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Add accommodation tags"
                                    options={[]}
                                    maxCount={5}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Accommodation email'
                                name='email'
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid email'
                                    }
                                ]}
                            >
                                <Input placeholder='Accommodation email' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Accommodation Address'
                                name='accommodationAddress'
                            >
                                <Input placeholder='Accommodation Address' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Accommodation Contact Number 1'
                                name='contact1'
                            >
                                <Input placeholder='Accommodation Contact Number 1' showCount maxLength={50} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label='Contact Person Name 1'
                                name='contact1Name'
                            >
                                <Input placeholder='Accommodation Contact Person 2' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Accommodation Contact Number 2'
                                name='conatctNo2'
                            >
                                <Input placeholder='Accommodation Contact Number 1' showCount maxLength={50} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label='Contact Person Name 2'
                                name='contact2Name'
                            >
                                <Input placeholder='Accommodation Contact Person 2' showCount maxLength={50} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Booking Process'
                                name='bookingProcess'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter booking process'
                                    }
                                ]}
                            >
                                <ReactQuill
                                    theme="snow"
                                    limits={{ maxLength: 500 }}
                                    modules={quillModules}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={`Accommodation Short Description ( ${shortDescriptionCharCount}/1000 characters)`}
                                name='accommodationDescriptionShort'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter accommodation short description'
                                    }
                                ]}
                            >
                                <ReactQuill
                                    theme="snow"
                                    limits={{ maxLength: 500 }}
                                    onChange={(content, delta, source, editor) => {
                                        setShortDescriptionCharCount(editor.getLength())
                                    }}
                                    modules={quillModules}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={`Accommodation Description ( ${longDescriptionCharCount}/10000 characters)`}
                                name='accommodationDescription'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter accommodation description'
                                    }
                                ]}
                            >
                                <ReactQuill
                                    theme="snow"
                                    limits={{ maxLength: 10000 }}
                                    onChange={(content, delta, source, editor) => {
                                        setLongDescriptionCharCount(editor.getLength())
                                    }}
                                    modules={quillModules}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Upload Pricing Documents (Max 15)'
                                name='pricingDocuments'
                            >
                                <Upload
                                    listType="picture"
                                    fileList={pricingFiles}
                                    onChange={onPricingFilesChange}
                                    multiple={true}
                                    maxCount={3}
                                    beforeUpload={(file) => {
                                        return false;
                                    }}
                                >
                                    {videoList?.length < 15 && <Button icon={<PlusOutlined />}>Upload</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Upload Images (Max 10)'
                                name='accommodationImages'
                            >
                                <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                    multiple={true}
                                    maxCount={10}
                                    beforeUpload={(file) => {
                                        return false;
                                    }}
                                >
                                    {fileList.length <= 10 && '+ Upload'}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Upload Videos (Max 3)'
                                name='accommodationVideos'
                            >
                                <Upload
                                    listType="picture"
                                    fileList={videoList}
                                    onChange={onVideoChange}
                                    multiple={true}
                                    maxCount={3}
                                    beforeUpload={(file) => {
                                        return false;
                                    }}
                                >
                                    {videoList?.length < 3 && <Button icon={<PlusOutlined />}>Upload</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Upload Thumbnail Image'
                                name='thumbnailUrl'
                            >
                                <ImgCrop
                                    rotationSlider
                                    modalTitle='Crop Image'
                                    modalOk='Crop'
                                    modalCancel='Cancel'
                                    showReset={true}
                                    aspect={16 / 9}
                                >
                                    <Upload
                                        listType="picture-card"
                                        fileList={thumbnailFile}
                                        onChange={onThumbnailChange}
                                        multiple={false}
                                        beforeUpload={(file) => {
                                            return false;
                                        }}
                                    >
                                        {thumbnailFile.length < 1 && '+ Upload'}
                                    </Upload>
                                </ImgCrop>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Col span={12}>
                        <Form.Item
                            label='Active Status'
                            name='activeStatus'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select active status'
                                }
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Select Active Status"
                                options={[
                                    {
                                        label: 'Active',
                                        value: ACTIVE
                                    },
                                    {
                                        label: 'Inactive',
                                        value: INACTIVE
                                    },
                                    {
                                        label: 'Pending',
                                        value: PENDING
                                    }
                                ]}
                                maxCount={1}
                            />
                        </Form.Item>
                    </Col>

                    <Form.Item>
                        <Button type='primary' htmlType='submit'>
                            Update Accommodation
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>

            {previewImage && (
                <Image
                    wrapperStyle={{
                        display: 'none',
                    }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </div>
    )
}

export default EditHotel