import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Drawer, Form, Input, Row, Spin, notification } from 'antd'
import { addAccommodationHouseRules, editAccommodationHouseRules } from '../../../../../../../redux/api/hotels.api';


const EditHouseRule = ({
    editHouseRuleVisible,
    setEditHouseRuleVisible,
    hotel,
    houseRules,
    getHouseRule
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('Adding facility...');
    const [api, contextHolder] = notification.useNotification();
    const formRef = useRef();

    useEffect(() => {
        if (editHouseRuleVisible) {
            formRef?.current?.setFieldsValue({
                checkInTime: houseRules.checkInTime,
                checkOutTime: houseRules.checkOutTime,
                cancellationPolicy: houseRules.cancellationPolicy,
                paymentPolicy: houseRules.paymentPolicy,
                childPolicy: houseRules.childPolicy,
                petPolicy: houseRules.petPolicy,
                smokingPolicy: houseRules.smokingPolicy,
                otherPolicy: houseRules.otherPolicy
            })
        }
    }, [editHouseRuleVisible]);

    const onFinish = async (values) => {
        setIsLoading(true);
        try {
            Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

            values.id = houseRules.id;

            setLoadingText('Updating DB...');
            const response = await editAccommodationHouseRules(values);
            if (response.status === 200) {
                api.success({
                    message: 'Success',
                    description: 'Rules updated successfully'
                });
                getHouseRule();
                setTimeout(() => {
                    setEditHouseRuleVisible(false);
                }, 2000);

            } else {
                api.error({
                    message: 'Error',
                    description: 'An error occurred while updating rules. Please try again.'
                });
            }

        } catch (error) {
            console.log(error);
            api.error({
                message: 'Error',
                description: `An error occurred while updating rules. Please try again. ${error.message}`
            });
        }
        setIsLoading(false);
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Edit house rule"
                width={720}
                open={editHouseRuleVisible}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                onClose={() => setEditHouseRuleVisible(false)}
            >
                <Spin spinning={isLoading} tip={loadingText}>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        ref={formRef}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="checkInTime"
                                    label="Check in Time"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select checkin time',
                                        },
                                    ]}
                                >
                                    <Input type='time' />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="checkOutTime"
                                    label="Check out Time"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a check out time',
                                        },
                                    ]}
                                >
                                    <Input type='time' />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="cancellationPolicy"
                                    label="Cancellation Policy"
                                >
                                    <Input.TextArea
                                        limits={{ maxLength: 500 }}
                                        placeholder="Please enter cancellation Poloicy"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="paymentPolicy"
                                    label="Payment Policy"
                                >
                                    <Input.TextArea
                                        limits={{ maxLength: 500 }}
                                        placeholder="Please enter payment Poloicy"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="childPolicy"
                                    label="Child Policy"
                                >
                                    <Input.TextArea
                                        limits={{ maxLength: 500 }}
                                        placeholder="Please enter child Poloicy"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="petPolicy"
                                    label="Pet Policy"
                                >
                                    <Input.TextArea
                                        limits={{ maxLength: 500 }}
                                        placeholder="Please enter pet Poloicy"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="smokingPolicy"
                                    label="Smoking Policy"
                                >
                                    <Input.TextArea
                                        limits={{ maxLength: 500 }}
                                        placeholder="Please enter pet Poloicy"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="otherPolicy"
                                    label="Other Policies"
                                >
                                    <Input.TextArea
                                        limits={{ maxLength: 500 }}
                                        placeholder="Please enter hotel Poloicy"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item>
                            <Button type='primary' htmlType='submit' loading={isLoading}>
                                Update Facility
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default EditHouseRule