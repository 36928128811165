import axios from 'axios';

import { API_BASE_URL } from '../../App/azure/api';
import { msalInstance } from '../../App/azure/authConfig';
import { getAccessToken } from '../../App/azure/auth';

export const getAllTourGuides = async (values) => {
    const token = await getAccessToken(msalInstance);
    const params = new URLSearchParams();

    if (values.page !== undefined && values.page !== null) params.append('page', values.page);
    params.append('pageSize', 5);

    if (values.guideType) params.append('guideType', values.guideType);
    if (values.guideArea) params.append('guideArea', values.guideArea);
    if (values.guideLanguages) params.append('guideLanguages', values.guideLanguages);
    if (values.vehicleAvailable) params.append('vehicleAvailable', values.vehicleAvailable);
    if (values.activeStatus) params.append('activeStatus', values.activeStatus);

    const url = `${API_BASE_URL}/getAllTourGuides?${params.toString()}`;

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: url,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}


export const registerAsGuide = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/registerAsGuide?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getGuideById = async (id) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getGuideById?id=${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const editGuideProfile = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/editGuideProfile?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const deleteGuideProfile = async (id) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/deleteGuideProfile?clientIp=${ipAddress}&id=${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const addGuideVehicle = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/addGuideVehicle?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getGuideVehicle = async (id) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getGuideVehicle?id=${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const editGuideVehicle = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/editGuideVehicle?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const removeGuideVehicle = async (id) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/removeGuideVehicle?clientIp=${ipAddress}&vehicleId=${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getGuideByUserId = async (id) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getGuideByUserId?id=${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}



// Guide Schedule

export const getTourGuideScheduleById = async (id) => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getTourGuideScheduleById?id=${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const updateTourGuideSchedule = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/updateTourGuideSchedule?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const addTourGuideSchedule = async (values) => {
    const token = await getAccessToken(msalInstance);
    const ipAddress = localStorage.getItem('vp-ip');

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/addTourGuideSchedule?clientIp=${ipAddress}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: values
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getAllGuideNames = async () => {
    const token = await getAccessToken(msalInstance);

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}/getAllGuideNames`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}