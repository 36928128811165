export const captilizeEachWord = (str) => {
    str = str.replace(/_/g, ' ');
    return str.replace(/\b\w/g, l => l.toUpperCase());
}

export const captilizeFirstLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const capitalizeFisrtLetterEachWord = (str) => {
    return str
        .toLowerCase()
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

export const resizeString = (str, length) => {
    return str?.length > length ? str.substring(0, length) + "..." : str;
}

export const trimHTML = (htmlString, maxLength) => {
    const div = document.createElement('div');
    div.innerHTML = htmlString;

    let currentLength = 0;
    const ellipsis = '...';

    function walk(node) {
        // Traverse child nodes
        for (let i = 0; i < node.childNodes.length; i++) {
            const child = node.childNodes[i];
            if (child.nodeType === Node.TEXT_NODE) {
                const text = child.textContent;
                const remainingLength = maxLength - currentLength;

                if (text.length > remainingLength) {
                    // Trim the text and add ellipsis
                    child.textContent = text.substring(0, remainingLength) + ellipsis;

                    // Remove remaining siblings
                    while (child.nextSibling) {
                        node.removeChild(child.nextSibling);
                    }
                    return;
                } else {
                    currentLength += text.length;
                }
            } else if (child.nodeType === Node.ELEMENT_NODE) {
                walk(child);
            }
        }
    }

    walk(div);
    return div.innerHTML;
}
