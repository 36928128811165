import { fetchActivityNames, fetchAllActivities } from '../../api/activities.api';
import {
    FETCH_ACTIVITIES,
    FETCH_ACTIVITIES_SUCCESS,
    FETCH_ACTIVITIES_FAIL,

    FETCH_ACTIVITY_NAMES,
    FETCH_ACTIVITY_NAMES_SUCCESS,
    FETCH_ACTIVITY_NAMES_FAIL
} from '../../types';

export const fetchActivitiesAC = (values) => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_ACTIVITIES
        })
        try {

            const attractionsResponse = await fetchAllActivities(values)

            dispatch({
                type: FETCH_ACTIVITIES_SUCCESS,
                payload: attractionsResponse.data
            })
        } catch (error) {
            dispatch({
                type: FETCH_ACTIVITIES_FAIL,
                payload: error.message
            })
        }
    }
}

export const fetchActivityNamesAC = () => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_ACTIVITY_NAMES
        })
        try {
            const response = await fetchActivityNames()
            dispatch({
                type: FETCH_ACTIVITY_NAMES_SUCCESS,
                payload: response.data
            })
        } catch (error) {
            dispatch({
                type: FETCH_ACTIVITY_NAMES_FAIL,
                payload: error.message
            })
        }
    }
}