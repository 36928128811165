import { fetchTransportPackages } from '../../api/transport.api';
import {
    FETCH_TRANSPORTS,
    FETCH_TRANSPORTS_SUCCESS,
    FETCH_TRANSPORTS_FAIL,

} from '../../types';

export const fetchTransportsAC = (values) => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_TRANSPORTS
        })
        try {
            const transportsResponse = await fetchTransportPackages(values)
            dispatch({
                type: FETCH_TRANSPORTS_SUCCESS,
                payload: transportsResponse.data
            })
        } catch (error) {
            dispatch({
                type: FETCH_TRANSPORTS_FAIL,
                payload: error.message
            })
        }
    }
} 