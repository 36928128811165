import React, { useEffect, useState } from 'react'
import PaymentsTable from './PaymentsTable'
import { getAllReviews } from '../../../../redux/api/reviews.api'

const Reviews = () => {
    const [reviews, setReviews] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        handleGetReviews()
    }, [])

    const handleGetReviews = async () => {
        setLoading(true)
        const response = await getAllReviews()
        if (response.status === 200) {
            setReviews(response.data)
        }
        setLoading(false)
    }

    return (
        <div className='payments'>
            <h1>Reviews</h1>
            <PaymentsTable reviews={reviews} loading={loading} />
        </div>
    )
}

export default Reviews