import React, { useState } from 'react'
import { Button, Col, Drawer, Form, Input, Row, Select, Spin, notification } from 'antd'
import { searchAccommodation } from '../../../../../../../redux/api/hotels.api';
import { addTourAccommodation } from '../../../../../../../redux/api/tourPackages.api';

const AddTourAccommodation = ({
    isVisible,
    setIsVisible,
    tourPackage,
    hadleGetAccommodations
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [selectedAccommodation, setSelectedAccommodation] = useState(null);

    const handleUserSearch = async (value) => {
        if (value === '') {
            setSearchResults([]);
            return;
        } else {
            setIsSearchLoading(true);
            try {
                const res = await searchAccommodation(value);
                if (res.status === 200) {
                    setSearchResults(res.data);
                }
            } catch (error) {
                console.log(error);
            }
            setIsSearchLoading(false);
        }
    };

    const onFinish = async (values) => {
        setIsLoading(true);
        try {
            Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

            if (!selectedAccommodation) {
                api.error({
                    message: 'Error',
                    description: 'Please select the accommodation'
                });
                setIsLoading(false);
                return;
            }

            if (selectedAccommodation?.id !== values.accommodationId) {
                api.error({
                    message: 'Error',
                    description: 'Please select the accommodation again'
                });
                setIsLoading(false);
                return;
            }

            values.packageId = tourPackage.id;
            values.accommodationType = selectedAccommodation?.accommodationType;
            values.title = selectedAccommodation?.accommodationName;
            values.description = selectedAccommodation?.accommodationDescriptionShort;
            values.starGrade = parseInt(selectedAccommodation?.starRating, 10);

            const res = await addTourAccommodation(values);

            if (res.status === 200) {
                api.success({
                    message: 'Success',
                    description: 'Accommodation added successfully'
                });
                setIsVisible(false);
                hadleGetAccommodations();
            } else {
                api.error({
                    message: 'Error',
                    description: 'An error occurred while adding the accommodation. Please try again.'
                });
            }

        } catch (error) {
            console.log(error);
            api.error({
                message: 'Error',
                description: `An error occurred while adding the accommodation. Please try again. ${error.message}`
            });
        }
        setIsLoading(false);
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Add Tour Accommodation"
                width={720}
                open={isVisible}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                onClose={() => setIsVisible(false)}
            >
                <Spin spinning={isLoading} tip='Please Wait'>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label="Select the Accommodation"
                                    name='accommodationId'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select the accommodation',
                                        }
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Select a accommodation"
                                        notFoundContent={isSearchLoading ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        onSearch={handleUserSearch}
                                        options={
                                            searchResults?.map(item => {
                                                return {
                                                    label: item.accommodationName,
                                                    value: item.id
                                                }
                                            })
                                        }
                                        onChange={(value, option) => {
                                            const hotel = searchResults.find(hotel => hotel.id === value);
                                            setSelectedAccommodation(hotel);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="dayNumber"
                                    label="Day Number"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the day number',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter the day number" type='number' />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item>
                            <Button type='primary' htmlType='submit' loading={isLoading}>
                                Add Accommodation
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default AddTourAccommodation