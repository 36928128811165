import { BlobServiceClient } from '@azure/storage-blob';

const BLOB_SAS_URL = process.env.REACT_APP_BLOB_SAS_URL;
export const BLOB_BASE_URL = process.env.REACT_APP_BLOB_BASE_URL;

export const USER_PROFILE_CONTAINER = 'profile-pictures';
export const DESTINATION_CONTAINER = 'destinations';
export const ATTRACTTIONS_CONTAINER = 'attractions';
export const ACTIVITIES_CONTAINER = 'activities';
export const EVENTS_CONTAINER = 'events';
export const ACCOMMODATION_CONTAINER = 'accommodations';
export const PARTNERS_BLOB = 'partners';
export const TOUR_PACKAGES_BLOB = 'tourpackages';
export const PAYMENTS_CONTAINER = 'payments';
export const USER_POSTS = 'user-posts';
export const SUPPORT_CONTAINER = 'support';

export const handleImageUpload = async (container, fileObj, size, blobName) => {
    try {
        const containerClient = new BlobServiceClient(BLOB_SAS_URL).getContainerClient(container);
        const blobRes = await containerClient.uploadBlockBlob(blobName, fileObj, size);
        return blobRes;
    } catch (error) {
        console.error('Error uploading image', error);
    }
}

export const handleDeleteBlob = async (container, blobName) => {
    try {
        const containerClient = new BlobServiceClient(BLOB_SAS_URL).getContainerClient(container);
        const blobClient = containerClient.getBlobClient(blobName);

        const exists = await blobClient.exists();

        if (exists) {
            await blobClient.delete();
        }
    } catch (error) {
        if (error.statusCode === 404) {
            console.error(`Blob ${blobName} does not exist. Error: ${error.message}`);
        } else {
            console.error('Error deleting blob', error);
        }
    }
}
