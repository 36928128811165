import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Drawer, Empty, Form, InputNumber, Popconfirm, Rate, Row, Select, Spin } from 'antd'
import ReactQuill from 'react-quill'

import './styles.scss'
import { addAccomaddUserTourAccommodationmodation, deleteUserTourAccommodation, editUserTourAccommodation, getHotelsByUserTour } from '../../../../../../../redux/api/bookings.api'
import LoadingComp from '../../../../../../components/LoadingComp'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { getAccommodationNames } from '../../../../../../../redux/api/hotels.api'

const { Option } = Select;

const AccommodationsBooking = ({ userTour }) => {
    const [loading, setLoading] = useState(true)
    const [accommodation, setAccommodation] = useState(null)
    const [open, setOpen] = useState(false)
    const [allAcommodations, setAllAccommodations] = useState([])
    const [loadingAllAccommodations, setLoadingAllAccommodations] = useState(true)
    const formRef = useRef();
    const [loadingAddAccommodation, setLoadingAddAccommodation] = useState(false)
    const editFormRef = useRef()
    const [openEdit, setOpenEdit] = useState(false)
    const [selectedAccommodation, setSelectedAccommodation] = useState(null)
    const [loadingEditAccommodation, setLoadingEditAccommodation] = useState(false)

    useEffect(() => {
        handleGetAccommodations(userTour?.id)
        handleGetAllAccommodations()
    }, [])

    const handleGetAllAccommodations = async () => {
        setLoadingAllAccommodations(true)
        try {
            const response = await getAccommodationNames()
            setAllAccommodations(response.data)
        } catch (error) {
            console.error('Error fetching all accommodations', error)
        }
        setLoadingAllAccommodations(false)
    }

    const handleGetAccommodations = async (id) => {
        setLoading(true)
        try {
            const response = await getHotelsByUserTour(id)
            setAccommodation(response.data)
        } catch (error) {
            console.error('Error fetching itineraries', error)
        }
        setLoading(false)
    }

    const handleDeleteAccommodation = async (id) => {
        try {
            const response = await deleteUserTourAccommodation(id)
            if (response.status === 200) {
                handleGetAccommodations(userTour.id)
            }
        } catch (error) {
            console.error('Error deleting accommodation', error)
        }
    }

    const handleOnAddAccommodation = async (values) => {
        setLoadingAddAccommodation(true)
        const data = {
            ...values,
            userTourId: userTour.id
        }
        try {
            const response = await addAccomaddUserTourAccommodationmodation(data)
            if (response.status === 200) {
                formRef.current.resetFields()
                setOpen(false)
                handleGetAccommodations(userTour.id)
                setSelectedAccommodation(null)
            }
        } catch (error) {
            console.error('Error adding accommodation', error)
        }
        setLoadingAddAccommodation(false)
    }


    const handleOnEditAccommodation = async (values) => {
        setLoadingEditAccommodation(true)
        const data = {
            ...values,
            id: selectedAccommodation?.id
        }
        try {
            const response = await editUserTourAccommodation(data)
            if (response.status === 200) {
                editFormRef.current.resetFields()
                setOpenEdit(false)
                handleGetAccommodations(userTour.id)
            }
        } catch (error) {
            console.error('Error updating accommodation', error)
        }
        setLoadingEditAccommodation(false)
    }

    return (
        <div className='user_toru_accommodations'>
            {
                loading ?
                    <div className='booking__loading_container'>
                        <LoadingComp />
                    </div>
                    :
                    <div className='booking__accommodations'>
                        <div
                            className='booking__accommodations__header'
                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                        >
                            <h3 className='header_3' style={{ margin: '0' }}>Accommodations</h3>
                            <Button type='primary'
                                onClick={() => setOpen(true)}
                            >
                                <PlusOutlined />
                                Add Accommodation
                            </Button>
                        </div>
                        {
                            accommodation?.length > 0 ?
                                accommodation.map((hotel) => (
                                    <div className='booking__accommodations__card'>
                                        <div className='booking__accommodations__card__header'>
                                            <h4 className='header_4'>{hotel?.title}</h4>
                                            <div className='booking__accommodations__card__header__actions'>
                                                <Button
                                                    onClick={() => {
                                                        editFormRef.current.setFieldsValue({
                                                            accommodationId: hotel?.accommodationId,
                                                            dayNumber: hotel?.dayNumber
                                                        })
                                                        setSelectedAccommodation(hotel)
                                                        setOpenEdit(true)
                                                    }}
                                                >
                                                    <EditOutlined />
                                                </Button>
                                                <Popconfirm
                                                    title="Are you sure you want to delete this accommodation?"
                                                    onConfirm={() => handleDeleteAccommodation(hotel?.id)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button danger >
                                                        <DeleteOutlined />
                                                    </Button>
                                                </Popconfirm>
                                            </div>
                                        </div>
                                        <p><span>Day: </span>{hotel?.dayNumber}</p>
                                        {hotel?.starGrade && <p><span>Star Rating: </span>
                                            <Rate disabled defaultValue={hotel?.starGrade} />
                                        </p>}
                                        <ReactQuill
                                            value={hotel?.description}
                                            readOnly={true}
                                            theme='bubble'
                                        />
                                    </div>
                                ))
                                :
                                <div>
                                    <Empty>
                                        <p>
                                            Not Added
                                        </p>
                                    </Empty>
                                </div>
                        }
                    </div>

            }

            <Drawer
                title="Add Accommodation"
                width={720}
                onClose={() => setOpen(false)}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                loading={loadingAllAccommodations}
            >
                <Spin spinning={loadingAddAccommodation} tip="Adding Accommodation...">
                    <Form
                        layout="vertical"
                        onFinish={handleOnAddAccommodation}
                        ref={formRef}
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="accommodationId"
                                    label="Accommodation / Hotel"
                                    rules={[{ required: true, message: 'Please select an accommodation' }]}
                                >
                                    <Select
                                        placeholder="Please select an accommodation"
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            allAcommodations.map((accommodation) => (
                                                <Option value={accommodation.id}>{accommodation.accommodationName}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="dayNumber"
                                    label="Day Number"
                                    rules={[{ required: true, message: 'Please enter the day number' }]}
                                >
                                    <InputNumber
                                        placeholder="Please enter the day number"
                                        style={{ width: '100%' }}
                                        min={1}
                                        max={100}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={loadingAddAccommodation}
                                >
                                    Add Accommodation
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Drawer>

            <Drawer
                title="Edit Accommodation"
                width={720}
                onClose={() => setOpenEdit(false)}
                open={openEdit}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                loading={loadingAllAccommodations}
            >
                <Spin spinning={loadingEditAccommodation} tip="Updating Accommodation...">
                    <Form
                        layout="vertical"
                        onFinish={handleOnEditAccommodation}
                        ref={editFormRef}
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="accommodationId"
                                    label="Accommodation / Hotel"
                                    rules={[{ required: true, message: 'Please select an accommodation' }]}
                                >
                                    <Select
                                        placeholder="Please select an accommodation"
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            allAcommodations.map((accommodation) => (
                                                <Option value={accommodation.id}>{accommodation.accommodationName}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="dayNumber"
                                    label="Day Number"
                                    rules={[{ required: true, message: 'Please enter the day number' }]}
                                >
                                    <InputNumber
                                        placeholder="Please enter the day number"
                                        style={{ width: '100%' }}
                                        min={1}
                                        max={100}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={loadingAddAccommodation}
                                >
                                    Update Accommodation
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default AccommodationsBooking