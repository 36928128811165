import React, { useEffect, useState } from 'react'

import { Layout } from 'antd';
import { Route, Routes } from 'react-router-dom';

import './styles.scss'
import { ADMIN_ROUTES, CUSTOMER_RELATIONS_MANAGER_ROUTES, HOTEL_MANAGER_ROUTES, MANAGER_ROUTES, PARTNERSHIP_MANAGER_ROUTES, SUPER_ADMIN_ROUTES, SUPPORT_AGENT_ROUTES, TOUR_GUIDE_ROUTES } from '../../../../Routes/roleBaseRoutes';
import { ADMIN, CUSTOMER_RELATIONS_MANAGER, HOTEL_MANAGER, MANAGER, PARTNERSHIP_MANAGER, SUPER_ADMIN, SUPPORT_AGENT, TOUR_GUIDE } from '../../../../Constants/roles';
import NotFound from '../../../NotFound';

const { Content } = Layout;

const DashboardContent = ({ myProfile }) => {
    const [routes, setRoutes] = useState([]);

    useEffect(() => {
        if (myProfile) {
            switch (myProfile.role) {
                case SUPER_ADMIN:
                    setRoutes(SUPER_ADMIN_ROUTES)
                    break;
                case ADMIN:
                    setRoutes(ADMIN_ROUTES)
                    break;
                case MANAGER:
                    setRoutes(MANAGER_ROUTES)
                    break;
                case PARTNERSHIP_MANAGER:
                    setRoutes(PARTNERSHIP_MANAGER_ROUTES)
                    break;
                case CUSTOMER_RELATIONS_MANAGER:
                    setRoutes(CUSTOMER_RELATIONS_MANAGER_ROUTES)
                    break;
                case SUPPORT_AGENT:
                    setRoutes(SUPPORT_AGENT_ROUTES)
                    break;
                case HOTEL_MANAGER:
                    setRoutes(HOTEL_MANAGER_ROUTES)
                    break;
                case TOUR_GUIDE:
                    setRoutes(TOUR_GUIDE_ROUTES)
                    break;
                default:
                    break;
            }
        }
    }, [myProfile])

    return (
        <Content className='dashboard-content'>
            <Routes>
                {routes}
                <Route path='*' element={<NotFound />} />
            </Routes>
        </Content>
    )
}

export default DashboardContent