import { Button, Col, DatePicker, Divider, Drawer, Form, Input, Row, Select, Space, Spin } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { capitalizeFisrtLetterEachWord } from '../../../../../../helpers/nameRender'
import { ACTIVE, CANCELLED, CANCELLED_TOUR, COMPLETED, CONFIRMED, INSTALMENT_PAID, INSTALMENT_PENDING, NOT_STARTED, ONGOING, PAID, PENDING, PENDING_APPROVAL, PENDING_PAYMENT, PENDING_VERIFICATION, REJECTED, TAX_PERCENTAGE } from '../../../../../../Constants/finance'
import moment from 'moment'

import './styles.scss'
import { countryList } from '../../../../../../Constants/itemTypes'
import { getAllGuideNames } from '../../../../../../../redux/api/guides.api'
import dayjs from 'dayjs'
import { confirmUserTour, editUserTour } from '../../../../../../../redux/api/bookings.api'
import useMyProfile from '../../../../../../hooks/useMyProfile'

const BookingDetails = ({
    userTour,
    handlegetUserTour,
    messageApi
}) => {
    const [editTourOpen, setEditTourOpen] = useState(false)
    const editFromRef = useRef(null)
    const [loadingEditTour, setLoadingEditTour] = useState(false)
    const [approveTourOpen, setApproveTourOpen] = useState(false)
    const approveFromRef = useRef(null)
    const [loadingApproveTour, setLoadingApproveTour] = useState(false)
    const [allGuides, setAllGuides] = useState([])
    const [loadingAllGuides, setLoadingAllGuides] = useState(false)
    const myProfile = useMyProfile();
    const [totalAmount, setTotalAmount] = useState(0)
    const [discount, setDiscount] = useState(0)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (editTourOpen) {
            handleGetallGuides()
        }
    }, [editTourOpen])

    useEffect(() => {
        if (editTourOpen) {
            calculateFinalAmount(totalAmount, discount)
        }
    }, [totalAmount, discount, editTourOpen])

    const calculateFinalAmount = (totalAmount, discount) => {
        var total = totalAmount ? parseFloat(totalAmount) : 0
        var dicountPercentage = discount ? parseFloat(discount) : 0
        var afterDiscount = total - (total * dicountPercentage) / 100
        var tax = (afterDiscount * TAX_PERCENTAGE) / 100
        var finalAmount = Math.round(afterDiscount + tax)
        editFromRef?.current?.setFieldsValue({
            finalAmount: finalAmount
        })
    }

    useEffect(() => {
        editFromRef?.current?.setFieldsValue({
            tourStatus: userTour?.tourStatus,
            transportOption: userTour?.transportOption,
            accommodationOption: userTour?.accommodationOption,
            tourStartDate: dayjs(userTour?.tourStartDate, 'YYYY-MM-DD'),
            totalAmount: userTour?.totalAmount,
            discountAmount: userTour?.discountAmount,
            tax: userTour?.totalAmount && TAX_PERCENTAGE,
            // finalAmount: userTour?.finalAmount,
            pickupLocation: userTour?.pickupLocation,
            dropLocation: userTour?.dropLocation,
            transportVehicleType: userTour?.tarnsportVehicle?.vehicleType,
            vehicleBrand: userTour?.tarnsportVehicle?.vehicleBrand,
            vehicleplateNumber: userTour?.tarnsportVehicle?.vehicleplateNumber,
            vehicleMaxPassenger: userTour?.tarnsportVehicle?.vehicleMaxPassenger,
            guideId: userTour?.guideId,
            emergencyContactName: userTour?.emergencyContactName,
            emergencyContactNumber: userTour?.emergencyContactNumber,
            emergencyEmail: userTour?.emergencyEmail,
            flightNumber: userTour?.flightDetails?.flightNumber,
            departureAirport: userTour?.flightDetails?.departureAirport,
            arrivalDateTime: userTour?.flightDetails?.arrivalDateTime ?
                dayjs(userTour?.flightDetails?.arrivalDateTime, 'YYYY-MM-DDTHH:mm:ssZ')
                : null,
        })

        setTotalAmount(userTour?.totalAmount)
        setDiscount(userTour?.discountAmount)

        calculateFinalAmount(userTour?.totalAmount, userTour?.discountAmount, userTour?.tax)

        userTour?.travellersInfromation?.adults?.map((adult, index) => {
            editFromRef?.current?.setFieldsValue({
                [`adults[${index}].firstName`]: adult.firstName,
                [`adults[${index}].lastName`]: adult.lastName,
                [`adults[${index}].email`]: adult.email,
                [`adults[${index}].phone`]: adult.phone,
                [`adults[${index}].country`]: adult.country,
                [`adults[${index}].passportNumber`]: adult.passportNumber,
                [`adults[${index}].allergies`]: adult.allergies,
            })
        })

        userTour?.travellersInfromation?.children?.map((child, index) => {
            editFromRef?.current?.setFieldsValue({
                [`children[${index}].firstName`]: child.firstName,
                [`children[${index}].lastName`]: child.lastName,
                [`children[${index}].email`]: child.email,
                [`children[${index}].phone`]: child.phone,
                [`children[${index}].country`]: child.country,
                [`children[${index}].passportNumber`]: child.passportNumber,
                [`children[${index}].allergies`]: child.allergies,
            })
        })

        userTour?.travellersInfromation?.infants?.map((infant, index) => {
            editFromRef?.current?.setFieldsValue({
                [`infants[${index}].firstName`]: infant.firstName,
                [`infants[${index}].lastName`]: infant.lastName,
                [`infants[${index}].email`]: infant.email,
                [`infants[${index}].phone`]: infant.phone,
                [`infants[${index}].country`]: infant.country,
                [`infants[${index}].passportNumber`]: infant.passportNumber,
                [`infants[${index}].allergies`]: infant.allergies,
            })
        })

    }, [editFromRef, allGuides])

    useEffect(() => {
        approveFromRef?.current?.setFieldsValue({
            bookingStatus: userTour?.bookingStatus,
            paymentStatus: userTour?.paymentStatus,
            isConfirmedByAdmin: userTour?.isConfirmedByAdmin,
            tourStatus: userTour?.tourStatus,
        })
    }, [approveFromRef, approveTourOpen])

    const handleGetallGuides = async () => {
        setLoadingAllGuides(true)
        try {
            const response = await getAllGuideNames()
            if (response.status === 200) {
                setAllGuides(response.data)
            }
        } catch (error) {
            console.log(error)
        }
        setLoadingAllGuides(false)
    }

    const handleEditTour = async (values) => {
        setLoadingEditTour(true)

        let travellersInfromation = {
            adults: userTour?.travellersInfromation?.adults?.length > 0 ?
                Array.from({ length: userTour?.travellersInfromation?.adults?.length }, (_, index) => index).map((index) => (
                    {
                        firstName: values[`adults[${index}].firstName`],
                        lastName: values[`adults[${index}].lastName`],
                        email: values[`adults[${index}].email`],
                        phone: values[`adults[${index}].phone`],
                        country: values[`adults[${index}].country`],
                        passportNumber: values[`adults[${index}].passportNumber`],
                        allergies: values[`adults[${index}].allergies`],
                    })) : [],
            children: userTour?.travellersInfromation?.children?.length > 0 ?
                Array.from({ length: userTour?.travellersInfromation?.children?.length }, (_, index) => index).map((index) => ({
                    firstName: values[`children[${index}].firstName`],
                    lastName: values[`children[${index}].lastName`],
                    email: values[`children[${index}].email`],
                    phone: values[`children[${index}].phone`],
                    country: values[`children[${index}].country`],
                    passportNumber: values[`children[${index}].passportNumber`],
                    allergies: values[`children[${index}].allergies`],
                })) : [],
            infants: userTour?.travellersInfromation?.infants?.length > 0 ?
                Array.from({ length: userTour?.travellersInfromation?.infants?.length }, (_, index) => index).map((index) => ({
                    firstName: values[`infants[${index}].firstName`],
                    lastName: values[`infants[${index}].lastName`],
                    email: values[`infants[${index}].email`],
                    phone: values[`infants[${index}].phone`],
                    country: values[`infants[${index}].country`],
                    passportNumber: values[`infants[${index}].passportNumber`],
                    allergies: values[`infants[${index}].allergies`],
                })) : [],
        };

        let travellersInfromationJSON = JSON.stringify(travellersInfromation)

        let flightInfo = {
            flightBooked: values?.flightNumber ? true : false,
            flightNumber: values?.flightNumber,
            departureAirport: values?.departureAirport,
            arrivalDateTime: values?.arrivalDateTime,
        }

        let flightInfoJSON = JSON.stringify(flightInfo)

        let vehicleInfo = null;
        if (values?.transportVehicleType) {
            vehicleInfo = {
                vehicleType: values?.transportVehicleType,
                vehicleBrand: values?.vehicleBrand,
                vehicleplateNumber: values?.vehicleplateNumber,
                vehicleMaxPassenger: values?.vehicleMaxPassenger,
            }

            vehicleInfo = JSON.stringify(vehicleInfo)
        }

        const data = {
            id: userTour?.id,
            pickupLocation: values?.pickupLocation,
            dropLocation: values?.dropLocation,
            transportOption: values?.transportOption,
            accommodationOption: values?.accommodationOption,
            tourStartDate: dayjs(values?.tourStartDate).format('YYYY-MM-DD'),
            totalAmount: values?.totalAmount || null,
            discountAmount: values?.discountAmount || null,
            finalAmount: values?.finalAmount || null,
            tax: values?.tax || null,
            guideId: values?.guideId,
            emergencyContactName: values?.emergencyContactName,
            emergencyContactNumber: values?.emergencyContactNumber,
            emergencyEmail: values?.emergencyEmail,
            travellersInfromation: travellersInfromationJSON,
            flightDetails: flightInfoJSON,
            tarnsportVehicle: vehicleInfo,
        }

        const response = await editUserTour(data)
        if (response.status === 200) {
            messageApi.success({
                message: 'Success',
                description: 'Booking details has been updated successfully',
            })
            handlegetUserTour()
            setEditTourOpen(false)
        } else {
            messageApi.error({
                message: 'Error',
                description: 'Failed to update booking details',
            })
        }

        setLoadingEditTour(false)
    }

    const handleApproveTour = async (values) => {
        setLoadingApproveTour(true)

        const data = {
            id: userTour?.id,
            bookingStatus: values?.bookingStatus,
            paymentStatus: values?.paymentStatus,
            isConfirmedByAdmin: values?.isConfirmedByAdmin,
            tourStatus: values?.tourStatus,
            confirmedBy: values?.isConfirmedByAdmin ? myProfile?.userProfile?.id : null,
        }

        const response = await confirmUserTour(data)
        if (response.status === 200) {
            messageApi.success({
                message: 'Success',
                description: 'Booking status has been updated successfully',
            })
            handlegetUserTour()
            setApproveTourOpen(false)
        } else {
            messageApi.error({
                message: 'Error',
                description: 'Failed to update booking status',
            })
        }

        setLoadingApproveTour(false)
    }

    return (
        <>
            <div className='booking_details'>
                <div
                    className='actions'
                    style={{ display: 'flex', gap: '10px' }}
                >
                    <Button
                        type='primary'
                        onClick={() => setEditTourOpen(true)}
                    >
                        Edit Booking
                    </Button>
                    <Button
                        onClick={() => setApproveTourOpen(true)}
                    >
                        Change Status
                    </Button>
                </div>
                <Divider
                    orientation="left"
                    orientationMargin="0"
                >
                    Basic Information
                </Divider>
                <div className='p_container'>
                    <p><span>Booking ID: </span>{userTour?.id}</p>
                    <p><span>Start Date: </span>{userTour?.tourStartDate}</p>
                    <p><span>Duration: </span>{userTour?.tourPackage?.durationDays} Days / {userTour?.tourPackage?.durationNights} Nights</p>
                    <p><span>Booked Date: </span>{userTour?.createdAt}</p>
                    <p>
                        <span>Customer: </span>
                        <a href={`/dashboard/users/all/${userTour?.userId}`} target='_blank' rel='noreferrer'>
                            {userTour?.userName}
                        </a>
                    </p>
                </div>
                <Divider
                    orientation="left"
                    orientationMargin="0"
                >
                    Status
                </Divider>
                <div className='p_container'>
                    <p
                        className={userTour?.isConfirmedByAdmin ? 'green' : 'red'}
                    ><span>Approve Status: </span>{userTour?.isConfirmedByAdmin ? 'Approved' : 'Pending Approval'}</p>
                    <p
                        className={userTour?.bookingStatus === PENDING_PAYMENT ? 'red' :
                            userTour?.bookingStatus === PENDING_VERIFICATION ? 'yellow' :
                                userTour?.bookingStatus === CONFIRMED ? 'green' :
                                    'red'}
                    ><span>Booking Status: </span>{userTour?.bookingStatus ? capitalizeFisrtLetterEachWord(userTour?.bookingStatus) : 'Pending'}
                    </p>
                    <p
                        className={userTour?.paymentStatus === PENDING_PAYMENT ? 'red' :
                            userTour?.paymentStatus === PENDING_VERIFICATION ? 'yellow' :
                                userTour?.paymentStatus === PAID ? 'green' :
                                    'red'}
                    ><span>Payment Status: </span>{userTour?.paymentStatus ? capitalizeFisrtLetterEachWord(userTour?.paymentStatus) : 'Pending'}
                    </p>
                </div>
                <Divider
                    orientation="left"
                    orientationMargin="0"
                >
                    Customer Selections
                </Divider>
                <div className='p_container'>
                    <p><span>Transport Option: </span>{userTour?.transportOption}</p>
                    <p><span>Accommodation Room Type: </span>{userTour?.accommodationOption}</p>
                </div>
                <Divider
                    orientation="left"
                    orientationMargin="0"
                >
                    Guide & Transport
                </Divider>
                <div className='p_container'>
                    <p><span>Your Tour Guide: </span>{userTour?.guideId || 'Not yet assigned'}</p>
                    <p><span>Vehicle: </span>{!userTour?.tarnsportVehicle ? 'Not yet assigned' : 'Assigned'}</p>
                    {
                        userTour?.tarnsportVehicle && <div className='p_container'>
                            <p><span>Vehicle Type: </span>{userTour?.tarnsportVehicle?.vehicleType}</p>
                            <p><span>Vehicle Brand & Model: </span>{userTour?.tarnsportVehicle?.vehicleBrand}</p>
                            <p><span>Plate Number: </span>{userTour?.tarnsportVehicle?.vehicleplateNumber}</p>
                            <p><span>Max Passenger Count: </span>{userTour?.tarnsportVehicle?.vehicleMaxPassenger}</p>
                        </div>
                    }
                </div>
                <Divider
                    orientation="left"
                    orientationMargin="0"
                >
                    Travelers & Contacts
                </Divider>
                <div className='travellers'>
                    {
                        userTour?.travellersInfromation?.adults?.length > 0
                        &&
                        <>
                            <h2>Adults</h2>
                            {
                                userTour?.travellersInfromation?.adults.map((traveler, index) => (
                                    <div key={index} className='p_container'>
                                        <p><span>First Name: </span>{traveler.firstName}</p>
                                        <p><span>Last Name: </span>{traveler.lastName}</p>
                                        <p><span>Email: </span>{traveler.email}</p>
                                        <p><span>Phone: </span>{traveler.phone}</p>
                                        <p><span>Country: </span>{traveler.country}</p>
                                        <p><span>Passport No: </span>{traveler.passportNumber}</p>
                                        <p><span>Allergies & Dietary Requirements: </span>{traveler.allergies || 'None'}</p>
                                    </div>
                                ))
                            }
                        </>
                    }
                    {
                        userTour?.travellersInfromation?.children?.length > 0
                        &&
                        <>
                            <h2>Children</h2>
                            {
                                userTour?.travellersInfromation?.children.map((traveler, index) => (
                                    <div key={index} className='p_container'>
                                        <p><span>First Name: </span>{traveler.firstName}</p>
                                        <p><span>Last Name: </span>{traveler.lastName}</p>
                                        <p><span>Email: </span>{traveler.email}</p>
                                        <p><span>Phone: </span>{traveler.phone}</p>
                                        <p><span>Country: </span>{traveler.country}</p>
                                        <p><span>Passport No: </span>{traveler.passportNumber}</p>
                                        <p><span>Allergies & Dietary Requirements: </span>{traveler.allergies || 'None'}</p>
                                    </div>
                                ))
                            }
                        </>
                    }
                    {
                        userTour?.travellersInfromation?.infants?.length > 0
                        &&
                        <>
                            <h2>Infants</h2>
                            {
                                userTour?.travellersInfromation?.infants.map((traveler, index) => (
                                    <div key={index} className='p_container'>
                                        <p><span>First Name: </span>{traveler.firstName}</p>
                                        <p><span>Last Name: </span>{traveler.lastName}</p>
                                        <p><span>Email: </span>{traveler.email}</p>
                                        <p><span>Phone: </span>{traveler.phone}</p>
                                        <p><span>Country: </span>{traveler.country}</p>
                                        <p><span>Passport No: </span>{traveler.passportNumber}</p>
                                        <p><span>Allergies & Dietary Requirements: </span>{traveler.allergies || 'None'}</p>
                                    </div>
                                ))
                            }
                        </>
                    }
                    <h2>Emergency Contacts</h2>
                    <div className='p_container'>
                        <p><span>Name: </span>{userTour?.emergencyContactName}</p>
                        <p><span>Phone: </span>{userTour?.emergencyContactNumber}</p>
                        <p><span>Email: </span>{userTour?.emergencyEmail}</p>
                    </div>

                    <h2>Flight Details</h2>
                    <div className='p_container'>
                        <p><span>Flight Booked: </span>{userTour?.flightDetails?.flightBooked ? 'Yes' : 'Not Yet'}</p>
                        {userTour?.flightDetails?.flightBooked && <>
                            <p><span>Flight No: </span>{userTour?.flightDetails?.flightNumber}</p>
                            <p><span>Departure Airport: </span>{userTour?.flightDetails?.departureAirport}</p>
                            <p><span>Arrival Date & Time: </span>{
                                moment(userTour?.flightDetails?.arrivalDateTime).format('DD/MM/YYYY HH:mm')
                            }</p>
                        </>}
                    </div>
                </div>
            </div>

            <Drawer
                title="Edit Booking"
                width={720}
                onClose={() => setEditTourOpen(false)}
                open={editTourOpen}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                loading={loadingAllGuides}
                extra={
                    <Space>
                        <Button onClick={() => setEditTourOpen(false)}>
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                editFromRef.current.submit()
                            }}
                        >
                            Update
                        </Button>
                    </Space>
                }
            >
                <Spin spinning={loadingEditTour} tip='Updating booking details...'>
                    <Form
                        layout="vertical"
                        ref={editFromRef}
                        onFinish={handleEditTour}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="transportOption"
                                    label="Transport Option"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select transport option'
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Please select transport option"
                                        allowClear
                                    >
                                        <Select.Option value='Hatchback'>Hatchback</Select.Option>
                                        <Select.Option value='Sedan'>Sedan</Select.Option>
                                        <Select.Option value='SUV'>SUV</Select.Option>
                                        <Select.Option value='Van'>Van</Select.Option>
                                        <Select.Option value='Coster'>Coster</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="accommodationOption"
                                    label="Accommodation Option"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select accommodation option'
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Please select accommodation option"
                                        allowClear
                                    >
                                        <Select.Option value='Single'>Single</Select.Option>
                                        <Select.Option value='Double'>Double</Select.Option>
                                        <Select.Option value='Triple'>Triple</Select.Option>
                                        <Select.Option value='Family'>Family</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="tourStartDate"
                                    label="Tour Start date"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select tour start date'
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        type='date'
                                        style={{ width: '100%' }}
                                        showNow={false}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider>Price</Divider>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name='totalAmount'
                                    label="Total Amount"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter total amount'
                                        },
                                    ]}
                                >
                                    <Input
                                        type='number'
                                        min={0}
                                        placeholder="Please enter total amount"
                                        suffix={'AUD'}
                                        onChange={(e) => setTotalAmount(e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name='discountAmount'
                                    label="Discount Percentage"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter discount amount'
                                        },
                                    ]}
                                >
                                    <Input
                                        type='number'
                                        min={0}
                                        max={100}
                                        placeholder="Please enter discount amount"
                                        suffix={'%'}
                                        onChange={(e) => setDiscount(e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name='tax'
                                    label="Tax + Service Charge"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter tax amount'
                                        },
                                    ]}
                                >
                                    <Input
                                        type='number'
                                        min={0}
                                        placeholder="Please enter tax amount"
                                        suffix={'%'}
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name='finalAmount'
                                    label="Final Amount"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter final amount'
                                        },
                                    ]}
                                >
                                    <Input
                                        type='number'
                                        min={0}
                                        placeholder="Please enter final amount"
                                        suffix={'AUD'}
                                        readOnly
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider>Pick-up & Drop</Divider>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name='pickupLocation'
                                    label="Pick-up Location"
                                >
                                    <Input
                                        placeholder="Please enter pick-up location"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name='dropLocation'
                                    label="Drop Location"
                                >
                                    <Input
                                        placeholder="Please enter drop location"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider>Travelers</Divider>
                        {userTour?.travellersInfromation?.adults?.length > 0 && <Divider
                            orientation='left'
                        >
                            Adults Information
                        </Divider>}
                        {
                            userTour?.travellersInfromation?.adults?.length > 0 && userTour?.travellersInfromation?.adults?.map((tarveler, index) => (
                                <div className='traveler'>
                                    <h4>Traveler {index + 1} Details - Adult</h4>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='First Name'
                                                    name={`adults[${index}].firstName`}
                                                    rules={[
                                                        { required: true, message: 'Please input your first name!' }
                                                    ]}
                                                >
                                                    <Input type='text' placeholder='John'
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Last Name'
                                                    name={`adults[${index}].lastName`}
                                                    rules={[{ required: true, message: 'Please input your last name!' }]}
                                                >
                                                    <Input type='text' placeholder='Doe'
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Email'
                                                    name={`adults[${index}].email`}
                                                    rules={[{ required: true, message: 'Please input your email!' }, { type: 'email' }]}
                                                >
                                                    <Input placeholder='example@example.com' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Phone'
                                                    name={`adults[${index}].phone`}
                                                    rules={[
                                                        { required: true, message: 'Please input your phone!' },
                                                        { pattern: /^[0-9]{10}$/, message: 'Please input valid phone number!' },
                                                    ]}
                                                >
                                                    <Input type='tel' placeholder='0400000000' />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Country'
                                                    name={`adults[${index}].country`}
                                                    rules={[{ required: true, message: 'Please input your country!' }]}
                                                >
                                                    <Select
                                                        placeholder='Select your country'
                                                        allowClear
                                                    >
                                                        {
                                                            countryList.map((country) => (
                                                                <Select.Option value={country}>{country}</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Passport Number'
                                                    name={`adults[${index}].passportNumber`}
                                                    rules={
                                                        [
                                                            {
                                                                required: true,
                                                                message: 'Please input your passport number!',
                                                            },
                                                            {
                                                                pattern: /^[A-Z0-9]{9}$/,
                                                                message: 'Please input valid passport number!',
                                                            },
                                                        ]
                                                    }
                                                >
                                                    <Input placeholder='A12345678' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <Form.Item
                                                    label='Allergies & Dietary Requirements'
                                                    name={`adults[${index}].allergies`}
                                                    rules={[{ required: false }]}
                                                >
                                                    <Input.TextArea
                                                        placeholder='Allergies & Dietary Requirements' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            ))
                        }
                        {userTour?.travellersInfromation?.children?.length > 0 && <Divider
                            orientation='left'
                        >
                            Children Information
                        </Divider>}
                        {
                            userTour?.travellersInfromation?.children?.length > 0 && userTour?.travellersInfromation?.children?.map((tarveler, index) => (
                                <div className='traveler'>
                                    <h4>Traveler {index + 1} Details - Children</h4>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='First Name'
                                                    name={`children[${index}].firstName`}
                                                    rules={[
                                                        { required: true, message: 'Please input your first name!' }
                                                    ]}
                                                >
                                                    <Input type='text' placeholder='John'
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Last Name'
                                                    name={`children[${index}].lastName`}
                                                    rules={[{ required: true, message: 'Please input your last name!' }]}
                                                >
                                                    <Input type='text' placeholder='Doe'
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Email'
                                                    name={`children[${index}].email`}
                                                    rules={[{ required: true, message: 'Please input your email!' }, { type: 'email' }]}
                                                >
                                                    <Input type='email' placeholder='example@example.com'
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Phone'
                                                    name={`children[${index}].phone`}
                                                    rules={[
                                                        { required: true, message: 'Please input your phone!' },
                                                        { pattern: /^[0-9]{10}$/, message: 'Please input valid phone number!' },
                                                    ]}
                                                >
                                                    <Input type='tel' placeholder='0400000000'
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Country'
                                                    name={`children[${index}].country`}
                                                    rules={[{ required: true, message: 'Please input your country!' }]}
                                                >
                                                    <Input type='select your country' placeholder='Australia'
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Passport Number'
                                                    name={`children[${index}].passportNumber`}
                                                    rules={
                                                        [
                                                            {
                                                                required: true,
                                                                message: 'Please input your passport number!',
                                                            },
                                                            {
                                                                pattern: /^[A-Z0-9]{9}$/,
                                                                message: 'Please input valid passport number!',
                                                            },
                                                        ]
                                                    }
                                                >
                                                    <Input placeholder='A12345678'
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <Form.Item
                                                    label='Allergies & Dietary Requirements'
                                                    name={`children[${index}].allergies`}
                                                    rules={[{ required: false }]}
                                                >
                                                    <Input.TextArea
                                                        placeholder='Allergies & Dietary Requirements' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            ))
                        }
                        {userTour?.travellersInfromation?.infants?.length > 0 && <Divider
                            orientation='left'
                        >
                            Infants Information
                        </Divider>}
                        {
                            userTour?.travellersInfromation?.infants?.length > 0 && userTour?.travellersInfromation?.infants?.length?.map((tarveler, index) => (
                                <div className='traveler'>
                                    <h4>Traveler {index + 1} Details - Infant</h4>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='First Name'
                                                    name={`infants[${index}].firstName`}
                                                    rules={[
                                                        { required: true, message: 'Please input your first name!' }
                                                    ]}
                                                >
                                                    <Input type='text' placeholder='John'
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Last Name'
                                                    name={`infants[${index}].lastName`}
                                                    rules={[{ required: true, message: 'Please input your last name!' }]}
                                                >
                                                    <Input type='text' placeholder='Doe'
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Email (Parent)'
                                                    name={`infants[${index}].email`}
                                                    rules={[{ required: true, message: 'Please input your email!' }, { type: 'email' }]}
                                                >
                                                    <Input type='email' placeholder='example@example.com'
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Phone (Parent)'
                                                    name={`infants[${index}].phone`}
                                                    rules={[
                                                        { required: true, message: 'Please input your phone!' },
                                                        { pattern: /^[0-9]{10}$/, message: 'Please input valid phone number!' },
                                                    ]}
                                                >
                                                    <Input type='tel' placeholder='0400000000'
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Country'
                                                    name={`infants[${index}].country`}
                                                    rules={[{ required: true, message: 'Please input your email!' }]}
                                                >
                                                    <Input type='select your country' placeholder='Australia'
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label='Passport Number (Parent)'
                                                    name={`infants[${index}].passportNumber`}
                                                    rules={
                                                        [
                                                            {
                                                                required: true,
                                                                message: 'Please input your passport number!',
                                                            },
                                                            {
                                                                pattern: /^[A-Z0-9]{9}$/,
                                                                message: 'Please input valid passport number!',
                                                            },
                                                        ]
                                                    }
                                                >
                                                    <Input placeholder='A12345678'
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <Form.Item
                                                    label='Allergies & Dietary Requirements'
                                                    name={`infants[${index}].allergies`}
                                                    rules={[{ required: false }]}
                                                >
                                                    <Input.TextArea
                                                        placeholder='Allergies & Dietary Requirements' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            ))
                        }
                        <Divider>Emergency Contact</Divider>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name='emergencyContactName'
                                    label="Emergency Contact Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please add emergency contact name'
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Please add emergency contact name"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name='emergencyContactNumber'
                                    label="Emergency Contact Number"
                                    rules={[
                                        { required: true, message: 'Please input your phone!' },
                                        { pattern: /^[0-9]{10}$/, message: 'Please input valid phone number!' },
                                    ]}
                                >
                                    <Input
                                        type='tel'
                                        placeholder="Please enter emergency contact number"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name='emergencyEmail'
                                    label="Emergency Contact Email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please add emergency contact email'
                                        },
                                    ]}
                                >
                                    <Input
                                        type='email'
                                        placeholder="Please add emergency contact email"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider>Transport Vehicle</Divider>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="transportVehicleType"
                                    label="Transport Vehicle Type"
                                >
                                    <Select
                                        placeholder="Please select transport vehicle type"
                                        allowClear
                                    >
                                        <Select.Option value='Hatchback'>Hatchback</Select.Option>
                                        <Select.Option value='Sedan'>Sedan</Select.Option>
                                        <Select.Option value='SUV'>SUV</Select.Option>
                                        <Select.Option value='Van'>Van</Select.Option>
                                        <Select.Option value='Coster'>Coster</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name='vehicleBrand'
                                    label="Vehicle Brand & Model"
                                >
                                    <Input
                                        placeholder="Please enter vehicle brand & model"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name='vehicleplateNumber'
                                    label="Vehicle Plate Number"
                                >
                                    <Input
                                        placeholder="Please enter vehicle plate number"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name='vehicleMaxPassenger'
                                    label="Vehicle Max Passenger"
                                >
                                    <Input
                                        type='number'
                                        placeholder="Please enter vehicle max passenger"
                                        min={0}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider>Tour Guide</Divider>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name='guideId'
                                    label="Guide"
                                >
                                    <Select
                                        placeholder="Please select guide"
                                        allowClear
                                    >
                                        {
                                            allGuides.map((guide) => (
                                                <Select.Option value={guide.id}>{guide.guideName}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider>Flight Details</Divider>
                        <Row gutter={16}>
                            <Form.Item
                                style={{ width: '100%', margin: '10px 0 0 0' }}
                                className='confirmation_form'
                                name='flightNumber'
                                label='Flight Number'
                            >
                                <Input placeholder='Flight Number' />
                            </Form.Item>

                            <Form.Item
                                style={{ width: '100%', margin: '10px 0 0 0' }}
                                className='confirmation_form'
                                name='departureAirport'
                                label='Departure Airport'
                            >
                                <Input placeholder='Departure Airport' />
                            </Form.Item>

                            <Form.Item
                                style={{ width: '100%', margin: '10px 0 0 0' }}
                                className='confirmation_form'
                                name='arrivalDateTime'
                                label='Arrival Date & Time'
                            >
                                <DatePicker
                                    showTime
                                    format='YYYY-MM-DD HH:mm:ss'
                                    placeholder='Arrival Date & Time'
                                    showNow={false}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Row>
                    </Form>
                </Spin>
            </Drawer>

            <Drawer
                title="Change Status"
                width={720}
                onClose={() => setApproveTourOpen(false)}
                open={approveTourOpen}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={() => setApproveTourOpen(false)}>
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                approveFromRef.current.submit()
                            }}
                        >
                            Update
                        </Button>
                    </Space>
                }
            >
                <Spin spinning={loadingApproveTour} tip='Updating booking status...'>
                    <Form
                        layout="vertical"
                        ref={approveFromRef}
                        onFinish={handleApproveTour}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name='bookingStatus'
                                    label='Booking Status'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select booking status'
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Please select booking status"
                                        allowClear
                                    >
                                        <Select.Option value={CONFIRMED}>
                                            Confirmed
                                        </Select.Option>
                                        <Select.Option value={PENDING}>
                                            Pending
                                        </Select.Option>
                                        <Select.Option value={REJECTED}>
                                            Rejected
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name='tourStatus'
                                    label='Tour Status'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select tour status'
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Please select tour status"
                                        allowClear
                                    >
                                        <Select.Option value={ACTIVE}>
                                            Active
                                        </Select.Option>
                                        <Select.Option value={NOT_STARTED}>
                                            Not Started
                                        </Select.Option>
                                        <Select.Option value={ONGOING}>
                                            Ongoing
                                        </Select.Option>
                                        <Select.Option value={COMPLETED}>
                                            Completed
                                        </Select.Option>
                                        <Select.Option value={CANCELLED_TOUR}>
                                            Cancelled
                                        </Select.Option>
                                        <Select.Option value={PENDING_APPROVAL}>
                                            Pending Approval
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name='paymentStatus'
                                    label='Payment Status'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select payment status'
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Please select payment status"
                                        allowClear
                                    >
                                        <Select.Option value={PENDING_PAYMENT}>
                                            Pending Payment
                                        </Select.Option>
                                        <Select.Option value={PENDING_VERIFICATION}>
                                            Pending Verification
                                        </Select.Option>
                                        <Select.Option value={PAID}>
                                            Paid
                                        </Select.Option>
                                        <Select.Option value={INSTALMENT_PENDING}>
                                            Instalment Pending
                                        </Select.Option>
                                        <Select.Option value={INSTALMENT_PAID}>
                                            Instalment Paid
                                        </Select.Option>
                                        <Select.Option value={CANCELLED}>
                                            Cancelled
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name='isConfirmedByAdmin'
                                    label='Approve Status'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select approve status'
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Please select approve status"
                                        allowClear
                                    >
                                        <Select.Option value={true}>
                                            Approved
                                        </Select.Option>
                                        <Select.Option value={false}>
                                            Pending Approval
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Drawer>
        </>
    )
}

export default BookingDetails