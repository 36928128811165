import React, { useEffect, useState } from 'react'
import useDestinations from '../../../../../hooks/useDestinations'
import { deleteDestination, getDestinationById } from '../../../../../../redux/api/destinations.api'
import { Button, Collapse, Divider, Empty, Image, Popconfirm, Skeleton, Spin, Tag, notification } from 'antd'
import { useNavigate } from 'react-router-dom'
import { CaretRightOutlined } from '@ant-design/icons'
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

import './styles.scss'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { DESTINATION_CONTAINER, handleDeleteBlob } from '../../../../../azure/blob'
import { fetchAllAttractions } from '../../../../../../redux/api/attractions.api'
import { fetchAllActivities } from '../../../../../../redux/api/activities.api'
import { fetchAllEvents } from '../../../../../../redux/api/events.api'
import { DEFAULT_THUMBNAIL } from '../../../../../Constants/otherConstsnts'
import { ADMIN, SUPER_ADMIN } from '../../../../../Constants/roles'
import useMyProfile from '../../../../../hooks/useMyProfile'
import { ACTIVE } from '../../../../../Constants/activeStatusType'

const Destination = () => {
    const destinations = useDestinations().destinations
    const url = window.location.pathname
    const destinationId = url.split('/').pop()
    const [loading, setLoading] = useState(false)
    const [destination, setDestination] = useState()
    const navigate = useNavigate()
    const [api, contextHolder] = notification.useNotification();
    const [loadingText, setLoadingText] = useState('Please Wait...')
    const [attractions, setAttractions] = useState([])
    const [loadingAttractions, setAttcationLoading] = useState(false)
    const [activities, setActivities] = useState([])
    const [loadingActivities, setActivitiesLoading] = useState(false)
    const [events, setEvents] = useState([])
    const [loadingEvents, setEventsLoading] = useState(false)
    const [shops, setShops] = useState([])
    const myProfile = useMyProfile().userProfile
    const [loadingShops, setShopsLoading] = useState(false)
    const [restaurants, setRestaurants] = useState([])
    const [loadingRestaurants, setRestaurantsLoading] = useState(false)
    const [emergencyContacts, setEmergencyContacts] = useState([])
    const [loadingEmergencyContacts, setEmergencyContactsLoading] = useState(false)
    const [reviews, setReviews] = useState([])
    const [loadingReviews, setReviewsLoading] = useState(false)

    useEffect(() => {
        if (destinations?.length > 0) {
            const destination = destinations.find(destination => destination.id === destinationId)
            if (destination) {
                setDestination(destination)
            } else {
                handleGetDestination(destinationId)
            }
        } else {
            handleGetDestination(destinationId)
        }
    }, [])

    const handleGetDestination = async (id) => {
        setLoading(true)
        try {
            const destination = await getDestinationById(id)
            setDestination(destination.data[0])
        } catch (error) {
            console.error('Error fetching destination', error)
        }
        setLoading(false)
    }

    const getBlobName = (blobUrl) => {
        const splitUrl = blobUrl.split('/')
        return splitUrl[splitUrl.length - 1]
    }

    const handleDeleteDestination = async () => {
        setLoading(true)
        try {
            // get all blobs
            let destinationImages = destination.destinationImages.split(',')
            let destinationVideos = destination.destinationVideos.split(',')
            let thumbnail = destination.thumbnailUrl

            if (destinationImages.length > 0) {
                setLoadingText('Deleting Destination Images');
                const imageDeletePromises = destinationImages.map(async (image, index) => {
                    const blobName = getBlobName(image);
                    setLoadingText(`Deleting image ${index + 1}`);
                    return handleDeleteBlob(DESTINATION_CONTAINER, blobName);
                });
                await Promise.all(imageDeletePromises);
            }

            if (destinationVideos.length > 0) {
                setLoadingText('Deleting Destination Videos');
                const videoDeletePromises = destinationVideos.map(async (video, index) => {
                    const blobName = getBlobName(video);
                    setLoadingText(`Deleting video ${index + 1}`);
                    return handleDeleteBlob(DESTINATION_CONTAINER, blobName);
                });
                await Promise.all(videoDeletePromises);
            }

            if (thumbnail) {
                setLoadingText('Deleting Destination Thumbnail');
                const blobName = getBlobName(thumbnail);
                setLoadingText(`Deleting thumbnail`);
                await handleDeleteBlob(DESTINATION_CONTAINER, blobName);
            }

            const res = await deleteDestination(destinationId)

            if (res.status === 200) {
                api.success({
                    message: 'Destination deleted successfully',
                    description: 'The destination has been successfully deleted'
                })

                setTimeout(() => {
                    navigate('/dashboard/tours/destinations')
                }, 2000)

            } else {
                api.error({
                    message: 'Error deleting destination',
                    description: 'An error occurred while deleting the destination'
                })
            }

        } catch (error) {
            console.error('Error deleting destination', error)
            api.error({
                message: 'Error deleting destination',
                description: error.message
            })
        }
        setLoading(false)
    }

    const handleGetArractions = async () => {
        setAttcationLoading(true)
        const res = await fetchAllAttractions({ destinationId: destinationId })
        setAttractions(res.data)
        setAttcationLoading(false)
    }

    const handleGetActivities = async () => {
        setActivitiesLoading(true)
        const res = await fetchAllActivities({ destinationId: destinationId })
        setActivities(res.data)
        setActivitiesLoading(false)
    }

    const handleGetEvents = async () => {
        setEventsLoading(true)
        const res = await fetchAllEvents({ destinationId: destinationId })
        setEvents(res.data)
        setEventsLoading(false)
    }

    const renderAttractions = () => {
        return (
            <>
                {loadingAttractions ?
                    <Skeleton active />
                    :
                    <div className='destination__content_collapsed'>
                        {attractions?.length > 0
                            ? attractions.map((attraction, index) => (
                                <div key={index} className='destination__content_collapsed__item'
                                    onClick={() => navigate(`/dashboard/tours/attractions/${attraction.id}`)}>
                                    <h3>{attraction.attractionName}</h3>
                                    <p>{attraction.attractionCity}</p>
                                </div>
                            ))
                            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </div>}
            </>
        )
    }

    const renderActivities = () => {
        return (
            <>
                {loadingActivities ?
                    <Skeleton active />
                    :
                    <div className='destination__content_collapsed'>
                        {activities?.length > 0
                            ? activities.map((activity, index) => (
                                <div key={index} className='destination__content_collapsed__item'
                                    onClick={() => navigate(`/dashboard/tours/activities/${activity.id}`)}>
                                    <h3>{activity.activityName}</h3>
                                    <p>{activity.activityCity}</p>
                                </div>
                            ))
                            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </div>}
            </>
        )
    }

    const renderEvents = () => {
        return (
            <>
                {loadingEvents ?
                    <Skeleton active />
                    :
                    <div className='destination__content_collapsed'>
                        {events?.length > 0
                            ? events.map((event, index) => (
                                <div key={index} className='destination__content_collapsed__item'
                                    onClick={() => navigate(`/dashboard/tours/events/${event.id}`)}>
                                    <h3>{event.eventName}</h3>
                                    <p>{event.eventCity}</p>
                                </div>
                            ))
                            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </div>}
            </>
        )
    }

    return (
        <div className='destination'>
            {contextHolder}
            <Spin
                spinning={loading}
                size='large'
                tip={loadingText}
            >
                <>
                    <div className='destination__header'>
                        <h1>
                            {destination?.destinationName}
                        </h1>
                        {(myProfile?.role === SUPER_ADMIN || myProfile?.role === ADMIN) &&
                            <div className='destination__header__actions'>
                                <Button type='primary' onClick={() => navigate(`/dashboard/tours/destinations/edit/${destinationId}`)} icon={<EditOutlined />}>
                                    Edit
                                </Button>
                                <Popconfirm
                                    title='Are you sure you want to delete this destination?'
                                    description={<p
                                        style={{
                                            width: '300px',
                                            color: 'red',
                                        }}
                                    >This action cannot be undone! This will permanently delete the destinations, its images, videos, attractions, events, activities, reviews, emergency contacts, shops, and restaurants.</p>}
                                    onConfirm={handleDeleteDestination}
                                    okText='Yes'
                                    cancelText='No'
                                >
                                    <Button danger type='primary' icon={<DeleteOutlined />}>
                                        Delete
                                    </Button>
                                </Popconfirm>
                            </div>
                        }
                    </div>
                    <div className='destination__content_images'>
                        <div className='destination__content__image'>
                            <img src={destination?.thumbnailUrl || DEFAULT_THUMBNAIL} alt='destination' />
                        </div>
                        <div className='destination__content__galery'>
                            <Image.PreviewGroup
                                preview={{
                                    onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                }}
                            >
                                {destination?.destinationImages?.split(',').map((image, index) => (
                                    <Image
                                        key={index}
                                        width={160}
                                        height={100}
                                        src={image}
                                        className='destination__content__galery__image'
                                    />
                                ))}
                            </Image.PreviewGroup>
                        </div>
                    </div>
                    <Divider />
                    <div className='destination__content_videos'>
                        <h2>Videos</h2>
                        <div className='destination__content__videos'>
                            {destination?.destinationVideos?.split(',').map((video, index) => (
                                <video key={index} controls>
                                    <source src={video} type='video/mp4' />
                                </video>
                            ))}
                        </div>
                    </div>

                    <Divider />

                    <div className='destination__content_details'>
                        <h2>
                            Details
                        </h2>
                        <div className='destination__content__details'>
                            <div className='left'>
                                <p><strong>City: </strong>{destination?.destinationCity || 'N/A'}</p>
                                <p><strong>Coordinates: </strong>{destination?.destinationCoordinates || 'N/A'}</p>
                                <p><strong>Weather: </strong>{destination?.weather || 'N/A'}</p>
                                <p><strong>Type: </strong>{destination?.destinationType || 'N/A'}</p>
                            </div>
                            <div className='right'>
                                <p><strong>Recommended Months: </strong>
                                    {destination?.recommendedMonths?.split(',').map((month, index) => (
                                        <Tag key={index}>{month} </Tag>
                                    ))}
                                </p>
                                <p><strong>Tags: </strong>
                                    {destination?.tags?.split(',').map((tag, index) => (
                                        <Tag key={index}>{tag} </Tag>
                                    ))}
                                </p>
                                <p><strong>Ticket: </strong>{destination?.ticketPrice || 'N/A'}</p>
                                <p><strong>Active Status: </strong>
                                    <Tag
                                        color={destination?.activeStatus === ACTIVE ? 'green' : 'red'}
                                    >{destination?.activeStatus} </Tag>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='destination__content_description'>
                        <h2>
                            Descriptions
                        </h2>
                        <h3>
                            Short Description
                        </h3>
                        <ReactQuill
                            value={destination?.destinationDescriptionShort}
                            readOnly={true}
                            theme='bubble'
                        />

                        <h3>
                            Full Description
                        </h3>
                        <ReactQuill
                            value={destination?.destinationDescription}
                            readOnly={true}
                            theme='bubble'
                        />
                    </div>

                    <Divider />

                    <Collapse
                        bordered={false}
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        onChange={() => {
                            if (attractions.length === 0) {
                                handleGetArractions()
                            }
                        }}
                        items={
                            [
                                {
                                    label: 'Attractions',
                                    key: '1',
                                    children: renderAttractions()
                                },
                            ]
                        }
                    />

                    <Collapse
                        bordered={false}
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        onChange={() => {
                            if (activities.length === 0) {
                                handleGetActivities()
                            }
                        }}
                        items={
                            [
                                {
                                    label: 'Activities',
                                    key: '1',
                                    children: renderActivities()
                                },
                            ]
                        }
                    />

                    <Collapse
                        bordered={false}
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        onChange={() => {
                            if (events.length === 0) {
                                handleGetEvents()
                            }
                        }}
                        items={
                            [
                                {
                                    label: 'Events',
                                    key: '1',
                                    children: renderEvents()
                                },
                            ]
                        }
                    />

                </>
            </Spin>
        </div>
    )
}

export default Destination