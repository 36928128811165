import { fetchAllDestinations, fetchDestinationNames } from '../../api/destinations.api';

import {
    FETCH_DESTINATIONS,
    FETCH_DESTINATIONS_SUCCESS,
    FETCH_DESTINATIONS_FAIL,

    FETCH_DESTINATION_NAMES,
    FETCH_DESTINATION_NAMES_SUCCESS,
    FETCH_DESTINATION_NAMES_FAIL
} from '../../types';

export const fetchDestinationsAC = (values) => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_DESTINATIONS
        })
        try {

            const destinationsResponse = await fetchAllDestinations(values)

            dispatch({
                type: FETCH_DESTINATIONS_SUCCESS,
                payload: destinationsResponse.data
            })
        } catch (error) {
            dispatch({
                type: FETCH_DESTINATIONS_FAIL,
                payload: error.message
            })
        }
    }
}

export const fetchDestinationNamesAC = () => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_DESTINATION_NAMES
        })
        try {
            const response = await fetchDestinationNames()
            dispatch({
                type: FETCH_DESTINATION_NAMES_SUCCESS,
                payload: response.data
            })
        } catch (error) {
            dispatch({
                type: FETCH_DESTINATION_NAMES_FAIL,
                payload: error.message
            })
        }
    }
}