import React, { useEffect, useState } from 'react'
import { getAllPayments } from '../../../../redux/api/payments.api'
import PaymentsTable from './PaymentsTable'

const Payments = () => {
    const [payments, setPayments] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        handleGetPayments()
    }, [])

    const handleGetPayments = async () => {
        setLoading(true)
        const response = await getAllPayments()
        if (response.status === 200) {
            setPayments(response.data)
        }
        setLoading(false)
    }

    return (
        <div className='payments'>
            <h1>Payments</h1>
            <PaymentsTable payments={payments} loading={loading} />
        </div>
    )
}

export default Payments