import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Drawer, Form, Image, Input, Row, Select, Spin, Upload, notification } from 'antd'
import { hotelRoomTypes } from '../../../../../../Constants/itemTypes';
import { ACCOMMODATION_CONTAINER, handleDeleteBlob, handleImageUpload } from '../../../../../../azure/blob';
import { editAccommodationRooms } from '../../../../../../../redux/api/hotels.api';
import ReactQuill from 'react-quill';

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const roomOptions = Object.keys(hotelRoomTypes).map(key => {
    return {
        label: getOptionName(hotelRoomTypes[key]),
        value: key
    };
});

const quillModules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
        ["formula"],
        [{ size: ["small", false, "large", "huge"] }],
    ],
    clipboard: {
        matchVisual: false
    },
    imageResize: {
        modules: ['Resize', 'DisplaySize']
    }
};

const EditRoom = ({
    editRoomVisible,
    setEditRoomVisible,
    hotel,
    handleGetRooms,
    selectedEditRoom,
    setSelectedEditRoom
}) => {
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('Adding room...');
    const [api, contextHolder] = notification.useNotification();
    const formRef = useRef();
    const [ourPrice, setOurPrice] = useState(0);
    const [margin, setMargin] = useState(0);

    useEffect(() => {
        if (selectedEditRoom && formRef.current) {

            formRef.current.setFieldsValue({
                roomType: selectedEditRoom.roomType,
                roomName: selectedEditRoom.roomName,
                roomDescription: selectedEditRoom.roomDescription,
                roomCapacity: selectedEditRoom.roomCapacity,
                numOfRooms: selectedEditRoom.numOfRooms,
                ourPrice: selectedEditRoom.ourPrice,
                margin: selectedEditRoom.margin,
            });

            if (selectedEditRoom.roomImages) {
                const images = selectedEditRoom.roomImages.split(',');
                const fileList = images.map((image, index) => {
                    return {
                        uid: index,
                        name: image.split('/').pop(),
                        status: 'done',
                        url: image
                    }
                });
                setFileList(fileList);
            }
        }
    }, [selectedEditRoom]);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const onFinish = async (values) => {
        setIsLoading(true);
        try {
            Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

            let imageUrls = [];

            const name = hotel.accommodationName.replace(/\s/g, '_').toLowerCase() + '_' + values.roomName.replace(/\s/g, '_').toLowerCase();

            // find new images and list images need to be deleted
            const newImages = fileList.filter(file => !file.url);
            const deletedImages = selectedEditRoom.roomImages?.split(',').filter(image => !fileList.find(file => file.url === image));

            if (newImages?.length > 0) {
                setLoadingText('Uploading Images');
                const imageUploadPromises = newImages.map(async (file, index) => {
                    setLoadingText(`Uploading image ${index + 1}`);
                    return handleImageUpload(
                        ACCOMMODATION_CONTAINER,
                        file.originFileObj,
                        file.size,
                        `${name}_${file.name}`
                    ).then((response) => {
                        imageUrls.push(response.blockBlobClient.url);
                    });
                });
                await Promise.all(imageUploadPromises);
            }

            // delete blobs
            if (deletedImages?.length > 0) {
                setLoadingText('Deleting Images');
                const imageDeletePromises = deletedImages.map(async (image, index) => {
                    const blobName = getBlobName(image);
                    setLoadingText(`Deleting image ${index + 1}`);
                    return handleDeleteBlob(ACCOMMODATION_CONTAINER, blobName);
                });
                await Promise.all(imageDeletePromises);
            }

            let updatedImages = [
                ...fileList.filter((file) => file.url).map((file) => file.url),
                ...imageUrls
            ];

            // remove unwanted parts from urls
            updatedImages.forEach((url, index) => {
                updatedImages[index] = sanitizeBlobUrls(url);
            });

            const data = {
                id: selectedEditRoom.id,
                roomType: values.roomType,
                roomName: values.roomName,
                roomDescription: values.roomDescription,
                ourPrice: values.ourPrice,
                margin: values.margin,
                roomCapacity: values.roomCapacity,
                numOfRooms: values.numOfRooms,
                roomImages: updatedImages?.length > 0 ? updatedImages.join(',') : null,
            }

            setLoadingText('Updating DB...');
            const response = await editAccommodationRooms(data);
            if (response.status === 200) {
                api.success({
                    message: 'Success',
                    description: 'Room updated successfully'
                });
                handleGetRooms();
                setSelectedEditRoom(null);
                setTimeout(() => {
                    setEditRoomVisible(false);
                }, 2000);

            } else {
                api.error({
                    message: 'Error',
                    description: 'An error occurred while adding the room. Please try again.'
                });
            }

        } catch (error) {
            console.log(error);
            api.error({
                message: 'Error',
                description: `An error occurred while adding the room. Please try again. ${error.message}`
            });
        }
        setIsLoading(false);
    }

    const getBlobName = (blobUrl) => {
        const splitUrl = blobUrl.split('/')
        return splitUrl[splitUrl.length - 1]
    }

    const sanitizeBlobUrls = (blobUrl) => {
        const splitUrl = blobUrl.split('?')
        return splitUrl[0]
    }

    const calculateDisplayPrice = (price, margin) => {
        price = parseFloat(price);
        margin = parseFloat(margin);

        return (price * (margin / 100)) + price;
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title={`Edit Room - ${selectedEditRoom?.roomName}`}
                width={720}
                open={editRoomVisible}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                onClose={() => setEditRoomVisible(false)}
            >
                <Spin spinning={isLoading} tip={loadingText}>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        ref={formRef}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="roomType"
                                    label="Room Type"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a room type',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select a room type"
                                        options={roomOptions}
                                        maxCount={1}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="roomName"
                                    label="Room Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter room name',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter room name" showCount maxLength={50} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="roomDescription"
                                    label="Room Description"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Add a description for the room',
                                        },
                                    ]}
                                >
                                    <ReactQuill
                                        theme="snow"
                                        limits={{ maxLength: 500 }}
                                        modules={quillModules}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="ourPrice"
                                    label="Room Price (USD)"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the room price',
                                        },
                                    ]}
                                    onChange={(e) => setOurPrice(e.target.value)}
                                >
                                    <Input placeholder="Please enter the room price (in USD)" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="margin"
                                    label="Price Margin (%)"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the price margin',
                                        },
                                    ]}
                                    onChange={(e) => setMargin(e.target.value)}
                                >
                                    <Input placeholder="Please enter the room price margin" type='number' max={100} />
                                </Form.Item>
                                {(ourPrice > 0 && margin > 0) &&
                                    <p
                                        style={{
                                            color: 'green',
                                        }}
                                    >
                                        Display Price: {calculateDisplayPrice(ourPrice, margin)}
                                    </p>}
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="roomCapacity"
                                    label="Room Capacity"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the room capacity',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter the room capacity" type='number' max={20} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="numOfRooms"
                                    label="Available Number of Rooms"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the number of rooms available',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter the number of rooms available" type='number' max={20} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label='Upload Images (Max 10)'
                                    name='roomImages'
                                >
                                    <Upload
                                        listType="picture-card"
                                        fileList={fileList}
                                        onPreview={handlePreview}
                                        onChange={handleChange}
                                        multiple={true}
                                        maxCount={10}
                                        beforeUpload={(file) => {
                                            return false;
                                        }}
                                    >
                                        {fileList.length <= 10 && '+ Upload'}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item>
                            <Button type='primary' htmlType='submit' loading={isLoading}>
                                Update Room
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Drawer>


            {previewImage && (
                <Image
                    wrapperStyle={{
                        display: 'none',
                    }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </div>
    )
}

export default EditRoom