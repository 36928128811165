import {
    FETCH_ACTIVITIES,
    FETCH_ACTIVITIES_SUCCESS,
    FETCH_ACTIVITIES_FAIL,

    FETCH_ACTIVITY_NAMES,
    FETCH_ACTIVITY_NAMES_SUCCESS,
    FETCH_ACTIVITY_NAMES_FAIL
} from '../../types';

const initialState = {
    loading: false,
    activities: [],
    activityNames: [],
    error: null
};

export const activitiesReducer = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case FETCH_ACTIVITIES:
            return {
                ...state,
                loading: true
            };
        case FETCH_ACTIVITIES_SUCCESS:
            return {
                ...state,
                loading: false,
                activities: action.payload,
                error: null
            };
        case FETCH_ACTIVITIES_FAIL:
            return {
                ...state,
                loading: false,
                activities: [],
                error: action.payload
            };
        case FETCH_ACTIVITY_NAMES:
            return {
                ...state,
                loading: true
            };
        case FETCH_ACTIVITY_NAMES_SUCCESS:
            return {
                ...state,
                loading: false,
                activityNames: action.payload,
                error: null
            };
        case FETCH_ACTIVITY_NAMES_FAIL:
            return {
                ...state,
                loading: false,
                activityNames: [],
                error: action.payload
            };

        default:
            return state;
    }
}