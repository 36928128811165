import React, { useEffect } from 'react'
import useMyProfile from '../../../../hooks/useMyProfile'

import { Badge, Modal, Dropdown } from 'antd'
import {
    MessageOutlined,
    SettingOutlined,
    QuestionCircleOutlined,
    LogoutOutlined,
    ExclamationCircleFilled
} from '@ant-design/icons'

import './styles.scss'
import { useMsal } from '@azure/msal-react'

import { DEFAULT_PROFILE_IMAGE } from '../../../../Constants/otherConstsnts'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { fetchNewNotificationCountAC } from '../../../../../redux/actions/notifications/notifications.ac'
import useNotifications from '../../../../hooks/useNotifications'

const { confirm } = Modal;

const DashboardHeader = () => {
    const myProfile = useMyProfile().userProfile
    const { instance } = useMsal();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const newNotificationsCount = useNotifications()?.newNotificationsCount;

    useEffect(() => {
        if (myProfile?.id) {
            handleGetNewNotificationsCount();
        }
    }, [myProfile]);


    const handleGetNewNotificationsCount = async () => {
        dispatch(fetchNewNotificationCountAC(myProfile?.id));
    }

    const handleLogout = async () => {
        instance.handleRedirectPromise()
            .then(() => {
                instance.logout();
            });
    };

    const showDLogoutConfirm = () => {
        confirm({
            title: 'Do you want to logout?',
            icon: <ExclamationCircleFilled />,
            content: 'You will be logged out from the system.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                await handleLogout();
            },
        });
    };

    const items = [
        {
            key: '1',
            label: (
                <a target="_blank" rel="noopener noreferrer" className='my-profile' onClick={() => navigate('/dashboard/myprofile')}>
                    <img src={myProfile?.profilePicUrl || DEFAULT_PROFILE_IMAGE} alt='profile' /> {myProfile?.userName}
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a target="_blank" rel="noopener noreferrer" className='menu-item' onClick={() => navigate(`/dashboard/users/edit/${myProfile.id}?settings`)}>
                    <SettingOutlined /> Settings
                </a>
            ),
        },
        {
            key: '3',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com" className='menu-item'>
                    <QuestionCircleOutlined /> Help & Support
                </a>
            ),
        },
        {
            key: '4',
            label: (
                <div className='menu-item logout' onClick={showDLogoutConfirm}>
                    <LogoutOutlined /> Logout
                </div>
            ),
        },
    ];

    return (
        <div className='dashboard-header'>
            <div
                className='messages-button'
                onClick={() => navigate('/dashboard/messages')}
            >
                <Badge count={newNotificationsCount} >
                    <MessageOutlined />
                </Badge>
            </div>
            <Dropdown
                menu={{ items, }}
                className='profile-dropdown'
                overlayClassName='profile-dropdown'
                trigger={['click']}
            >
                <div className='profile-button'>
                    <img src={myProfile?.profilePicUrl || DEFAULT_PROFILE_IMAGE} alt='profile' />
                </div>
            </Dropdown>
        </div>
    )
}

export default DashboardHeader