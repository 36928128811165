import { ExportOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Collapse, Empty, Form, Input, List, Select, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useGuides from '../../../../hooks/useGuides';
import { fetchGuidesAC } from '../../../../../redux/actions/guides/guides.ac';
import { tourGuideTypes } from '../../../../Constants/itemTypes';
import { ACTIVE, ONUSE } from '../../../../Constants/activeStatusType';
import { DEFAULT_PROFILE_IMAGE } from '../../../../Constants/otherConstsnts';
import InviteGuide from './InviteGuide';

const getOptionName = (value) => {
    return value
        .toLowerCase()
        .replace(/_./g, (match) => ' ' + match.charAt(1).toUpperCase())
        .replace(/^\w/, (c) => c.toUpperCase());
};

const guideTypeOptions = Object.keys(tourGuideTypes).map(key => {
    return {
        label: getOptionName(tourGuideTypes[key]),
        value: key
    };
});

const TourGuides = () => {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1)
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [filterValues, setFilterValues] = useState({})
    const guides = useGuides().guides
    const [isInviteVisible, setIsInviteVisible] = useState(false)

    useEffect(() => {
        handleFetchGuides(1, {})
    }, [])

    const handleFetchGuides = async (page, values) => {
        setLoading(true)
        values.page = page
        await dispatch(fetchGuidesAC(values))
        setLoading(false)
    }

    const onFilterSubmit = async (values) => {
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);
        setFilterValues(values)
        await handleFetchGuides(1, values)
    }

    return (
        <div className='accommodations'>
            <div className='accommodations__header'>
                <div className='left'>
                    <h1>
                        Tour Guides
                    </h1>
                </div>
                <div className='destinations__header__actions'>
                    <Button type='primary' icon={<PlusOutlined />}
                        onClick={() => setIsInviteVisible(true)}
                    >
                        Add Tour Guide
                    </Button>
                </div>
            </div>

            <div className='filters'>
                <Collapse
                    items={[
                        {
                            key: '1',
                            label: 'Filters',
                            children:
                                <Form
                                    layout='inline'
                                    onFinish={onFilterSubmit}
                                >
                                    <Form.Item
                                        name='guideType'
                                    >
                                        <Select
                                            showSearchsa
                                            mode='single'
                                            allowClear
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Vehicle Type"
                                            options={guideTypeOptions}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name='activeStatus'
                                    >
                                        <Select
                                            showSearchsa
                                            mode='single'
                                            allowClear
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Guide Status"
                                            options={[
                                                {
                                                    label: 'Active',
                                                    value: ACTIVE
                                                },
                                                {
                                                    label: 'On Use',
                                                    value: ONUSE
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name='guideArea'
                                    >
                                        <Input placeholder='Guide Area' style={{ width: '150px' }} />
                                    </Form.Item>
                                    <Form.Item
                                        name='vehicleAvailable'
                                    >
                                        <Select
                                            showSearchsa
                                            mode='single'
                                            allowClear
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Vehicle Available"
                                            options={[
                                                {
                                                    label: 'Yes',
                                                    value: 'true'
                                                },
                                                {
                                                    label: 'No',
                                                    value: 'false'
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type='primary' htmlType='submit' loading={loading}>
                                            Get Results
                                        </Button>
                                    </Form.Item>
                                </Form>
                        },
                    ]}
                />
            </div>

            <div className='destinations__list'>
                {guides && guides.length > 0 ?
                    <List
                        loading={loading}
                        pagination={{
                            position: 'bottom',
                            align: 'end',
                            pageSize: 5,
                            showSizeChanger: false,
                            total: guides[0]?.filteredTourGuidesCount,
                            current: page,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                            onChange: (page) => { setPage(page); handleFetchGuides(page, filterValues) }
                        }}
                        dataSource={guides}
                        renderItem={(item, index) => (
                            <List.Item key={index} className='destination__item'>
                                <div className='right'
                                    style={{
                                        paddingLeft: '20px',
                                    }}
                                >
                                    <div className='destination__item__header'>
                                        <h3>{item?.guideName}</h3>
                                        <Button type='primary' onClick={() => navigate(`/dashboard/partners/tour-guides/${item.id}`)} icon={<ExportOutlined />}>
                                            View
                                        </Button>
                                    </div>
                                    <div className='destination__item__content'
                                        style={{
                                            display: 'flex',
                                            gap: '20px',
                                        }}
                                    >
                                        <img src={item?.profilePicUrl || DEFAULT_PROFILE_IMAGE} alt={item?.guideName} style={{
                                            width: '100px',
                                            height: '100px',
                                            borderRadius: '50%',
                                        }} />
                                        <div
                                            style={{
                                                display: 'flex',
                                                gap: '20px',
                                            }}
                                        >
                                            <div>
                                                <p><strong>Guide Type: </strong>{item?.guideType}</p>
                                                <p><strong>Vehicle Type: </strong>{item?.vehicleType || 'N/A'}</p>
                                                <p><strong>User Name: </strong>{item?.userName || 'N/A'}</p>
                                            </div>
                                            <div>
                                                <p><strong>Capacity: </strong>{item?.vehicleCapacity || 'N/A'}</p>
                                                <p><strong>Status: </strong>{item?.activeStatus || 'N/A'}</p>
                                                <p><strong>Contact: </strong>{item?.contactNumber || 'N/A'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </List.Item>
                        )}
                    />
                    :
                    <>
                        {
                            loading ?
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '500px'
                                }}>
                                    <Spin size='large' />
                                </div>
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </>
                }
            </div>

            <InviteGuide
                isVisble={isInviteVisible}
                setIsVisible={setIsInviteVisible}
                handleFetchGuides={handleFetchGuides}
            />
        </div>
    )
}

export default TourGuides