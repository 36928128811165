import React, { useEffect, useState } from 'react'
import { getCustomTourById } from '../../../../../../redux/api/bookings.api';
import { notification, Tabs } from 'antd';
import BookingDetails from './BookingDetails';

import './styles.scss'
import AccommodationsBooking from './BookingAccommodations';
import BookingPayments from './BookingPayments';
import UserTourItinery from './UserTourItinery';
import CustomTourChat from './CustomTourChat';
import TravelerDetails from './TravelerDetails';

const CustomTourBooking = () => {
    const url = window.location.pathname;
    const bookingId = url.split("/").pop();
    const [userTour, setUserTour] = useState(null);
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = notification.useNotification();

    useEffect(() => {
        handlegetUserTour();
    }, []);

    const handlegetUserTour = async () => {
        setLoading(true);
        const response = await getCustomTourById(bookingId);
        if (response.status === 200) {
            setUserTour(response.data);
        }
        setLoading(false);
    }

    const items = [
        {
            key: '1',
            label: 'Booking Details',
            children: <BookingDetails
                userTour={userTour}
                handlegetUserTour={handlegetUserTour}
                messageApi={messageApi}
            />
        },
        {
            key: '2',
            label: "Travelers' Details",
            children: <TravelerDetails
                userTour={userTour}
                handlegetUserTour={handlegetUserTour}
                messageApi={messageApi}
            />
        },
        {
            key: '3',
            label: 'Tour Accommodation',
            children: <AccommodationsBooking userTour={userTour} />
        },
        {
            key: '4',
            label: 'Payment Details',
            children: <BookingPayments userTour={userTour} />
        },
        {
            key: '5',
            label: 'Itinerary',
            children: <UserTourItinery userTour={userTour} messageApi={messageApi} handlegetUserTour={handlegetUserTour} />
        },
        {
            key: '6',
            label: 'Messages',
            children: <CustomTourChat userTour={userTour} messageApi={messageApi} />
        }
    ]

    return (
        <div className='booking_body'>
            {contextHolder}
            {loading ? <h1>Loading...</h1> : userTour ? (
                <div>
                    <Tabs
                        defaultActiveKey="1"
                        items={items}
                    />
                </div>
            ) : <div>No data found</div>}
        </div>
    )
}

export default CustomTourBooking