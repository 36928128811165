import { ACTIVITES_ROUTE, ACTIVITIES_ADD_ROUTE, ACTIVITY_EDIT_ROUTE, ACTIVITY_ROUTE, ALL_PARTNERS_ROUTE, ATTRACTTION_ROUTE, ATTRACTTIONS_ADD_ROUTE, ATTRACTTIONS_EDIT_ROUTE, ATTRACTTIONS_ROUTE, BLOG_ROUTE, BLOGS_ADD_ROUTE, BLOGS_EDIT_ROUTE, BLOGS_ROUTE, CUSTOM_EVENT_BOOKING_ROUTE, CUSTOM_EVENTS_BOOKINGS_ROUTE, CUSTOM_TOUR_BOOKING_ROUTE, CUSTOM_TOUR_BOOKINGS_ROUTE, DESTINATION_ROUTE, DESTINATIONS_ADD_ROUTE, DESTINATIONS_EDIT_ROUTE, DESTINATIONS_ROUTE, DRIVERS_ROUTE, EARNINGS_ROUTE, EVENT_EDIT_ROUTE, EVENT_ROUTE, EVENTS_ADD_ROUTE, EVENTS_ROUTE, FLIGHT_BOOKINGS_ROUTE, GUIDE_MY_PROFILE_ROUTE, GUIDE_SCHEDULE_ROUTE, HOME_ROUTE, HOTEL_BOOKINGS_ROUTE, HOTEL_ROUTE, HOTELS_ADD_ROUTE, HOTELS_EDIT_ROUTE, HOTELS_ROUTE, INBOX_ROUTE, MY_PROFILE_ROUTE, PARTNER_ROUTE, PAYMENT_ROUTE, PAYMENTS_ROUTE, RESTURENTS_ROUTE, REVIEW_ROUTE, REVIEWS_ROUTE, SHOPS_ROUTE, SUPPORT_ROUTE, SUPPORT_TICKET_ROUTE, TOUR_GUIDE_ROUTE, TOUR_GUIDES_ROUTE, TOUR_PACKAGE_BOOKING_ROUTE, TOUR_PACKAGE_BOOKINGS_ROUTE, TOUR_PACKAGE_EDIT_ROUTE, TOUR_PACKAGE_ROUTE, TOUR_PACKAGES_ADD_ROUTE, TOUR_PACKAGES_ROUTES, TRANSPORT_ROUTE, TRANSPORTS_ADD_ROUTE, TRANSPORTS_EDIT_ROUTE, TRANSPORTS_ROUTE, USER_EDIT_ROUTE, USER_ROUTE, USERS_ROUTE, USERS_SUB_ROUTE } from "./routes";


export const SUPER_ADMIN_ROUTES = [
    HOME_ROUTE,
    USERS_ROUTE, USERS_SUB_ROUTE, USER_EDIT_ROUTE, MY_PROFILE_ROUTE, USER_ROUTE,

    FLIGHT_BOOKINGS_ROUTE, HOTEL_BOOKINGS_ROUTE,

    TOUR_PACKAGE_BOOKINGS_ROUTE, TOUR_PACKAGE_BOOKING_ROUTE,
    CUSTOM_TOUR_BOOKINGS_ROUTE, CUSTOM_TOUR_BOOKING_ROUTE,
    CUSTOM_EVENTS_BOOKINGS_ROUTE, CUSTOM_EVENT_BOOKING_ROUTE,

    ALL_PARTNERS_ROUTE, PARTNER_ROUTE,

    DRIVERS_ROUTE,

    HOTELS_ROUTE, HOTELS_ADD_ROUTE, HOTEL_ROUTE, HOTELS_EDIT_ROUTE,

    RESTURENTS_ROUTE,

    SHOPS_ROUTE,

    TOUR_GUIDES_ROUTE, TOUR_GUIDE_ROUTE,

    TRANSPORTS_ROUTE, TRANSPORTS_ADD_ROUTE, TRANSPORT_ROUTE, TRANSPORTS_EDIT_ROUTE,

    ACTIVITES_ROUTE, ACTIVITIES_ADD_ROUTE, ACTIVITY_ROUTE, ACTIVITY_EDIT_ROUTE,

    ATTRACTTIONS_ROUTE, ATTRACTTIONS_ADD_ROUTE, ATTRACTTIONS_EDIT_ROUTE, ATTRACTTION_ROUTE,

    EVENTS_ROUTE, EVENTS_ADD_ROUTE, EVENT_ROUTE, EVENT_EDIT_ROUTE,

    DESTINATIONS_ROUTE, DESTINATION_ROUTE, DESTINATIONS_ADD_ROUTE, DESTINATIONS_EDIT_ROUTE,

    TOUR_PACKAGES_ROUTES, TOUR_PACKAGES_ADD_ROUTE, TOUR_PACKAGE_ROUTE, TOUR_PACKAGE_EDIT_ROUTE,

    BLOGS_ROUTE, BLOG_ROUTE, BLOGS_ADD_ROUTE, BLOGS_EDIT_ROUTE,

    SUPPORT_ROUTE, SUPPORT_TICKET_ROUTE,

    REVIEWS_ROUTE, REVIEW_ROUTE,

    PAYMENTS_ROUTE, PAYMENT_ROUTE,

    EARNINGS_ROUTE,

    INBOX_ROUTE,
];

export const ADMIN_ROUTES = [
    HOME_ROUTE, USERS_ROUTE, USERS_SUB_ROUTE, USER_ROUTE, MY_PROFILE_ROUTE, FLIGHT_BOOKINGS_ROUTE, HOTEL_BOOKINGS_ROUTE, TOUR_PACKAGE_BOOKINGS_ROUTE, ALL_PARTNERS_ROUTE,
    DRIVERS_ROUTE, HOTELS_ROUTE, RESTURENTS_ROUTE, SHOPS_ROUTE, TOUR_GUIDES_ROUTE, TRANSPORTS_ROUTE, ACTIVITES_ROUTE, ACTIVITIES_ADD_ROUTE, ACTIVITY_ROUTE, ACTIVITY_EDIT_ROUTE, ATTRACTTIONS_ROUTE, ATTRACTTIONS_ADD_ROUTE, ATTRACTTIONS_EDIT_ROUTE, ATTRACTTION_ROUTE, EVENTS_ROUTE, EVENT_ROUTE, EVENT_EDIT_ROUTE, EVENTS_ADD_ROUTE, DESTINATIONS_ROUTE, DESTINATION_ROUTE, DESTINATIONS_ADD_ROUTE, DESTINATIONS_EDIT_ROUTE, TOUR_PACKAGES_ROUTES, BLOGS_ROUTE, SUPPORT_ROUTE, INBOX_ROUTE];

export const MANAGER_ROUTES = [
    HOME_ROUTE, USERS_ROUTE, USERS_SUB_ROUTE, USER_ROUTE, MY_PROFILE_ROUTE, FLIGHT_BOOKINGS_ROUTE, HOTEL_BOOKINGS_ROUTE, TOUR_PACKAGE_BOOKINGS_ROUTE, ALL_PARTNERS_ROUTE,
    DRIVERS_ROUTE, HOTELS_ROUTE, RESTURENTS_ROUTE, SHOPS_ROUTE, TOUR_GUIDES_ROUTE, TRANSPORTS_ROUTE, ACTIVITES_ROUTE, ACTIVITIES_ADD_ROUTE, ACTIVITY_ROUTE, ACTIVITY_EDIT_ROUTE, ATTRACTTIONS_ROUTE, ATTRACTTIONS_ADD_ROUTE, ATTRACTTIONS_EDIT_ROUTE, ATTRACTTION_ROUTE, EVENTS_ROUTE, EVENT_ROUTE, EVENT_EDIT_ROUTE, EVENTS_ADD_ROUTE, DESTINATIONS_ROUTE, DESTINATION_ROUTE, DESTINATIONS_EDIT_ROUTE, DESTINATIONS_ADD_ROUTE, TOUR_PACKAGES_ROUTES, BLOGS_ROUTE, SUPPORT_ROUTE, INBOX_ROUTE];

export const PARTNERSHIP_MANAGER_ROUTES = [
    HOME_ROUTE, USERS_ROUTE, USERS_SUB_ROUTE, USER_ROUTE, MY_PROFILE_ROUTE, FLIGHT_BOOKINGS_ROUTE, HOTEL_BOOKINGS_ROUTE, TOUR_PACKAGE_BOOKINGS_ROUTE, ALL_PARTNERS_ROUTE,
    DRIVERS_ROUTE, HOTELS_ROUTE, RESTURENTS_ROUTE, SHOPS_ROUTE, TOUR_GUIDES_ROUTE, TRANSPORTS_ROUTE, SUPPORT_ROUTE, INBOX_ROUTE];

export const CUSTOMER_RELATIONS_MANAGER_ROUTES = [
    HOME_ROUTE, USERS_ROUTE, USERS_SUB_ROUTE, USER_ROUTE, MY_PROFILE_ROUTE, FLIGHT_BOOKINGS_ROUTE, HOTEL_BOOKINGS_ROUTE, TOUR_PACKAGE_BOOKINGS_ROUTE, ALL_PARTNERS_ROUTE,
    DRIVERS_ROUTE, HOTELS_ROUTE, RESTURENTS_ROUTE, SHOPS_ROUTE, TOUR_GUIDES_ROUTE, TRANSPORTS_ROUTE, ACTIVITES_ROUTE, ACTIVITIES_ADD_ROUTE, ACTIVITY_ROUTE, ACTIVITY_EDIT_ROUTE, ATTRACTTIONS_ROUTE, ATTRACTTIONS_ADD_ROUTE, ATTRACTTIONS_EDIT_ROUTE, ATTRACTTION_ROUTE, EVENTS_ROUTE, EVENT_ROUTE, EVENT_EDIT_ROUTE, EVENTS_ADD_ROUTE, DESTINATIONS_ROUTE, DESTINATION_ROUTE, TOUR_PACKAGES_ROUTES, BLOGS_ROUTE, SUPPORT_ROUTE, INBOX_ROUTE];

export const SUPPORT_AGENT_ROUTES = [
    HOME_ROUTE, USERS_ROUTE, USERS_SUB_ROUTE, USER_ROUTE, MY_PROFILE_ROUTE, FLIGHT_BOOKINGS_ROUTE, HOTEL_BOOKINGS_ROUTE, TOUR_PACKAGE_BOOKINGS_ROUTE, ALL_PARTNERS_ROUTE,
    DRIVERS_ROUTE, HOTELS_ROUTE, RESTURENTS_ROUTE, SHOPS_ROUTE, BLOGS_ROUTE, SUPPORT_ROUTE, INBOX_ROUTE];

export const HOTEL_MANAGER_ROUTES = [
    HOME_ROUTE, USERS_ROUTE, USERS_SUB_ROUTE, USER_ROUTE, MY_PROFILE_ROUTE, FLIGHT_BOOKINGS_ROUTE, HOTEL_BOOKINGS_ROUTE, TOUR_PACKAGE_BOOKINGS_ROUTE, ALL_PARTNERS_ROUTE,
    DRIVERS_ROUTE, HOTELS_ROUTE, RESTURENTS_ROUTE, SHOPS_ROUTE, SUPPORT_ROUTE, INBOX_ROUTE];


export const TOUR_GUIDE_ROUTES = [
    HOME_ROUTE, USERS_SUB_ROUTE, MY_PROFILE_ROUTE, USER_EDIT_ROUTE,

    FLIGHT_BOOKINGS_ROUTE, HOTEL_BOOKINGS_ROUTE, TOUR_PACKAGE_BOOKINGS_ROUTE, ALL_PARTNERS_ROUTE,

    DRIVERS_ROUTE, HOTELS_ROUTE, RESTURENTS_ROUTE, SHOPS_ROUTE, TOUR_GUIDES_ROUTE, SUPPORT_ROUTE,

    GUIDE_MY_PROFILE_ROUTE, GUIDE_SCHEDULE_ROUTE,

    ACTIVITES_ROUTE, ACTIVITY_ROUTE,

    ATTRACTTIONS_ROUTE, ATTRACTTION_ROUTE,

    EVENTS_ROUTE, EVENT_ROUTE,

    DESTINATIONS_ROUTE, DESTINATION_ROUTE,

    TOUR_PACKAGES_ROUTES, TOUR_PACKAGES_ADD_ROUTE, TOUR_PACKAGE_ROUTE, TOUR_PACKAGE_EDIT_ROUTE,

    INBOX_ROUTE,
];