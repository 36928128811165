import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Drawer, Form, Input, Row, Spin, notification } from 'antd'
import { addTourPackageInclude, editTourPackageInclude } from '../../../../../../../redux/api/tourPackages.api';
import ReactQuill, { Quill } from 'react-quill';

const quillModules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
        ["formula"],
        [{ size: ["small", false, "large", "huge"] }],
    ],
    clipboard: {
        matchVisual: false
    },
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
};

const EditPackageInclude = ({
    isVisible,
    setIsVisible,
    selectedPackageIncludes,
    handleGetPackageIncludes
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [discriptionWordCount, setDiscriptionWordCount] = useState(0);
    const formRef = useRef();

    useEffect(() => {
        if (selectedPackageIncludes) {
            formRef?.current?.setFieldsValue({
                includesType: selectedPackageIncludes.includesType,
                includesName: selectedPackageIncludes.includesName,
                includesDescription: selectedPackageIncludes.includesDescription
            });
        }

    }, [selectedPackageIncludes, isVisible]);

    const onFinish = async (values) => {
        setIsLoading(true);
        try {
            Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '' || values[key] === null) && delete values[key]);

            values.id = selectedPackageIncludes.id;

            const res = await editTourPackageInclude(values);

            if (res.status === 200) {
                api.success({
                    message: 'Success',
                    description: 'Package include updated successfully'
                });
                setIsVisible(false);
                handleGetPackageIncludes();
            } else {
                api.error({
                    message: 'Error',
                    description: 'An error occurred while updating the package include. Please try again.'
                });
            }

        } catch (error) {
            console.log(error);
            api.error({
                message: 'Error',
                description: `An error occurred while updating the package include. Please try again. ${error.message}`
            });
        }
        setIsLoading(false);
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Edit Tour Accommodation"
                width={720}
                open={isVisible}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                onClose={() => setIsVisible(false)}
            >
                <Spin spinning={isLoading} tip='Please Wait...'>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        ref={formRef}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="includesType"
                                    label="Tour Package Includes Type"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the tour package includes type',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter the tour package includes type" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="includesName"
                                    label="Tour Package Includes Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the tour package includes name',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter the tour package includes name" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label={`Tour PackageInclude Description ( ${discriptionWordCount}/500 characters)`}
                                    name='includesDescription'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the tour package includes description',
                                        }
                                    ]}
                                >
                                    <ReactQuill
                                        theme="snow"
                                        limits={{ maxLength: 500 }}
                                        onChange={(content, delta, source, editor) => {
                                            setDiscriptionWordCount(editor.getLength())
                                        }}
                                        modules={quillModules}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item>
                            <Button type='primary' htmlType='submit' loading={isLoading}>
                                Update Accommodation
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default EditPackageInclude