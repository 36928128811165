import {
    FETCH_DESTINATIONS,
    FETCH_DESTINATIONS_SUCCESS,
    FETCH_DESTINATIONS_FAIL,

    FETCH_DESTINATION_NAMES,
    FETCH_DESTINATION_NAMES_SUCCESS,
    FETCH_DESTINATION_NAMES_FAIL
} from '../../types';

const initialState = {
    loading: false,
    destinations: [],
    destinationNames: [],
    error: null
};

export const destinationsReducer = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case FETCH_DESTINATIONS:
            return {
                ...state,
                loading: true
            };
        case FETCH_DESTINATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                destinations: action.payload,
                error: null
            };
        case FETCH_DESTINATIONS_FAIL:
            return {
                ...state,
                loading: false,
                destinations: [],
                error: action.payload
            };

        case FETCH_DESTINATION_NAMES:
            return {
                ...state,
                loading: true
            };
        case FETCH_DESTINATION_NAMES_SUCCESS:
            return {
                ...state,
                loading: false,
                destinationNames: action.payload,
                error: null
            };
        case FETCH_DESTINATION_NAMES_FAIL:
            return {
                ...state,
                loading: false,
                destinationNames: [],
                error: action.payload
            };

        default:
            return state;
    }
};